import React, { useState } from "react";
import { Grow, Snackbar } from "@mui/material";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from "react-virtualized";
import { cx } from "@emotion/css";
import SimpleSORowSKU from "./SimpleSORowSKU";
import ViewerSKU from "../ViewerSKU";
import { SimpleSKUTableStyled } from "./styles";
import RowEmptySimple from "../../Tables/RowEmptySimple";
import { itemTableRowHeight } from "../../../helpers/orderDashboard";
import LoadingBackdrop from "../../WholeScreenFocusBackdrop/LoadingBackdrop";
import { getSettingsTableHeader } from "../../CompanyTabs/settingsHelper";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import {
  PREFIX_ITEM_TABLE,
  generalPermissions,
} from "../../../helpers/constants";
import SimpleShipmentRowSKU from "./SimpleShipmentRowSKU";

function SKUTableSimple({
  prefix,
  actualRows,
  entity,
  onChangeData,
  isCopyTable,
  isLoading,
  modalShowImage,
  imagesTableStorage,
  isModal,
  modalFilterText,
  purchaseOrders,
  tableHeader,
  openSnackBar,
  handleSnackBar,
  handleSorting,
  sorting,
  classTable = "",
}) {
  const isShipment = prefix === PREFIX_ITEM_TABLE.SHIPMENT;
  const isAllowed = useIsAllowedFunction();
  const [photosViewer, setPhotosViewer] = useState({
    open: false,
    photos: [],
  });

  const [showImage, setShowImage] = useState(
    !imagesTableStorage[prefix] || !!modalShowImage
  );

  const hasPermission = isAllowed(
    isShipment
      ? generalPermissions.SHIPMENT_AMOUNT
      : generalPermissions.SALES_ORDER_AMOUNT
  );

  const rowRenderer = ({ index, parent, key, style }) => (
    <CellMeasurer
      key={key}
      cache={cache}
      parent={parent}
      columnIndex={0}
      rowIndex={index}
    >
      <div style={style}>
        {!isShipment && (
          <SimpleSORowSKU
            key={key + actualRows[index].id}
            itemSKU={actualRows[index]}
            style={style}
            showImage={showImage}
            handlePhotosViewer={handlePhotosViewer}
            entity={entity}
            prefix={prefix}
            purchaseOrders={purchaseOrders}
            onChangeData={onChangeData}
          />
        )}
        {isShipment && (
          <SimpleShipmentRowSKU
            key={key + actualRows[index].id}
            itemSKU={actualRows[index]}
            style={style}
            showImage={showImage}
            handlePhotosViewer={handlePhotosViewer}
            entity={entity}
            prefix={prefix}
            purchaseOrders={purchaseOrders}
            onChangeData={onChangeData}
          />
        )}
      </div>
    </CellMeasurer>
  );

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 65,
  });
  const idExpandModal = isModal ? "ExpandModal" : "";

  function handlePhotosViewer({ open, photos = [] }) {
    if (photos.length > 0) {
      setPhotosViewer({ photos, open });
    } else {
      setPhotosViewer({ photos: [{ src: "" }, open] });
    }
  }

  return (
    <SimpleSKUTableStyled
      className={cx("tableSKUContainer", classTable, {
        modalSKUContainer: isModal,
      })}
      id={`itemTable${prefix}${idExpandModal}`}
    >
      {showImage && photosViewer.open && (
        <ViewerSKU
          showImage={showImage}
          handlePhotosViewer={handlePhotosViewer}
          photosViewer={photosViewer.photos}
          openViewer={photosViewer.open}
        />
      )}
      {isLoading && <LoadingBackdrop size={60} withLogo={true} />}
      {openSnackBar && (
        <Snackbar
          className={"snackbar-table-link"}
          open={openSnackBar}
          onClose={() => handleSnackBar(false)}
          TransitionComponent={Grow}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          style={{ position: "absolute !important", top: 100 }}
          autoHideDuration={1500}
          message={
            <span style={{ margin: "auto" }} id="message-id">
              Copied to clipboard
            </span>
          }
        />
      )}
      <div className="headerSKURow">
        {getSettingsTableHeader({
          headers: tableHeader.filter(
            (column) => !column.permission || isAllowed(column.permission)
          ),
          filters: sorting,
          checked: showImage,
          onChangeCheckBox: () => setShowImage(!showImage),
          updateSorting: (sorting) => {
            handleSorting(sorting);
          },
          hadSpecialStyle: !hasPermission,
        })}
      </div>
      {actualRows.length === 0 && (
        <RowEmptySimple searchText={modalFilterText} />
      )}
      {actualRows.length !== 0 && (
        <AutoSizer>
          {({ width, height }) => {
            const sizeRow = showImage
              ? itemTableRowHeight.WITH_IMAGE
              : itemTableRowHeight.NORMAL;
            const heightSize = isCopyTable
              ? height
              : actualRows.length * sizeRow;
            return (
              <List
                width={width}
                height={heightSize}
                rowCount={actualRows.length}
                deferredMeasurementCache={cache}
                rowHeight={sizeRow}
                rowRenderer={rowRenderer}
                className="list"
              />
            );
          }}
        </AutoSizer>
      )}
    </SimpleSKUTableStyled>
  );
}

export default SKUTableSimple;
