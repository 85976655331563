import React from "react";
import { NavLink } from "react-router-dom";
import userTypes from "../../api/types/userTypes";
import TagsModal from "../../components/Modal/TagsModal";
import {
  getProgressValue,
  getDashboardSearchPath,
  getTagsLabel,
  sortObjectsBy,
} from "../../helpers/helpers";
import { dbTables } from "../../api/types/dbTables";
import { getShipmentBadge } from "../../helpers/shipments";
import {
  GENERAL_PERMISSION_VALUE,
  PO_STATUS,
} from "../../helpers/constants";
import { getTagStatus } from "../../helpers/salesOrder";
import { checkSpecificPermissions } from "../../hooks/permissions";
import { colors } from "../../assets/jss/variables";
import HiddenDataIconAnimated from "../../helpers/iconAnimation/HiddenDataIconAnimated";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { AnimatedListStyled } from "../StyledComponent/AnimatedOpenList";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import { getDiamondStatus } from "../../helpers/ScreenComponetHelper/componetHelper";

function ShipmentsItem({
  item,
  user,
  onUpdateItem,
  isTradeDashEmployee,
  tags,
  isAllowed,
  salesOrderId,
  salesOrderSetup = {},
  onChangeSalesOrderSetup = () => {},
  shipmentBadgeClasses = {},
  filters,
  type = "SO",
  shipmentDiamonds,
  shipmentProgress,
  iconStyle = {},
  query = "",
  paddingLeft = 0,
}) {
  const currentTags = filters.tag || [];
  const setup = salesOrderSetup[item.id] || {};
  const showShipments = setup.shipments || item.shipments.length <= 1 || query;
  let hasTag = false;
  if (!showShipments) {
    item.shipments.forEach((shipments) => {
      const shipmentsTags = shipments.tags || [];
      hasTag = shipmentsTags.some((tag) => currentTags.includes(tag)) || hasTag;
    });
  }



  const getProgress = (shipment) => {
    if (!shipmentProgress) {
      return <div />;
    } else {
      if (shipment.status === PO_STATUS.VOIDED) {
        return <div className="progressContainer" />;
      }

      return (
        <CircularStaticProgress
          label={`${shipment.completedTasks}/${shipment.totalTasks} Tasks`}
          value={+getProgressValue(shipment)}
          thickness={16}
          style={{
            transform: "rotate(270deg)",
            color: "#39F",
          }}
          size={15}
          className="progressContainer"
        />
      );
    }
  };
  let paddingValue = 0;
  if (!shipmentDiamonds) {
    paddingValue = paddingValue + 15;
  }
  if (!shipmentProgress) {
    paddingValue = paddingValue + 15;
  }
  const hiddenStyle = { paddingRight: paddingValue };
  const shipments = item.shipments || [];
  return (
    <AnimatedListStyled
      className="listContainer"
      style={{
        height: showShipments
          ? shipments.length * 22.1 + (shipments.length - 1) * 5
          : 24,
        paddingLeft,
        textDecoration: "none",
      }}
    >
      <div
        className="displayItems"
        style={{
          opacity: showShipments ? 1 : 0,
          zIndex: showShipments ? 1 : 0,
        }}
        key={item.id + "poListanimated"}
      >
        {item.shipments
          .filter(
            (shipment) =>
              user.role === userTypes.SUPER_ADMIN ||
              user.role === userTypes.TRADEDASH_EMPLOYEE ||
              checkSpecificPermissions({
                userPermissions: user.permissions,
                permissionToCheck: [
                  shipment.customerId,
                  GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                ],
              })
          )
          .sort(sortObjectsBy("number", false))
          .map((shipment, index) => {
            const shipmentPoIds = shipment.purchaseOrderIds || [];
            let poId = shipment.purchaseOrderIds[0];
            if (type === "SO") {
              const intersection = shipmentPoIds.filter((id) =>
                item.purchaseOrderIds.includes(id)
              );
              poId = intersection[0] || poId;
            }
            const shipmentTags = shipment.tags || [];
            return (
              <div
                key={shipment.id + index + item.id}
                style={{
                  ...hiddenStyle,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="orderList"
              >
                <div className="labelText">
                  {shipmentDiamonds &&
                    getDiamondStatus({
                      diamondStatus: shipment.diamondStatus,
                      itemStatus: shipment.status,
                    })}
                  {getProgress(shipment)}
                  {isAllowed("see_tags") && (
                    <div key={shipment.id + item.id + "tagModalcontainer"}>
                      <TagsModal
                        key={shipment.id + item.id + "tagModal"}
                        label={getTagsLabel(tags, shipmentTags).tagsString}
                        tags={getTagsLabel(tags, shipmentTags).tagsArray}
                        allTags={tags}
                        item={shipment}
                        currentUser={user}
                        tagReference={dbTables.SHIPMENTS}
                        onUpdateItem={onUpdateItem}
                        isThereItemRef={false}
                        isReadOnly={isTradeDashEmployee(user)}
                        status={getTagStatus({
                          tags: shipmentTags,
                          currentTags: currentTags,
                        })}
                      />
                    </div>
                  )}
                  <NavLink
                    to={
                      !(
                        user.role === userTypes.SUPER_ADMIN ||
                        user.role === userTypes.TRADEDASH_EMPLOYEE ||
                        checkSpecificPermissions({
                          userPermissions: user.permissions,
                          permissionToCheck: [
                            shipment.customerId,
                            GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
                          ],
                        })
                      )
                        ? "#"
                        : getDashboardSearchPath({
                            salesOrderId,
                            purchaseOrderId: poId,
                            shipmentId: shipment.id,
                            section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                          })
                    }
                    style={{
                      overflow: "visible",
                      marginLeft: -5,
                      textDecoration: "none",
                    }}
                    id="list-view-content-shipment"
                  >
                    {getShipmentBadge({
                      shipment: shipment,
                      styles: { margin: "0px 0px 2px 0px" },
                      classes: shipmentBadgeClasses.medium,
                    })}
                  </NavLink>
                  <ThreePanelButton
                    soId={salesOrderId}
                    poId={poId}
                    shipmentId={shipment.id}
                    tab={2}
                  />
                </div>
              </div>
            );
          })}
      </div>
      {shipments.length > 0 && (
        <div
          className="iconAnimated"
          style={{
            opacity: !showShipments ? 1 : 0,
            zIndex: !showShipments ? 1 : 0,
            ...iconStyle,
          }}
        >
          {HiddenDataIconAnimated({
            styles: {
              cursor: "pointer",
              display: "flex",
            },
            color: hasTag ? colors.diamondRed : "",
            onClick: () => {
              onChangeSalesOrderSetup({
                ...salesOrderSetup,
                [item.id]: {
                  ...salesOrderSetup[item.id],
                  shipments: !salesOrderSetup[item.id]
                    ? true
                    : !salesOrderSetup[item.id].shipments,
                },
              });
            },
          })}
          <span
            style={{
              marginLeft: 6,
              fontWeight: 500,
              color: hasTag ? colors.diamondRed : "",
            }}
            key={item.id + "shipmentsLength"}
          >
            {item.shipments.length}
          </span>
        </div>
      )}
    </AnimatedListStyled>
  );
}

export default ShipmentsItem;
