import { colors } from "../../assets/jss/variables";
import { styled } from "@mui/material";
import AppConfig from "../../constants/AppConfig";
import { dbTables } from "../../api/types/dbTables";

const gridTemplate =
  "52px 20px 40px 350px 270px 350px 190px 150px 150px 100px 1fr 20px";

export const HeaderTaskGroup = styled("div")(() => ({
  "&.poHeader": {
    height: 40,
    display: "flex",
    alignItems: "center",
    minWidth: 0,
    maxWidth: "100%",
    position: "sticky",
    top: "0px",
    zIndex: 101,
    backgroundColor: "white",
  },
  "& .groupInfoContainer": {
    display: "flex",
    alignItems: "center",
    backgroundColor: colors.primaryDark,
    fontWeight: "bold",
    color: "white",
    padding: 8,
    marginRight: 0,
    height: "100%",
    borderRight: "1px solid",
    position: "sticky",
    justifyContent: "center",
    minWidth: 100,
    "& a": {
      color: "#FFF",
      textDecoration: "underline",
    },
  },
}));

export const FilterTaskContainer = styled("div")(() => ({
  "&.root": {
    height: "100%",
    overflowY: "auto",
    position: "relative",
    padding: 16,
    backgroundColor: "#F6FBFF",
    zIndex: 0,
  },
  "& .contentContainer": {
    marginTop: 16,
    width: "100%",
    boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
    backgroundColor: "white",
    position: "relative",
    paddingBottom: 5,
  },

  "& .contentOptions": {
    padding: "16px 16px 0px 16px",
    height: 88,
  },
  "& .contentSettings": {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 15,
    marginBottom: 0,
    height: 40,
  },
  "& .buttonsFilter": {
    display: "grid",
    padding: "16px 16px 0px",
    gap: 16,
  },
}));

export const TaskTableContainer = styled("div")(() => ({
  "&.contentListTask ": {
    height: "calc(100vh - 335px)",
    width: "100%",
    backgroundColor: "white",
    "& .MuiTableCell-root": {
      color: colors.primaryDark,
    },
    marginRight: 3,
    overflow: "auto",
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.thumbScrollColor,
      },
    },
    "&::-webkit-scrollbar": {
      width: 8,
      height: 8,
      marginRight: 3,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-track:vertical": {
      marginTop: 52,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
      borderRadius: 5,
      marginLeft: 3,
    },
  },
  "&.groupContainer": {
    paddingBottom: 0,
    "&::-webkit-scrollbar-track:vertical": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
}));

export const TaskByProjectContainerStyled = styled("div")(() => ({
  "&.tasksProjectContainer": {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    gap: 16,
    padding: "17px 21px",
    "& .task-by-projects-and-searchbox": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& .arrowDropDownIcon": {
        transition: "transform 0.3s ease",
        color: colors.primaryDark,
      },
      "& .arrowActive": {
        transform: "rotate(180deg)",
      },
      "& .SOButtonSelect": {
        gap: 5,
        borderRadius: 5,
        border: `1px solid ${colors.whiteBlue}`,
        background: "white",
        paddingInline: 15,
        color: colors.primaryDark,
        fontSize: 14,
        fontWeight: 400,
      },
    },
    "& .headerTable": {
      fontSize: "0.875rem",
      background: colors.tableHeaderColor,
      height: 51,
      borderBottom: "1px solid #87A3C2",
      borderTop: "1px solid #87A3C2",
      zIndex: 2,
      fontWeight: 700,
      color: colors.primaryDark,
      display: "flex",
      alignItems: "center",
      paddingInline: "10px 5px",
      paddingBlock: 0,
      position: "sticky",
      top: 0,
      gap: 5,
    },
    "& .taskContainerData": {
      boxShadow: "0px 4px 10px rgb(0 0 0 / 25%)",
      backgroundColor: "white",
      paddingBottom: 5,
      height: "100%",
      overflow: "hidden",
      "& .arrow": {
        marginRight: 16,
      },
    },
    "& .salesOrderDataContainer": {
      display: "grid",
      gridTemplateColumns: gridTemplate,
      overflow: "scroll",
      height: "calc(100vh - 299px)",
      alignContent: "start",
      color: colors.primaryDark,
      fontSize: "0.85em",
      "&:hover": {
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: colors.thumbScrollColor,
        },
      },
      "&::-webkit-scrollbar": {
        width: 8,
        height: 8,
        marginRight: 3,
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "transparent",
      },
      "&::-webkit-scrollbar-track:vertical": {
        marginTop: 52,
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "transparent",
        borderRadius: 5,
        marginLeft: 3,
      },
      "& .navLink": {
        display: "flex",
        "& .span-description": {
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "fit-content",
          textWrap: "nowrap",
          color: colors.primaryDark,
          textDecoration: "underline",
        },
      },
      "& .taskLink": {
        zIndex: 1,
        opacity: 0.55,
      },
      "& .orderTaskRow": {
        display: "grid",
        gridTemplateColumns: gridTemplate,
        position: "relative",
        gridColumn: "12 span",
        "& .cancelIcon": {
          cursor: "pointer",
          color: colors.diamondRed,
          zIndex: 2,
        },
        "& .completeBackground": {
          position: "absolute",
          top: 0,
          left: 112,
          height: 42,
          transition: "width 6s ease",
          width: "calc(100% - 133px)",
          backgroundColor: "white",
          opacity: 0.55,
        },
        "& .nonCompleted": {
          position: "absolute",
          top: 0,
          left: 112,
          height: 42,
          backgroundColor: "rgba(0, 0, 0, 0.2)",
          transition: "opacity 1s ease",
          width: 0,
          opacity: 0.55,
        },

        "& .slowTransition": {
          transition: "width 6.2s ease",
        },
        "& .fastTransition": {
          transition: "width 0.62s ease",
        },
        "& .onComplete": {
          width: "calc(100% - 133px)",
        },
        "& div": {
          transition: "all 1s",
        },
        "& .badgeDueDateContainer": {
          maxWidth: 32,
          minWidth: 32,
          height: 25,
          fontWeight: "bold",
          zIndex: 2,
          "& .dayoffset": {
            cursor: "pointer",
            borderRadius: 100,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: 13,
            height: 25,
            width: 32,
            padding: 5,
            background: "white",
            boxShadow: "0 1px 4px 1px #888",
          },
          "& .early": {
            color: " #30d536",
          },
          "& .late": {
            color: "#e02020",
          },
          "& .disabled": {
            opacity: 0.3,
            color: "#25486d",
            boxShadow: "none",
            background: "unset",
            cursor: "default",
          },
        },
        "& .blank": {
          height: 42,
          border: "none !important",
          background: `${AppConfig.themeColors.salesOrderBackgroundColor} !important`,
        },
        [`& .blank${dbTables.PURCHASE_ORDERS}`]: {
          height: 42,
          borderLeft: `1px solid ${colors.dividerColor}`,
        },
        [`& .blank${dbTables.SHIPMENTS}`]: {
          height: 42,
          borderLeft: `1px solid ${colors.dividerColor}`,
        },
        "& .buttonArrow": {
          width: 28,
          borderRadius: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
          transform: "rotate(-90deg)",
          "&:hover": {
            background: colors.backgroundWhite,
          },
          "&:active": {
            background: colors.backgroundWhiteAction,
            "& line": {
              strokeWidth: 2.5,
            },
          },
          "& .arrowDropDownIcon": {
            transition: "transform 0.3s ease",
            color: colors.primaryDark,
          },
          "& .arrowActive": {
            transform: "rotate(90deg)",
          },
        },

        [`& .task_${dbTables.SALES_ORDERS}`]: {
          background: AppConfig.themeColors.salesOrderBackgroundColor,
        },
        [`& .task_${dbTables.SHIPMENTS}`]: {
          background: AppConfig.themeColors.shipmentColor,
        },
        [`& .task_${dbTables.PURCHASE_ORDERS}`]: {
          background: AppConfig.themeColors.purchaseOrderBackgroundColor,
        },
      },

      "& .infoContainer": {
        "& .subOrderNumber": {
          gridColumn: "span 3",
          gap: 12,
          display: "flex",
          alignItems: "center",
          borderLeft: `1px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
        },
        "& .lastCell": {
          borderRight: `1px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          padding: 0,
        },
      },
      [`& .${dbTables.PURCHASE_ORDERS}Background`]: {
        "& .cellRow": {
          background: AppConfig.themeColors.purchaseOrderBackgroundColor,
          borderTop: `1px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          transition: "border 0.3s ease",
        },
      },
      [`& .${dbTables.SHIPMENTS}Background`]: {
        "& .cellRow": {
          background: AppConfig.themeColors.shipmentColor,
          borderTop: `1px solid ${AppConfig.themeColors.shipmentBordeColor}`,
        },
      },
      "& .lastItem": {
        borderBottom: `1px solid ${colors.dividerColor}`,
        transition: "border 0.3s ease",
      },
      "& .divClick": {
        cursor: "pointer",
      },

      "& .cellRow": {
        background: AppConfig.themeColors.salesOrderBackgroundColor,
        height: 42,
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
        "& .timeline-icon": {
          cursor: "pointer",
        },
      },
      "& .cellRowTask": {
        borderTop: `1px solid ${colors.dividerColor}`,
        height: 42,
        display: "flex",
        alignItems: "center",
        paddingLeft: 10,
      },

      "& .startDateCell": {
        gap: 5,
        "& .calendarIcon": {
          opacity: 0,
        },
        "&:hover": {
          "& .calendarIcon": {
            opacity: 1,
          },
        },
      },

      "& .avatarCell": {
        "& .avatarArrow": {
          opacity: 0,
        },
        "&:hover": {
          "& .avatarArrow": {
            opacity: 1,
          },
        },
      },

      "& .taskDescription": {
        display: "flex",
        gridColumn: "span 3",
        gap: 20,
        borderLeft: `1px solid ${colors.dividerColor}`,
      },
      "& .rightContainer": {
        borderRight: `1px solid ${colors.dividerColor}`,
        padding: 0,
      },
      "& .soNumber": {
        gridColumn: "span 2",
        gap: 5,
      },
    },
  },
  "& .contentOptions": {
    display: "flex",
    flexDirection: "column",
    paddingTop: 16,
    "& .buttonsFilter": {
      display: "grid",
      paddingInline: 30,
      alignItems: "center",
      gridTemplateColumns:
        "30px min-content min-content min-content minmax(0, 270px)",
      gap: 16,
      "& .regularContainer": {
        height: 40,
        cursor: "pointer",
      },
      "& .arrow": {
        cursor: "pointer",
      },
    },
    "& .toolsContainer": {
      height: 40,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      gap: 26,
      paddingInline: 26,
      marginBottom: 4,
      "& .buttonTool": {
        cursor: "pointer",
        height: 40,
        width: 40,
        minWidth: 40,
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "&:hover": {
          background: colors.backgroundWhite,
          "& .expandRow": {
            "& path": {
              strokeWidth: 2.5,
            },
          },
        },
        "&:active": {
          background: colors.backgroundWhiteAction,
          "& .expandRow": {
            "& .item1": {
              transform: "translateY(1px)",
            },
            "& .item3": {
              transform: "translateY(-1px)",
            },
            "& path": {
              strokeWidth: 3,
            },
          },
        },
      },
    },
  },
  "& .hiddenItems": {
    height: "0px !important",
    borderWidth: "0px !important",
    overflow: "hidden",
  },
}));
