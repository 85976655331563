import React from "react";
import { PurchaseOrderBadgeStyled } from "./styles";

function POBadge({ number, id = "", className = "purchase-order-badge" }) {
  return (
    <PurchaseOrderBadgeStyled className={className} id={id}>
      <span>{number}</span>
    </PurchaseOrderBadgeStyled>
  );
}

export default POBadge;
