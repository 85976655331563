import {
  generalPermissions,
  headerColumns,
  notAllowedToSee,
} from "../../helpers/constants";
import { getTotal, getBalance, totalCBMfromSO } from "../Managers/helpers";

export function footerPurchaseOrderList(totalPOs, values, isAllowed) {
  return {
    [headerColumns.FAVORITE]: {
      id: 0,
      value: `Totals of all ${totalPOs} POs:`,
      colSpan: 5,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SO]: {
      id: 1,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SHIPMENTS]: {
      id: 2,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.CUSTOMER]: {
      id: 3,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.CREATION_DATE]: {
      id: 4,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.ORDER_READY_DATE]: {
      id: 5,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.VENDOR]: {
      id: 6,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.DISCOUNT_PERCENTAGE]: {
      id: 7,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        display: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? ""
          : "none",
      },
    },
    [headerColumns.TOTAL]: {
      id: 8,
      value: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ? values.totals
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 15px 9px 9px",
        height: 45,
        display: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? ""
          : "none",
      },
    },
    [headerColumns.POTENTIAL_LATE_FEE]: {
      id: 9,
      value: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ? values.totalPotentialLateFee
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 22px 9px 9px",
        height: 45,
        display: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? ""
          : "none",
      },
    },
    [headerColumns.CBM]: {
      id: 10,
      value: values.totalCBM,
      divider: true,
      styles: {
        padding: "9px 16px 9px 9px",
        height: 45,
      },
    },
    [headerColumns.SHIPPING_TERMS]: {
      id: 11,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.TOTAL_SHIPMENTS]: {
      id: 12,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.VENDOR_INVOICE]: {
      id: 13,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.UNIQUE_ITEMS]: {
      id: 14,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.DISCOUNT]: {
      id: 15,
      value: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ? values.totalDiscount
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 16px 9px 9px",
        height: 45,
        display: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? ""
          : "none",
      },
    },
    [headerColumns.SUB_TOTAL]: {
      id: 16,
      value: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ? values.totalSubtotals
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 16px 9px 9px",
        height: 45,
        display: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? ""
          : "none",
      },
    },
    [headerColumns.DEPOSIT]: {
      id: 17,
      value: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ? values.totalDeposit
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 15px 9px 9px",
        height: 45,
        display: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? ""
          : "none",
      },
    },
    [headerColumns.BALANCE]: {
      id: 18,
      value: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ? values.totalBalance
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 16px 9px 9px",
        height: 45,
        display: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? ""
          : "none",
      },
    },
    [headerColumns.TOTAL_WEIGHT]: {
      id: 19,
      value: values.totalWeight || "0.00",
      divider: true,
      styles: {
        padding: "9px 15px 9px 9px",
        height: 45,
      },
    },

    [headerColumns.DOCUMENT_DATE]: {
      id: 20,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.EXTRA]: {
      id: 21,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        width: "100%",
      },
    },
  };
}

export function footerSalesOrderList(totalSOs, values, isAllowed) {
  return {
    [headerColumns.FAVORITE]: {
      id: 0,
      value: `Totals of all ${totalSOs} Sales Orders:`,
      colSpan: 5,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.POS]: {
      id: 1,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SHIPMENTS]: {
      id: 2,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.CUSTOMER]: {
      id: 3,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.CREATION_DATE]: {
      id: 4,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SHIP_BY_DATE]: {
      id: 5,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PAY_TERMS_START]: {
      id: 6,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.REQUIRED_DELIVERY]: {
      id: 7,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.DISCOUNT_PERCENTAGE]: {
      id: 8,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        display: isAllowed(generalPermissions.SALES_ORDER_AMOUNT) ? "" : "none",
      },
    },
    [headerColumns.TOTAL]: {
      id: 9,
      value: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ? values.totals
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 16px 9px 4px",
        height: 45,
        display: isAllowed(generalPermissions.SALES_ORDER_AMOUNT) ? "" : "none",
      },
    },
    [headerColumns.POTENTIAL_LATE_PENALTY]: {
      id: 10,
      value: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ? values.totalPotentialLateFee
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 16px 9px 16px",
        height: 45,
        display: isAllowed(generalPermissions.SALES_ORDER_AMOUNT) ? "" : "none",
      },
    },
    [headerColumns.CBM]: {
      id: 11,
      value: values.totalCBM,
      divider: true,
      styles: {
        padding: "9px 17px 9px 8px",
        height: 45,
      },
    },
    [headerColumns.SHIPPING_TERMS]: {
      id: 12,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.TOTAL_SHIPMENTS]: {
      id: 13,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SALES_INVOICE]: {
      id: 14,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.CUSTOMER_PO]: {
      id: 15,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SALES_RESP]: {
      id: 16,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.UNIQUE_ITEMS]: {
      id: 17,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.DISCOUNT]: {
      id: 18,
      value: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ? values.totalDiscount
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 14px 9px 16px",
        height: 45,
        display: isAllowed(generalPermissions.SALES_ORDER_AMOUNT) ? "" : "none",
      },
    },
    [headerColumns.SUB_TOTAL]: {
      id: 19,
      value: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ? values.totalSubtotals
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 16px 9px 4px",
        height: 45,
        display: isAllowed(generalPermissions.SALES_ORDER_AMOUNT) ? "" : "none",
      },
    },
    [headerColumns.DEPOSIT]: {
      id: 20,
      value: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ? values.totalDeposit
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 16px 9px 16px",
        height: 45,
        display: isAllowed(generalPermissions.SALES_ORDER_AMOUNT) ? "" : "none",
      },
    },
    [headerColumns.BALANCE]: {
      id: 21,
      value: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ? values.totalBalance
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 16px 9px 4px",
        height: 45,
        display: isAllowed(generalPermissions.SALES_ORDER_AMOUNT) ? "" : "none",
      },
    },
    [headerColumns.DOCUMENT_DATE]: {
      id: 22,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.REFERENCE_NUMBER]: {
      id: 23,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.EXTRA]: {
      id: 24,
      value: "",
      styles: {
        width: "100%",
        height: 45,
      },
    },
  };
}

export function footerShipmentsScreen(totalSOs, values, isAllowed) {
  return {
    [headerColumns.FAVORITE]: {
      id: 0,
      value: `Totals of all ${totalSOs} Shipments:`,
      colSpan: 5,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SALES_ORDER]: {
      id: 1,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.CUSTOMER]: {
      id: 2,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PO]: {
      id: 3,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.CREATION_DATE]: {
      id: 4,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SHIP_DATE]: {
      id: 5,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.DELIVERY_DATE]: {
      id: 6,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.CBM]: {
      id: 7,
      value: values.totalCBM || "$0.00",
      divider: true,
      styles: {
        padding: "9px 16px 9px 6px",
        height: 45,
      },
    },
    [headerColumns.BOOKING]: {
      id: 8,
      value: "",
      styles: {
        padding: "9px 22px 9px 16px",
        height: 45,
      },
    },
    [headerColumns.BOL]: {
      id: 9,
      value: "",
      styles: {
        padding: "9px 22px 9px 16px",
        height: 45,
      },
    },
    [headerColumns.CONTAINER]: {
      id: 10,
      value: "",
      styles: {
        padding: "9px 22px 9px 16px",
        height: 45,
      },
    },
    [headerColumns.SHIPPING_TERMS]: {
      id: 11,
      value: "",
      styles: {
        padding: "9px 6px 9px 8px",
        height: 45,
      },
    },
    [headerColumns.TOTAL_VALUE]: {
      id: 12,
      value: isAllowed(generalPermissions.SHIPMENT_AMOUNT)
        ? values.totals || "$0.00"
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 17px 9px 16px",
        height: 45,
        display: isAllowed(generalPermissions.SHIPMENT_AMOUNT) ? "" : "none",
      },
    },
    [headerColumns.POTENTIAL_LATE_FEE]: {
      id: 13,
      value: isAllowed(generalPermissions.SHIPMENT_AMOUNT)
        ? values.totalPotentialLateFee || "$0.00"
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 17px 9px 16px",
        height: 45,
        display: isAllowed(generalPermissions.SHIPMENT_AMOUNT) ? "" : "none",
      },
    },
    [headerColumns.CUSTOMER_PO]: {
      id: 14,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.LOADING_PORT]: {
      id: 15,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.UNLOADING_PORT]: {
      id: 16,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.FINAL_DESTINATION]: {
      id: 17,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.UNIQUE_ITEMS]: {
      id: 18,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.FREIGHT_FORWARDER]: {
      id: 19,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SALES_INVOICE]: {
      id: 20,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.TOTAL_WEIGHT]: {
      id: 21,
      value: values.totalWeight || "0.00",
      divider: true,
      styles: {
        padding: "9px 17px 9px 16px",
        height: 45,
      },
    },
    [headerColumns.EXTRA]: {
      id: 22,
      value: "",
      styles: {
        width: "100%",
        height: 45,
      },
    },
  };
}

export function footerOrdersByPhasePOs(totalPOs, totalAmountPOs, isAllowed) {
  return {
    [headerColumns.FAVORITE]: {
      id: 0,
      value: `Totals of all ${totalPOs} POs:`,
      selected: true,
      colSpan: 3,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.VENDOR]: {
      id: 2,
      selected: true,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PO_VALUE]: {
      id: 3,
      value: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ? totalAmountPOs
        : notAllowedToSee,
      divider: true,
      selected: true,
      styles: {
        padding: "9px 16px 9px 4px",
        height: 45,
      },
    },
    [headerColumns.ORDER_READY_DATE]: {
      id: 4,
      value: " ",
      selected: true,
      styles: {
        padding: "9px 16px",
        height: 45,
        color: "red",
      },
    },
    [headerColumns.PROPOSAL_QUOTE]: {
      id: 6,
      value: "",
      selected: true,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PRE_PRODUCTION]: {
      id: 7,
      value: "",
      selected: true,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PRODUCTION]: {
      id: 8,
      value: "",
      selected: true,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.BOOKING_TRANSIT]: {
      id: 9,
      value: "",
      selected: true,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PAYMENT_BILLING]: {
      id: 10,
      value: "",
      selected: true,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.EXTRA]: {
      id: 11,
      value: "",
      selected: true,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
  };
}
export function footerOrdersByPhasePOsWithinSO(
  totalPOs,
  totalAmountPOs,
  isAllowed
) {
  return {
    [headerColumns.EXPAND]: {
      id: 0,
      value: `Totals of all ${totalPOs} POs:`,
      colSpan: 4,
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.VENDOR]: {
      id: 2,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.PO_VALUE]: {
      id: 3,
      value: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ? totalAmountPOs
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 28px 9px 4px",
        height: 45,
        overflow: "visible",
      },
    },
    [headerColumns.ORDER_READY_DATE]: {
      id: 4,
      value: "",
      colSpan: 2,
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.PROPOSAL_QUOTE]: {
      id: 6,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.PRE_PRODUCTION]: {
      id: 7,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.PRODUCTION]: {
      id: 8,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.BOOKING_TRANSIT]: {
      id: 9,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.PAYMENT_BILLING]: {
      id: 10,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.EXTRA]: {
      id: 11,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
  };
}

export function footerOrdersByPhaseShipmentsWithinSO(
  totalShipments,
  totalAmountShipments,
  isAllowed
) {
  return {
    [headerColumns.EXPAND]: {
      id: 0,
      value: `Totals of all ${totalShipments} Shipments:`,
      colSpan: 4,
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.FINAL_DESTINATION]: {
      id: 2,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.SHIPMENT_VALUE]: {
      id: 3,
      value: isAllowed(generalPermissions.SHIPMENT_AMOUNT)
        ? totalAmountShipments
        : notAllowedToSee,
      divider: true,
      styles: {
        padding: "9px 26px 9px 4px",
        height: 45,
        overflow: "visible",
      },
    },
    [headerColumns.SHIP_DATE]: {
      id: 4,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.DELIVERY_DATE]: {
      id: 5,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.PROPOSAL_QUOTE]: {
      id: 6,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.PRE_PRODUCTION]: {
      id: 7,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.PRODUCTION]: {
      id: 8,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.BOOKING_TRANSIT]: {
      id: 9,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.PAYMENT_BILLING]: {
      id: 10,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
    [headerColumns.EXTRA]: {
      id: 11,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
        borderTop: "2px solid #87A3C2",
      },
    },
  };
}

export function footerOrdersByPhaseSOs(
  totalPOs = 0,
  totalAmountPOs,
  isAllowed
) {
  return {
    [headerColumns.EXPAND]: {
      id: 0,
      value: `Totals of all ${totalPOs} SOs:`,
      colSpan: 4,
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.POS]: {
      id: 2,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.TOTAL]: {
      id: 3,
      value: isAllowed(generalPermissions.SALES_ORDER_AMOUNT)
        ? totalAmountPOs
        : notAllowedToSee,
      divider: true,
      selected: true,
      styles: {
        padding: "9px 32px 9px 4px",
        height: 45,
        overflow: "visible",
      },
    },
    [headerColumns.CUSTOMER]: {
      id: 4,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.SO_SHIP_DATE]: {
      id: 5,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PROPOSAL_QUOTE]: {
      id: 7,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PRE_PRODUCTION]: {
      id: 8,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PRODUCTION]: {
      id: 9,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.BOOKING_TRANSIT]: {
      id: 10,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.PAYMENT_BILLING]: {
      id: 11,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
    [headerColumns.EXTRA]: {
      id: 12,
      value: "",
      styles: {
        padding: "9px 16px",
        height: 45,
      },
    },
  };
}

function getTotals(list = [], isAllowed = () => {}, screenName) {
  let result = {
    amount: 0,
    deposit: 0,
    balance: 0,
    potentialLateFee: 0,
    CBM: 0,
  };
  result.amount = getTotal(list, "amount", "$0,0.00", isAllowed);
  result.deposit = getTotal(list, "deposit", "$0,0.00", isAllowed);
  result.balance = getBalance(list, "$0,0.00", isAllowed);
  result.potentialLateFee = getTotal(
    list,
    "potentialLateFee",
    "$0,0.00",
    isAllowed
  );
  if (screenName === "SalesOrdersScreen") {
    result.CBM = totalCBMfromSO(list, isAllowed);
  } else {
    result.CBM = getTotal(list, "CBM", "0.00", isAllowed);
  }
  return result;
}

export function getFooterRow(
  list = [],
  footerFormatOrder = [],
  isAllowed = () => {},
  screenName
) {
  let totals = getTotals(list, isAllowed, screenName);
  const footer = footerFormatOrder.map((element) => {
    if (element.value === "amount") return { ...element, value: totals.amount };
    if (element.value === "deposit")
      return { ...element, value: totals.deposit };
    if (element.value === "balance")
      return { ...element, value: totals.balance };
    if (element.value === "potentialLateFee")
      return { ...element, value: totals.potentialLateFee };
    if (element.value === "CBM") return { ...element, value: totals.CBM };
    return element;
  });
  return footer;
}
