import React from "react";
import moment from "moment";
import {
  PO_STATUS,
  SALES_ORDER_DIAMOND_STATUS,
  TASK_TYPE,
  taskStatus,
} from "./constants";
import lateIcon from "../assets/flag-icons/red_diamond.svg";
import nearDueIcon from "../assets/flag-icons/orange_diamond.svg";
import nearDueOutlined from "../assets/flag-icons/orange-diamond-white-outline.png";
import lateIconOutlined from "../assets/flag-icons/red-diamond-white-outline.png";
import { typeOfTask } from "../api/types/dbTables";
import { hexToRgbA } from "./helpers";
import AppConfig from "../constants/AppConfig";

export function getWeekendClass(day, flagViewOptions) {
  const dayOfWeek = moment(day).day();
  if (
    (dayOfWeek === 0 || dayOfWeek === 6) &&
    !!flagViewOptions[viewOptionLabels.WEEKENDS]
  ) {
    return "weekend";
  }
  return "day";
}

export function getCalendarItemClass({ date, task }) {
  if (
    moment(date).startOf("day").valueOf() ===
    moment(task.startDate).startOf("day").valueOf()
  ) {
    if (task.duration === 1) {
      return "task-start-with-duration-1";
    }
    return "task-start";
  }
  if (
    moment(date).endOf("day").valueOf() ===
    moment(task.finishDate).endOf("day").valueOf()
  ) {
    return "task-end";
  }
  if (
    moment(date).startOf("day").valueOf() >=
      moment(task.startDate).startOf("day").valueOf() &&
    moment(date).endOf("day").valueOf() <=
      moment(task.finishDate).endOf("day").valueOf()
  ) {
    return "task-middle";
  }

  return "calendar-empty";
}

export function getMilestone({ day, milestones, flagViewOptions }) {
  const milestone = milestones.find(
    (el) =>
      moment(el.date).startOf("day").valueOf() ===
      moment(day).startOf("day").valueOf()
  );
  if (milestone && milestone.isToday) {
    return milestone;
  } else if (milestone && flagViewOptions[viewOptionLabels.MILESTONES]) {
    return milestone;
  }
  return false;
}

export function getPhaseClassName(task, date) {
  let offset = moment(task.startDate).diff(task.finishDate, "days");
  offset = Math.floor(Math.abs(offset / 2));
  const minOffset = offset - 2;
  const maxOffset = offset + 2;
  if (
    moment(task.startDate).format("M/D/YYYY") ===
    moment(date).format("M/D/YYYY")
  ) {
    return "start-phase";
  } else if (
    moment(task.finishDate).format("M/D/YYYY") ===
    moment(date).format("M/D/YYYY")
  ) {
    return "finish-phase";
  } else if (
    moment(date).format("M/D/YYYY") ===
    moment(task.startDate).add(offset, "days").format("M/D/YYYY")
  ) {
    return "middle-phase";
  } else if (
    moment(date).valueOf() >=
      moment(task.startDate).add(minOffset, "days").valueOf() &&
    moment(date).valueOf() <=
      moment(task.startDate).add(maxOffset, "days").valueOf()
  ) {
    return "middle-phase-range";
  } else {
    return "content-phase";
  }
}

export function isThereGhostTask({ task, ghostTask, flagViewOptions }) {
  if (
    flagViewOptions[viewOptionLabels.GHOSTED_CHANGES] &&
    JSON.stringify({
      startDate: task.startDate,
      finishDate: task.finishDate,
      assignedTo: task.assignedTo,
      status: task.status,
    }) !==
      JSON.stringify({
        startDate: ghostTask.startDate,
        finishDate: ghostTask.finishDate,
        assignedTo: ghostTask.assignedTo,
        status: ghostTask.status,
      })
  ) {
    return true;
  }
  return false;
}

export function getDate({ date, unitTime = "day" }) {
  return moment(date).startOf(unitTime).format("M/D/YY");
}

function convertHex(hex, opacity) {
  if (!hex) return "#000";
  hex = hex.replace("#", "");
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  let result = "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
  return result;
}

export function isVerticalHighlighed({ taskOnHover, date }) {
  if (
    taskOnHover &&
    taskOnHover.id &&
    taskOnHover.verticalIndicator &&
    getDate({ date }) >= getDate({ date: taskOnHover.startDate }) &&
    getDate({ date }) <= getDate({ date: taskOnHover.finishDate })
  ) {
    return true;
  } else {
    return false;
  }
}

export function getOnHoverClass({ taskOnHover, task }) {
  if (taskOnHover && task && taskOnHover.ids.includes(task.id)) {
    return {
      background: convertHex(task.color, 0.2),
    };
  }
  return {};
}

export function getWindowWidth() {
  return window.innerWidth - 55;
}

export function getWindowHeight(value) {
  return window.innerHeight - 126 - value;
}

export function getTaskDiamond({ status, styles, isOutlined = false }) {
  switch (status) {
    case taskStatus.LATE:
      return (
        <img
          draggable={false}
          src={isOutlined ? lateIconOutlined : lateIcon}
          alt="late"
          style={styles}
        />
      );
    case taskStatus.NEAR_DUE:
      return (
        <img
          draggable={false}
          src={isOutlined ? nearDueOutlined : nearDueIcon}
          alt="late"
          style={styles}
        />
      );
    case taskStatus.NOT_STARTED:
    case taskStatus.IN_PROGRESS:
      return <div></div>;
    case taskStatus.COMPLETE:
      return null;
    default:
      return null;
  }
}

export function getOrderDiamond({ order, styles, isOutlined = false }) {
  if (order.diamondStatus === SALES_ORDER_DIAMOND_STATUS.LATE) {
    return (
      <img
        src={isOutlined ? lateIconOutlined : lateIcon}
        alt="late"
        style={styles}
      />
    );
  } else if (order.diamondStatus === SALES_ORDER_DIAMOND_STATUS.NEAR_DUE) {
    return (
      <img
        src={isOutlined ? nearDueOutlined : nearDueIcon}
        alt="late"
        style={styles}
      />
    );
  }
  return null;
}

export function getBorderIndicator({ taskOnHover, date, size = 3 }) {
  if (getDate({ date: taskOnHover.startDate }) === getDate({ date })) {
    if (taskOnHover.duration === 1) {
      return {
        borderLeft: `${size}px solid #7E94AC`,
        borderRight: `${size}px solid #7E94AC`,
      };
    }
    return {
      borderLeft: `${size}px solid #7E94AC`,
    };
  } else if (
    getDate({ date: moment(taskOnHover.finishDate) }) === getDate({ date })
  ) {
    return {
      borderRight: `${size}px solid #7E94AC`,
    };
  }
  return {};
}

export function getReferenceTask({ task, predecesorTask }) {
  if (!task || !predecesorTask) {
    return "";
  }
  if (task.dependsOnSOT || !task.factoryId) {
    return "S" + (predecesorTask.listIndex + 1);
  } else {
    return "P" + (predecesorTask.listIndex + 1);
  }
}

export const fullDependencyType = {
  SS: "Start-Start",
  FS: "Finish-Start",
  FF: "Finish-Finish",
};

export function getReminderDays({ date, isEndDate = true }) {
  const diffdays = moment()
    .startOf("day")
    .diff(moment(date).startOf("day"), "days");
  if (diffdays === -1) {
    return (
      <b
        style={{
          color: "#334C73",
          fontWeight: 400,
          marginLeft: 4,
        }}
      >
        Tomorrow
      </b>
    );
  } else if (diffdays === 1) {
    return (
      <b
        style={{
          color: isEndDate ? "red" : "#334C73",
          fontWeight: 400,
          marginLeft: 4,
        }}
      >
        Yesterday
      </b>
    );
  } else if (diffdays > 0) {
    return (
      <b
        style={{
          color: isEndDate ? "red" : "#334C73",
          fontWeight: 400,
          marginLeft: 4,
        }}
      >
        ({Math.abs(diffdays)} {diffdays === 1 ? "day" : "days"} ago)
      </b>
    );
  } else if (diffdays < 0) {
    return (
      <b style={{ marginLeft: 4 }}>
        ({"in " + Math.abs(diffdays)}{" "}
        {Math.abs(diffdays) === 1 ? "day" : "days"})
      </b>
    );
  } else if (diffdays === 0) {
    return (
      <b
        style={{
          color: isEndDate ? "red" : "#334C73",
          fontWeight: 400,
          marginLeft: 4,
        }}
      >
        Today
      </b>
    );
  }
}

export const viewOptionsArray = [
  {
    id: 1,
    label: "Weekends",
    enabled: true,
    position: "left",
  },
  {
    id: 2,
    label: "Milestones",
    enabled: true,
    position: "left",
  },
  {
    id: 3,
    label: "Ad-hoc tasks",
    enabled: true,
    position: "left",
  },
  {
    id: 4,
    label: "Diamonds in task bars",
    enabled: true,
    position: "left",
  },
  {
    id: 5,
    label: "Full table details",
    enabled: false,
    position: "left",
  },
  {
    id: 6,
    label: "Arrows",
    enabled: true,
    position: "right",
  },
  {
    id: 7,
    label: "Section dividers",
    enabled: true,
    position: "right",
  },
  {
    id: 8,
    label: "Ghosted changes",
    enabled: true,
    position: "right",
  },
  {
    id: 9,
    label: "Jump to the end buttons",
    enabled: true,
    position: "right",
  },
];

export const viewOptionsArrayv2 = [
  {
    id: 1,
    label: "Weekends",
    enabled: true,
    position: "left",
  },
  {
    id: 2,
    label: "Milestones",
    enabled: true,
    position: "left",
  },
  {
    id: 3,
    label: "Ad-hoc tasks",
    enabled: true,
    position: "left",
  },
  {
    id: 4,
    label: "Diamonds in task bars",
    enabled: true,
    position: "left",
  },
  {
    id: 5,
    label: "Full table details",
    enabled: false,
    position: "right",
  },
  {
    id: 6,
    label: "Arrows",
    enabled: true,
    position: "right",
  },
  {
    id: 7,
    label: "Section dividers",
    enabled: true,
    position: "right",
  },
  {
    id: 8,
    label: "Ghosted changes",
    enabled: true,
    position: "right",
  },
];

export const milestoneHeader = [
  {
    id: 1,
    label: "Color",
    width: "15%",
  },
  {
    id: 2,
    label: "Title",
    width: "60%",
  },
  {
    id: 3,
    label: "Date",
    width: "20%",
  },
  {
    id: 4,
    label: "",
    width: "5%",
  },
];

export function getFlagViewOptions(viewOptions) {
  const flagViewOptions = {};
  viewOptions.forEach((option) => {
    flagViewOptions[option.label] = option.enabled;
  });
  return flagViewOptions;
}

const viewOptionLabels = {
  WEEKENDS: "Weekends",
  MILESTONES: "Milestones",
  AD_HOC_TASKS: "Ad-hoc tasks",
  DIAMOND_IN_TASK_BARS: "Diamonds in task bars",
  ARROWS: "Arrows",
  SECTION_DIVIDERS: "Section dividers",
  GHOSTED_CHANGES: "Ghosted changes",
  JUMP_TO_THE_END_BUTTONS: "Jump to the end buttons",
  FULL_TABLE_DETAILS: "Full table details",
};

export const colorPickerArray = [
  {
    color: "#F9D451",
    position: "top",
  },
  {
    color: "#FA9917",
    position: "top",
  },
  {
    color: "#768EE1",
    position: "top",
  },
  {
    color: "#FF7C7C",
    position: "top",
  },
  {
    color: "#A462F8",
    position: "top",
  },
  {
    color: "#67D2EA",
    position: "top",
  },
  {
    color: "#C8EA67",
    position: "top",
  },
  {
    color: "#A0B895",
    position: "top",
  },
  {
    color: "#59DCB5",
    position: "bottom",
  },
  {
    color: "#6FD36D",
    position: "bottom",
  },

  {
    color: "#FF4F4F",
    position: "bottom",
  },
  {
    color: "#C09A6D",
    position: "bottom",
  },
  {
    color: "#989898",
    position: "bottom",
  },
  {
    color: "#D7B1A5",
    position: "bottom",
  },
  {
    color: "#D28C8C",
    position: "bottom",
  },
  {
    color: "#D46BEE",
    position: "bottom",
  },
];

export function getTaskReference({ task, vendorIndex }) {
  if (task.isAdHocTask) {
    return "";
  } else if (task.type === TASK_TYPE.SALES_ORDER) {
    return `S.${vendorIndex || task.number}`;
  } else {
    return `P.${vendorIndex || task.number}`;
  }
}

export function getPredecesorTask({ predecesorTask }) {
  if (!predecesorTask) {
    return "";
  } else if (predecesorTask.type === TASK_TYPE.SALES_ORDER) {
    return "S." + predecesorTask.number;
  } else {
    return "P." + predecesorTask.number;
  }
}

export { convertHex, viewOptionLabels };

export function isOrderVoided({ task, orders }) {
  const { salesOrder = {}, purchaseOrders = [], shipments = [] } = orders;
  switch (task.type) {
    case typeOfTask.SALES_ORDER:
      return salesOrder.status === PO_STATUS.VOIDED;
    case typeOfTask.PURCHASE_ORDER:
      const currentPO =
        purchaseOrders.find((po) => po.id === task.purchaseOrderId) || {};
      return currentPO.status === PO_STATUS.VOIDED;

    default:
      console.log(shipments, task);
      return false;
  }
}

export function getTaskBackgroundColor({ task, isWhite }) {
  switch (task.type) {
    case typeOfTask.SALES_ORDER:
      return hexToRgbA(
        AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        0.2
      );
    case typeOfTask.SHIPMENT:
      return hexToRgbA(
        AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        0.2
      );
    case typeOfTask.PURCHASE_ORDER: {
      if (isWhite) {
        return "#FFF";
      } else {
        return hexToRgbA(AppConfig.themeColors.purchaseOrderBorderColor, 0.2);
      }
    }
    default:
      return "#fff";
  }
}

export const TABLE_HEADERS_GANTT_CHART = {
  STATUS: "STATUS",
  REFERENCE: "REFERENCE",
  PO: "PO",
  TASK_NAME: "TASK_NAME",
  PREDECESOR: "PREDECESOR",
  DEPENDENCY: "DEPENDENCY",
  DURATION: "DURATION",
  START_DATE: "START_DATE",
  END_DATE: "END_DATE",
  ASSIGNED_TO: "ASSIGNED_TO",
  CHECK_BOX_TO_COMPLETE: "CHECK_BOX_TO_COMPLETE",
  BADGE: "BADGE",
  PO_BAR: "PO_BAR",
  BUTTONS_TO_EXPAND: "BUTTONS_TO_EXPAND",
};
