import React, { useState, useEffect, useRef, useCallback } from "react";
import { POListContainer, POTableContainer } from "./styles";
import GeneralFilters from "../../components/FilterTable/GeneralFilters";
import ButtonFilter, {
  BUTTON_FILTER_BOX_SHADOW,
} from "../../components/PurchaseOrderList/ButtonFilter";
import {
  useCompanyId,
  useJSONLocalStorage,
  useFavorites,
  useFavoritesList,
} from "../../hooks";
import { dbTables, USER_CUSTOM_SETTINGS } from "../../api/types/dbTables";
import {
  debounce,
  getMaxDate,
  getScreenListGrid,
  getTheDate,
  sortObjectsBy,
} from "../../helpers/helpers";
import moment from "moment";
import { useIsAllowedFunction } from "../../hooks/permissions";
import SearchBox from "../../components/SearchBox/SearchBox";
import FilterDisplay from "../../components/PurchaseOrderList/FilterDisplay";
import numeral from "numeral";
import FooterTable from "../../components/Tables/FooterTable";
import { footerPurchaseOrderList } from "../../components/Managers/FooterTableManager";
import { purchaseOrderListHeader } from "../../components/Managers/TableHeaderProvider";
import {
  getListValuesFromArray,
  getScreenTraceName,
  getToolTipArrayValue,
} from "../../helpers/purchaseOrderList";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import BasicPagination from "../../components/Tables/BasicPagination";
import { columnHeaderSetup } from "../../helpers/screenHeaderSetup";
import {
  useUser,
  useUserCustomSetting,
  useUserMentions,
} from "../../hooks/user";
import { useFactories } from "../../hooks/factories";
import { useCustomers } from "../../hooks/customers";
import { useTags } from "../../hooks/tags";
import {
  excludeElementsScreen,
  generalPermissions,
  headerColumns,
  LIST_VIEW_BUTTONS_GRID,
} from "../../helpers/constants";
import { StarIcon } from "../../helpers/iconSvgPath";
import { getStatusAndDiamondStatus } from "../../helpers/screens";
import { performanceFirebase } from "../../firebase";
import { getUserAvatar } from "../../helpers/users";
import AttentionModal from "../../components/Modal/AttentionModal";
import { filterList } from "../../helpers/salesOrderHelpers";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import { trace } from "firebase/performance";
import StatusOrderButtons from "../../components/PurchaseOrderList/StatusOrderButtons";
import ToolsButtonScreen from "../../components/PurchaseOrderList/ToolsButtonScreen";
import { orderType } from "../../helpers/salesOrder";
import Mark from "mark.js";
import PurchaseOrdersTableRow from "./PurchaseOrderTableRow";
import { useMediaQuery } from "@mui/material";
import { ButtonReset } from "../StyledComponent/ButtonReset";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { isEqual } from "lodash";
import DateRangePicker from "../../components/DatePicker/DateRangePicker";
import LateNearDueDiamondButtons from "../../components/PurchaseOrderList/LateNearDueDiamondButtons";
import { updateColumnHeaders } from "../../helpers/tasks";
import ColumnHeader from "../../api/model/ColumnHeader.model";

const dateFields = ["Created Date", "Document Date", "Order Ready Date"];
const BODY_ID = "purchaseOrderTableRow";

function PurchaseOrdersScreen() {
  const dateRef = useRef(null);
  const matches = useMediaQuery("(max-width:1425px)");
  const userMentions = useUserMentions(dbTables.PURCHASE_ORDERS);
  const { get, set } = useJSONLocalStorage("poListFilters");
  const { get: getPurchaseOrderSetup, set: setPurchaseOrderSetup } =
    useJSONLocalStorage("purchaseOrdersScreen");
  const [currentPOSetup, setCurrentPOSetup] = useState(
    getPurchaseOrderSetup() || {}
  );
  const user = useUser();
  const userCustomSettings = useUserCustomSetting({
    userId: user.id,
    setting: USER_CUSTOM_SETTINGS.SCREEN_HEADERS,
  });
  const companyId = useCompanyId();
  const favorites = useFavoritesList({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const favoritesDB = useFavorites({
    dbTable: dbTables.PURCHASE_ORDER_FAVORITES,
  });
  const [loading, setLoading] = useState(false);
  const isAllowed = useIsAllowedFunction();

  const customers = useCustomers();
  const factories = useFactories();
  const tags = useTags();
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const dataLocalStorage = get() || {};
  const [dataFiltered, setDataFiltered] = useState({
    purchaseOrders: [],
    totalAmounts: {},
  });
  const [filters, setFilters] = useState({
    completed: dataLocalStorage.completed || false,
    customer: dataLocalStorage.customer || [],
    date: dataLocalStorage.date || "",
    dateRange: dataLocalStorage.dateRange || "",
    factory: dataLocalStorage.factory || [],
    inProgress: !get() ? true : dataLocalStorage.inProgress || false,
    late: dataLocalStorage.late || false,
    nearDue: dataLocalStorage.nearDue || false,
    tag: dataLocalStorage.tag || [],
    voided: dataLocalStorage.voided || false,
  });
  const [openList, setOpenList] = useState(false);
  const [callFunction, setCallFunction] = useState(true);
  const [previousFilter, setPreviousFilter] = useState({});
  const [nonLookUpfilters, setNonLookUpFilters] = useState({
    favorite: dataLocalStorage.favorite || false,
    user: dataLocalStorage.user || false,
    query: dataLocalStorage.query || "",
    sortedColumn: dataLocalStorage.sortedColumn || "",
    orderBy: dataLocalStorage.orderBy || "",
  });
  const [openDate, setOpenDate] = useState(false);
  const [existsDate, setExistsDate] = useState(dataLocalStorage.date || false);
  const [filterDate, setFilterDate] = useState(dataLocalStorage.date || "");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    dataLocalStorage.rowsPerPage || 10
  );

  const [headerCells, setHeaderCells] = useState([]);
  const [currentFavorites, setCurrentFavorites] = useState([]);
  const [openAttentionModal, setOpenAttentionModal] = useState(false);

  const onDebounce = useCallback(
    debounce(() => {
      setCallFunction(true);
    }, 1250),
    []
  );

  function changeFilters(newFilters) {
    if (!isEqual(filters, newFilters)) {
      onDebounce();
      setFilters({ ...newFilters });
    }
  }

  const handleSetupStorage = ({ shipmentValue, soValue, item }) => {
    setCurrentPOSetup((oldSetup) => {
      const oldValue = oldSetup || {};
      const itemValue = oldValue[item.id] || {};
      const shipmentsSetup =
        shipmentValue === undefined ? itemValue.shipments : shipmentValue;
      const soSetup = soValue === undefined ? itemValue.SOs : soValue;
      setPurchaseOrderSetup(item.id, {
        shipments: shipmentsSetup,
        SOs: soSetup,
      });
      return {
        ...oldValue,
        [item.id]: { shipments: shipmentsSetup, SOs: soSetup },
      };
    });
  };

  useEffect(() => {
    function onAdjustHeader() {
      let userHeaderCell = userCustomSettings.POHeaderSetup || [];
      userHeaderCell = userHeaderCell.map((header) => {
        const poHeader = purchaseOrderListHeader[header.name];
        if (
          poHeader &&
          poHeader[generalPermissions.PURCHASE_ORDER_AMOUNT] &&
          !isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
        ) {
          return {
            ...header,
            ...poHeader,
            styles: {
              ...purchaseOrderListHeader[header.name].styles,
              width: header.width || 0,
              display: "none",
            },
          };
        } else if (header.name === "mentions") {
          return {
            ...header,
            ...poHeader,
            displayLabel: getUserAvatar({
              user,
              hasToolTip: false,
              styles: {
                width: 24,
                height: 24,
                fontSize: 13,
                outline: "2px solid #000",
              },
            }),
            styles: {
              ...poHeader.styles,
              width: header.width || 0,
            },
          };
        }
        return {
          ...header,
          ...purchaseOrderListHeader[header.name],
          styles: {
            ...purchaseOrderListHeader[header.name].styles,
            width: header.width || 0,
          },
        };
      });

      let headerCellsCpy = [...userHeaderCell];

      if (!isEqual(headerCells, headerCellsCpy)) {
        setHeaderCells(headerCellsCpy);
      }
    }
    onAdjustHeader();
  }, [
    rowsPerPage,
    page,
    nonLookUpfilters,
    purchaseOrders,
    isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT),
    userCustomSettings?.POHeaderSetup?.length,
  ]);

  useEffect(() => {
    if (favorites.length !== currentFavorites.length) {
      setCurrentFavorites(favorites);
    }
  }, [favorites]);

  async function getPurchaseOrders() {
    setLoading(true);
    setPreviousFilter(filters);
    if (!filters.inProgress && !filters.completed && !filters.voided) {
      setPurchaseOrders([]);
      setLoading(false);
      return;
    }
    const traceName = getScreenTraceName({
      filters,
      scope: dbTables.PURCHASE_ORDERS,
    });
    const traceInstance = trace(performanceFirebase, traceName);
    if (traceName) {
      traceInstance.start();
    }

    const { status, diamondStatus } = getStatusAndDiamondStatus({ filters });

    try {
      const functions = getFunctions();
      const purchaseOrderEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "listview",
          env: globalEnvironment,
          params: "/purchaseorders",
        })
      );
      purchaseOrderEndpoint({
        filters: {
          ...filters,
          status,
          diamondStatus,
          dateRange: "",
          dateStart: filters.dateRange
            ? moment(filters.dateRange.start).valueOf()
            : "",
          dateEnd: filters.dateRange
            ? moment(filters.dateRange.end).valueOf()
            : "",
        },
        companyId,
        userId: user.id,
      }).then((result) => {
        const { purchaseOrdersDB } = result.data;
        const parsePurchaseOrders = purchaseOrdersDB.map((po) => ({
          ...po,
          orderReadyDate: moment(po.orderReadyDate).valueOf(),
        }));
        setPurchaseOrders([...parsePurchaseOrders]);

        if (traceName) {
          traceInstance.stop();
        }
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      if (traceName) {
        traceInstance.stop();
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    const { filteredList, totalAmounts } = filterList({
      list: purchaseOrders,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
      userMentions,
    });
    setDataFiltered({ purchaseOrders: filteredList, totalAmounts });
  }, [headerCells, currentFavorites, purchaseOrders]);

  useEffect(() => {
    if (!openList && !isEqual(filters, previousFilter)) {
      onDebounce();
    }
  }, [openList]);

  useEffect(() => {
    if (!callFunction) {
      return;
    }
    setCallFunction(false);
    if (openList) {
      return;
    }
    if (
      filters.date &&
      filters.date !== "" &&
      !filters.dateRange.start &&
      !filters.dateRange.end
    ) {
      return;
    }
    if (isEqual(filters, previousFilter)) {
      console.log("same filter");
      return;
    }
    setPurchaseOrders([]);
    setDataFiltered({ purchaseOrders: [], totalAmounts: {} });
    setPage(1);
    getPurchaseOrders();
    set({ ...nonLookUpfilters, ...filters, rowsPerPage });
  }, [callFunction]);

  useEffect(() => {
    set({ ...filters, ...nonLookUpfilters, rowsPerPage });
  }, [rowsPerPage]);

  useEffect(() => {
    setPage(1);
    set({ ...filters, ...nonLookUpfilters, rowsPerPage });
    const { filteredList, totalAmounts } = filterList({
      list: purchaseOrders,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
      userMentions,
    });

    setDataFiltered({ purchaseOrders: filteredList, totalAmounts });
  }, [nonLookUpfilters]);

  function changeButtonFilter(filter) {
    changeFilters({ ...filters, [filter]: !filters[filter] });
  }

  function changeNonLookUpButtonFilter(filter) {
    setNonLookUpFilters({
      ...nonLookUpfilters,
      [filter]: !nonLookUpfilters[filter],
    });
  }

  const markInstance = new Mark(document.getElementById(BODY_ID));

  function performMark(keyword = "") {
    var options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: excludeElementsScreen,
    };
    markInstance.unmark({
      done: () => {
        markInstance.mark(keyword, options);
      },
    });
  }

  useEffect(() => {
    if (!loading) {
      setTimeout(() => performMark(nonLookUpfilters.query), 150);
    }
  }, [nonLookUpfilters, loading, rowsPerPage, page]);

  function resetFilters() {
    setFilters({
      factory: [],
      customer: [],
      dateRange: "",
      date: "",
      inProgress: true,
      completed: false,
      voided: false,
      late: false,
      nearDue: false,
    });
    setNonLookUpFilters({
      ...nonLookUpfilters,
      week: false,
      query: "",
      user: false,
      favorite: false,
    });
    setFilterDate("");
    setExistsDate(false);
    setPage(1);
    onDebounce();
  }

  function changeDate(date) {
    setFilters({
      ...filters,
      dateRange: date,
      date: filterDate,
    });
    setOpenDate(false);
    setOpenList(false);
  }

  function handleCloseDatePicker() {
    setOpenDate(false);
    setOpenList(false);
    if (!filterDate) {
      setFilters({ ...filters, date: "" });
    }
  }

  function onOpenDatePicker(field) {
    setOpenList(true);
    setExistsDate(true);
    setFilterDate(field);
    setFilters({
      ...filters,
      date: field,
      dateRange: { start: "", end: "" },
    });
    setTimeout(() => setOpenDate(true), 50);
  }

  function handleChangePage(newPage) {
    setPage(newPage);
  }

  function renderFooter(list = [], renderFooterTable, totalAmounts) {
    return (
      <React.Fragment>
        <FooterTable
          footer={footerPurchaseOrderList(list.length, totalAmounts, isAllowed)}
          headerCells={headerCells.sort(sortObjectsBy("index", false))}
          isThereColumnSetting={true}
        />
      </React.Fragment>
    );
  }

  function getCSVBody(purchaseOrders) {
    return purchaseOrders.map((item) => {
      return {
        favorite: favorites.map((favoriteId) => favoriteId).includes(item.id)
          ? "YES"
          : "NO",
        status: item.late ? "Red" : item.nearDue ? "Orange" : "",
        progress: item.progress,
        mentions: item.totalMentions,
        PONumber: item.number,
        SONumber: item.SONumber,
        shipments:
          item.shipments.map((shipment) => shipment.number).join(", ") + " \n ",
        customerName: item.customerName,
        date:
          !item.date || item.date === ""
            ? ""
            : moment(item.date).format("M/D/YY"),
        POShipDate:
          !item.shipDate || item.shipDate === ""
            ? ""
            : moment(item.shipDate).format("M/D/YY"),
        creationDate:
          !item.creationDate || item.creationDate === ""
            ? ""
            : moment(item.creationDate).format("M/D/YY"),
        orderReadyDate:
          !item.orderReadyDate || item.orderReadyDate === ""
            ? ""
            : moment(item.orderReadyDate).format("M/D/YY"),
        factoryName: item.factoryName,
        discount: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.discount).format("0,0.00")
          : "",
        discountPercentage: item.discountPercentage || "",
        total: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.total).format("0,0.00")
          : "",
        subtotal: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.subtotal).format("0,0.00")
          : "",
        deposit: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.deposit).format("0,0.00")
          : "",
        balance: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.balance).format("0,0.00")
          : "",
        potentialLateFee: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.potentialLateFee).format("0,0.00")
          : "",
        totalWeight: isAllowed(generalPermissions.PURCHASE_ORDER_AMOUNT)
          ? numeral(item.totalWeight).format("0,0.00")
          : "",
        CBM: numeral(item.CBM).format("0,0.00"),
        shippingTerms: item.shippingTerms,
        invoice: item.invoice,
        uniqueItems: item.uniqueItems,
        totalShipments: item.shipmentIds.length,
        poLabels: getListValuesFromArray({
          arr: item.tags || [],
          list: tags || [],
          field: "name",
        }),
      };
    });
  }

  function handleUpdateItem(item, tagReference, poId) {
    let purchaseOrdersCpy = [...purchaseOrders];
    if (tagReference === dbTables.PURCHASE_ORDERS) {
      purchaseOrdersCpy = purchaseOrdersCpy.map((po) => {
        if (item.id === po.id) {
          return {
            ...item,
          };
        }
        return po;
      });
    } else if (tagReference === dbTables.SHIPMENTS) {
      purchaseOrdersCpy = purchaseOrdersCpy.map((po) => ({
        ...po,
        shipments: po.shipments.map((shipment) => {
          if (shipment.id === item.id) {
            return item;
          }
          return shipment;
        }),
      }));
    } else if (tagReference === dbTables.SALES_ORDERS) {
      purchaseOrdersCpy = purchaseOrdersCpy.map((po) => {
        if (po.id === poId) {
          return {
            ...po,
            salesOrders: po.salesOrders.map((so) => {
              if (so.id === item.id) {
                return item;
              }
              return so;
            }),
          };
        }
        return po;
      });
    }
    setPurchaseOrders(purchaseOrdersCpy);
    const { filteredList, totalAmounts } = filterList({
      list: purchaseOrdersCpy,
      favorites: favorites,
      headerCells,
      headerColumns,
      nonLookUpfilters,
      userMentions,
    });

    setDataFiltered({
      purchaseOrders: filteredList,
      totalAmounts,
    });
  }

  function areAllPOExpanded({ purchaseOrders = [], purchaseOrderSetup = {} }) {
    const isThereASectionClosed = purchaseOrders.some((purchaseOrder) => {
      if (!purchaseOrderSetup[purchaseOrder.id]) {
        return true;
      } else if (!purchaseOrderSetup[purchaseOrder.id].shipments) {
        return true;
      }
      return false;
    });
    return !isThereASectionClosed;
  }

  const clearFilterData = ({ filterKey }) => {
    setFilters({ ...filters, [filterKey]: [] });
    onDebounce();
  };

  const filterCustomerActive = filters.customer?.length > 0;
  const filterFactoryActive = filters.factory?.length > 0;
  const filterTagActive = isAllowed("see_tags") && filters.tag?.length > 0;

  return (
    <React.Fragment>
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          description={
            <React.Fragment>
              You do not have access to the shipment documents folder for this
              shipment
            </React.Fragment>
          }
          onClose={() => setOpenAttentionModal(false)}
          onClick={() => setOpenAttentionModal(false)}
        />
      )}
      {loading && (
        <LoadingBackdrop
          size={60}
          backdropStyle={{
            position: "fixed",
          }}
          withLogo={true}
        />
      )}
      <POListContainer className="root">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <GeneralFilters
            onChange={changeFilters}
            dateFields={dateFields}
            currentFilters={filters}
            onOpenDatePicker={onOpenDatePicker}
            handleListOpen={(value) => {
              setTimeout(() => setOpenList(value), value ? 200 : 50);
            }}
            enabledFields={{
              customer: true,
              factory: true,
              date: true,
              tag: true,
            }}
          />
          <SearchBox
            placeholder="Search for PO or Sales Order or shipment number, etc."
            value={nonLookUpfilters.query}
            hasBeenReseted={() => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: "" });
            }}
            filters={nonLookUpfilters}
            onDebounceValue={(nonLookUpfilters, value) => {
              setNonLookUpFilters({ ...nonLookUpfilters, query: value });
            }}
          />
        </div>
        <div className="contentContainer">
          <div
            className="buttonsFilter"
            style={{
              gridTemplateColumns: getScreenListGrid({
                grid: LIST_VIEW_BUTTONS_GRID.SO,
                filtersActive: [
                  filterCustomerActive,
                  filterFactoryActive,
                  filterTagActive,
                ],
              }),
            }}
          >
            <ButtonFilter
              buttonId="list-view-button-filter-favorite"
              value={nonLookUpfilters.favorite}
              className="buttonStar"
              onClick={() => changeNonLookUpButtonFilter("favorite")}
              boxShadowType={BUTTON_FILTER_BOX_SHADOW.FAVORITE}
            >
              <StarIcon />
            </ButtonFilter>
            <LateNearDueDiamondButtons
              filters={filters}
              changeButtonFilter={changeButtonFilter}
            />

            <ButtonFilter
              buttonId="list-view-button-filter-useronly"
              value={nonLookUpfilters.user}
              onClick={() => changeNonLookUpButtonFilter("user")}
              toolTip="only"
              className="onlyAvatar"
            >
              {getUserAvatar({
                user,
                hasToolTip: false,
                styles: {
                  width: 24,
                  height: 24,
                  marginRight: 5,
                  fontSize: 13,
                  outline: "2px solid #000",
                },
              })}
              <span className="onlyText"> Only</span>
            </ButtonFilter>
            <StatusOrderButtons
              changeButtonFilter={changeButtonFilter}
              filters={{ ...filters, existsDate }}
            />

            {filterCustomerActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-customer"
                onClear={() => clearFilterData({ filterKey: "customer" })}
                label={filters.customer.length === 1 ? "Customer" : "Customers"}
                tooltip={
                  getToolTipArrayValue(filters.customer, customers).tooltip
                }
                value={getToolTipArrayValue(filters.customer, customers).value}
              />
            )}
            {filterFactoryActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-factory"
                onClear={() => clearFilterData({ filterKey: "factory" })}
                label={filters.factory.length === 1 ? "Vendor" : "Vendors"}
                tooltip={
                  getToolTipArrayValue(filters.factory, factories).tooltip
                }
                value={getToolTipArrayValue(filters.factory, factories).value}
              />
            )}
            {filterTagActive && (
              <FilterDisplay
                clearIconId="list-view-filter-badge-clear-label"
                onClear={() => clearFilterData({ filterKey: "tag" })}
                label={filters.tag.length === 1 ? "Label" : "Labels"}
                tooltip={getToolTipArrayValue(filters.tag, tags).tooltip}
                value={tags && getToolTipArrayValue(filters.tag, tags).value}
              />
            )}

            {existsDate && (
              <>
                <div
                  onClick={() => {
                    console.log(filters);
                    setOpenDate(true);
                    setOpenList(true);
                  }}
                  ref={dateRef}
                >
                  <FilterDisplay
                    clearIconId="list-view-filter-badge-clear-date"
                    onClear={() => {
                      setFilterDate("");
                      setExistsDate(false);
                      setFilters({
                        ...filters,
                        date: "",
                        dateRange: { start: new Date(), end: new Date() },
                      });
                      setOpenDate(false);
                      setOpenList(false);
                      onDebounce();
                    }}
                    label={filters.date}
                    tooltip={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                    value={
                      filters.dateRange
                        ? `${getTheDate(
                            filters.dateRange.start
                          )} - ${getTheDate(filters.dateRange.end)}`
                        : "Pick a date"
                    }
                  />
                </div>
                {openDate && (
                  <DateRangePicker
                    open={openDate}
                    el={dateRef.current}
                    onClose={handleCloseDatePicker}
                    onChange={changeDate}
                    value={filters.dateRange}
                    cancelIcon
                    maxDate={getMaxDate(filters.date)}
                  />
                )}
              </>
            )}
            <ButtonReset
              id="list-view-button-filter-reset"
              className="root"
              onClick={resetFilters}
            >
              {matches ? "RESET" : "RESET FILTERS"}
            </ButtonReset>
          </div>
          <ToolsButtonScreen
            typeSetup={columnHeaderSetup.PO_HEADER_SETUP}
            nonLookUpfilters={nonLookUpfilters}
            changeNonLookUpButtonFilter={changeNonLookUpButtonFilter}
            headerCells={headerCells}
            csvData={getCSVBody(dataFiltered.purchaseOrders)}
            onChangeHeader={(newItems) => setHeaderCells(newItems)}
            hiddenButton={{
              [orderType.PURCHASE_ORDER]: true,
              [orderType.SHIPMENT]: true,
            }}
            hiddenDataAction={(value) => {
              let purchaseOrderSetup = {};
              purchaseOrders.forEach((purchaseOrder) => {
                purchaseOrderSetup[purchaseOrder.id] = {
                  shipments: !value,
                  SOs: !value,
                };
              });
              setCurrentPOSetup({ ...purchaseOrderSetup });
              setPurchaseOrderSetup({ ...purchaseOrderSetup });
            }}
            isAllExpanded={areAllPOExpanded({
              purchaseOrders,
              purchaseOrderSetup: currentPOSetup,
            })}
            onSaveHeaders={async (newItems) => {
              setLoading(true);
              const successfullySaved = await updateColumnHeaders({
                header: columnHeaderSetup.PO_HEADER_SETUP,
                columnHeaders: newItems,
                user: user,
              });
              if (successfullySaved) {
                setLoading(false);
              }
            }}
          />

          <POTableContainer className="contentListPO">
            <div className={"tableContainer"} id={BODY_ID}>
              <MainTableRefactor
                emptyFilter={
                  !filters.inProgress && !filters.completed && !filters.voided
                }
                headCells={headerCells.sort(sortObjectsBy("index", false))}
                bodyRows={dataFiltered.purchaseOrders.slice(
                  (page - 1) * rowsPerPage,
                  page * rowsPerPage
                )}
                renderRow={(item, index) => {
                  return (
                    <PurchaseOrdersTableRow
                      key={item.id + index}
                      item={item}
                      favorites={favorites}
                      user={user}
                      filters={filters}
                      isAllowed={isAllowed}
                      headers={headerCells.sort(sortObjectsBy("index", false))}
                      tags={tags}
                      onUpdateItem={handleUpdateItem}
                      isThereColumnSetting={true}
                      purchaseOrderSetup={currentPOSetup}
                      nonLookUpfilters={nonLookUpfilters}
                      onChangePurchaseOrderSetup={({ shipments, SOs }) => {
                        handleSetupStorage({
                          item,
                          shipmentValue: shipments,
                          soValue: SOs,
                        });
                      }}
                      favoritesDB={favoritesDB}
                    />
                  );
                }}
                footer={renderFooter(
                  dataFiltered.purchaseOrders,
                  true,
                  dataFiltered.totalAmounts
                )}
                filters={nonLookUpfilters}
                onUpdateFilters={(nonLookUpfilters) => {
                  setPage(1);
                  setNonLookUpFilters({ ...nonLookUpfilters });
                }}
                onResizeColumn={async ({ cell, offset }) => {
                  setLoading(true);
                  let newHeaderCells = [...headerCells];
                  newHeaderCells = newHeaderCells.map((headerCell) => {
                    if (headerCell.name === cell.name) {
                      return {
                        ...headerCell,
                        styles: {
                          ...headerCell.styles,
                          width: offset,
                        },
                      };
                    }
                    return headerCell;
                  });
                  let newItemsCpy = [...newHeaderCells];
                  newItemsCpy = newItemsCpy.map((item) => ({
                    ...new ColumnHeader({
                      enabled: item.enabled,
                      index: item.index,
                      name: item.name,
                      unmovable: item.unmovable || false,
                      width: item.styles.width,
                    }),
                  }));
                  setHeaderCells(newHeaderCells);
                  const successfullySaved = await updateColumnHeaders({
                    header: columnHeaderSetup.PO_HEADER_SETUP,
                    columnHeaders: newItemsCpy,
                    user,
                  });
                  if (successfullySaved) {
                    setLoading(false);
                  }
                }}
              />
            </div>

            <BasicPagination
              rowsPerPage={rowsPerPage}
              page={page}
              count={getCountPagination(
                dataFiltered.purchaseOrders,
                rowsPerPage
              )}
              onChangePagination={(event, newPage) => {
                handleChangePage(newPage);
              }}
              onChangeRowsPerPage={(event) => {
                setRowsPerPage(+event.target.value);
                setPage(1);
              }}
            />
          </POTableContainer>
        </div>
      </POListContainer>
    </React.Fragment>
  );
  function getCountPagination(purchaseOrders, rowsPerPage) {
    return Math.ceil(purchaseOrders.length / rowsPerPage);
  }
}

export default PurchaseOrdersScreen;
