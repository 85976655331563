import { ORDER_TYPE } from "../../api/types/dbTables";

const enMessages = {
  "generic.text.create": "Create",
  "generic.text.done": "Done",
  "generic.text.cancel": "Cancel",
  "generic.text.delete": "Delete",
  "generic.text.edit": "Edit",
  "generic.text.yes": "Yes",
  "generic.text.no": "No",
  "generic.text.back": "Back",
  "generic.text.submit": "Submit",
  "generic.text.accept": "Accept",
  "generic.text.confirm": "Confirm",
  "generic.text.update": "Update",
  "generic.text.save": "Save",
  "generic.text.reassign": "Reassign",
  "generic.text.description": "Description",
  "generic.text.void": "Void",
  "generic.text.active": "Active",
  "generic.text.active/inactive": "Active/Inactive",
  "generic.text.name": "Name",
  "generic.text.category": "Category",
  "generic.text.createdBy": "Created By",
  "generic.text.dateCreated": "Date Created",
  "generic.text.updatedBy": "Updated By",
  "generic.text.dateUpdated": "Date Updated",
  "generic.text.blank": " ",
  "generic.textForm.name": "Name",
  "generic.textForm.email": "Email",
  "generic.textForm.cellPhone": "Cell phone",
  "generic.textForm.firstName": "First name",
  "generic.textForm.lastName": "Last name",
  "generic.textForm.displayName": "Display name",
  "generic.textForm.role": "Role",
  "generic.textForm.avatar": "Avatar",
  "generic.textForm.permissionGroup": "Access Groups",
  "generic.textForm.typeOfUser": "Type of user",

  "shipment.table.save": " Save changes to item table",
  "sidebar.feedback": "Feedback",
  "sidebar.alerts": "Alerts",
  "sidebar.appBar": "App Bar",
  "sidebar.avatars": "Avatars",
  "sidebar.buttons": "Buttons",
  "sidebar.bottomNavigations": "Bottom Navigations",
  "sidebar.badges": "Badges",
  "sidebar.cards": "Cards",
  "sidebar.cardsMasonry": "Cards Masonry",
  "sidebar.chip": "Chip",
  "sidebar.dialog": "Dialog",
  "sidebar.dividers": "Dividers",
  "sidebar.drawers": "Drawers",
  "sidebar.popover": "Popover",
  "sidebar.expansionPanel": "Expansion Panel",
  "sidebar.gridList": "Grid List",
  "sidebar.list": "List",
  "sidebar.menu": "Menu",
  "sidebar.progress": "Progress",
  "sidebar.snackbar": "Snackbar",
  "sidebar.selectionControls": "Selection Controls",
  "sidebar.tabs": "Tabs",
  "sidebar.permissionManagement": "Permission Management",
  invoice: "Invoice",
  Shipments: "Shipments",
  Expenses: "Expenses",
  "title.text.Supplier": "Supplier",
  "title.text.GLAccount": "G/L Account",
  "title.text.GLAccount.update": "Update G/L Account",
  "title.text.GLAccount.create": "Create G/L Account",
  "title.text.category.update": "Update Category",
  "title.text.category.create": "Create Category",
  "title.text.label.update": "Update Label",
  "title.text.label.create": "Create Label",
  "title.text.expenses": "Expenses",
  "widgets.currentDate": "Current Date",
  "widgets.todayOrders": "Today Orders",
  "widgets.toDoList": "To Do Lists",
  "widgets.discoverPeople": "Discover People",
  "widgets.commments": "Comments",
  "widgets.newCustomers": "New Customers",
  "widgets.recentNotifications": "Recent Notifications",
  "widgets.appNotifications": "App Notifications",
  "widgets.ratings": "Ratings",
  "widgets.newEmails": "New Emails",
  "widgets.siteVisitors": "Site Visitors",
  "widgets.socialCompanines": "Social Companines",
  "widgets.recentActivities": "Recent Activities",
  "widgets.gallery": "Gallery",
  "widgets.pricing": "Pricing",
  "widgets.enterpriseEdition": "Enterprise Edition",
  "widgets.personalEdition": "Personal Edition",
  "widgets.teamEdition": "Team Edition",
  "widgets.standard": "Standard",
  "widgets.advanced": "Advanced",
  "widgets.master": "Master",
  "widgets.Mega": "Mega",
  "widgets.logIn": "Log In",
  "widgets.signUp": "Sign Up",
  "widgets.lockScreen": "Lock Screen",
  "widgets.alertsWithLink": "Alerts With Link",
  "widgets.additionalContent": "Additional Content",
  "widgets.alertDismiss": "Alert Dismiss",
  "widgets.uncontrolledDisableAlerts": "Uncontrolled Disable Alerts",
  "widgets.contexualAlerts": "Contexual Alerts",
  "widgets.alertsWithIcons": "Alerts With Icons",
  "widgets.Simple App Bars": "Simple App Bars",
  "widgets.appBarsWithButtons": "App Bars With Buttons",
  "widgets.imageAvatars": "Image Avatars",
  "widgets.lettersAvatars": "Letters Avatars",
  "widgets.iconsAvatars": "Icons Avatars",
  "widgets.flatButtons": "Flat Buttons",
  "widgets.raisedButton": "Raised Button",
  "widgets.buttonWithIconAndLabel": "Button With Icon And Label",
  "widgets.floatingActionButtons": "Floating Action Buttons",
  "widgets.iconButton": "Icon Button",
  "widgets.socialMediaButton": "Social Media Button",
  "widgets.reactButton": "React Button",
  "widgets.buttonOutline": "Button Outline",
  "widgets.buttonSize": "Button Size",
  "widgets.buttonState": "Button State",
  "widgets.buttonNavigationWithNoLabel": "button Navigation With No Label",
  "widgets.buttonNavigation": "Button Navigation",
  "widgets.iconNavigation": "Icon Navigation",
  "widgets.badgeWithHeadings": "Badge With Headings",
  "widgets.contexualVariations": "Contexual Variations",
  "widgets.badgeLinks": "Badge Links",
  "widgets.materialBadge": "Material Badge",
  "widgets.simpleCards": "Simple Cards",
  "widgets.backgroundVarient": "Background Varient",
  "widgets.cardOutline": "Card Outline",
  "widgets.overlayCard": "Overlay Card",
  "widgets.cardGroup": "Card Group",
  "widgets.cardTitle": "Card Title",
  "widgets.speacialTitleTreatment": "Speacial Title Treatment",
  "widgets.chipWithClickEvent": "Chip With Click Event",
  "widgets.chipArray": "Chip Array",
  "widgets.dialogs": "Dialogs",
  "widgets.listDividers": "List Dividers",
  "widgets.insetDividers": "Inset Dividers",
  "widgets.temporaryDrawers": "Temporary Drawers",
  "widgets.permanentDrawers": "Permanent Drawers",
  "widgets.simpleExpansionPanel": "Simple Expansion Panel",
  "widgets.controlledAccordion": "Controlled Accordion",
  "widgets.secondaryHeadingAndColumns": "Secondary Heading And Columns",
  "widgets.imageOnlyGridLists": "Image Only Grid Lists",
  "widgets.advancedGridLists": "Advanced Grid Lists",
  "widgets.singleLineGridLists": "Single Line Grid Lists",
  "widgets.simpleLists": "Simple Lists",
  "widgets.folderLists": "Folder Lists",
  "widgets.listItemWithImage": "List Item With Image",
  "widgets.switchLists": "Switch Lists",
  "widgets.insetLists": "Inset Lists",
  "widgets.nestedLists": "Nested Lists",
  "widgets.checkboxListControl": "Checkbox List Control",
  "widgets.pinedSubHeader": "Pined Sub Header",
  "widgets.InteractiveLists": "Interactive Lists",
  "widgets.simpleMenus": "Simple Menus",
  "widgets.selectedMenu": "Selected Menu",
  "widgets.maxHeightMenu": "Max Height Menu",
  "widgets.changeTransition": "Change Transition",
  "widgets.paper": "Paper",
  "widgets.anchorPlayGround": "Anchor Play Ground",
  "widgets.tooltip": "ToolTip",
  "widgets.positionedToolTips": "Positioned Snackbar",
  "widgets.circularProgressBottomStart": "Circular Progress Bottom Start",
  "widgets.interactiveIntegration": "Interactive Integration",
  "widgets.determinate": "Determinate",
  "widgets.linearProgressLineBar": "Linear Progress Line Bar",
  "widgets.indeterminate": "Indeterminate",
  "widgets.buffer": "Buffer",
  "widgets.query": "Query",
  "widgets.transitionControlDirection": "Transition Control Direction",
  "widgets.simpleSnackbar": "Simple Snackbar",
  "widgets.positionedSnackbar": "Positioned Snackbar",
  "widgets.contexualColoredSnackbars": "Contexual Colored Snackbars",
  "widgets.simpleCheckbox": "Simple Checkbox",
  "widgets.interminateSelection": "Interminate Selection",
  "widgets.disabledCheckbox": "Disabled Checkbox",
  "widgets.customColorCheckbox": "Custom Color Checkbox",
  "widgets.VerticalStyleCheckbox": "Vertical Style Checkbox",
  "widgets.horizontalStyleCheckbox": "Horizontal Style Checkbox",
  "widgets.radioButtons": "Radio Buttons",
  "widgets.disabledRadio": "Disabled Radio",
  "widgets.withError": "With Error",
  "widgets.switches": "Swiches",
  "widgets.dateAndTimePicker": "Date And Time Picker",
  "widgets.defaultPicker": "Default Picker",
  "widgets.timePicker": "Time Picker",
  "widgets.weekPicker": "Week Picker",
  "widgets.defaultDatePicker": "Default Date Picker",
  "widgets.customPicker": "Custom Picker",
  "widgets.tabs": "Tabs",
  "widgets.fixedTabs": "Fixed Tabs",
  "widgets.basicTab": "Basic Tab",
  "widgets.wrappedLabels": "Wrapped Labels",
  "widgets.centeredLabels": "Centered Labels",
  "widgets.forcedScrolledButtons": "Forced Scrolled Buttons",
  "widgets.iconsTabs": "Icons Tabs",
  "widgets.withDisableTabs": "With Disable Tabs",
  "widgets.iconWithLabel": "Icon With Label",
  "widgets.stepper": "Stepper",
  "widgets.horizontalLinear": "Horizontal Linear",
  "widgets.horizontalNonLinear": "Horizontal Non Linear",
  "widgets.horizontalLinerAlternativeLabel":
    "Horizontal Liner Alternative Label",
  "widgets.horizontalNonLinerAlternativeLabel":
    "Horizontal Non Liner Alternative Label",
  "widgets.verticalStepper": "Vertical Stepper",
  "widgets.descriptionAlert": "Description Alert",
  "widgets.customIconAlert": "Custom Icon Alert",
  "widgets.withHtmlAlert": "With Html Alert",
  "widgets.promptAlert": "Prompt Alert",
  "widgets.passwordPromptAlert": "Password Prompt Alert",
  "widgets.customStyleAlert": "Custom Style Alert",
  "widgets.autoComplete": "Auto Complete",
  "widgets.reactSelect": "React Select",
  "widgets.downshiftAutoComplete": "Downshift Auto Complete",
  "widgets.reactAutoSuggests": "React Auto Suggests",
  "widgets.aboutUs": "About Us",
  "widgets.ourVission": "Our Vission",
  "widgets.ourMissions": "Our Missions",
  "widgets.ourMotivation": "Our Motivation",
  "widgets.defualtReactForm": "Defualt React Form",
  "widgets.url": "Url",
  "widgets.textArea": "Text Area",
  "widgets.file": "File",
  "widgets.formGrid": "Form Grid",
  "widgets.inlineForm": "Inline Form",
  "widgets.inputSizing": "Input Sizing",
  "widgets.inputGridSizing": "Input Grid Sizing",
  "widgets.hiddenLabels": "Hidden Labels",
  "widgets.formValidation": "Form Validation",
  "widgets.number": "Number",
  "widgets.date": "Date",
  "widgets.time": "Time",
  "widgets.color": "Color",
  "widgets.search": "Search",
  "widgets.selectMultiple": "Select Multiple",
  "widgets.inputWithSuccess": "Input With Success",
  "widgets.inputWithDanger": "Input With Danger",
  "widgets.simpleTextField": "Simple Text Field",
  "widgets.componet": "Components",
  "widgets.layouts": "Layouts",
  "widgets.inputAdorements": "Input Adorements",
  "widgets.formattedInputs": "Formatted Inputs",
  "widgets.simpleSelect": "Simple Select",
  "widgets.nativeSelect": "Native Select",
  "widgets.MutltiSelectList": "Mutlti Select List",
  "widgets.lineChart": "Line Chart",
  "widgets.barChart": "Bar Chart",
  "widgets.stackedBarChart": "Stacked Bar Chart",
  "widgets.lineBarAreaChart": "Line Bar Area Chart",
  "widgets.areaChart": "Area Chart",
  "widgets.stackedAreaChart": "Stacked Area Chart",
  "widgets.verticalChart": "Vertical Chart",
  "widgets.radarChart": "Radar Chart",
  "widgets.doughnut": "Doughnut",
  "widgets.polarChart": "Polar Chart",
  "widgets.pieChart": "Pie Chart",
  "widgets.bubbleChart": "Bubble Chart",
  "widgets.horizontalBar": "Horizontal Bar",
  "widgets.basicTable": "Basic Table",
  "widgets.contexualColoredTable": "Contexual Colored Table",
  "widgets.dataTable": "Data Table",
  "widgets.employeeList": "Employee List",
  "widgets.responsiveTable": "Responsive Table",
  "widgets.responsiveFlipTable": "Responsive Flip Table",
  "widgets.reactGridControlledStateMode": "React Grid Controlled State Mode",
  "widgets.productsReports": "Products Reports",
  "widgets.taskList": "Task List",
  "widgets.basicCalender": "Basic Calender",
  "widgets.culturesCalender": "Cultures Calender",
  "widgets.dragAndDropCalender": "Drag And Drop Calender",
  "widgets.selectableCalender": "Selectable Calender",
  "widgets.customRendering": "Custom Rendering",
  "widgets.customCalender": "Custom Calender",
  "widgets.searchMailList": "Search Mail List",
  "components.buyNow": "Buy Now",
  "compenets.choose": "Choose",
  "widgets.forgetPassword": "Forget Password",

  "components.userManagement.userRolesModal.createRoles": "Create a role",
  "components.userManagement.userPermissionsModal.addUserRoles":
    "Add users to this role",
  "components.userManagement.userCompany.createUser": "Create User",
  "components.userManagement.userCompany.editUser": "Edit User",
  "components.saleOrders.ActionsModal.voidPO": "Void PO",
  "components.saleOrders.ActionsModal.voidPOPQ": "Void PO/PQ",
  "components.saleOrders.ActionsModal.voidSO": "Void SO",
  "compenets.signIn": "Sign In",
  "compenets.dontHaveAccountSignUp": "Dont Have Account SignUp",
  "compenets.enterUserName": "Enter User Name",
  "compenets.enterEmailAddress": "Enter Email Address",
  "compenets.confirmPasswords": "Confirm Passwords",
  "components.alreadyHavingAccountSignIn": "Already Having Account Sign In",
  "components.enterYourPassword": "Enter Your Password",
  "components.unlock": "Unlock",
  "components.enterPasswords": "Enter Passwords",
  "components.resestPassword": "Resest Password",
  "components.pageNotfound": "Page not Found",
  "components.goToHomePage": "Go To Home Page",
  "components.sorryServerGoesWrong": "Sorry Server Goes Wrong",
  "components.persistentDrawer": "Persistent Drawer",
  "components.withHtml": "With Html",
  "components.prompt": "Prompt",
  "components.withDescription": "With Description",
  "components.success": "Success",
  "components.passwordPrompt": "Password Prompt",
  "components.warning": "Warning",
  "components.customIcon": "Custom Icon",
  "components.customStyle": "Custom Style",
  "components.basic": "Basic",
  "components.submit": "Submit",
  "components.compose": "Compose",
  "components.sendMessage": "Send Message",
  "components.addNewTasks": "Add New Tasks",
  "components.addToCart": "Add To Cart",
  "components.payNow": "Pay Now",
  "components.print": "Print",
  "components.cart": "Cart",
  "components.viewCart": "View Cart",
  "components.checkout": "Checkout",
  "widgets.QuickLinks": "Quick Links",
  "widgets.upgrade": "upgrade",
  "widgets.app": "App",
  "widgets.addNew": "Add New",
  "widgets.orderDate": "Order Date",
  "widgets.status": "Status",
  "widgets.trackingNumber": "Tracking Number",
  "widgets.action": "Action",
  "widgets.designation": "Designation",
  "widgets.subject": "subject",
  "widgets.send": "Send",
  "widgets.saveAsDrafts": "Save As Drafts",
  "widgets.onlineSources": "Online Sources",
  "widgets.lastMonth": "Last Month",
  "widgets.widgets": "Widgets",
  "widgets.listing": "Listing",
  "widgets.paid": "Paid",
  "widgets.refunded": "Refunded",
  "widgets.done": "Done",
  "widgets.pending": "Pending",
  "widgets.cancelled": "Cancelled",
  "widgets.approve": "Approve",
  "widgets.following": "Following",
  "widgets.follow": "follow",
  "widgets.graphs&Charts": "Graphs & Charts",
  "widgets.open": "Open",
  "widgets.bounced": "Bounced",
  "widgets.spam": "Spam",
  "widgets.unset": "Unset",
  "widgets.bandwidthUse": "Bandwidth Use",
  "widgets.dataUse": "Data Use",
  "widgets.unsubscribe": "Unsubscribe",
  "widgets.profile": "Profile",
  "widgets.messages": "Messages",
  "widgets.support": "Support",
  "widgets.faq(s)": "Faq(s)",
  "widgets.upgradePlains": "Upgrade Plains",
  "widgets.logOut": "Log Out",
  "widgets.mail": "Mail",
  "widgets.adminTheme": "Admin Theme",
  "widgets.wordpressTheme": "Wordpress Theme",
  "widgets.addToCart": "Add To Cart",
  "widgets.plan": "Plan",
  "widgets.basic": "Basic",
  "widgets.pro": "Pro",
  "widgets.startToBasic": "Start To Basic",
  "widgets.upgradeToPro": "Upgrade To Pro",
  "widgets.upgradeToAdvance": "Upgrade To Advance",
  "widgets.comparePlans": "Compare Plans",
  "widgets.free": "Free",
  "widgets.frequentlyAskedQuestions": "Frequently Asked Questions",
  "widgets.searchIdeas": "Search Ideas",
  "widgets.startDate": "Start Date",
  "widgets.endDate": "End Date",
  "widgets.category": "Category",
  "widgets.apply": "Apply",
  "widgets.yesterday": "Yesterday",
  "widgets.totalOrders": "Total Orders",
  "widgets.totalVisitors": "Total Visitors",
  "widgets.typeYourQuestions": "Type Your Questions",
  "widgets.username": "Username",
  "widgets.password": "Password",
  "widgets.signIn": "Sign In",
  "widgets.enterYourPassword": "Enter Your Password",
  "widgets.alreadyHavingAccountLogin": "Already Having Account Login",
  "widgets.composeMail": "Compose Mail",
  "widgets.issue": "Issue",
  "widgets.recentChat": "Recent Chat",
  "widgets.previousChat": "Previous Chat",
  "widgets.all": "All",
  "widgets.filters": "Filters",
  "widgets.deleted": "Deleted",
  "widgets.starred": "Starred",
  "widgets.frontend": "Frontend",
  "widgets.backend": "Backend",
  "widgets.api": "Api",
  "widgets.simpleAppBar": "Simple App Bar",
  "widgets.recents": "Recents",
  "widgets.cardLink": "Card Link",
  "widgets.anotherLink": "Another Link",
  "widgets.cardSubtitle": "card Subtitle",
  "widgets.confirmationDialogs": "Confirmation Dialogs",
  "widgets.deletableChip": "Deletable Chip",
  "widgets.customDeleteIconChip": "Custom Delete Icon Chip",
  "widgets.openAlertDialog": "Open Alert Dialog",
  "widgets.openResponsiveDialog": "Open Responsive Dialog",
  "widgets.openSimpleDialog": "Open Simple Dialog",
  "widgets.openFormDialog": "Open Form Dialog",
  "widgets.follower": "Follower",
  "widgets.important": "Important",
  "widgets.private": "Private",
  "widgets.openLeft": "Open Left",
  "widgets.openRight": "Open Right",
  "widgets.openTop": "Open Top",
  "widgets.openBottom": "Open Bottom",
  "widgets.selectTripDestination": "Select Trip Destination",
  "widgets.pinnedSubheaderList": "Pinned Subheader List",
  "widgets.singleLineItem": "Single Line Item",
  "widgets.optionA": "Option A",
  "widgets.optionB": "Option B",
  "widgets.optionC": "Option C",
  "widgets.optionM": "Option M",
  "widgets.optionN": "Option N",
  "widgets.optionO": "Option O",
  "widgets.customColor": "Custom Color",
  "widgets.centeredTabs": "Centered Tabs",
  "widgets.multipleTabs": "Multiple Tabs",
  "widgets.preventScrolledButtons": "Prevent Scrolled Buttons",
  "widgets.browse": "Browse",
  "widgets.formValidate": "Form Validate",
  "widgets.code": "Code",
  "widgets.company": "Company",
  "widgets.price": "Price",
  "widgets.change": "Change",
  "widgets.high": "High",
  "widgets.low": "Low",
  "widgets.volume": "Volume",
  "widgets.personalDetails": "Personal Details",
  "widgets.occupation": "Occupation",
  "widgets.companyName": "Company Name",
  "widgets.phoneNo": "Phone No",
  "widgets.updateProfile": "Update Profile",
  "widgets.reject": "Reject",
  "widgets.exportToExcel": "Export To Excel",
  "widgets.workWeek": "Work Week",
  "widgets.agenda": "Agenda",
  "widgets.conference": "Conference",
  "widgets.multilevel": "Multilevel",
  "widgets.dailySales": "Daily Sales",
  "widgets.today": "Today",
  "widgets.campaignPerformance": "Campaign Performance",
  "widgets.supportRequest": "Support Request",
  "widgets.usersList": "Users List ",
  "widgets.lastWeek": "Last Week",
  "themeOptions.sidebarOverlay": "Sidebar Overlay",
  "themeOptions.sidebarBackgroundImages": "Sidebar Background Images",
  "themeOptions.appSettings": "App Settings",
  "themeOptions.sidebarImage": "Sidebar Image",
  "themeOptions.miniSidebar": "Mini Sidebar",
  "themeOptions.boxLayout": "Box Layout",
  "themeOptions.rtlLayout": "Rtl Layout",
  "themeOptions.darkMode": "Dark Mode",
  "themeOptions.sidebarLight": "Light",
  "themeOptions.sidebarDark": "Dark",
  "sidebar.dateTimePicker": "Date & Time Picker",
  "components.summary": "Summary",
  "hint.whatAreYouLookingFor": "What are You Looking For",
  "components.yesterday": "Yesterday",
  "components.last7Days": "Last 7 Days",
  "components.last1Month": "Last 1 Month",
  "components.last6Month": "Last 6 Month",
  "components.spaceUsed": "Space Used",
  "components.followers": "Follower",
  "components.trending": "Trending",
  "components.paid": "Paid",
  "components.refunded": "Refunded",
  "components.done": "Done",
  "components.pending": "Pending",
  "components.cancelled": "Cancelled",
  "components.approve": "Approve",
  "components.week": "Week",
  "components.month": "Month",
  "components.year": "Year",
  "components.today": "Today",
  "components.popularity": "Popularity",
  "components.drafts": "Drafts",
  "components.sent": "Sent",
  "components.trash": "Trash",
  "components.all": "All",
  "components.do": "Do",
  "components.title": "Title",
  "components.projectName": "Project Name",
  "components.companyName": "Company Name",
  "components.openAlert": "Open Alert",
  "components.slideInAlertDialog": "Slide In Alert Dialog",
  "components.openFullScreenDialog": "Open Full Screen Dialogs",
  "components.basicChip": "Basic Chip",
  "components.clickableChip": "Clickable Chip",
  "components.left": "Left",
  "components.right": "Right",
  "components.expansionPanel1": "Expansion Panel 1",
  "components.expansionPanel2": "Expansion Panel 2",
  "components.generalSetting": "General Setting",
  "components.advancedSettings": "Advanced Settings",
  "components.occupation": "Occupation",
  "components.phoneNo": "Phone No",
  "components.address": "Address",
  "components.city": "City",
  "components.state": "State",
  "components.zipCode": "Zip Code",
  "components.social Connection": "Social Connection",
  "widgets.buyMore": "Buy More",
  "widgets.trafficChannel": "Traffic Channel",
  "widgets.stockExchange": "Stock Exchange",
  "widgets.tweets": "Tweets",
  "widgets.ourLocations": "Our Locations",
  "widgets.sales": "Sales",
  "widgets.to": "To",
  "widgets.shipTo": "Ship To",
  "widgets.unitPrice": "Unit Price",
  "widgets.total": "Total",
  "widgets.note": "Note",
  "widgets.chipWithAvatar": "Chip With Avatar",
  "widgets.chipWithTextAvatar": "Chip With Text Avatar",
  "widgets.chipWithIconAvatar": "Chip With Icon Avatar",
  "widgets.customClickableChip": "Custom Clickable Chip",
  "widgets.outlineChip": "Outline Chip",
  "widgets.disableChip": "Disable Chip",
  "widgets.alertDialog": "Alert Dialog",
  "widgets.animatedSlideDialogs": "Animated Slide Dialogs",
  "widgets.fullScreenDialogs": "Full Screen Dialogs",
  "widgets.formDialogs": "Form Dialogs",
  "widgets.simpleDialogs": "Simple Dialogs",
  "widgets.responsiveFullScreen": "Responsive Full Screen",
  "widgets.primary": "Primary",
  "widgets.social": "Social",
  "widgets.user": "User",
  "widgets.admin": "Admin",
  "widgets.permanentdrawer": "Permanent Drawer",
  "widgets.persistentdrawer": "Persistent Drawer",
  "widgets.swiches": "Swiches",
  "widgets.horizontalLinearAlternativeLabel":
    "Horizontal Linear Alternative Label",
  "widgets.horizontalNonLinearAlternativeLabel":
    "Horizontal Non Linear Alternative Label",
  "widgets.notifications": "Notifications",
  "widgets.basicAlert": "Basic Alert",
  "widgets.successAlert": "Success Alert",
  "widgets.warningAlert": "Warning Alert",
  "widgets.reactAutoSuggest": "React Auto Suggest",
  "widgets.components": "Components",
  "widgets.inputAdornments": "Input Adorements",
  "widgets.multiSelectList": "Multi Select List",
  "widgets.contextualColoredTable": "Contexual Colored Table",
  "widgets.updateYourEmailAddress": "Update Your Email Address",
  "widgets.selectADefaultAddress": "Select A Default Address",
  "widgets.activity": "Activity",
  "widgets.basicCalendar": "Basic Calendar",
  "widgets.culturesCalendar": "Cultures Calendar",
  "widgets.dragAndDropCalendar": "Drag And Drop Calendar",
  "widgets.quillEditor": "Quill Editor",
  "widgets.reactDND": "React DND",
  "widgets.dragula": "Dragula",
  "hint.searchMailList": "Search Mail List",
  "widgets.AcceptorrRejectWithin": "Accept or reject within",
  "widgets.quoteOfTheDay": "Quote Of The Day",
  "widgets.updated10Minago": "Updated 10 min ago",
  "widgets.personalSchedule": "Personal Schedule",
  "widgets.activeUsers": "Active Users",
  "widgets.totalRequest": "Total Request",
  "widgets.new": "New",
  "widgets.ShareWithFriends": "Share with friends!",
  "widgets.helpToShareText":
    "Help us spread the world by sharing our website with your friends and followers on social media!",
  "widgets.thisWeek": "This Week",
  "widgets.howWouldYouRateUs": "How would you rate us?",
  "widgets.booking": "Booking",
  "widgets.confirmed": "Confirmed",
  "widgets.monthly": "Monthly",
  "widgets.weekly": "Weekly",
  "widgets.target": "Target",
  "widgets.totalActiveUsers": "Total Active Users",
  "sidebar.user": "User",
  "sidebar.miscellaneous": "Miscellaneous",
  "sidebar.promo": "Promo",
  "themeOptions.themeColor": "Theme Color",
  "module.inbox": "Inbox",
  "module.drafts": "Drafts",
  "module.sent": "Sent",
  "module.trash": "Trash",
  "module.spam": "Spam",
  "module.frontend": "Frontend",
  "module.backend": "Backend",
  "module.api": "Api",
  "module.issue": "Issue",
  "components.emailPrefrences": "Email Prefrences",
  "components.myProfile": "My Profile",
  "sidebar.gettingStarted": "Getting Started",
  "widgets.deadline": "Deadline",
  "widgets.team": "Team",
  "widgets.projectManagement": "Project Management",
  "widgets.latestPost": "Latest Post",
  "widgets.projectTaskManagement": "Project Task Management",
  "widgets.selectProject": "Select Project",
  "widgets.activityBoard": "Activity Board",
  "widgets.checklist": "Checklist",
  "sidebar.shop": "Shop",
  "sidebar.cart": "Cart",
  "sidebar.checkout": "Checkout",
  "components.product": "Product",
  "components.quantity": "Quantity",
  "components.totalPrice": "Total Price",
  "components.removeProduct": "Remove Product",
  "components.mobileNumber": "Mobile Number",
  "components.address2Optional": "Address 2 (Optional)",
  "components.country": "Country",
  "components.zip": "Zip",
  "components.saveContinue": "Save & Continue",
  "components.placeOrder": "Place Order",
  "components.payment": "Payment",
  "components.billingAddress": "Billing Address",
  "components.ShippingAddressText":
    "Shipping address is the same as billing address.",
  "components.CartEmptyText": "Your Shopping Cart Is Empty!",
  "components.NoItemFound": "No Item Found",
  "components.goToShop": "Go To Shop",
  "components.cardNumber": "Card Number",
  "components.expiryDate": "Expiry Date",
  "components.cvv": "CVV",
  "components.nameOnCard": "Name On Card",
  "components.confirmPayment": "Confirm Payment",
  "sidebar.saas": "SAAS",
  "sidebar.level1": "Level 1",
  "sidebar.level2": "Level 2",
  "sidebar.boxed": "Boxed",
  "sidebar.extensions": "Extensions",
  "sidebar.imageCropper": "Image Cropper",
  "sidebar.videoPlayer": "Video Player",
  "sidebar.dropzone": "Dropzone",
  "widgets.baseConfig": "Base Config",
  "widgets.customControlBar": "Custom Control Bar",
  "widgets.withDownloadButton": "With Download Button",
  "widgets.httpLiveStreaming": "HTTP Live Streaming",
  "widgets.keyboardShortcuts": "Keyboard Shortcuts",
  "button.cropImage": "Crop Image",
  "widgets.preview": "Preview",
  "widgets.croppedImage": "Cropped Image",
  "widgets.transactionList": "Transaction List",
  "widgets.transferReport": " Transfer Report",
  "widgets.expenseCategory": "Expense Category",
  "widgets.upcomingEvents": "Upcoming events",
  "widgets.OngoingProjects": "Ongoing Projects",
  "widgets.ProjectStatus": "Project Status",
  "widgets.LiveChatSupport": "Live Chat Support",
  "sidebar.projects": "projects",
  "sidebar.projectDetail": "project Detail",
  "sidebar.clients": "clients",
  "sidebar.reports": "reports",
  "blockedCompany.title": "This company was disabled",
  "adminsettings.title": "Administrative Options",
  "adminsettings.sidebar.title": "Companies",
  "adminsettings.sidebar.button": "ADD NEW",
  "adminsettings.create": "CREATE NEW PROJECT",
  "adminsettings.observe": "OBSERVE",
  "adminsettings.notselected": "Please, select a company",
  "adminsettings.companynotfound":
    "We're sorry, the company you're looking for no longer exists :(",
  "adminsettings.noObservedCompanyId": "You're not observing any company",
  "salesOrderGroup.taskcount": "Task Count",
  "customers.title": "Customers",
  "customers.sidebar.title": "Customers",
  "salesordertemplate.title": "Sales Order Template",
  "salesordertemplate.assignedtitle": "Assigned To",
  "salesordertemplate.add": "Add Task",
  "errormessage.nocompanyname": "Company name cannot be empty",
  "errormessage.companynametoolong": "Company name is too long",
  "errormessage.nocompanydomain": "Company domain cannot be empty",
  "errormessage.companydomaintoolong": "Company domain is too long",
  "errormessage.invaliddomain": "Company domain is invalid",
  "customers.customermodal.latepenaltyterms.label": "Late penalty terms",
  "customers.customermodal.contactname.label": "Contact name",
  "customers.customermodal.notes.label": "Notes",
  "companytabs.customers.button.addcustomer": "ADD CUSTOMER",
  "user.profile.save": "Load diferent image",
  "companytabs.user.button.adduser": "ADD USER",
  "companytabs.user.button.addReport": "ADD REPORT",
  "companytabs.user.button.addGLAccount": "ADD G/L Account",
  "companytabs.user.button.addCategory": "NEW Category",
  "userscompany.createuserform.active": "Active",
  "userscompany.createuserform.firstname.required": "First name(Required)",
  "userscompany.createuserform.lastname.required": "Last name(Required)",
  "userscompany.createuserform.displayname.required": "Display name(Required)",
  "userscompany.createuserform.cellphone.required": "Cell phone(Required)",
  "userscompany.createuserform.email.required": "Email(Required)",
  "userscompany.createuserform.analyticsPersona.label": "App Analytics Persona",
  "userscompany.createuserform.role.required": "Role(Required)",
  "userscompany.createuserform.analyticsPersona.required":
    "App Analytics Persona(Optional)",
  "userscompany.tableheader.analyticsPerson": "Analytics App Persona",
  "userscompany.tableheader.automaticSystemUser": "Automatic System User",
  "userscompany.tableheader.active": "Active",
  "errormessage.specialcharacters":
    "Company name should only contain certain special characters(- , .)",
  "adminsettings.secondarytitle": "Settings",
  "companymodal.custommodal.createcompany": "Create a company",
  "factorymodal.custommodal.editfactory": "Edit a factory",
  "factorymodal.custommodal.createfactory": "Create a factory",
  "documents.button.create.new.folder": "New Folder",
  "documents.button.create.new.file": "New File",
  "salesorders.title": "Sales Orders",
  "salesorders.versions": "S.O. Template Versions",
  "purchaseorderdashboard.tasks": "Tasks ",
  "purchaseorderdashboard.all": "All Tasks",
  "purchaseorderdashboard.allNotes": "All",
  "purchaseorderdashboard.mineNotes": "My Mentions",
  "purchaseorderdashboard.mine": "My Tasks",
  "purchaseorderdashboard.completed": "INCLUDE COMPLETED",
  "purchaseorderdashboard.activitystream": "Activity Stream",
  "purchaseorderdashboard.activity": "Activity",
  "purchaseorderdashboard.threads": "Threads",
  "purchaseorderdashboard.notes": "Notes",
  "purchaseorderdashboard.mentions": "@Mentions",
  "purchaseorderdashboard.addNotes": "Add Notes",
  "companytabs.documents.salesorders.title": "SO File & Folder Place holders",
  "companytabs.documents.purchasesorders.title":
    "PO File & Folder Place holders",
  "companytabs.documents.shipments.title":
    "Shipment File & Folder Place holders",
  "documents.createnewfolder.save": "SAVE",
  "documents.createnewfolder.cancel": "CANCEL",
  "salesordersmanager.validation.salesorder.amount":
    "Amount field in SO is required",
  "salesordersmanager.validation.salesorder.customer":
    "Customer field in SO is required",
  "salesordersmanager.validation.salesorder.finaldestination":
    "Final destination field in SO is required",
  "salesordersmanager.validation.salesorder.loadingport":
    "Loading port field in SO is required",
  "salesordersmanager.validation.salesorder.person":
    "Person field in SO is required",
  "salesordersmanager.validation.salesorder.potentiallatefee":
    "Potential late fee field in SO is required",
  "salesordersmanager.validation.salesorder.shippingterms":
    "Shipping terms field in SO is required",
  "salesordersmanager.validation.salesorder.unloadingport":
    "Unloading port field in SO is required",
  "salesordersmanager.validation.salesorder.number":
    "Number field in SO is required",
  "salesordersmanager.validation.salesorder.numberexists":
    "SO Number value already exists",
  "salesordersmanager.validation.purchaseorder.number":
    "Number field is required in PO",
  "salesordersmanager.validation.purchaseorder.numberexists":
    "PO Number value already exists",
  "salesordersmanager.validation.purchaseorder.duplicate":
    "PO Number value deplicated",
  "salesordersmanager.validation.purchaseorder.amount":
    "Amount field is required in PO",
  "salesordersmanager.validation.purchaseorder.customer":
    "Customer field is required in PO",
  "salesordersmanager.validation.purchaseorder.factory":
    "Factory field is required in PO",
  "salesordersmanager.validation.purchaseorder.finaldestination":
    "Final destination field is required in PO",
  "salesordersmanager.validation.purchaseorder.loadingport":
    "Loading port field is required in PO",
  "salesordersmanager.validation.purchaseorder.potentiallatefee":
    "Potential late fee field is required in PO",
  "salesordersmanager.validation.purchaseorder.shippingterms":
    "Shipping terms field is required in PO",
  "salesordersmanager.validation.purchaseorder.unloadingport":
    "Unloading port field is required in PO",
  "salesordersmanager.successfulupdated": "Successful updated",
  "salesordersmanager.successfulDeleted": "Successful deleted",
  "salesordersmanager.successfulcreated": "Successful created",
  "errormessage.companyexists": "Company name already exists",
  "errormessage.companynameissue": "Company name already exists",
  "admincontent.successfullcompanynameupdated":
    "Company changes successfull updated ",
  "admincontent.companynamenotsaved": "Company changes has not been saved",
  "permissionGroup.create.custommodal": "Create Access Group",
  "permissionGroup.edit.custommodal": "Edit Access Group Name",
  "featureFlag.description.formtextfield": "Description",

  "adminsettings.unobserve": "UN-OBSERVE",
  "companyusers.validate.domain": "The email domain is not valid",
  "companyusers.validate.exists.name":
    "The user name already exists in this company",
  "companyusers.validate.required.displayname": "Display name is required",
  "companyusers.validate.exists.email": "The user email already exists",
  "companyusers.validate.exists.phonenumber":
    "The user phone number already exists",
  "Purchase Orders": "Purchase Orders",
  "Sales Orders": "Sales Orders",
  Notifications: "Notifications",
  Week: "Week",
  Tasks: "Tasks",
  "Orders by Phase": "Orders by Phase",
  Reports: "Reports",
  Factories: "Factories",
  Customers: "Customers",
  "Manage Users": "Manage User",
  "Admin Settings": "Admin Settings",
  PROPOSAL: "Proposal/Quote",
  PRE_PRODUCTION: "Pre-production",
  PRODUCTION: "Production",
  BOOKING_TRANSIT: "Booking and transit",
  PAYMENT_BILLING: "Payment and billing",
  "components.customers.customermodal.header.updatecustomer":
    "Update a customer",
  "components.customers.customermodal.header.createcustomer":
    "Create a customer",
  "components.customers.customermodal.customsnackbar.alreadyexists":
    "The customer name already exists in this company",
  "components.adminsettings.adminsidebar.adminsettingsitem.logout": "Logout",
  "components.adminsettings.adminsidebar.adminsettingsitem.settings":
    "Settings",
  "components.adminsettings.adminsidebar.adminsettingsitem.feature.flags":
    "Feature flags",
  "components.companytabs.permissionGroups.title": "Access Groups",
  "components.companytabs.permissionGroups.custombutton.addGroup": "Add Group",
  "components.companytabs.factories.button.addfactory": "Add factory",
  "components.companytabs.factorymodal.customsnackbar.message":
    "The factory name already exists in this company",
  "components.companytabs.factorymodal.customsnackbar.message.toomuchcharacters":
    "The factory name is too long, max. 55 characters",
  "components.companytabs.factorymodal.customsnackbar.message.invalidcharacters":
    "Only (- , .) are allowed special characters.",
  "components.companytabs.factorymodal.namelabel.terms": "Terms",
  "components.companytabs.factorymodal.namelabel.contact": "Contact",
  "components.companytabs.factorymodal.namelabel.notes": "Notes",
  "components.confirmationdialog.withconfirmation.custombutton.continue":
    "Continue",
  "components.documents.draganddropuploadfile.modalpermission.selectdocumenttypeandviewpermissions":
    "Access Groups",
  "components.purchaseorderlist.purchaseorderlistheader.podeliverydate":
    "PO Delivery Date",
  "components.documents.draganddropuploadfile.p.draganddrop":
    "Drag & Drop Files",
  "components.documents.menuoptions.uploadfile": "Upload Files",
  "components.documents.menuoptions.newfile": "New Files",
  "components.documents.menuoptions.newfileTemplate": "Add placeholder file",
  "components.documents.menuoptions.newfolder": "New Folder",
  "components.documents.menuoptions.newfolderTemplate": "Add Sub-folder",
  "components.documents.menuoptions.multiselect": "Multi-select",
  "components.documents.menuoptions.cancelmultiselect": "Cancel Multi-select",
  "components.documents.menuoptions.uploadnewversion": "Upload New Version",
  "components.documents.menuoptions.preview": "Preview",
  "components.documents.menuoptions.folderPreview": "Image Previews",
  "components.documents.menuoptions.copylink": "Copy Link",
  "components.documents.menuoptions.rename": "Rename",
  "components.documents.menuoptions.permissions": "Access Groups",
  "components.documents.menuoptions.download": "Download",
  "components.documents.menuoptions.moveToTopLevel": "Move to top level",
  "components.documents.menupermission.onlyonefilecanbeuploadedatatime":
    "Only one file can be uploaded at time",
  "components.documents.modalpermission.documenttype": "Access groups",
  "components.documents.modalpermission.selectone": "Select One jojo",
  "components.documents.modalpermission.viewpermission": "Current selection",
  "components.documents.modalpermission.cancel": "CANCEL",
  "components.documents.modalpermission.done": "DONE",
  "components.documenttemplate.managmentdocuments.order": "Order",
  "components.documenttemplate.managmentdocuments.documenttype":
    "Document Type",
  "components.errorcomponents.notobserving.youarenotobservinganycompany":
    "You are not observing any company",
  "components.factories.categorysection.addtask": "ADD TASK",
  "components.factories.categorysection.ordinal": "Ordinal",
  "components.factories.taskdescription": "Task description",
  "components.factories.categorysection.dependencytype": "Dependency type",
  "components.factories.categorysection.predecessor": "Predecessor",
  "components.factories.categorysection.duration": "Duration",
  "components.factories.categorysection.offset": "Offset",
  "components.factories.categorysection.person": "Person",
  "components.factories.categorysection.notificationEarly":
    "Notification completed early",
  "components.factories.categorysection.notificationLate":
    "Notification completed late",
  "components.factories.categorysection.action": "Action",
  "components.factories.categorysection.isShipment": "Shipment",
  "vendor.template.warningMessage":
    "Please make sure to fill in all fields on all tasks before saving",
  "components.factories.factorytable.miniprojecttemplate":
    "MINI PROJECT TEMPLATE",
  "components.factories.factorytemplatemodal.clonetemplate": "Clone template",
  "components.factories.salesordercontainer.salesordertasks":
    "Sales Order Task",
  "components.factories.salesordercontainer.proposal": "Proposal",
  "components.factories.salesordercontainer.preproduction": "Pre - Production",
  "components.factories.salesordercontainer.production": "Production",
  "components.factories.salesordercontainer.bookingtransit":
    "Booking & Transit",
  "components.factories.salesordercontainer.paymentbilling":
    "Payment & Billing",
  "components.purchaseorderdashboard.salesorder": "Sales Order Note",
  "components.purchaseorderdashboard.quote": "Quote Note",
  "components.purchaseorderdashboard.purchaseorder": "Purchase Order Note",
  "components.purchaseorderdashboard.purchasequote": "Purchase Quote Note",
  "components.purchaseorderdashboard.shipment": "Shipment Note",
  "components.purchaseorderdashboard.notessection.noteiseditablefor1minuteaftersubmitting":
    "This note is editable for 6 seconds after submitting.",
  "components.purchaseorderdashboard.notessection.noteInformation":
    "User's names in grey do not have permission to see the vendor of this PO.",
  "components.purchaseorderdashboard.notessection.send": "Send",
  "components.purchaseorderdashboard.projecttasktabs.all": "All",
  "components.purchaseorderdashboard.projecttasktabs.salesorder": "Sales Order",
  "components.purchaseorderdashboard.projecttasktabs.purchaseorder":
    "Purchase Order",
  "components.purchaseorderdashboard.projectnotes.newsalesordernote":
    "New Sales Order Note",
  "components.purchaseorderdashboard.projectnotes.newpurchaseordernote":
    "New Purchase Order Note",
  "components.purchaseorderdashboards.task.notassigned": "Not Assigned",
  "components.purchaseorderlist.purchaseorderlistheader.sodeliverydate":
    "SO Delivery Date",
  "components.purchaseorderlist.purchaseorderlistheader.soshipdate":
    "SO Ship Date",
  "components.purchaseorderlist.purchaseorderlistheader.poshipdate":
    "PO Ship Date",
  "components.purchaseorderlist.purchaseorderlistheader.customer": "Customer",
  "components.purchaseorderlist.purchaseorderlistheader.podate": "PO Date",
  "components.purchaseorderlist.purchaseorderlistheader.required": "Required",
  "components.purchaseorderlist.purchaseorderlistheader.revised": "Revised",
  "components.purchaseorderlist.purchaseorderlistheader.factory": "Factory",
  "components.purchaseorderlist.purchaseorderlistheader.povalue": "PO Value",
  "components.purchaseorderlist.purchaseorderlistheader.deposit": "Deposit",
  "components.purchaseorderlist.purchaseorderlistheader.balance": "Balance",
  "components.purchaseorderlist.purchaseorderlistheader.potenciallatepenalty":
    "Potential Late Penalty",
  "components.purchaseorderlist.purchaseorderlistheader.shippingterms":
    "Shipping Terms",
  "components.purchaseorderlist.purchaseorderlistheader.loadingport":
    "Loading Port",
  "components.purchaseorderlist.purchaseorderlistheader.unloadingport":
    "Unloading Port",
  "components.purchaseorderlist.purchaseorderlistheader.finaldestination":
    "Final Destination",
  "factories.title": "Vendors",
  Vendors: "Vendors",
  Tags: "Labels",
  Roles: "Roles",
  Settings: "Settings",
  reAssignPO: "Re-assign PO",
  "general.basicinfo": "Basic Info",
  "factory.name": "Vendor Name",
  "factory.number": "Vendor Number",
  "factory.mainContact": "Main Contact",
  "factory.terms": "Terms (in days)",
  "factory.notes": "Notes",
  "factory.email": "Email",
  "factory.inactive": "Inactive",
  "factory.categories": "Categories",
  "customer.name": "Customer Name",
  "customer.number": "Customer Number",
  "customer.inactive": "Inactive",
  "customer.terms": "Terms (in days)",
  "customer.email": "Customer Email",
  "customer.mainContact": "Main Contact",
  "customer.latePenaltyTerms": "Late Penalty Terms",
  "customer.cellPhone": "Phone",
  "customer.jobTitle": "Job Title",
  "customer.notes": "Notes",
  "customer.categories": "Categories",
  "expenses.attention.export.message.one":
    "Are you sure you want to export this expense? It will be marked as exported with today's date to avoid future duplication.",
  "expenses.attention.export.message":
    "Are you sure you want to export these expenses? They will be marked as exported with today’s date to avoid future duplication.",
  "components.companytabs.factorymodal.customsnackbar.message.isblank":
    "Name can not be blank",
  "components-salesOrders-salesOrderTemplate-DiscardChanges": "Discard Changes",
  "components-salesOrders-salesOrderTemplate-SavenewVersionofTemplate":
    "Save New Version of Template",
  "companyusers.validate.error.displayname":
    "Invalid display name. Please ensure the name only includes letters, numbers, hyphens, and underscores; and is between 2 and 24 characters in length.",
  "companyusers.validate.error.nonvalidphone":
    "Phone number is required or invalid",
  "components.companytabs.factorymodal.customsnackbar.message.tooLong":
    "Name is too long",
  "salesorderlist.title": "Sales Orders",
  "tags.title": "Labels",
  "shipments.title": "Shipments",
  "logistics.title": "Logistics",
  "weekview.title": "Task - Weekly View",
  "PurchaseOrderInfo-Attention-Modal-Title": "Attention",
  "purchaseOrderInfo.Attention.Modal.Description.existsPONumber":
    "That PO number already exists, please pick another number",
  "purchaseOrderInfo.Attention.Modal.Description.emptyPONumber":
    "That PO number can not be empty",
  "salesOrderInfo.Attention.Modal.Description.existsSONumber":
    "That SO number already exists, please pick another number",
  "salesOrderInfo.Attention.Modal.Description.emptySONumber":
    "That SO number can not be empty",
  "factory.jobTitle": "Job Title",
  "factory.phone": "Phone",
  sales_resp: "Sales Rep",
  "salesordersmanager.validation.salesorder.date":
    "Sales Order date is required",
  "salesordersmanager.successfulreassigned": "Succeessful Reasigned!",
  "salesordersmanager.successfuldeletedPO": "Successful Deleted!",
  "tasklist.title": "Tasks",
  "notifications.filters.allNotifications": "All",
  "notifications.filters.directMessage": "DMs",
  "notifications.filters.adHocTask": "AD-HOC TASK",
  "notifications.filters.completeEarly": "COMP. EARLY",
  "notifications.filters.completeLate": "COMP. LATE",
  "general.filters.export.csv": "Export to CSV",
  "factories.screen.po.template": "PO Template",
  "Sales Order": "Sales Order",
  "Purchase Order": "Purchase Order",
  "View Permissions": "Access Groups",
  "components.salesOrders.salesOrderModal.customButtom.Add.New.Purchase.Order.To.This.Project":
    "Add new Purchase Order to this project",
  Labels: "Labels",
  "order.dashboard.adhoc.task.sales.order": "SALES ORDER",
  "order.dashboard.adhoc.task.purchase.order": "PURCHASE ORDER",
  "order.dashboard.adhoc.task.shipment": "SHIPMENT",
  "orderDashboard.salesOrder.info.date": "Document Date:",
  "orderDashboard.salesOrder.info.paymentTermsStart": "Pay Terms Start:",
  "orderDashboard.salesOrder.info.create": "Created Date:",
  "orderDashboard.salesOrder.info.deliveryDate": "Required Delivery:",
  "orderDashboard.salesOrder.info.cbm": "Total CBM:",
  "orderDashboard.salesOrder.info.totalPOs": "Total POs:",
  "orderDashboard.salesOrder.info.totalPQs": "Total PQs:",
  "orderDashboard.salesOrder.info.totalPOsAndPQs": "Total POs and PQs:",
  "orderDashboard.salesOrder.info.invoice": "Invoice to Customer:",
  "orderDashboard.salesOrder.info.amount": "Subtotal:",
  "orderDashboard.salesOrder.info.totalshipments": "Total Shipments:",
  "orderDashboard.emptyField": "",
  "orderDashboard.salesOrder.info.shipmentWindow": "Shipment Window:",
  "orderDashboard.salesOrder.info.deliveryWindow": "Delivery Window:",
  "orderDashboard.salesOrder.info.customerPO": "Customer PO:",
  "orderDashboard.salesOrder.info.deposit": "Deposit:",
  "orderDashboard.salesOrder.info.salesRepresentative": "Sales Rep:",
  "orderDashboard.salesOrder.info.referenceNumber": "Reference Number:",
  "orderDashboard.salesOrder.info.shipDate": "Ship By Date:",
  "orderDashboard.salesOrder.info.uniqueItems": "Unique Items:",
  "orderDashboard.salesOrder.info.potentialLateFee": "Potential Late Fee:",
  "orderDashboard.salesOrder.info.balance": "Balance:",
  "orderDashboard.salesOrder.info.weight": "Total Weight:",
  "orderDashboard.purchaseOrder.info.date": "Document Date:",
  "orderDashboard.purchaseOrder.info.create": "Created Date:",
  "orderDashboard.purchaseOrder.info.orderReadyDate": "Order Ready Date:",
  "orderDashboard.purchaseOrder.info.cbm": "Total CBM:",
  "orderDashboard.purchaseOrder.info.amount": "Total:",
  "orderDashboard.purchaseOrder.info.subTotal": "Subtotal:",
  "orderDashboard.purchaseOrder.info.shipmentWindow": "Shipment Window::",
  "orderDashboard.purchaseOrder.info.invoice": "Vendor Invoice:",
  "orderDashboard.purchaseOrder.info.discountPercentage":
    "Discount Percentage:",
  "orderDashboard.purchaseOrder.info.discount": "Discount:",
  "orderDashboard.purchaseOrder.info.shipDate": "Ship Date:",
  "orderDashboard.purchaseOrder.info.weight": "Total Weight:",
  "orderDashboard.purchaseOrder.info.deposit": "Deposit:",
  "orderDashboard.purchaseOrder.info.totalShipments": "Total Shipments:",
  "orderDashboard.purchaseOrder.info.potentialLateFee": "Potential Late Fee:",
  "orderDashboard.purchaseOrder.info.uniqueItems": "Unique Items:",
  "orderDashboard.purchaseOrder.info.balance": "Balance:",
  "orderDashboard.purchaseOrder.info.shippingTerms": "Shipping Terms:",
  "orderDashboard.shipment.info.date": "Created:",
  "orderDashboard.shipment.info.cbm": "Total CBM:",
  "orderDashboard.shipment.info.booking": "Booking:",
  "orderDashboard.shipment.info.loadingPort": "Loading Port:",
  "orderDashboard.shipment.info.amount": "Total Value:",
  "orderDashboard.shipment.info.invoice": "Invoice to Customer:",
  "orderDashboard.shipment.info.shipDate": "Ship Date:",
  "orderDashboard.shipment.info.weight": "Weight:",
  "orderDashboard.shipment.info.billOfLading": "Bill of Lading:",
  "orderDashboard.shipment.info.unloadingPort": "Unloading Port:",
  "orderDashboard.shipment.info.potentialLateFee": "Potential Late Fee:",
  "orderDashboard.shipment.info.customerPO": "Customer PO:",
  "orderDashboard.shipment.info.deliveryDate": "Delivery Date:",
  "orderDashboard.shipment.info.uniqueItems": "Unique Items:",
  "orderDashboard.shipment.info.container": "Container:",
  "orderDashboard.shipment.info.shippingTerms": "Shipping Terms:",
  "orderDashboard.shipment.info.finalDestination": "Final Destination:",
  "orderDashboard.shipment.info.freightForwarder": "Freight Forwarder:",
  "order.type.sales.order": "Sales Order",
  "order.type.purchase.order": "Purchase Order",
  "order.type.purchase.po": "PO",
  "order.type.quote": "Quote",
  "order.type.purchase.quote": "Purchase Quote",
  "update.column.headers.for.list.views":
    "Update column headers for list views",
  "column.settings.reportEmbed": "Report Embed",
  "column.settings.loomEmbed": "Loom Embed",
  "modal.settings.updateReport": "Update Report",
  "modal.settings.createReport": "Create Report",
  "modal.settings.updateFolder": "Update Folder",
  "modal.settings.createFolder": "Create Folder",
  "skuTable.itemNumber": "Item Number",
  "skuTable.image": "Image",
  "skuTable.description": "Description",
  "skuTable.salesOrderQuantity": "Order Quantity",
  "skuTable.salesOrderPrice": "Price",
  "skuTable.extendedPrice": "Extented Price",
  "skuTable.cbmMaster": "CBM / Master",
  "skuTable.piecesPerMaster": "Pieces per Master",
  "skuTable.cartons": "Cartons",
  "skuTable.totalCbm": "Total CBM",
  [`skuTable.${ORDER_TYPE.PURCHASE_ORDER}`]: "PO",
  [`skuTable.${ORDER_TYPE.PURCHASE_QUOTE}`]: "PQ",
  "skuTable.shipmentQuantity": "Shipment Quantity",
  "skuTable.unitCost": "Unit Cost",
  "skuTable.extendedCost": "Extented Cost",
  "skuTable.weight": "Weight",
  "skuTable.orderQuantity": "Order Quantity",
  "skuTable.quantity": "Quantity",
  "skuTable.customer": "Customer",
  "skuTable.finalDestination": "Final Destination",
};

export default enMessages;
