import React, { useEffect, useState } from "react";
import { useCompanyId } from "../../hooks";
import { useCompanyUsers, useUser } from "../../hooks/user";
import IntlMessages from "../../util/IntlMessages";
import {
  featureFlagNames,
  GENERAL_PERMISSION_VALUE,
  PO_STATUS,
} from "../../helpers/constants";
import { useIsAllowedFunction } from "../../hooks/permissions";
import timeLineIconv2 from "../../assets/flag-icons/timeline-icon-v2.png";
import CustomizedModal from "../Modal/CustomizedModal";
import TimelineStructure from "../Timeline/TimelineStructure";
import AttentionModal from "../Modal/AttentionModal";
import {
  cleanTasks,
  createActivityEntriesFromTimeline,
  saveTasks,
  updateMainEntities,
} from "../../helpers/timelineModal";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { ProjectTaskFiltersStyled } from "./styles";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import VendorDashboardTemplate from "./VendorDashboardTemplate";
import { useFactories } from "../../hooks/factories";
import { VoidLabel } from "../../helpers/iconSvgPath";
import FilterTab from "./FilterTab";
import ExpandPanelButton from "../Buttons/ExpandPanelButton";
import {
  getShipmentsListener,
  sendSignShipmentsIds,
} from "../../container/TaskListScreen/TaskScreenHelper";
import { useCustomers } from "../../hooks/customers";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";

const filters = { ALL: "ALL", MINE: "MINE", COMPLETED: "COMPLETED" };

function ProjectTaskFilters({
  onTabClick = () => {},
  filterValue = "",
  checkboxValue = false,
  onChangeCheckbox = () => {},
  purchaseOrder,
  SOTasks,
  POTasks,
  salesOrder,
  purchaseOrders,
  activePanel,
  onActivePanel,
}) {
  const isAllowed = useIsAllowedFunction();
  const filtersPOs = purchaseOrders.filter(
    (po) => po.status !== PO_STATUS.VOIDED
  );
  const companyID = useCompanyId();
  const user = useUser();
  const [completedTasks, setCompletedTasks] = useState({
    completedTasks: 0,
    totalTasks: 0,
  });
  const factories = useFactories();
  const customers = useCustomers();
  const [openModal, setOpenModal] = useState(false);
  const [signShipment, setSignShipment] = useState(false);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [allShipments, setAllShipment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shipmentListenerData, setShipmentListenerData] = useState({
    ids: [],
    unsubscribeFunc: [],
  });

  const featureFlags = useFeatureFlags({ companyId: companyID });
  const companyUsers = useCompanyUsers({});
  useEffect(() => {
    if (purchaseOrders.length > 0 && openModal) {
      const sendSignShipment = sendSignShipmentsIds({
        purchaseOrders: purchaseOrders,
        currentShipmentIds: shipmentListenerData.ids,
      });
      if (!sendSignShipment) {
        setLoading(false);
      }
      setSignShipment(sendSignShipment);
    }
    if (!openModal) {
      shipmentListenerData.unsubscribeFunc.forEach((unsub = () => {}) =>
        unsub()
      );
      setShipmentListenerData({
        ids: [],
        unsubscribeFunc: [],
      });
      setAllShipment([]);
    }
  }, [purchaseOrders, openModal]);

  useEffect(() => {
    if (signShipment) {
      shipmentListenerData.unsubscribeFunc.forEach((unsub = () => {}) =>
        unsub()
      );
      getShipmentsListener({
        purchaseOrders,
        companyId: companyID,
        customers,
        setShipmentsData: ({ shipmentData }) => {
          setAllShipment(shipmentData);
          setTimeout(() => setLoading(false), 1000);
        },
      }).then((result) => {
        setShipmentListenerData({
          ids: result.ids,
          unsubscribeFunc: result.unlistener,
        });
        setSignShipment(false);
      });
    }
  }, [signShipment]);

  useEffect(() => {
    const SOCompletedLength = SOTasks.filter((task) => task.complete).length;
    const POCompletedLength = POTasks.filter((task) => task.complete).length;
    setCompletedTasks({
      completedTasks: SOCompletedLength + POCompletedLength,
      totalTasks: SOTasks.length + POTasks.length,
    });
  }, [checkboxValue, POTasks, SOTasks]);

  function isThereTimelineFeatureFlag(featureFlags = [], user) {
    if (featureFlags.length === 0 || !user) {
      return null;
    }

    const featureFlagVersion2 = featureFlags.find(
      (feature) => feature.name === featureFlagNames.TIMELINE_V2
    );

    if (
      featureFlagVersion2 &&
      featureFlagVersion2.users &&
      featureFlagVersion2.users[user.id]
    ) {
      return (
        <img
          className="timeline-icon"
          src={timeLineIconv2}
          onClick={() => {
            setLoading(true);
            setTimeout(() => {
              setOpenModal(true);
            }, 50);
          }}
          alt="timeline2"
        />
      );
    }
    return null;
  }

  return (
    <ProjectTaskFiltersStyled className="projectTaskFilterContainer">
      {loading && <LoadingBackdrop />}
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={
            <React.Fragment>
              Are you sure to discard these changes?
            </React.Fragment>
          }
          isOpen={openModalAttention}
          onClick={() => {
            setOpenModal(false);
            setOpenModalAttention(false);
          }}
          onClose={() => setOpenModalAttention(false)}
          cancellable
          cancelText="No"
          confirmationText="Yes"
        />
      )}
      {openModal && !loading && (
        <CustomizedModal
          open={openModal}
          onClose={() => {
            if (salesOrder.status === PO_STATUS.VOIDED) {
              setOpenModal(false);
            } else {
              setOpenModalAttention(true);
            }
          }}
          body={
            <TimelineStructure
              companyId={companyID}
              user={user}
              salesOrder={salesOrder || {}}
              purchaseOrders={filtersPOs || []}
              shipments={allShipments}
              onCancelTimeLineModal={() => setOpenModal(false)}
              onSubmitTimeLineModal={({
                tasks,
                confirmedActions,
                salesOrder,
                purchaseOrders,
                shipments = [],
              }) => {
                const tasksCpy = cleanTasks([...tasks]);
                saveTasks(tasksCpy);
                updateMainEntities({
                  confirmedActions,
                  salesOrder,
                  purchaseOrders,
                  shipments: shipments,
                });
                createActivityEntriesFromTimeline({
                  confirmedActions,
                  salesOrder,
                  purchaseOrders,
                  shipments: shipments,
                  companyUsers,
                  user,
                });
                setOpenModal(false);
              }}
            />
          }
        />
      )}

      <div className="title">
        <ExpandPanelButton
          activePanel={activePanel}
          handleActivePanel={onActivePanel}
        />
        {salesOrder.status === PO_STATUS.VOIDED && <VoidLabel />}
        {isThereTimelineFeatureFlag(featureFlags, user)}
        <span>
          <IntlMessages id="purchaseorderdashboard.tasks" />
        </span>
        {purchaseOrder.status !== PO_STATUS.VOIDED &&
          purchaseOrder.isOutdated &&
          isAllowed(GENERAL_PERMISSION_VALUE.MODIFY_FACTORIES_TEMPLATES) && (
            <VendorDashboardTemplate
              vendor={factories.find(
                (factory) => factory.id === purchaseOrder.factoryId
              )}
              purchaseOrder={purchaseOrder}
              user={user}
              companyId={companyID}
              taskInfo={true}
              typeClass={"taskContainer"}
            />
          )}
      </div>

      <div className={"projectTaskFilters"}>
        <div className="general-filters">
          <FilterTab
            onClick={() => onTabClick(filters.ALL)}
            activeTab={filterValue}
            value={filters.ALL}
            label="purchaseorderdashboard.all"
            styles={{ fontSize: 11 }}
          />
          <FilterTab
            onClick={() => onTabClick(filters.MINE)}
            activeTab={filterValue}
            value={filters.MINE}
            label="purchaseorderdashboard.mine"
            noTransform={true}
            styles={{ fontSize: 11 }}
          />

          <div className="indicator" />
        </div>
        <div className="show-completed">
          <CustomCheckbox
            id="od-ts-custom-checkbox"
            className={"checkboxCompleted"}
            checked={checkboxValue}
            onChange={onChangeCheckbox}
            size="small"
            iconSize={13}
          />
          <div
            id="od-ts-checkbox"
            className="sc-text-container"
            onClick={() => onChangeCheckbox(!checkboxValue)}
          >
            <span>
              <IntlMessages id="purchaseorderdashboard.completed" />
            </span>
            {checkboxValue && (
              <span style={{ margin: "0px 4px" }}>
                {`(${
                  completedTasks.completedTasks +
                  "/" +
                  completedTasks.totalTasks
                })`}
              </span>
            )}
          </div>
        </div>
      </div>
    </ProjectTaskFiltersStyled>
  );
}

export default ProjectTaskFilters;
