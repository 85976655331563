import React, { useState, useEffect, useCallback } from "react";
import { getOrderDiamond } from "../../helpers/timelineCalendar";
import TooltipTD from "../Tooltip/TooltipTD";
import ColorPicker from "./ColorPicker";
import ShipmentPicker from "./ShipmentPicker";
import { POBadgeContainerStyled } from "./styles";
import { debounce } from "../../helpers/helpers";

function POBadge({
  purchaseOrder,
  onChangePOBadge,
  onMouseEnter,
  onMouseLeave,
  purchaseOrders,
  selectedShipments,
  onChangeSelectedShipments,
  shipmentOnHover = null,
  hoverTask = false,
}) {
  const [PObadgeBackground, setPObadgeBackground] = useState("white");
  const onHoverPOBadge = ({ color = "white", highlight = false }) => {
    setPObadgeBackground(color);
    if (highlight) {
      onMouseEnter(purchaseOrder);
    } else {
      onMouseLeave();
    }
  };

  const onDebounceHover = useCallback(
    debounce(({ color, highlight }) => {
      onHoverPOBadge({ color, highlight });
    }, 1000),
    []
  );

  useEffect(() => {
    if (shipmentOnHover !== null) {
      if (
        purchaseOrder.shipmentIds &&
        purchaseOrder.shipmentIds.includes(shipmentOnHover)
      ) {
        setPObadgeBackground(purchaseOrder.color);
      }
      return;
    } else if (PObadgeBackground !== "white") {
      setPObadgeBackground("white");
      return;
    }
  }, [shipmentOnHover]);

  const resetColor = () => {
    onDebounceHover.cancel();
    onHoverPOBadge({});
  };

  return (
    <POBadgeContainerStyled
      className={"POBadgeContainer"}
      style={{
        borderColor: purchaseOrder.color,
        backgroundColor: hoverTask ? purchaseOrder.color : PObadgeBackground,
      }}
      onMouseEnter={() => {
        onDebounceHover({ color: purchaseOrder.color, highlight: true });
      }}
      onMouseLeave={resetColor}
    >
      <input
        className="check-box"
        type="checkbox"
        checked={purchaseOrder.isShownInTimeline}
        value={purchaseOrder.isShownInTimeline}
        onChange={() =>
          onChangePOBadge({
            purchaseOrder: {
              ...purchaseOrder,
              isShownInTimeline: !purchaseOrder.isShownInTimeline,
            },
            purchaseOrders,
          })
        }
      />
      {getOrderDiamond({ order: purchaseOrder, styles: {}, isOutlined: true })}
      <div className="description">
        <TooltipTD
          label={`${purchaseOrder.number} - ${
            purchaseOrder.factoryName || "factoryName"
          }`}
        >
          {purchaseOrder.number} - {purchaseOrder.factoryName || "factoryName"}
        </TooltipTD>
      </div>
      {selectedShipments.length > 0 && (
        <ShipmentPicker
          selectedShipments={selectedShipments}
          onChangeSelectedShipments={onChangeSelectedShipments}
          onReset={resetColor}
        />
      )}
      <ColorPicker
        milestone={purchaseOrder}
        onChangeMilestone={(value) => {
          onChangePOBadge({ purchaseOrder: value, purchaseOrders });
          resetColor();
        }}
        onClose={resetColor}
      />
    </POBadgeContainerStyled>
  );
}

export default POBadge;
