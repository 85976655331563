import React, { useState } from "react";
import { CSVLink } from "react-csv";
import moment from "moment";
import { PREFIX_ITEM_TABLE, TOOLTIP_TYPE } from "../../helpers/constants";
import {
  AdvertiseIconIcon,
  CopyItemTable,
  DownloadCSVIcon,
  ExpandTableIcon,
  HiddenDataIcon,
} from "../../helpers/iconSvgPath";
import TooltipTD from "../Tooltip/TooltipTD";
import { SKUContainerButtonStyled } from "./styles";
import { CloudDownload as CloudDownloadIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import SearchLabel from "../Inputs/SearchLabel";
import AttentionModal from "../Modal/AttentionModal";
import { SEARCH_BOX_TYPES } from "../../helpers/orderDashboard";
import { cx } from "@emotion/css";
import { colors } from "../../assets/jss/variables";
import { Button } from "@mui/material";
import { excelPOStyles, excelStyles } from "../../helpers/itemsTableColumns";

const titleText = {
  [PREFIX_ITEM_TABLE.PURCHASE_ORDER]: "Purchase Order Item Table",
  [PREFIX_ITEM_TABLE.SALES_ORDER]: "Sales Order Item Table",
  [PREFIX_ITEM_TABLE.SHIPMENT]: "Shipment Item Table",
};

function ItemTableButtons({
  isModal,
  prefix,
  isAllOpen,
  onCollapseAll = () => {},
  csvData,
  tableHeader,
  onExpandModal,
  pdfTableHeader,
  handleOnCopyTable = () => {},
  handleOpenSnackBar = () => {},
  cleanSearchBox = false,
  onWriteSearch = () => {},
  handleSearchBox = () => {},
  handleHiddenImages = () => {},
  hasMismatchTag = false,
  currentRows = {},
  parseRows = [],
}) {
  const [attentionModalText, setAttentionModalText] = useState("");
  const containerClass = isModal ? "itemsButtonsModal" : "itemsButtons";

  const copyTable = () => {
    const headerRows = document.querySelectorAll(".headerSKURow");
    const bodyRows = document.querySelectorAll(".itemSKURow");
    const tableHTML =
      prefix === PREFIX_ITEM_TABLE.PURCHASE_ORDER
        ? excelPOStyles({ headerRows, bodyRows, currentRows })
        : excelStyles({ headerRows, bodyRows, prefix });
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.top = "0";
    document.body.appendChild(container);

    container.innerHTML = tableHTML;

    const range = document.createRange();
    range.selectNode(container);
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand("copy");

    window.getSelection().removeAllRanges();

    document.body.removeChild(container);

    handleOnCopyTable(true);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  async function createFile(url) {
    try {
      let response = await fetch(url.replace("http://", "https://"), {
        method: "GET",
        headers: {
          "access-control-allow-origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      let data = await response.blob();
      let metadata = {
        type: "image/jpeg",
      };
      let file = new File([data], "test.jpg", metadata);
      const base64 = await toBase64(file);
      return base64;
    } catch (error) {
      console.log("-----", error);
    }
  }

  const exportPDF = async () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = titleText[prefix];
    const headers = [pdfTableHeader.map((header) => header.label)];
    let rawImages = {};
    const pdfData = [];
    for (let i = 0; i < csvData.length; i++) {
      const data = csvData[i];
      const dataContainer = [];
      for (let j = 0; j < pdfTableHeader.length; j++) {
        const header = pdfTableHeader[j];
        const value = data[header.key];
        let contentData = header.type ? `$${value}` : value;
        if (value === "" || !value) {
          contentData = "";
        }
        if (header.key === "image" && value) {
          contentData = `${i}_${j}`;
          const base64 = await createFile(value);
          rawImages = { ...rawImages, [contentData]: base64 };
        }
        const style = header.style || {};
        dataContainer.push({
          content: contentData,
          styles: {
            halign: "center",
            valign: "middle",
            ...style,
          },
        });
      }
      pdfData.push(dataContainer);
    }
    let content = {
      startY: 50,
      head: headers,
      body: pdfData,
      headStyles: {
        fillColor: [202, 224, 252],
        textColor: colors.primaryDark,
        halign: "center",
      },
      columnStyles: {
        1: { minCellWidth: 60, minCellHeight: 60 },
      },
      styles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.05,
      },
      rowPageBreak: "avoid",
    };
    doc.text(title, marginLeft, 40);
    doc.autoTable({
      ...content,
      didDrawCell: async (data) => {
        if (data.section === "body" && data.column.index === 1) {
          const [text = ""] = data.cell.text;
          if (text !== "") {
            doc.addImage(
              rawImages[text],
              "JPEG",
              data.cell.x + 2,
              data.cell.y + 2,
              50,
              50
            );
          }
        }
      },
    });

    doc.save(`${prefix}ItemTable.pdf`);
  };

  const hasMultipleChilds =
    prefix === PREFIX_ITEM_TABLE.PURCHASE_ORDER
      ? parseRows.some(
          ({ salesOrders = [] }) =>
            salesOrders.length > 1 ||
            salesOrders.some(({ shipments = [] }) => shipments.length > 1)
        )
      : false;
  return (
    <SKUContainerButtonStyled
      className={containerClass}
      id={`${prefix}Item-table-buttons`}
    >
      {!!attentionModalText && (
        <AttentionModal
          title="Attention"
          description={attentionModalText}
          isOpen={!!attentionModalText}
          onClick={() => {
            setAttentionModalText("");
          }}
          cancellable={false}
          confirmationText="Ok"
          onClose={() => setAttentionModalText("")}
          styleModal={{
            height: "auto",
            minHeight: 235,
            width: 477,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        />
      )}
      <SearchLabel
        returnQuery={(query) => {
          onWriteSearch(query);
        }}
        placeholderOnExpand="Search for item number, description, etc."
        cleanSearchBox={cleanSearchBox}
        onDisableCleanSearchBox={() => handleSearchBox(false)}
        searchLabelId={
          isModal
            ? "item-table-button-search-box-modal"
            : "item-table-button-search-box"
        }
        type={SEARCH_BOX_TYPES.SKU_TABLE}
        className={isModal ? "" : prefix}
      />
      <Button
        className={cx(prefix, "iconContainer")}
        style={{ display: "none" }}
        onClick={async () => await exportPDF()}
      >
        <TooltipTD
          label="Export to PDF"
          placement="top"
          type={TOOLTIP_TYPE.DEFAULT}
          hasArrow={false}
          popperProps={{}}
          style={{ height: 24, width: 24 }}
        >
          <CloudDownloadIcon className="pdfIcon" />
        </TooltipTD>
      </Button>
      {hasMismatchTag && (
        <div
          className={cx(prefix, "iconContainer")}
          onClick={async () => setAttentionModalText(hasMismatchTag.content)}
        >
          <TooltipTD label={hasMismatchTag.tooltip || hasMismatchTag.content}>
            <AdvertiseIconIcon />
          </TooltipTD>
        </div>
      )}
      {prefix === PREFIX_ITEM_TABLE.PURCHASE_ORDER && hasMultipleChilds && (
        <Button
          className={cx(prefix, "iconContainer")}
          onClick={() => onCollapseAll()}
        >
          <TooltipTD
            label={isAllOpen ? "Collapse all rows" : "Expand all rows"}
            type={TOOLTIP_TYPE.DEFAULT}
            hasArrow={false}
            popperProps={{}}
            style={{ height: 24, width: 24 }}
          >
            <HiddenDataIcon svgClass="expandRow" />
          </TooltipTD>
        </Button>
      )}

      <Button
        className={cx(prefix, "iconContainer")}
        onClick={() => {
          handleHiddenImages();
          onCollapseAll(true);
          handleOnCopyTable(false);
          setTimeout(() => {
            handleOpenSnackBar(true);
            copyTable();
          }, 200);
        }}
      >
        <TooltipTD
          label="Copy Table"
          placement="top"
          type={TOOLTIP_TYPE.DEFAULT}
          hasArrow={false}
          popperProps={{}}
          style={{ height: 24, width: 24 }}
        >
          <CopyItemTable svgClass="copyIcon" />
        </TooltipTD>
      </Button>

      <Button className={cx(prefix, "iconContainer")}>
        <CSVLink
          data={csvData}
          headers={tableHeader}
          filename={`Item Table-${prefix}-${moment().format("M-D-YY")}.csv`}
          style={{ height: 24, width: 24 }}
        >
          <TooltipTD
            label="Export to CSV"
            placement="top"
            type={TOOLTIP_TYPE.DEFAULT}
            hasArrow={false}
            popperProps={{}}
            style={{ height: 24, width: 24, display: "flex" }}
          >
            <DownloadCSVIcon svgClass="csvIcon" />
          </TooltipTD>
        </CSVLink>
      </Button>

      {!isModal && (
        <Button className={cx(prefix, "iconContainer")} onClick={onExpandModal}>
          <TooltipTD
            label="Expand Table"
            placement="top"
            type={TOOLTIP_TYPE.DEFAULT}
            hasArrow={false}
            popperProps={{}}
            style={{ height: 24, width: 24 }}
          >
            <ExpandTableIcon
              style={{
                cursor: "pointer",
              }}
              svgClass="expandTableIcon"
            />
          </TooltipTD>
        </Button>
      )}
    </SKUContainerButtonStyled>
  );
}

export default ItemTableButtons;
