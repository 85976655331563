import React from "react";
import { activityType } from "../../../helpers/activitiesStream";
import { cx } from "@emotion/css";
import { emojisIcon } from "../../../helpers/emojisHelper";
import TooltipTD from "../../Tooltip/TooltipTD";
import { InfoIcon, ReplyIcon } from "../../../helpers/iconSvgPath";
import ReadByPopover from "../../Widgets/Activities/ReadByPopover";
import {
  useCurrentPurchaseOrder,
  useCurrentSalesOrder,
} from "../../../hooks/salesOrders";
import { useCurrentShipment } from "../../../hooks/shipments";
import {
  getLabelScope,
  getLockIcon,
  getTextFromType,
  handleReplyActivity,
} from "./ActivityHelpers";

function ActivityHead({
  activity,
  users,
  hasThreadPermission,
  handleShowThread,
  onReadActivity = () => {},
  sizeThreadChilds = 0,
  activityStreamState = {},
  dispatchActivityStream = () => {},
  threadTitle = "Show this thread",
}) {
  const salesOrder = useCurrentSalesOrder({});
  const purchaseOrder = useCurrentPurchaseOrder();
  const currentShipment = useCurrentShipment({ purchaseOrder });

  const numberOfReplies =
    sizeThreadChilds > 0 ? `(${sizeThreadChilds + 1})` : "";
  const { noteThread } = activityStreamState;
  const noteIsFinish =
    !activity.enableToEdit && activity.type === activityType.NOTE;
  const isThread = sizeThreadChilds || activity.threadId;
  const showDataThread = hasThreadPermission && noteIsFinish && !noteThread;
  return (
    <div className="activity-header">
      <div className="activity-scope-left">{getLabelScope(activity.scope)}</div>
      <div className="title">
        <span>{getTextFromType(activity.type)}</span>
      </div>
      <div className="options">
        {showDataThread && isThread && (
          <div
            className={cx("threadStyle", `${activity.scope}Border`)}
            onClick={() => {
              onReadActivity();
              handleShowThread(activity);
            }}
          >
            {emojisIcon[activity.iconIndex] && (
              <img
                width={16}
                style={{ marginRight: 6 }}
                src={emojisIcon[activity.iconIndex].src}
                alt={emojisIcon[activity.iconIndex].alt}
              />
            )}
            <span>
              {threadTitle} {numberOfReplies}
            </span>
            {numberOfReplies && (
              <TooltipTD
                label="This is the first note of this thread"
                showToolTip
              >
                <InfoIcon style={{ marginLeft: 5, marginBottom: -2 }} />
              </TooltipTD>
            )}
          </div>
        )}
      </div>
      <div className="scope">
        {showDataThread && !isThread && (
          <TooltipTD
            style={{
              cursor: "pointer",
              display: "flex",
            }}
            label={"Reply to this note"}
          >
            <ReplyIcon
              height={15}
              onClick={() => {
                onReadActivity();
                handleReplyActivity({
                  activity: { ...activity, newThread: true },
                  currentShipment,
                  dispatchActivityStream,
                  purchaseOrder,
                  salesOrder,
                });
              }}
            />
          </TooltipTD>
        )}
        {getLockIcon({ activity })}
        {activity.readByUsers &&
          Object.keys(activity.readByUsers).length > 0 && (
            <ReadByPopover
              activity={activity}
              users={users}
              onMarkNote={() => {
                onReadActivity();
              }}
            />
          )}
      </div>
    </div>
  );
}

export default ActivityHead;
