import React from "react";
import {
  ACTIVITIES_STREAM_STATUS,
  AUTOMATIC_SYSTEM_LABEL,
  itemTableReference,
  MISMATCH_STATUS,
  PREFIX_ITEM_TABLE,
  SHIPMENT_STATUS,
} from "../../../helpers/constants";
import ShipmentItem from "../../../api/model/ShipmentItem";
import { createShipmentActivityStream } from "../../../helpers/activitiesStream";
import {
  getCustomerName,
  getDashboardSearchPath,
  getShipmentDestination,
} from "../../../helpers/helpers";
import numeral from "numeral";
import SalesOrderItem from "../../../api/model/SalesOrderItem";
import Item from "../../../api/model/Item";
import { shipmentTriggerTypes } from "../../../helpers/shipment";
import {
  getCartons,
  getExtendedCostPrice,
  getTotalCBM,
  getTotalWeight,
  parseNumberValue,
} from "../../../helpers/orderDashboardRefactored";
import { globalScopes } from "../../../api/types/dbTables";
import itemStatus from "../../../api/types/itemStatus";
import { updateDoc } from "firebase/firestore";
import { ArrowsToSortIcon } from "../../../helpers/iconSvgPath";
import { isEqual, sumBy } from "lodash";
import {
  mismatchLabel,
  ORDER_DASHBOARD_ACTUAL_VIEWS,
} from "../sections/helpers";
import { itemTableRowHeight } from "../../../helpers/orderDashboard";

export const sortItemsBy = function ({
  property,
  reverse,
  currentIdPath,
  columnsTable = [],
  purchaseOrders = [],
}) {
  let order = reverse ? -1 : 1;
  return function (a, b) {
    const currentColumn = columnsTable.find((item) => item.key === property);
    const aValue = currentColumn.complexValue
      ? currentColumn.complexValue(a, currentIdPath, purchaseOrders)
      : a[property];
    const bValue = currentColumn.complexValue
      ? currentColumn.complexValue(b, currentIdPath, purchaseOrders)
      : b[property];
    let result = "";
    if (typeof aValue === "string" && typeof bValue === "string") {
      result =
        aValue.toLowerCase() > bValue.toLowerCase()
          ? 1
          : aValue.toLowerCase() < bValue.toLowerCase()
          ? -1
          : 0;
    } else {
      if (!aValue && !bValue) {
        result = 0;
        return result;
      }
      if (!aValue && bValue) {
        result = -1;
        return result * order;
      }
      if (aValue && !bValue) {
        result = 1;
        return result * order;
      }

      result = aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
    }
    return result * order;
  };
};

export function getShipmentsIds({
  items = [],
  currentShipmentId,
  currentShipmentIds = [],
  setOpenSnackBar = () => {},
  isSamePO,
}) {
  if (currentShipmentId) {
    let cpyShipmentIds = currentShipmentIds;
    const existItemsShipment = items.some(
      (item) => parseNumberValue(item.shipmentQuantity[currentShipmentId]) > 0
    );
    if (!existItemsShipment) {
      cpyShipmentIds = cpyShipmentIds.filter((id) => id !== currentShipmentId);
      if (isSamePO) {
        setOpenSnackBar(true);
      }
      return cpyShipmentIds;
    }
    return currentShipmentIds;
  } else {
    let purchaseOrderShipments = [];
    items.forEach((item) => {
      const salesOrders = item.salesOrders || [];
      salesOrders.forEach((so) => {
        const shipments = so.shipments || [];
        for (let i = 0; i < shipments.length; i++) {
          const shipment = shipments[i];
          if (
            shipment.id !== "new" &&
            !purchaseOrderShipments.includes(shipment.id)
          ) {
            purchaseOrderShipments.push(shipment.id);
          }
        }
      });
    });
    return purchaseOrderShipments;
  }
}

const sumLoadash = (itemList = [], key) => {
  const result = sumBy(itemList, function (shipment) {
    return parseInt(shipment[key]);
  });
  return result;
};

const updateSalesOrderLabel = ({ items, currentSalesOrder, tagMismatch }) => {
  let mismatchGeneral = false;
  let saleOrderTags = currentSalesOrder.tags || [];
  items.forEach((item) => {
    const mismatchStatus = item.mismatchStatus || {};
    mismatchGeneral = mismatchGeneral || mismatchStatus.salesOrder;
  });

  if (!mismatchGeneral && saleOrderTags.includes(tagMismatch.id)) {
    saleOrderTags = saleOrderTags.filter((tag) => tag !== tagMismatch.id);
  }

  if (mismatchGeneral && !saleOrderTags.includes(tagMismatch.id)) {
    saleOrderTags.push(tagMismatch.id);
  }

  // save data
  if (currentSalesOrder.ref) {
    updateDoc(currentSalesOrder.ref, {
      tags: saleOrderTags,
    });
  }
};

const updatePurchaseOrderLabel = ({
  allPurchaseOrders,
  items,
  mismatchGeneral,
  currentShipmentId,
  setOpenSnackBar,
  isSamePO,
  mismatchShipment,
}) => {
  allPurchaseOrders.forEach((purchaseOrder) => {
    let updatePurchaseOrder = false;
    const currentShipmentIds = purchaseOrder.shipmentIds || [];
    let mismatchItem = {
      salesOrder: false,
      purchaseOrder: false,
      shipments: false,
    };
    const filterItems = items.filter(
      (item) => item.purchaseOrderId === purchaseOrder.id
    );

    filterItems.forEach((item) => {
      const mismatchStatus = item.mismatchStatus || {};

      mismatchItem = {
        salesOrder: mismatchItem.salesOrder || !!mismatchStatus.salesOrder,
        purchaseOrder:
          mismatchItem.purchaseOrder || !!mismatchStatus.purchaseOrder,
        shipments:
          mismatchStatus.shipments === undefined
            ? true
            : mismatchItem.shipments || !!mismatchStatus.shipments,
      };
    });
    const shipmentIds = getShipmentsIds({
      items: filterItems,
      currentShipmentId,
      currentShipmentIds,
      setOpenSnackBar,
      isSamePO,
    });
    let purchaseOrderTags = purchaseOrder.tags || [];
    if (
      !mismatchItem.purchaseOrder &&
      purchaseOrderTags.includes(mismatchGeneral.id)
    ) {
      purchaseOrderTags = purchaseOrderTags.filter(
        (tag) => tag !== mismatchGeneral.id
      );
      updatePurchaseOrder = true;
    }
    if (
      mismatchItem.purchaseOrder &&
      !purchaseOrderTags.includes(mismatchGeneral.id)
    ) {
      purchaseOrderTags.push(mismatchGeneral.id);
      updatePurchaseOrder = true;
    }
    if (!isEqual(shipmentIds, currentShipmentIds)) {
      updatePurchaseOrder = true;
    }

    if (
      !mismatchItem.shipments &&
      purchaseOrderTags.includes(mismatchShipment.id)
    ) {
      purchaseOrderTags = purchaseOrderTags.filter(
        (tag) => tag !== mismatchShipment.id
      );
      updatePurchaseOrder = true;
    }
    if (
      mismatchItem.shipments &&
      !purchaseOrderTags.includes(mismatchShipment.id)
    ) {
      purchaseOrderTags.push(mismatchShipment.id);
      updatePurchaseOrder = true;
    }
    if (updatePurchaseOrder && purchaseOrder.ref) {
      updateDoc(purchaseOrder.ref, {
        tags: purchaseOrderTags,
        shipmentIds: shipmentIds,
      });
    }
  });
};

export const getDependencyShipmentList = ({
  column = {},
  value = [],
  row = {},
}) => {
  let valueList;
  if (!column.parentId) {
    valueList = value || [];
  } else {
    const parentList = row[column.parentId] || [];
    const fistParentValue = parentList[0] || {};
    valueList = fistParentValue[column.id] || [];
  }
  return valueList;
};

export const parseValueSubValueTable = ({
  saleOrder = {},
  shipments = [],
  shipmentColumn = {},
  salesOrderColumn = {},
  numberRow = 1,
  parentIndex = 0,
  item = {},
}) => {
  const subHeaderSO = salesOrderColumn.subHeader || [];
  const saleOrderStyle = salesOrderColumn.style || {};
  const subHeaderShipment = shipmentColumn.subHeader || [];
  const shipmentStyle = shipmentColumn.style || {};
  const rowData = [];
  let childIndex = parentIndex === 0 ? 1 : 0;
  for (childIndex; childIndex < numberRow; childIndex++) {
    const shipment = shipments[childIndex] || {};
    const subRowSO = subHeaderSO.map((subHeaderData) => {
      return {
        ...subHeaderData,
        columnStyle: saleOrderStyle,
        borderColor:
          childIndex !== numberRow - 1
            ? saleOrderStyle.background || subHeaderData.background || ""
            : null,
        key: salesOrderColumn.id,
        row: {
          ...saleOrder,
          hiddenValue: parentIndex === 0 || childIndex !== 0,
        },
        parentRow: null,
        item,
      };
    });
    const subRowShipment = subHeaderShipment.map((subHeaderData) => ({
      ...subHeaderData,
      columnStyle: shipmentStyle,
      key: shipmentColumn.id,
      row: shipment,
      parentRow: saleOrder,
      item,
    }));
    rowData.push([...subRowSO, ...subRowShipment]);
  }
  return rowData;
};

export const haveMultipleItems = ({ row, isMultiHeader }) => {
  let simpleDataArray = true;
  const salesOrder = row.salesOrders || [];
  if (salesOrder.length > 1 || !isMultiHeader) {
    return false;
  }
  salesOrder.forEach((saleOrder) => {
    const shipments = saleOrder.shipments || [];
    if (shipments.length > 1) {
      simpleDataArray = false;
    }
  });
  return simpleDataArray;
};

export const changeShipmentItemsSKU = ({
  itemsShipment,
  currentItem,
  allocation,
  newItemData = {},
  row,
  purchaseOrderIds,
  currentPurchaseOrder,
  currentShipment,
  allRows,
}) => {
  let cpyItemsShipment = itemsShipment;
  const findIndex = cpyItemsShipment.findIndex(
    (item) => item.id === currentItem.id
  );
  let redirectRow;
  if (allocation > 0) {
    redirectRow = row;
    if (findIndex < 0) {
      cpyItemsShipment.push(newItemData);
    } else {
      cpyItemsShipment[findIndex] = { ...newItemData };
    }
    const findId = purchaseOrderIds.find(
      (id) => id === currentPurchaseOrder.id
    );
    const newPOids = findId
      ? [...purchaseOrderIds]
      : [...purchaseOrderIds, currentPurchaseOrder.id];
    return {
      redirectRow,
      items: cpyItemsShipment,
      status:
        newPOids.length <= 0 ? SHIPMENT_STATUS.VOIDED : currentShipment.status,
      purchaseOrderIds: findId
        ? [...purchaseOrderIds]
        : [...purchaseOrderIds, currentPurchaseOrder.id],
      triggerType:
        newPOids.length <= 0
          ? shipmentTriggerTypes.VOID
          : shipmentTriggerTypes.DISMISS_TRIGGER,
    };
  } else {
    const shipmentExist = allRows.some((newRow) =>
      newRow.shipmentIds.includes(row.id)
    );
    if (!shipmentExist) {
      purchaseOrderIds = purchaseOrderIds.filter(
        (id) => id !== currentPurchaseOrder.id
      );
      redirectRow = { ...row, erased: true };
    }
    if (findIndex >= 0) {
      cpyItemsShipment = cpyItemsShipment.filter(
        (item) => item.id !== currentItem.id
      );
    }
    return {
      redirectRow,
      items: cpyItemsShipment,
      purchaseOrderIds,
      status:
        purchaseOrderIds.length <= 0
          ? SHIPMENT_STATUS.VOIDED
          : currentShipment.status,
      triggerType:
        purchaseOrderIds.length <= 0
          ? shipmentTriggerTypes.VOID
          : shipmentTriggerTypes.DISMISS_TRIGGER,
    };
  }
};

export const changeMainShipmentSKU = ({
  allRows,
  rowImage,
  row,
  poIds,
  currentShipment,
}) => {
  let purchaseOrderIds = poIds;
  let redirectRow;
  const shipmentQuantity = parseFloat(row.shipmentQuantity);
  let newItems = allRows.map((itemRow) => ({
    id: itemRow.id,
    itemNumber: itemRow.itemNumber,
    description: itemRow.description,
    cbmMaster: itemRow.cbmMaster,
    piecesPerMaster: itemRow.piecesPerMaster,
    unitCost: itemRow.unitCost,
    shipmentQuantity:
      itemRow.id === row.id
        ? {
            [currentShipment.id]: shipmentQuantity,
          }
        : itemRow.shipmentQuantity,
    currentPurchaseOrderId: itemRow.purchaseOrderId,
    image:
      itemRow.id === row.id
        ? [rowImage]
        : itemRow.image
        ? [itemRow.image[0]]
        : [],
    weight: parseFloat(itemRow.weight),
  }));

  let poExist = true;
  if (shipmentQuantity > 0) {
    return { items: [...newItems] };
  } else {
    const currentFilterItem = newItems.find((itemRow) => itemRow.id === row.id);
    newItems = newItems.filter((itemRow) => itemRow.id !== row.id);
    poExist = newItems.some(
      (newRow) =>
        newRow.currentPurchaseOrderId ===
        currentFilterItem.currentPurchaseOrderId
    );
    if (!poExist) {
      purchaseOrderIds = purchaseOrderIds.filter(
        (id) => id !== currentFilterItem.currentPurchaseOrderId
      );
      redirectRow = {
        ...row,
        erased: row.currentPurchaseOrder === row.purchaseOrderId,
      };
    }
    return {
      redirectRow,
      items: newItems,
      purchaseOrderIds,
      status:
        purchaseOrderIds.length <= 0
          ? SHIPMENT_STATUS.VOIDED
          : currentShipment.status,
      triggerType:
        purchaseOrderIds.length <= 0
          ? shipmentTriggerTypes.VOID
          : shipmentTriggerTypes.DISMISS_TRIGGER,
    };
  }
};

export const updateShipmentItems = async ({
  row,
  reference,
  allRows,
  currentPurchaseOrder,
  currentShipment,
  currentItem,
  setRedirectShipment,
}) => {
  if (row.id === "new") {
    return;
  }
  let purchaseOrderIds = [...(currentShipment.purchaseOrderIds || [])];
  const allocation = parseFloat(row.allocation || 0);
  let itemsShipment = currentShipment.items || [];
  const [rowImage = ""] = currentItem.image;
  const newItemData = {
    id: currentItem.id,
    itemNumber: currentItem.itemNumber,
    description: currentItem.description,
    cbmMaster: currentItem.cbmMaster,
    piecesPerMaster: currentItem.piecesPerMaster,
    unitCost: currentItem.unitCost,
    shipmentQuantity: { [currentShipment.id]: allocation },
    currentPurchaseOrderId: currentPurchaseOrder.id,
    image: [rowImage] || [""],
    weight: parseFloat(currentItem.weight),
  };
  if (reference === itemTableReference.SHIPMENTS) {
    const newItems = changeShipmentItemsSKU({
      allocation,
      allRows,
      currentItem,
      currentPurchaseOrder,
      currentShipment,
      itemsShipment,
      purchaseOrderIds,
      row,
      newItemData,
    });
    const { redirectRow, ...updateData } = newItems;
    await updateDoc(currentShipment.ref, updateData);
    if (redirectRow) {
      setRedirectShipment(redirectRow);
    }
  } else {
    const newMainShipmentItem = changeMainShipmentSKU({
      allRows,
      currentShipment,
      poIds: purchaseOrderIds,
      row,
      rowImage,
    });
    const { redirectRow, ...updateData } = newMainShipmentItem;
    await updateDoc(currentShipment.ref, updateData);
    if (redirectRow) {
      setRedirectShipment({ ...redirectRow, erased: row.erased });
    }
  }
};

export const updateSOTag = ({ purchaseOrders, items, tags }) => {
  const mismatchGeneral = tags.find(
    (tag) => tag.name === AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_MISMATCH
  );
  const mismatchShipment = tags.find(
    (tag) => tag.name === AUTOMATIC_SYSTEM_LABEL.SHIPMENT_MISMATCH
  );
  updatePurchaseOrderLabel({
    allPurchaseOrders: purchaseOrders,
    items,
    mismatchGeneral,
    mismatchShipment,
  });
};

export const updateShipmentTag = ({
  allPurchaseOrders,
  items,
  tags,
  currentShipmentId,
  currentItem,
  currentPurchaseOrder,
  setOpenSnackBar = () => {},
}) => {
  const mismatchGeneral = tags.find(
    (tag) => tag.name === AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_MISMATCH
  );
  const mismatchShipment = tags.find(
    (tag) => tag.name === AUTOMATIC_SYSTEM_LABEL.SHIPMENT_MISMATCH
  );
  const itemPo = allPurchaseOrders.find(
    (po) => po.id === currentItem.purchaseOrderId
  );
  const isSamePO = currentPurchaseOrder.id === itemPo.id;
  updatePurchaseOrderLabel({
    allPurchaseOrders: [itemPo],
    items,
    mismatchGeneral,
    currentShipmentId,
    setOpenSnackBar,
    isSamePO,
    mismatchShipment,
  });
};

export const updateTags = ({
  items,
  currentSalesOrder = {},
  tags,
  currentPurchaseOrder = {},
  allPurchaseOrders = [],
  prefix,
  currentItem,
  currentShipmentId,
  setOpenSnackBar = () => {},
}) => {
  const mismatchGeneral = tags.find(
    (tag) => tag.name === AUTOMATIC_SYSTEM_LABEL.SALES_ORDER_MISMATCH
  );
  const mismatchShipment = tags.find(
    (tag) => tag.name === AUTOMATIC_SYSTEM_LABEL.SHIPMENT_MISMATCH
  );

  //update PO labels
  switch (prefix) {
    case PREFIX_ITEM_TABLE.PURCHASE_ORDER:
      updatePurchaseOrderLabel({
        allPurchaseOrders: [currentPurchaseOrder],
        items,
        mismatchGeneral,
        mismatchShipment,
      });
      break;
    case PREFIX_ITEM_TABLE.SHIPMENT: {
      const itemPo = allPurchaseOrders.find(
        (po) => po.id === currentItem.purchaseOrderId
      );
      const isSamePO = currentPurchaseOrder.id === itemPo.id;
      updatePurchaseOrderLabel({
        allPurchaseOrders: [itemPo],
        items,
        mismatchGeneral,
        currentShipmentId,
        setOpenSnackBar,
        isSamePO,
        mismatchShipment,
      });
      break;
    }

    default:
      updatePurchaseOrderLabel({
        allPurchaseOrders,
        items,
        mismatchGeneral,
        mismatchShipment,
      });
      break;
  }

  updateSalesOrderLabel({
    items,
    tagMismatch: mismatchGeneral,
    tagShipment: mismatchShipment,
    currentSalesOrder,
  });

  // check SO

  return;
};

const getDecimalCsvFormat = (value, fixed = 2) => {
  return parseNumberValue(value).toFixed(fixed).replace(".", ",");
};

export const formatCsvPOtable = ({
  rows,
  shipments,
  customers,
  isPOTable = false,
  currentShipment,
  currentSalesOrder,
  currentSalesOrders = [],
}) => {
  const parseCsvData = [];
  rows.forEach((item) => {
    const salesOrderId = currentSalesOrder.id;
    const shipmentId = currentShipment.id;
    const salesOrdersQuantity = item.salesOrderQuantity || {};
    const salesOrdersPrice = item.salesOrderPrice || {};
    const shipmentsQuantity = item.shipmentQuantity || {};
    const poQuantity = parseNumberValue(item.purchaseOrderQuantity);
    const soQuantity = parseNumberValue(salesOrdersQuantity[salesOrderId]);
    const soPrice = parseNumberValue(salesOrdersPrice[salesOrderId]);
    const unitCost = parseNumberValue(item.unitCost);
    const cbmMaster = parseNumberValue(item.cbmMaster);
    const piecesPerMaster = parseNumberValue(item.piecesPerMaster);
    const shipmentQuantity = parseNumberValue(shipmentsQuantity[shipmentId]);
    const weight = parseNumberValue(item.weight);
    const totalCBM = cbmMaster * (soQuantity / piecesPerMaster);
    const shipmentTotalCBM = cbmMaster * (shipmentQuantity / piecesPerMaster);
    const totalWeight = weight * (shipmentQuantity / piecesPerMaster);
    const soCartons = getDecimalCsvFormat(
      parseNumberValue(soQuantity / piecesPerMaster),
      1
    );
    let csvData = {
      itemNumber: item.itemNumber,
      image: item.image[0] || "",
      description: item.description,
      salesOrderQuantity: soQuantity,
      salesOrderPrice: numeral(soPrice).format("0,0.00"),
      extendedPrice: numeral(soPrice * soQuantity).format("0,0.00"),
      cbmMaster: numeral(cbmMaster).format("0,0.00"),
      piecesPerMaster: piecesPerMaster,
      soCartons: soCartons,
      totalCbm: numeral(totalCBM).format("0,0.00"),
      purchaseOrderQuantity: poQuantity,
      unitCost: numeral(unitCost).format("0,0.00"),
      extendedCost: numeral(unitCost * poQuantity).format("0,0.00"),
      salesOrderItemQuantity: "",
      salesOrderItemNumber: "",
      customer: "",
      shipmentItemQuantity: "",
      shipmentItemNumber: "",
      finalDestination: "",
      shipmentQuantity: shipmentQuantity,
      shipmentExtendedCost: numeral(unitCost * shipmentQuantity).format(
        "0,0.00"
      ),
      shipmentCartons: getDecimalCsvFormat(
        parseNumberValue(shipmentQuantity / piecesPerMaster),
        1
      ),
      shipmentTotalCbm: numeral(shipmentTotalCBM).format("0,0.00"),
      weight: numeral(totalWeight).format("0,0.00"),
    };
    const salesOrders = item.salesOrders || [];
    if (salesOrders.length > 0 && isPOTable) {
      salesOrders.forEach((so, saleOrderIndex) => {
        let salesOrder =
          currentSalesOrders.find((currentSO) => so.id === currentSO.id) || {};
        salesOrder = { ...so, number: salesOrder.number || "noNumber" };

        csvData = {
          ...csvData,
          salesOrderItemQuantity: salesOrder.allocation,
          salesOrderItemNumber: salesOrder.number,
          customer: getCustomerName(customers, salesOrder.customerId, true),
        };
        let itemShipments = salesOrder.shipments || [];
        itemShipments = itemShipments.filter(
          (shipment) => shipment.id !== "new"
        );
        if (itemShipments.length === 0) {
          parseCsvData.push(csvData);
        } else {
          for (let i = 0; i < itemShipments.length; i++) {
            const shipment = itemShipments[i];
            let newData = {
              ...csvData,
              shipmentItemQuantity: shipment.allocation,
              shipmentItemNumber: shipment.number,
              finalDestination: getShipmentDestination(
                shipments,
                shipment.id,
                true
              ),
            };
            if (i === 0 && saleOrderIndex > 0) {
              newData = {
                ...newData,
                image: "",
                itemNumber: "",
                description: "",
                purchaseOrderQuantity: "",
                unitCost: "",
                extendedCost: "",
              };
            }
            if (i > 0) {
              newData = {
                ...newData,
                image: "",
                itemNumber: "",
                description: "",
                purchaseOrderQuantity: "",
                unitCost: "",
                extendedCost: "",
                salesOrderItemQuantity: "",
                salesOrderItemNumber: "",
                customer: "",
              };
            }
            parseCsvData.push(newData);
          }
        }
      });
    } else {
      parseCsvData.push(csvData);
    }
  });
  if (parseCsvData.length === 0) {
    return [{}];
  }
  return parseCsvData;
};

export const filterSORowTableItem = ({ searchText, rowTable }) => {
  if (!searchText) {
    return rowTable;
  }
  return rowTable.filter((item) => {
    const parseSearch = searchText.toLowerCase();
    const parseItemNumber = item.itemNumber.toLowerCase();
    const parseDescription = item.description.toLowerCase();
    return (
      parseItemNumber.includes(parseSearch) ||
      parseDescription.includes(parseSearch)
    );
  });
};

export const filterPORowTableItem = ({
  rowTable,
  searchText,
  salesOrders = [],
  shipments = [],
  customers = [],
}) => {
  if (!searchText) {
    return rowTable;
  }
  return rowTable.filter((item) => {
    const parseSearch = searchText.toLowerCase();
    const parseItemNumber = item.itemNumber.toLowerCase();
    const parseDescription = item.description.toLowerCase();
    const shipmentNumbers = [];
    const soNumbers = [];
    const customersName = [];
    const finalDestinations = [];
    item.salesOrderIds.forEach((soId) => {
      const currentSO = salesOrders.find((so) => so.id === soId);
      if (currentSO) {
        soNumbers.push(currentSO.number.toLowerCase());
        const currentCustomer = customers.find(
          (customer) => customer.id === currentSO.customerId
        );
        if (currentCustomer) {
          customersName.push(currentCustomer.name.toLowerCase());
        }
      }
    });
    item.shipmentIds.forEach((shipmentId) => {
      const currentShipment = shipments.find(
        (shipment) => shipment.id === shipmentId
      );
      if (currentShipment) {
        shipmentNumbers.push(`${currentShipment.number}`.toLowerCase());
        finalDestinations.push(currentShipment.finalDestination.toLowerCase());
      }
    });
    return (
      parseItemNumber.includes(parseSearch) ||
      parseDescription.includes(parseSearch) ||
      soNumbers.some((number) => number.includes(parseSearch)) ||
      customersName.some((customerName) =>
        customerName.includes(parseSearch)
      ) ||
      shipmentNumbers.some((number) => number.includes(parseSearch)) ||
      finalDestinations.some((number) => number.includes(parseSearch))
    );
  });
};

export const filterShipmentRowTableItem = ({
  rowTable,
  searchText,
  purchaseOrders = [],
}) => {
  if (!searchText) {
    return rowTable;
  }
  return rowTable.filter((item) => {
    const parseSearch = searchText.toLowerCase();
    const parseItemNumber = item.itemNumber.toLowerCase();
    const parseDescription = item.description.toLowerCase();
    const poItem =
      purchaseOrders.find((po) => po.id === item.purchaseOrderId) || {};
    return (
      parseItemNumber.includes(parseSearch) ||
      parseDescription.includes(parseSearch) ||
      (poItem.number || "").toLowerCase().includes(parseSearch)
    );
  });
};

export const filterRowTableItem = ({
  prefix,
  rowTable,
  searchText,
  salesOrders = [],
  purchaseOrders = [],
  shipments = [],
  customers = [],
}) => {
  if (!searchText) {
    return rowTable;
  }
  let currentItemData = rowTable.filter((item) => {
    const parseSearch = searchText.toLowerCase();
    const parseItemNumber = item.itemNumber.toLowerCase();
    const parseDescription = item.description.toLowerCase();
    switch (prefix) {
      case PREFIX_ITEM_TABLE.SALES_ORDER:
        return (
          parseItemNumber.includes(parseSearch) ||
          parseDescription.includes(parseSearch)
        );
      case PREFIX_ITEM_TABLE.PURCHASE_ORDER:
        const shipmentNumbers = [];
        const soNumbers = [];
        const customersName = [];
        const finalDestinations = [];
        item.salesOrderIds.forEach((soId) => {
          const currentSO = salesOrders.find((so) => so.id === soId);
          if (currentSO) {
            soNumbers.push(currentSO.number.toLowerCase());
            const currentCustomer = customers.find(
              (customer) => customer.id === currentSO.customerId
            );
            if (currentCustomer) {
              customersName.push(currentCustomer.name.toLowerCase());
            }
          }
        });
        item.shipmentIds.forEach((shipmentId) => {
          const currentShipment = shipments.find(
            (shipment) => shipment.id === shipmentId
          );
          if (currentShipment) {
            shipmentNumbers.push(`${currentShipment.number}`.toLowerCase());
            finalDestinations.push(
              currentShipment.finalDestination.toLowerCase()
            );
          }
        });
        return (
          parseItemNumber.includes(parseSearch) ||
          parseDescription.includes(parseSearch) ||
          soNumbers.some((number) => number.includes(parseSearch)) ||
          customersName.some((customerName) =>
            customerName.includes(parseSearch)
          ) ||
          shipmentNumbers.some((number) => number.includes(parseSearch)) ||
          finalDestinations.some((number) => number.includes(parseSearch))
        );
      default:
        const poItem =
          purchaseOrders.find((po) => po.id === item.purchaseOrderId) || {};
        return (
          parseItemNumber.includes(parseSearch) ||
          parseDescription.includes(parseSearch) ||
          poItem.number.includes(parseSearch)
        );
    }
  });
  return currentItemData;
};

export const parseRowTableItem = ({
  rows,
  resetNew,
  sortTable = {},
  currentIdPath = {},
  columnsTable,
  purchaseOrders,
}) => {
  const cpyRows = rows.map((row) => {
    const cpyRow = row || {};
    let salesOrders = row.salesOrders || [];
    const parseSalesOrders = salesOrders.map((salesOrder) => {
      const cpySalesOrder = salesOrder;
      const shipments = salesOrder.shipments || [];
      const realShipments = shipments.filter(
        (shipment) => shipment.id !== MISMATCH_STATUS.NEW
      );
      const currentAllocationShipment = sumLoadash(realShipments, "allocation");
      const currentAllocationSO = parseInt(salesOrder.allocation || 0);
      const itemRemainderIndex = shipments.findIndex(
        (shipment) => shipment.id === MISMATCH_STATUS.NEW
      );
      if (currentAllocationSO < currentAllocationShipment) {
        cpySalesOrder.status = "danger";
        if (itemRemainderIndex !== -1) {
          cpySalesOrder.status = "";
          shipments.splice(itemRemainderIndex, 1);
        }
      } else if (currentAllocationSO > currentAllocationShipment) {
        if (itemRemainderIndex === -1) {
          cpySalesOrder.status = "";
          shipments.push({
            id: MISMATCH_STATUS.NEW,
            status: MISMATCH_STATUS.NEW,
            allocation: currentAllocationSO - currentAllocationShipment,
            number: "-",
            finalDestination: "-",
          });
        } else {
          let remainderShipment = shipments[itemRemainderIndex];
          if (remainderShipment.status === MISMATCH_STATUS.NEW) {
            remainderShipment.allocation =
              currentAllocationSO - currentAllocationShipment;
          } else if (
            resetNew &&
            remainderShipment.status !== MISMATCH_STATUS.INCOMPLETE
          ) {
            remainderShipment.status = MISMATCH_STATUS.NEW;
            remainderShipment.allocation =
              currentAllocationSO - currentAllocationShipment;
          }
        }
      } else {
        if (itemRemainderIndex !== -1) {
          cpySalesOrder.status = "";
          shipments.splice(itemRemainderIndex, 1);
        }
      }
      return { ...cpySalesOrder, shipments };
    });
    return {
      ...cpyRow,
      itemNumber: cpyRow.itemNumber.toString(),
      salesOrders: [...parseSalesOrders],
    };
  });
  const rowsWithPermission = cpyRows
    .filter((row) => !row.notAllowedToSee)
    .sort(
      sortItemsBy({
        property: sortTable.sortedColumn,
        reverse: sortTable.orderBy === "ASC",
        columnsTable,
        currentIdPath,
        purchaseOrders,
      })
    );
  const rowsMasked = rows.filter((row) => row.notAllowedToSee);

  return [...rowsWithPermission, ...rowsMasked];
};

export const addItemActivityStream = ({
  row,
  parentRow,
  item,
  reference,
  currentShipmentId,
  currentPurchaseOrder,
  user,
  companyUsers,
  currentShipment,
}) => {
  switch (reference) {
    case itemTableReference.SHIPMENTS:
      if (row && parentRow && row.id !== "new") {
        if (row.prevId) {
          createShipmentActivityStream({
            shipmentItem: row,
            shipmentId: row.id,
            type: ACTIVITIES_STREAM_STATUS.NEW,
            item,
            user,
            purchaseOrder: currentPurchaseOrder,
            companyId: currentPurchaseOrder.companyId,
            companyUsers: companyUsers,
          });
        } else {
          const shipments = parentRow.shipments || [];
          const currentItemShipment =
            shipments.find((shipment) => shipment.id === row.id) || {};
          if (parseInt(row.allocation) < 1) {
            createShipmentActivityStream({
              shipmentItem: row,
              shipmentId: row.id,
              type: ACTIVITIES_STREAM_STATUS.ERASE,
              oldValue: currentItemShipment.allocation,
              user,
              item,
              purchaseOrder: currentPurchaseOrder,
              companyId: currentPurchaseOrder.companyId,
              companyUsers: companyUsers,
            });
          } else {
            createShipmentActivityStream({
              shipmentItem: row,
              shipmentId: currentItemShipment.id,
              type: ACTIVITIES_STREAM_STATUS.UPDATED,
              oldValue: currentItemShipment.allocation,
              newValue: row.allocation,
              user,
              item,
              purchaseOrder: currentPurchaseOrder,
              companyId: currentPurchaseOrder.companyId,
              companyUsers: companyUsers,
            });
          }
        }
      }
      return;

    default:
      const shipmentQuantity = item.shipmentQuantity || [];
      const currentAllocation = shipmentQuantity[currentShipmentId];
      createShipmentActivityStream({
        shipmentItem: { ...currentShipment, ...row },
        shipmentId: currentShipmentId,
        type: ACTIVITIES_STREAM_STATUS.UPDATED,
        oldValue: currentAllocation,
        newValue: row.shipmentQuantity,
        user,
        item,
        purchaseOrder: currentPurchaseOrder,
        companyId: currentPurchaseOrder.companyId,
        companyUsers: companyUsers,
      });
      break;
  }
};

export const saveItem = async ({ newRow }) => {
  let itemSalesOrders = newRow.salesOrders || [];
  if (itemSalesOrders.length > 0) {
    itemSalesOrders = itemSalesOrders.map((salesOrder) => {
      let shipments = salesOrder.shipments || [];
      if (shipments.length > 0) {
        shipments = shipments.map((shipment) => {
          return {
            ...new ShipmentItem({ ...shipment }),
          };
        });
      } else {
        shipments.push({
          id: "new",
          status: "new",
          allocation: salesOrder.allocation,
          number: "-",
          finalDestination: "-",
        });
      }
      return { ...new SalesOrderItem({ ...salesOrder }), shipments };
    });
  }
  if (newRow.ref) {
    await updateDoc(newRow.ref, {
      ...new Item({ ...newRow }),
      salesOrders: itemSalesOrders,
    });
  }

  return { ...new Item({ ...newRow }), salesOrders: itemSalesOrders };
};

export const getArrowIconSKU = ({
  isSorting,
  additionalStyle = {},
  sortTable = {},
}) => {
  const arrowStyle = {
    opacity: 1,
    color: "#3399FF",
    position: "absolute",
    ...additionalStyle,
  };
  if (isSorting && sortTable.asc) {
    return (
      <ArrowsToSortIcon
        svgClass="headerArrowButton"
        style={isSorting ? arrowStyle : additionalStyle}
        leftColor="#25486D"
        rightColor="#EEEEEE"
      />
    );
  } else if (isSorting && !sortTable.desc) {
    return (
      <ArrowsToSortIcon
        svgClass="headerArrowButton"
        style={isSorting ? arrowStyle : additionalStyle}
        rightColor="#25486D"
        leftColor="#EEEEEE"
      />
    );
  }
  return (
    <ArrowsToSortIcon
      svgClass={"headerArrowButton"}
      style={isSorting ? arrowStyle : additionalStyle}
    />
  );
};

export function checkMismatchInTable({
  type = "",
  items = [],
  salesOrder = {},
  shipment = {},
}) {
  switch (type) {
    case globalScopes.PURCHASE_ORDER:
      let mismatchPurchaseOrder = false;
      items.forEach((item) => {
        if (!mismatchPurchaseOrder) {
          const soItems = item.salesOrders || [];
          soItems.forEach((so) => {
            if (!mismatchPurchaseOrder) {
              const shipmentItems = so.shipments || [];
              let shipmentAllocation = 0;
              shipmentItems.forEach((item) => {
                if (item.id !== "new") {
                  shipmentAllocation += item.allocation;
                }
              });
              mismatchPurchaseOrder = so.allocation !== shipmentAllocation;
            }
          });
        }
      });
      return mismatchPurchaseOrder;

    case globalScopes.SHIPMENT:
      let mismatchShipment = false;
      items.forEach((item) => {
        if (!mismatchShipment) {
          const carton = getCartons({
            quantity: item.shipmentQuantity[shipment.id],
            pieces: item.piecesPerMaster,
          });
          mismatchShipment = carton % 1 !== 0;
        }
      });
      return mismatchShipment;

    default:
      let mismatch = false;
      let isWholeNumber = false;
      items.forEach((item) => {
        if (item.status !== itemStatus.READ_ONLY) {
          const soQuantity = item.salesOrderQuantity || {};
          const poQuantity = item.purchaseOrderQuantity || 0;
          const cartons = getCartons({
            pieces: item.piecesPerMaster,
            quantity: soQuantity[salesOrder.id],
          });

          mismatch =
            parseFloat(soQuantity[salesOrder.id] || 0) !==
              parseFloat(poQuantity) || mismatch;
          isWholeNumber = cartons % 1 !== 0 || isWholeNumber;
        }
      });
      if (mismatch && isWholeNumber) {
        return mismatchLabel.SO_MISMATCH_AND_NUMBER;
      } else if (mismatch) {
        return mismatchLabel.SO_MISMATCH;
      } else if (isWholeNumber) {
        return mismatchLabel.SO_WHOLE_NUMBER;
      }

      return "";
  }
}

export const getPOMismatchTag = ({
  currentSalesOrder,
  currentPurchaseOrder,
  poItemsList,
}) =>
  checkMismatchInTable({
    salesOrder: currentSalesOrder,
    purchaseOrder: currentPurchaseOrder,
    type: globalScopes.PURCHASE_ORDER,
    saleOrder: currentSalesOrder,
    items: poItemsList,
  });

export const getShipmentMismatch = ({ currentShipment, shipmentsItems }) =>
  checkMismatchInTable({
    shipment: currentShipment,
    type: globalScopes.SHIPMENT,
    items: shipmentsItems,
  });

export const getSOMismatchTable = ({ currentSalesOrder, soItemsList }) => {
  return checkMismatchInTable({
    salesOrder: currentSalesOrder,
    type: globalScopes.SALES_ORDER,
    items: soItemsList,
  });
};

export const getAllMismatchTable = ({
  currentSalesOrder,
  currentPurchaseOrder,
  poItemsList,
  soItemsList,
  currentShipment,
  shipmentsItems,
}) => {
  const hasPOMismatchTag = checkMismatchInTable({
    salesOrder: currentSalesOrder,
    purchaseOrder: currentPurchaseOrder,
    type: globalScopes.PURCHASE_ORDER,
    saleOrder: currentSalesOrder,
    items: poItemsList,
  });
  const hasSOMismatchTag = checkMismatchInTable({
    salesOrder: currentSalesOrder,
    type: globalScopes.SALES_ORDER,
    items: soItemsList,
    purchaseOrder: currentPurchaseOrder,
  });

  const hasShipmentMismatch = checkMismatchInTable({
    shipment: currentShipment,
    type: globalScopes.SHIPMENT,
    items: shipmentsItems,
  });

  return { hasSOMismatchTag, hasPOMismatchTag, hasShipmentMismatch };
};

export const getSKUProperties = ({
  itemSKU,
  prefix,
  entity = {},
  purchaseOrders = [],
}) => {
  const {
    itemNumber,
    image = [],
    salesOrderQuantity = {},
    shipmentQuantity = {},
    salesOrderPrice = {},
    cbmMaster = "0",
    piecesPerMaster,
    purchaseOrderId,
    unitCost,
    description,
    status,
    weight,
    purchaseOrderQuantity,
    salesOrders,
    shipmentIds,
  } = itemSKU;
  const purchaseOrder = purchaseOrders.find((po) => po.id === purchaseOrderId);
  if (prefix === PREFIX_ITEM_TABLE.SALES_ORDER) {
    const soPrice = salesOrderPrice[entity.id] || 0;
    const soQuantity = salesOrderQuantity[entity.id] || 0;
    return {
      itemNumber,
      description,
      image: image[0] || "",
      quantity: soQuantity,
      price: soPrice,
      extendedCost: getExtendedCostPrice({
        cost: soPrice,
        quantity: soQuantity,
      }),
      cbmMaster: parseFloat(cbmMaster).toFixed(2),
      piecesPerMaster,
      cartons: getCartons({
        pieces: piecesPerMaster,
        quantity: soQuantity,
      }),
      totalCBM: getTotalCBM({
        cbm: cbmMaster,
        pieces: piecesPerMaster,
        quantity: soQuantity,
      }),
      purchaseOrder,
      status,
    };
  }
  if (prefix === PREFIX_ITEM_TABLE.PURCHASE_ORDER) {
    const shipments = salesOrders.map((so) => [...(so.shipments || [])]).flat();
    return {
      itemNumber,
      image: image[0] || "",
      description,
      quantity: purchaseOrderQuantity,
      purchaseOrder,
      unitCost,
      extendedCost: getExtendedCostPrice({
        cost: unitCost,
        quantity: purchaseOrderQuantity,
      }),
      totalRows: Math.max(salesOrders.length, shipments.length),
      salesOrders,
      status,
      shipmentIds,
    };
  } else {
    const currentShipmentQuantity = shipmentQuantity[entity.id] || 0;
    return {
      itemNumber,
      image: image[0] || "",
      description,
      quantity: currentShipmentQuantity,
      purchaseOrder,
      unitCost,
      extendedCost: getExtendedCostPrice({
        cost: unitCost,
        quantity: currentShipmentQuantity,
      }),
      cbmMaster: parseFloat(cbmMaster).toFixed(2),
      piecesPerMaster,
      cartons: getCartons({
        quantity: currentShipmentQuantity,
        pieces: piecesPerMaster,
      }),
      totalCBM: getTotalCBM({
        cbm: cbmMaster,
        pieces: piecesPerMaster,
        quantity: currentShipmentQuantity,
      }),
      weight: getTotalWeight({
        weight: weight,
        pieces: piecesPerMaster,
        quantity: currentShipmentQuantity,
      }),
      status,
    };
  }
};

export const getPOPathBySKU = ({ purchaseOrder, queryParams }) => {
  const itemPurchaseOrderId = purchaseOrder.id;
  let newQuerySearch = {};
  if (queryParams.purchaseOrderId !== itemPurchaseOrderId && purchaseOrder.id) {
    newQuerySearch = {
      ...queryParams,
      purchaseOrderId: purchaseOrder.id,
      shipmentId: purchaseOrder.shipmentIds[0] || "",
      section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
    };
  } else {
    newQuerySearch = {
      ...queryParams,
      section: ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
    };
  }
  return getDashboardSearchPath({
    ...newQuerySearch,
  });
};

export const getRowStyles = ({ open, showImage, totalRows, backdropStyle }) => {
  const sizeRow = showImage
    ? itemTableRowHeight.WITH_IMAGE + 0.75
    : itemTableRowHeight.NORMAL + 0.75;
  const parseTotalRow = totalRows - 1 < 0 ? 0 : totalRows - 1;
  const currentHeight = open
    ? sizeRow + parseTotalRow * itemTableRowHeight.NORMAL
    : sizeRow;
  return {
    minHeight: currentHeight,
    height: currentHeight,
    gridTemplateRows: `${sizeRow}px repeat(${totalRows - 1}, ${
      itemTableRowHeight.NORMAL
    }px)`,
    overflow: "hidden",
    zIndex: backdropStyle ? 1500 : "",
  };
};

export const getDiffSOQuantity = ({ shipments, salesOrderItem }) => {
  const totalShipment = shipments.reduce(
    (a, b) => parseInt(a, 10) + parseInt(b.id === "new" ? 0 : b.allocation, 10),
    0
  );
  const soAllocation = parseInt(salesOrderItem.allocation, 10);
  const diffQuantity = totalShipment - soAllocation;
  const dangerStyle = diffQuantity !== 0;
  return { dangerStyle, diffQuantity };
};
