import { colors } from "../../assets/jss/variables";
import AppConfig from "../../constants/AppConfig";
import { darkBlue, onHoverBackground } from "../../helpers/variableColors";
import { styled, keyframes, Popover, Collapse, Table } from "@mui/material";
import { ArrowsToSortIcon } from "../../helpers/iconSvgPath";
import { dbTables } from "../../api/types/dbTables";

export const ProjectNotesContainerStyled = styled("div")(() => ({
  "&.orderDashboardNotesContainer": {
    flex: 1,
    position: "relative",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    boxShadow: "0 2px 3px 1px #dddddd",
    transition: "all 1s ease",
    border: `1px solid ${colors.dividerColor}`,
    zIndex: 13,
    "& .hambugerIconButton": {
      padding: 7,
      borderRadius: 6,
      position: "relative",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        background: colors.backgroundWhite,
      },
      "&:active": {
        background: colors.backgroundWhiteAction,
      },
    },
    "& .mentions-filter": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: 6,
    },
    "& .content-card-header": {
      padding: "12px 20px 20px 16px",
      paddingBottom: 0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      fontSize: 14,
      position: "relative",
      fontWeight: "normal",
      "& .subContainerFilter": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        marginBottom: 8,
        paddingRight: 9,
        "& .dividerReply": {
          transition: "height 0.8s",
          width: 2,
          minWidth: 2,
          height: 20,
          backgroundColor: colors.primaryDark,
          marginRight: 16,
        },
      },
      "& .project-tasks": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        height: 40,
        paddingRight: 4,
        "& .title": {
          fontSize: 23,
          fontWeight: "normal",
          color: colors.primaryDark,
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxHeight: 60,
          display: "flex",
          alignItems: "baseline",
          gap: 6,
        },

        "& .general-filters": {
          display: "flex",
          justifyContent: "space-between",
          fontSize: 11,
          position: "relative",
          "& .indicator": {
            position: "absolute",
            background: "rgb(204, 204, 204)",
            height: 2,
            width: "98%",
            top: 26,
            opacity: 0.6,
          },
        },
      },
    },
    "& .content-card-content": {
      flex: 1,
      fontSize: 13,
      fontWeight: "normal",
      position: "relative",
      color: colors.primaryDark,
      marginBottom: 8,
      borderRadius: 10,
      padding: "15px 2px 0px 2px",
      background: "#fff",
    },
  },
  "&.active": {
    transition: "all 2s ease",
    margin: 4,
    border: `0px solid ${colors.dividerColor}`,
  },

  "& .motionClass": {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: colors.dividerColor,
    transition: "all 2s ease",
    borderRadius: "8px",
  },
  "& .dashboard-activities-animated": {
    position: "relative",
    zIndex: 1,
    backgroundColor: "white",
    borderRadius: "8px",
    overflow: "hidden",
    height: "100%",
    margin: 0,
    display: "flex",
    flexDirection: "column",
    paddingBottom: 0,
    transition: "all 2s ease",
  },
  "& .activeThread": {
    margin: 4,
    border: `0px solid ${colors.dividerColor}`,
  },
  "& .contentCenter": {
    height: "100%",
    display: "flex",
    width: "100%",
    "& .contentNotes": {
      width: "100%",
    },
    "& .content-card-content": {
      height: "80%",
    },
  },
  "& .poligon_PURCHASE_ORDER": {
    background: colors.purchaseOrderBorderColor,
  },
  "& .poligon_SALES_ORDER": {
    background: colors.salesOrderBorderColor,
  },
  "& .poligon_PURCHASE_QUOTE": {
    background: colors.purchaseOrderBorderColor,
  },
  "& .poligon_QUOTE": {
    background: colors.salesOrderBorderColor,
  },
  "& .poligon_SHIPMENT": {
    background: colors.shipmentBorderColor,
  },
}));

export const TitleExpandModalStyled = styled("div")(() => ({
  "&.titleExpandModalContainerStyled": {
    position: "absolute",
    top: 32,
    left: 35,
    fontSize: 14,
    fontWeight: 400,
    display: "flex",
    gap: 8,
    "& .principalTitle": {
      color: colors.darkGray,
    },
    "& .subtitle": {
      color: colors.primaryDark,
      marginRight: 38,
    },
  },
}));

export const DocumentPanelContainerStyled = styled("div")(() => ({
  "&.documentPanelContainer": {
    background: "lightblue",
    flex: 1,
    position: "relative",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    boxShadow: "0 2px 3px 1px #dddddd",
    height: "100%",
    transition: "all 1s ease",
    "& .header-container": {
      textAlign: "center",
      fontSize: 23,
      fontWeight: "300",
      display: "flex",
      alignItems: "baseline",
      gap: 6,
    },
    "& .content-card-header": {
      padding: "12px 47px 20px 30px",
      paddingBottom: 0,
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
      fontSize: 14,
      position: "relative",
      fontWeight: "normal",
      "& .documents-title": {
        fontSize: 23,
        fontWeight: "normal",
        color: "#25486d",
        display: "flex",
        alignItems: "center",
      },
      "& .custom-tabs": {
        minHeight: "0px !important",
      },
      "& .custom-tabs-container": {
        height: 40,
        "& .SALES_ORDER": {
          background: `${AppConfig.themeColors.salesOrderBackgroundColor} !important`,
          border: `1px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
        },
        "& .activeSALES_ORDER": {
          background: `${AppConfig.themeColors.salesOrderBackgroundColor} !important`,
          border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
          zIndex: 1,
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
        },
        "& .PURCHASE_ORDER": {
          background: `${AppConfig.themeColors.purchaseOrderBackgroundColor} !important`,
          border: `1px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
        },
        "& .activePURCHASE_ORDER": {
          background: `${AppConfig.themeColors.purchaseOrderBackgroundColor} !important`,
          border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
          zIndex: 1,
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
        },
        "& .SHIPMENT": {
          background: `${AppConfig.themeColors.shipmentColor} !important`,
          border: `1px solid ${AppConfig.themeColors.shipmentBordeColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
        },
        "& .activeSHIPMENT": {
          background: `${AppConfig.themeColors.shipmentColor} !important`,
          border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
          zIndex: 1,
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
        },
      },
    },

    "& .custom-tab": {
      padding: 0,
      minHeight: "0px !important",
      height: 40,
      fontSize: 15,
      backgroundColor: "#cbddf0",
      borderColor: "#90aed1",
      margin: "0px 6px",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      "&:active": {
        transform: "none",
      },
      "& .disabled": {
        backgroundColor: "#dce6f0",
        opacity: 0.3,
      },
      "& .reassigned": {
        maxWidth: "360px !important",
      },
    },

    "& .content-card-content": {
      flex: 1,
      fontSize: 13,
      fontWeight: "normal",
      position: "relative",
      color: colors.primaryDark,
      borderRadius: 10,
      padding: "10px 10px 0px 16px",
      background: "#fff",
      borderTop: "2px solid",
      height: "100%",
      "& #folder-estructure-list": {
        height: "calc(100vh - 270px) !Important",
        "&::-webkit-scrollbar": {
          backgroundColor: "transparent",
          width: 16,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
          borderRadius: 7,
        },
        "&::-webkit-scrollbar-track": {
          border: " solid 1px transparent",
        },
        "&:hover": {
          "&::-webkit-scrollbar-thumb": {
            boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
            borderBlock: "1px solid transparent",
            borderInline: "5px solid transparent",
          },
        },
      },
    },
    [`& .container${dbTables.SALES_ORDERS}`]: {
      background: AppConfig.themeColors.salesOrderBackgroundColor,
      border: "none",
    },
    [`& .container${dbTables.PURCHASE_ORDERS}`]: {
      background: AppConfig.themeColors.purchaseOrderBackgroundColor,
      border: "none",
    },
    [`& .container${dbTables.SHIPMENTS}`]: {
      background: AppConfig.themeColors.shipmentColor,
      border: "none",
    },
    "& .documentScrollContainer": {
      paddingBottom: 0,
      position: "absolute",
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      paddingTop: 64,
      borderRadius: 8,
    },
    "& .documentScrollContainer_sales_orders": {
      border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
    },
    "& .documentScrollContainer_purchase_orders": {
      border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
    },
    "& .documentScrollContainer_shipments": {
      border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
    },
  },
}));

export const ShipmentTabsContainerStyled = styled("div")(() => ({
  "&.TabsContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: 40,
    margin: "0px 16px 0px 16px",
    position: "relative",
    "& .custom-popover": {
      zIndex: 100,
      "& .custom-root": {
        maxHeight: "calc(100vh - 400px)",
        minWidth: 150,
        overflowY: "auto",
        "& .custom-menu-item": {
          color: colors.primaryDark,
          fontSize: 14,
        },
      },
    },
  },
  "& .customTabs": {
    margin: "8px 0px 0px -5px",
    position: "relative",
    bottom: -2,
  },
  "& .customTabIndicator": {
    display: "none",
  },
  "& .customTabsContainer": {
    height: 40,
    marginLeft: 35,
  },
  "& .scrollButtons": {
    width: 20,
  },
  "& .customTab": {
    padding: "0px 12px",
    minHeight: "0px !Important",
    height: 40,
    fontSize: 15,
    backgroundColor: "#EFF8FF",
    margin: "0px 6px",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    "& .disabled": {
      backgroundColor: "#dce6f0",
      opacity: 0.3,
    },

    "& .reassigned": {
      width: 300,
      "& .wrapped-po": {
        width: "100%",
        "& .tabLabel": {
          width: "100% !Important",
        },
      },
    },
    "& .wrapped-shipment": {
      minHeight: "0px !important",
      height: 40,
      padding: 15,
      margin: 0,
      width: "auto",
      minWidth: 180,
      alignItems: "flex-start",
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      "&:hover": {
        ".tabLabel + img": {
          display: "inline",
        },
      },
    },
  },
  "& .activeTab": {
    backgroundColor: colors.shipmentBadge,
    border: `2px solid ${colors.shipmentBorderColor}`,
    borderBottom: "transparent",
    fontWeight: "bold !important",
    boxShadow: "0px -10px 10px rgb(0 0 0 / 25%)",
    zIndex: 3,
    "&:focus": {
      border: `2px solid ${colors.shipmentBorderColor}`,
      borderBottom: "transparent",
    },
  },
  "& .shipmentInfo": {
    zIndex: 16,
  },
}));

export const TabLabelStyled = styled("div")(() => ({
  "&.tabLabelContainer": {
    width: "100%",
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: 3,
  },
  "& .tabInfoContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "auto",
    "& .tab-label-container": {
      width: "-webkit-fill-available",
      display: "flex",
      alignItems: "center",
      "& .tabLabel": {
        width: "100%",
        fontSize: 15,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        display: "flex",
        alignItems: "center",
        // fontWeight: 500,
        color: colors.primaryDark,
        "& + img": {
          display: "none",
        },
      },
      "& .tabLabel:hover": {},
    },
    "& .tab-po-information": {
      width: 100,
      height: 21,
      gap: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginBottom: 2,
      "&:hover": {
        "& .starContainer": {
          opacity: 1,
        },
      },
      "& .avatarHoverEffect": {
        "&:hover": {
          outline: `1px solid ${colors.primaryDark} !Important`,
        },
      },
    },
  },
  "& .progress": {
    height: 2,
    width: "100%",
    backgroundColor: "#c7d4e1",
    "& div": {
      backgroundColor: AppConfig.themeColors.blue,
      height: 2,
      transition: "all 0.3s",
    },
  },
}));

export const ShipmentTitleContainer = styled("div")(() => ({
  "&.shipmentTitleContainer": {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "& .stc-shipment-number": {
      display: "flex",
      alignItems: "center",
      width: 270,
      marginRight: 20,
    },
    "& .stc-shipment-customer": {
      width: 548,
    },
    "& .shipmentBadge": {
      marginLeft: 6,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "3px 15px 2px 12px",
      background: "white",
      border: `2px solid ${colors.shipmentBorderColor}`,
      borderRadius: 12,
      color: colors.primaryDark,
      fontSize: 16,
      height: 24,
      fontWeight: 700,
      marginTop: 1,
    },
  },
}));
export const PurchaseOrderNavigationStyled = styled("div")(() => ({
  "&.purchaseOrderNavigationContainer": {
    height: 40,
    width: "100%",

    "& .customTabsContainer": {
      "@media screen and (max-width: 1920px)": {
        "& .custom-scroller": {
          maxWidth: 1600,
        },
      },
      "& .custom-tabs-container": {
        height: 40,
        "& .SALES_ORDER": {
          background: `${AppConfig.themeColors.salesOrderBackgroundColor} !important`,
          border: `1px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
        },
        "& .activeSALES_ORDER": {
          background: `${AppConfig.themeColors.salesOrderBackgroundColor} !important`,
          border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
          zIndex: 1,
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
        },
        "& .PURCHASE_ORDER": {
          background: `${AppConfig.themeColors.purchaseOrderBackgroundColor} !important`,
          border: `1px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
        },
        "& .activePURCHASE_ORDER": {
          background: `${AppConfig.themeColors.purchaseOrderBackgroundColor} !important`,
          border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
          borderBottom: "transparent",
          fontSize: 15,
          width: "50%",
          zIndex: 1,
          boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.25)",
        },
      },
      "& .custom-tabs-container-first": {
        height: 40,
        marginLeft: -180,
      },
      "& .custom-tabs": {
        minHeight: 0,
      },

      "& .disabledTab": {
        backgroundColor: "#cbddf0",
        opacity: 0.5,
        position: "absolute",
        height: "100%",
        width: "100%",
      },
      "& .activeDisabledTab": {
        backgroundColor: "#E6F0F9",
      },

      "& .custom-tab": {
        padding: "0px 12px",
        minHeight: 0,
        height: 40,
        fontSize: 15,
        backgroundColor: "#cbddf0",
        borderColor: "#90aed1",
        margin: "0 6px",
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,

        "&:active": {
          transform: "none",
        },

        "&.reassigned": {
          maxWidth: 360,
          "& .wrapped-po": {
            width: "100%",
            "& .tabLabel": {
              width: "100% !important",
            },
          },
        },
      },
      "&custom-tab:hover": {
        backgroundColor: "#c2daf7",
      },
      "& .wrapped": {
        minHeight: 0,
        height: 40,
        padding: 8,
        margin: 0,
        "& .tabLabel": {
          fontSize: 15,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "flex",
          alignItems: "center",
        },
        "& .tabLabel:hover": {
          "& + img": {
            display: "inline",
          },
        },
        "& .progress": {
          height: 2,
          width: "100%",
          backgroundColor: AppConfig.themeColors.primaryLight,
        },
      },
      "& .wrapped-po": {
        minHeight: 0,
        height: 40,
        padding: 15,
        margin: 0,
        width: "auto",
        minWidth: 180,
        alignItems: "flex-start",
        "&:hover": {
          "& .tabLabel + img": {
            display: "inline",
          },
        },
      },
      "& .tab-label": {
        fontSize: 10,
        textAlign: "left",
        "& span": {
          margin: 0,
        },
      },
      "& .tab-label-container": {
        lineHeight: 1.5,
        padding: 0,
        margin: 0,
        textAlign: "left",
      },
    },
    "& .activeTab": {
      backgroundColor: `${colors.purchaseOrderBackgroundColor} !important`,
      border: ` 2px solid ${colors.purchaseOrderBorderColor}`,
      borderBottom: "transparent",
      zIndex: 2,
      fontWeight: "bold !important",
    },
  },

  "& .purchaseOrderInfo": {
    zIndex: 15,
  },
  "& .shipmentInfobody": {
    zIndex: 15,
  },
  "& .shipmentDetailContainer": {
    position: "relative",
    backgroundColor: colors.shipmentBadge,
    border: `2px solid ${colors.shipmentBorderColor}`,
    borderRadius: 10,
    padding: "0px 16px 0px 0px",
    "& .shipment-title-container": {
      display: "flex",
      alignItems: "center",
      padding: "19px 15px 0px 10px",
      position: "relative",
      "& .stc-shipment-number": {
        display: "flex",
        alignItems: "center",
        width: 270,
        marginRight: 20,
      },
      "& .stc-shipment-customer": {
        width: 548,
      },
      "& .shipmentBadge": {
        marginLeft: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px 15px 2px 12px",
        background: "white",
        border: `2px solid ${colors.shipmentBorderColor}`,
        borderRadius: 12,
        color: colors.primaryDark,
        fontSize: 16,
        height: 24,
        fontWeight: 700,
        marginTop: 1,
      },
    },
  },
  "& .header": {
    backgroundColor: `${colors.salesOrderBackgroundColor} !important`,
    borderRadius: 10,
  },
  "& .borderColor": {
    border: `2px solid ${colors.salesOrderBorderColor} !important`,
  },
  "& .poContainer": {
    background: `${colors.purchaseOrderBackgroundColor} !important`,
    border: ` 2px solid ${colors.purchaseOrderBorderColor} !important`,
    padding: "20px 16px 20px 10px !important",
    marginBottom: "19px !important",
  },
  "& .container": {
    display: "grid",
    gridTemplateColumns: "repeat(5, 271px)",
    gridGap: "1px 20px",
  },
}));

export const ShipmentInfoGridStyled = styled("div")(() => ({
  "&.newShipmentDetailContainer": {
    backgroundColor: AppConfig.themeColors.white,
    borderRadius: 6,
    padding: "32px 55px 0px 35px;",
    height: 340,
    overflowY: "auto",
    "& .shipment-title-container": {
      display: "flex",
      alignItems: "center",
      marginBottom: 17,
      padding: 0,
      position: "sticky",
      left: 0,
      "& .stc-shipment-number": {
        width: 265,
        marginRight: 20,
        display: "flex",
        alignItems: "center",
      },
      "& .stc-shipment-customer": {
        width: 536,
      },
      "& .shipmentBadge": {
        marginLeft: 6,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px 15px 2px 12px",
        background: colors.shipmentBadge,
        border: `2px solid ${colors.shipmentBorderColor}`,
        borderRadius: 12,
        color: colors.primaryDark,
        fontSize: 16,
        height: 24,
        fontWeight: 700,
        marginTop: 3,
      },
    },
  },
  "& .shipmentInfoContainer": {
    display: "grid",
    gridTemplateColumns: "repeat(5, 271px)",
    gridGap: "0px 20px",
    marginBottom: 16,
    "& .sales-order-property": {
      borderTop: `1px solid ${colors.dividerColor}`,
      width: "100%",
      "& .edit-icon": {
        right: 7,
        color: colors.primaryDark,
      },
    },
  },
  "& .numberBadge": {
    fontSize: 14,
    padding: "3px 8px",
    fontWeight: 700,
    background: AppConfig.themeColors.shipmentColor,
    borderRadius: 8,
    marginLeft: 18,
  },
  "& .cancelButton": {
    fontSize: 14,
    background: " #FFFFFF",
    border: "1px solid #8D9CB1",
    bordeRadius: 5,
    color: "#334C73",
  },
  "& .doneButton": {
    width: 153,
    fontWeight: 700,
    fontSize: 14,
    backgroundColor: "#218CFF",
    marginRight: 17,
  },
}));

export const TaskItemContainerStyled = styled("div")(() => ({
  "&.taskListContainer": {
    position: "relative",
    "& .taskPOItemWrapper": {
      background: "white",
    },
    "& .hiddenText": {
      height: "0 !important",
      transition: "height 2s ease-out",
      overflow: "hidden",
    },
    "& .taskSOItemWrapper": {
      background: "#E6F0F9",
    },
    "& .taskItemContainer": {
      display: "flex",
      userSelect: "none",
      alignItems: "center",
      height: "100%",
      "& .description-task-container": {
        width: "100%",
        height: "25px",
        margin: "0px 8px",
      },
      "& .cancelIcon": {
        position: "absolute",
        right: 4,
        width: 15,
        height: 15,
        zIndex: 1,
        color: colors.diamondRed,
      },
      "& .task-description-wrapper": {
        "& .span-task-description": {
          display: "-webkit-box",
          overflow: "hidden",
          textOverflow: "ellipsis",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          wordBreak: "break-word",
        },
      },
    },
    "& .taskStatusContainer": {
      maxWidth: 32,
      minWidth: 32,
      height: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      zIndex: 3,
      left: 3,
    },
  },
}));

export const AdhocTaskStyled = styled("div")(() => ({
  "&.adhocTaskContainer": {
    padding: "0px 6px 16px 13px",
    margin: "8px 3px 8px 14px",
    border: "2px solid #B6C193",
    borderRadius: 3,
  },
  "& .tabAdhocTaskType": {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 8,
    position: "relative",
    "& .verticalDivider": {
      height: "42px",
      width: "1px",
      backgroundColor: "#e0e0e0",
      marginRight: "25px",
      marginLeft: "22px",
    },
    "& .indicator": {
      position: "absolute",
      background: "rgb(204, 204, 204)",
      height: 2,
      width: "95%",
      top: 34,
      opacity: 0.6,
    },
  },
  "& .adhocTaskTypeContainer": {
    display: "flex",
    alignItems: "flex-start",
    height: 40,
  },

  "& .adhocTaskInfoWrapper": {
    backgroundColor: "transparent",
    zIndex: 4,
    display: "flex",
    justifyContent: "center",
    "& .button-enable": {
      background: "lightgray",
      color: "darkslategrey",
      borderRadius: 4,
      height: 40,
      width: "10%",
      margin: 2,
      fontSize: 12,
      fontWeight: 500,
      border: `1px solid ${AppConfig.themeColors.dividerColor}`,
    },
    "& .button-disable": {
      background: "#39f",
      borderRadius: 4,
      height: 40,
      width: "10%",
      margin: 2,
      fontSize: 12,
      fontWeight: 500,
      color: "white",
      border: `1px solid ${AppConfig.themeColors.dividerColor}`,
    },
  },

  "& .adhocTaskInfoContainer": {
    backgroundColor: "white",
    border: "1px solid #a8b9ca",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    zIndex: 10,
    margin: 2,
    padding: 4,
    justifyContent: "flex-end",
    width: "100%",
    "&:hover": {
      "& .adhoc-task-description": {
        "&::placeholder": {
          color: "#53575B",
        },
      },

      "& .adhoc-task-phase": {
        "& p": {
          color: "#53575B !Important",
        },
      },

      "& .adhoc-task-user": {
        "& .default-user-icon": {
          "& circle": {
            fill: "#53575B",
          },
        },
      },
    },
    "& .adhoc-task-description": {
      "&::placeholder": {
        color: "#92A1B0",
        fontWeight: 300,
        fontSize: 12,
      },
    },

    "& input": {
      padding: 8,
      background: "white",
      top: 0,
      border: "none",
      width: "100%",
    },
    "& .adhocTaskBox": {
      height: "100%",
      minWidth: 50,
      borderLeft: `1px solid ${AppConfig.themeColors.dividerColor}`,
      padding: 4,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      fontWeight: 400,
      userSelect: "none",
    },

    "& .date-box": { width: 80, display: "flex", fontSize: "0.85em" },
    "& .adhoc-task-user": {
      minWidth: 33,
      width: 33,
      display: "flex",
      justifyContent: "center",
      "& img": {
        objectFit: "contain",
        borderRadius: "50%",
        backgroundColor: "$primary",
      },
    },
    "& .adhoc-task-phase": {
      "& span": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  },
}));

export const ProjectTaskFiltersStyled = styled("div")(() => ({
  "&.projectTaskFilterContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 10,
    "& .title": {
      fontSize: 23,
      fontWeight: "normal",
      paddingLeft: 8,
      color: colors.primaryDark,
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxHeight: 60,
      display: "flex",
      alignItems: "baseline",
      gap: 6,
      "& .timeline-icon": {
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  },

  "& .projectTaskFilters": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    textAlign: "left",
    fontSize: 11,
    marginRight: 6,

    "& .show-completed": {
      textTransform: "uppercase",
      fontWeight: 200,
      fontSize: 11,
      gap: 2,
      height: 13,
      display: "flex",
      width: 200,
      justifyContent: "flex-end",
      margin: "7px 0px 8px 18px",
      opacity: 1,
      alignItems: "center",
      "& .checkboxCompleted": {
        padding: 0,
      },
      "& .sc-text-container": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        position: "relative",
        lineHeight: "10px",
      },
      "& input": {
        margin: "0 5px 2px 0",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    "& .general-filters": {
      display: "flex",
      position: "relative",
      height: 30,
      "& .indicator": {
        position: "absolute",
        background: "rgb(204, 204, 204)",
        height: 2,
        width: "100%",
        top: 26,
        opacity: 0.6,
      },
    },
  },
}));
export const ArrowsToSortIconStyled = styled(ArrowsToSortIcon)(() => ({
  "&.headerArrowButton": {
    display: "inline-block",
    position: "absolute",
    left: "auto",
  },
}));

export const WindowTableStyled = styled(Table)(() => ({
  "&.table": {
    marginBottom: 18,
    "& .date": {
      fontSize: 14,
      color: darkBlue,
      padding: "6px 34px 6px 0px",
      border: "none",
      textAlign: "right",
    },
    "& .number": {
      fontSize: 14,
      color: darkBlue,
      padding: "6px 0px 6px 24px",
      border: "none",
      textAlign: "left",
    },
    "& .finalDestination": {
      fontSize: 14,
      color: darkBlue,
      padding: "6px 13px 6px 6px",
      border: "none",
    },
  },
}));

export const ShipmentDateWindowPopoverStyled = styled(Popover)(() => ({
  "&.shipmentDateWindowPopoverContainer": {
    "& .shipmentDateWindowPaperContainer": {
      width: "auto",
      "& .sdw-title-container": {
        fontSize: 20,
        fontWeight: 400,
        color: colors.darkGray,
        height: 59,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .sdw-body-container": {},
      "& .sdw-divider": {
        height: 1,
        width: "90%",
        background: colors.darkGray,
        margin: "auto",
      },
    },
    "& .sortedHeaderCell": {
      backgroundColor: "transparent",
      zIndex: 100,
      top: 155,
      minHeight: 45,
      height: 45,
      color: colors.primaryDark,
      fontSize: 14,
      fontWeight: 400,
      "& svg": {
        color: "#39f",
      },
    },
    "& .headerCell": {
      backgroundColor: "transparent",
      zIndex: 100,
      top: 155,
      height: 45,
      minHeight: 45,
      color: colors.primaryDark,
      fontSize: 14,
      fontWeight: 400,
      "& svg": {
        opacity: 0,
      },
      "&:hover": {
        "& svg": {
          opacity: 1,
        },
      },
    },

    "& .valueContainer": {
      fontSize: 13,
      paddingTop: 4,
    },
    "& .tableHeaderContainer": {
      background: "white",
    },
    "& .tableRow": {
      "&:hover": {
        background: onHoverBackground,
      },
    },
  },
}));

export const ShipmentDateWindowStyled = styled("div")(() => ({
  "&.shipmentDateWindowContainer": {},
}));

export const POVendorTemplateButtonsStyled = styled("div")(() => ({
  "&.containerPO": {
    marginLeft: 125,
    display: "flex",
    "& .buttonPOVendor": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 6px",
      background: "#218CFF",
      borderRadius: 5,
      fontWeight: 700,
      lineHeight: "16px",
      textAlign: "center",
      color: "white",
      minWidth: 95,
      "& .MuiButton-label": {
        height: "100%",
        lineHeight: "initial",
      },
    },
  },
  "&.taskContainer": {
    display: "flex",
    position: "absolute",
    top: 43,
    gap: 5,
    "& .buttonPOVendor": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "10px 6px",
      background: "#218CFF",
      borderRadius: 5,
      fontWeight: 700,
      lineHeight: "16px",
      textAlign: "center",
      color: "white",
      minWidth: "auto",
      fontSize: 11,
      minHeight: 20,
      height: 28,

      "& .MuiButton-label": {
        height: "100%",
        lineHeight: "initial",
      },
    },
  },
}));

export const CreateNoteCollapseStyled = styled(Collapse)(() => ({
  "&.createNoteCollapse": {
    "&:active": {},
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      flexDirection: "column",
    },
    "& .noteButton": {
      color: colors.primaryDark,
      width: "100%",
      fontWeight: 400,
      fontSize: 14,
      textAlign: "center",
      padding: "12px 0px",
      height: 40,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
        fontWeight: 700,
      },
      "&:focus": {
        fontWeight: 600,
      },
      "&:active": {
        transform: "scale(1)",
      },
      "& .text-name": {
        marginTop: 4,
      },
      "& .orderIcon": {
        fill: colors.primaryDark,
        marginRight: 8,
      },
    },

    "& .soCreationNote": {
      background: colors.salesOrderBackgroundColor,
      border: `2px solid ${colors.salesOrderBorderColor}`,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      "&:not(.disableStyled):hover": {
        background: colors.salesOrderHoverColor,
        borderColor: `${colors.salesOrderBorderColor}`,
      },
      "&:not(.disableStyled):active": {
        background: colors.backgroundGreenAction,
        borderColor: `${colors.backgroundGreenAction}`,
      },
    },
    "& .poCreationNote": {
      background: colors.purchaseOrderBackgroundColor,
      border: `2px solid ${colors.purchaseOrderBorderColor} `,
      "&:not(.disableStyled):hover": {
        background: colors.purchaseOrderHoverColor,
        borderColor: `${colors.purchaseOrderBorderColor} `,
      },
      "&:not(.disableStyled):active": {
        background: colors.backgroundBlueAction,
        borderColor: `${colors.backgroundBlueAction} `,
      },
    },
    "& .shipmentCreationNote": {
      background: colors.shipmentBadge,
      border: `2px solid ${colors.shipmentBorderColor}`,
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      "&:not(.disableStyled):hover": {
        background: colors.shipmentHoverColor,
        borderColor: `${colors.shipmentBorderColor}`,
      },
      "&:not(.disableStyled):active": {
        background: colors.backgroundPinkAction,
        borderColor: `${colors.backgroundPinkAction}`,
      },
    },
    "& .disableStyled": {
      color: "#ccc",
      "& .orderIcon": {
        fill: "#ccc",
        marginRight: 8,
      },
      "&:hover": {
        cursor: "not-allowed",
        fontWeight: 400,
      },
    },
  },
}));

const slideInRightKF = keyframes`
  from {
    width: 0; 
  }
  to {
    width: 578px;
  }
`;

const slideInLeftKF = keyframes`
  from {
    width: 578px;
  }
  to {
    width: 0;
  }
`;

const opacityRightKF = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const opacityLeftKF = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const NotesSectionStyled = styled("div")(() => ({
  "&.notesSectionContainer": {},
}));

export const NoteSectionBodyContainerStyled = styled("div")(() => ({
  "&.noteSectionBodyContainer": {
    height: "calc(100vh - 170px)",
    width: 690,

    "& .badge-notes": {
      margin: "0px 0px 12px 21px",
      fontSize: 12,
      fontWeight: 500,
    },
    "& .section-content-notes": {
      margin: "0px 21px",
      height: "auto",
      display: "flex",
      "& div": {
        width: "100%",
        height: "100%",
        "& div": {
          height: "100%",
          "& textarea": {
            position: "absolute !important",
          },
        },
        "& .user-mention": {
          fontWeight: 500,
        },
        "& .user-mention-disabled": {
          fontWeight: 500,
          opacity: 0.5,
        },
      },
    },
    "& .section-bottom": {
      margin: "15px 6px 0px -3px",
      display: "flex",
      "& .section-info-container": {
        alignItems: "flex-start",
        width: "75%",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        "& .info": {
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: 9,
          "& .textContainer": {
            fontSize: 12,
            fontWeight: 400,
            color: "#6F7E8E",
            lineHeight: "16px",
          },
          "&:hover": {
            "& .textContainer": {
              fontWeight: 700,
            },
            "& .infoIcon": {
              "& .circleClass": {
                fill: "#84A1D6",
              },
            },
          },
        },
      },
      "& .delete-note-section": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 3,
        marginLeft: 3,
        cursor: "pointer",
        transition: "all 0.3s",
        gap: 9,
        height: 15,
        "& .deleteText": {
          padding: "0px 4px",
          marginTop: "2px",
          marginLeft: "3px",
          color: "#FF9494",
          fontSize: 12,
          fontWeight: 400,
          transition: "all 0.3s",
        },
        "& .trashIcon": {
          transition: "all 0.3s",
          width: 12,
          height: 13,
        },
        "&:hover": {
          "& .deleteText": {
            color: "#FA1616",
          },
          "& .trashIcon": {
            "& path": {
              fill: "#FA1616",
            },
          },
        },
        "&:active": {
          "& .deleteText": {
            fontSize: 14,
            fontWeight: 500,
            color: "#FA1616",
          },

          "& .trashIcon": {
            width: 15,
            height: 16,
            "& path": {
              fill: "#FA1616",
            },
          },
        },
      },
      "& .section-actions-buttons": {
        width: "25%",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        "& .actionsContainer": {
          display: "flex",
          height: 55,
          alignItems: "flex-start",
          gap: 15,
          position: "relative",
          "& .LockNoteIcon": {
            marginTop: 8,
            transition: "all 0.5s",
            marginLeft: 4,
            "& path": {
              transition: "all 0.3s",
            },
            scale: "1",
            "&:hover": {
              scale: "1.32",
            },
            "&:active": {
              "& .lockHead": {
                transform: "translateY(-4px)",
                strokeWidth: 2,
              },
              "& .lockBody": {
                strokeWidth: 2,
              },
            },
          },
          "& .locked": {
            "& .lockBody": {
              fill: "#E02020",
            },
            "&:active": {
              "& .lockHead": {
                transform: "translateY(-4px)",
                strokeWidth: 2,
              },
            },
          },
          "& .unlock": {
            "& .lockBody": {
              fill: colors.sliderGreen,
            },
            "& .lockHead": {
              transform: "translateY(-4px)",
            },
            "&:active": {
              "& .lockHead": {
                transform: "rotate(0deg) translateX(0px) translateY(0px)",
                strokeWidth: 2,
              },
            },
          },
        },
        "& .btn-attach-icon": {
          background: "#ccc",
          height: 40,
          width: 58,
          border: "1px solid $sales-order-color",
        },
        "& .buttonSubmit": {
          background: "#39f",
          margin: 0,
          borderRadius: 4,
          height: 40,
          width: 130,
          fontSize: 14,
          transition: "transform 0.3s, font-size 0.3s, background 0.3s",
          transformOrigin: "center center",
          "& .sendIcon": {
            transition: "all 0.3s",
            width: 14,
            height: 12,
          },
          "&:hover": {
            background: "#0A75E0",
            transform: "scale(1.115, 1.25)",
            fontSize: 20,
            fontWeight: 700,
            "& .sendIcon": {
              width: 20,
              height: 18,
            },
          },
          "&:active": {
            textShadow: "4px 10px 4px rgba(0, 0, 0, 0.25)",
            background: "#0A75E0",
            transform: "scale(1.115, 1.25)",
            fontSize: 24,
            fontWeight: 700,
            "& .sendIcon": {
              width: 20,
              height: 18,
              transform: "rotate(-30deg) translateX(6px) translateY(-4px)",
              filter: "drop-shadow(4px 10px 4px rgba(0, 0, 0, 0.25))",
            },
          },
        },
        "& .buttonDisabled": {
          pointerEvents: "none",
        },
        "& .label-description": {
          color: "#828282",
          fontSize: 12,
          textAlign: "right",
          marginBottom: 9,
          marginLeft: "auto",
          "&:hover": {
            transition: "all 0.3s",
            scale: "1.32",
          },
        },
      },
    },
  },
  "& .slideRight": {
    animationName: slideInRightKF,
    animationDuration: "0.2s",
    "& div:first-child": {
      animationName: opacityRightKF,
      animationDuration: "0.3s",
    },
  },
  "& .slideLeft": {
    animationName: slideInLeftKF,
    animationDuration: "0.2s",
    "& div:first-child": {
      animationName: opacityLeftKF,
      animationDuration: "0.25s",
    },
  },
  "& .btnStyle": {
    margin: 0,
    borderRadius: 0,
    width: "33.33%",
    color: colors.primaryDark,
    height: 40,
    fontSize: 16,
    fontWeight: 400,
    "&:hover": {
      cursor: "pointer",
      fontWeight: 700,
    },
    "&:focus": {
      fontWeight: 600,
    },
    "&:active": {
      transform: "unset",
    },
    "&:first-child": {
      borderRadius: "3px 0px 0px 3px",
    },
    "&:last-child": {
      borderRadius: "0px 3px 3px 0px",
    },
  },

  "& .buttonContainer": {
    width: "100%",
    "& .MuiButton-outlined.Mui-disabled": {
      alignItems: "center",
      fontWeight: 400,
      fontSize: 14,
      textAlign: "center",
      padding: "12px 0px",
      height: 40,
      opacity: 0.6,
      color: colors.primaryDark,
    },
  },
}));

export const POMetadataContainterStyled = styled("div")(() => ({
  "&.poContainer": {
    height: "100%",
    display: "flex",
    flexDirection: "column",

    "& .vendorLink": {
      wordSpacing: 15,
      color: colors.primaryDark,
      textDecoration: "underline",
    },
    "& .poTitleContent": {
      padding: "10px 19px 0px 1px",
      fontSize: 20,
      display: "flex",
      marginBottom: "5px",
      alignItems: "center",
      gap: 15,
    },
  },
}));

export const OrderDashboardContainerStyled = styled("div")(() => ({
  "&.orderDashboardContainer": {
    overflowY: "hidden",
    overflowX: "auto",
    height: "calc(100vh - 65px)",
    margin: "0px 32px 0px 8px",
    "& .sales-order-info-container": {
      border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
      background: AppConfig.themeColors.salesOrderBackgroundColor,
      borderRadius: 10,
      margin: "12px 3px 0px 0px",
      transition: "height 0.8s, opacity 1.2s ease-in-out",
      opacity: 1,
      height: "calc(100vh - 90px)",
      overflow: "hidden",
      width: "calc(100vw - 128px)",
      minWidth: 1645,
      display: "flex",
      "& .so-info-backdrop": {
        zIndex: 1202,
        position: "relative",
        background: colors.salesOrderBackgroundColor,
      },
      "& .blockContainer": {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .blockText": {
          color: colors.dangerRed,
          fontSize: 21,
        },
      },
      "& .sales-order-info": {
        padding: "0px 15px 0px 36px",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        height: "calc(100vh - 185px)",
        display: "flex",
        flexDirection: "column",
        "& .metadata-info-container": {
          display: "flex",
          flexDirection: "column",
          textAlign: "left",
          position: "relative",
          height: "100%",
        },
        "& .metadata-info": {
          display: "grid",
          gridGap: "1px 20px",
          gridTemplateColumns:
            "repeat(5, minmax(291px, calc((100vw - 307px) / 5)))",
        },
        "& .poInfoGrid": {
          paddingRight: 9,
          minWidth: 1529,
          gridTemplateColumns:
            "repeat(5, minmax(285px, calc((100vw - 350px) / 5)))",
        },
        "& .shipmentInfoGrid": {
          minWidth: 1569,
          padding: "0px 0px 0px 1px",
          gridTemplateColumns: "repeat(5, minmax(250px, 1fr))",
        },
        "& .salesOrderInfoGrid": {
          paddingRight: 19,
          minWidth: 1539,
        },
      },

      "& .above-purchase-order-navigation": {
        height: 8,
        width: "100%",
      },
      "& .above-shipment-navigation": {
        height: 8,
        width: "100%",
      },
    },
    // SALES ORDER INFO CONTAINER
    "& .sales-order-rendering": {
      height: "calc(100vh - 90px)",
      display: "flex",
      flexDirection: "column",
      paddingInline: 14,
      paddingBlock: "30px 0px",
      "& .min-height-metadata-content-so": {
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        opacity: 1,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      },
      "& .purchase-order-info-container-hidden": {
        border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
        background: AppConfig.themeColors.purchaseOrderBackgroundColor,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        position: "relative",
        opacity: 1,
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        height: "100%",
        "& .min-height-metadata-content-po": {
          opacity: 0,
          height: 14,
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
        "& .shipment-info-container-hidden": {
          opacity: 0,
          height: 0,
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
      },
    },
    "& .purchase-order-rendering": {
      flexDirection: "column",
      padding: "14px 12px 14px 12px",
      transition: "padding-bottom 0.8s ease-in-out",
      "& .min-height-metadata-content-so": {
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        opacity: 0,
        height: 0,
      },
      // PURCHASE ORDER INFO CONTAINER
      "& .purchase-order-info-container": {
        border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
        background: AppConfig.themeColors.purchaseOrderBackgroundColor,
        borderRadius: 10,
        position: "relative",
        opacity: 1,
        display: "flex",
        flexDirection: "column",
        "& .purchase-order-info": {
          height: "100%",
          padding: "20px 36px 14px 37px",
          position: "relative",
          "& .sales-order-info": {
            background: "transparent",
            height: 200,
            paddingBlock: 20,
            paddingInline: 0,
          },
          "& #purchaseOrderTableContainer": {
            maxHeight: "calc(100vh - 522px)",
          },
        },
      },
      "& .purchase-order-info-container-hidden": {
        "& .min-height-metadata-content-po": {
          opacity: 0,
          height: "0px",
          transition: "height 0.8s, opacity 1.2s ease-in-out",
          "& .shipment-info-container-hidden": {
            transition: "height 0.8s, opacity 1.2s ease-in-out",
            "& .min-height-metadata-content-shipment": {
              opacity: 0,
              height: 0,
              transition: "height 0.8s, opacity 1.2s ease-in-out",
            },
          },
        },
      },
      "& .purchase-order-info-container-visible": {
        overflow: "hidden",
        height: "100%",
        width: "100%",
        "& .min-height-metadata-content-po": {
          height: "100%",
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
      },
      "& .shipment-info-container": {
        margin: "0px 13px 0px 12px",
        border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
        borderBottom: 0,
        background: AppConfig.themeColors.shipmentColor,
        position: "relative",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        height: "100%",
        transition:
          "height 0.8s, opacity 1.2s ease-in-out, padding-bottom 0.8s ease-in-out",

        "& .min-height-metadata-content-shipment": {
          transition:
            "height 0.8s, opacity 1.2s ease-in-out, padding-bottom 0.8s ease-in-out",
        },
      },
      //
    },
    "& .shipment-rendering": {
      width: "calc(100vw - 128px)",
      minWidth: 1645,
      padding: "14px 12px 14px 12px",

      "& .shipment-info-container": {
        padding: "0px 36px 14px 36px",
        border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
        background: AppConfig.themeColors.shipmentColor,
        position: "relative",
        borderRadius: 10,
        opacity: 1,
        overflow: "hidden",
        "& .shipment-info": {
          paddingTop: 20,
          height: "100%",
          "& .shipmentInfoBody": {
            height: "100%",
            display: "flex",
            position: "relative",
            textAlign: "left",
            flexDirection: "column",
          },
          "& .sales-order-info": {
            background: "transparent",
            height: 200,
            paddingBlock: 20,
            paddingInline: 0,
          },
        },
      },
      "& .purchase-order-info-container": {
        border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
        background: AppConfig.themeColors.purchaseOrderBackgroundColor,
        padding: "14px 12px 14px 12px",
        borderRadius: 10,
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        transition: "padding-bottom 1s ease-in-out",
      },

      "& .shipment-info-container-hidden": {
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        "& .min-height-metadata-content-shipment": {
          opacity: 0,
          transition: "height 0.8s, opacity 1.2s ease-in-out",
        },
      },
      "& .shipment-info-container-visible": {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        "& .min-height-metadata-content-shipment": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          transition:
            "height 0.8s, opacity 1.2s ease-in-out, padding-bottom 0.8s ease-in-out",
        },
      },
      //

      "& .min-height-metadata-content-so": {
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        opacity: 0,
        height: 0,
      },
      "& .min-height-metadata-content-po": {
        transition: "height 0.8s, opacity 1.2s ease-in-out",
        opacity: 0,
        height: 0,
      },
    },
    "& .three-panel-rendering": {
      height: 0,
      opacity: 0,
      transition: "height 0.8s, opacity 1.2s ease-in-out",
      margin: 0,
    },
    "& .min-height-metadata-content-three-panel-section": {
      margin: "4px 0px 12px 0px",
      transformOrigin: "bottom",
      background: "white",
      "& .three-panel-section": {
        height: "100%",
        "& .project-dashboard-container": {
          height: "100%",
          minHeight: "inherit !Important",
        },
        "& .three-panel-section-empty": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "inherit",
          fontSize: 21,
          color: colors.dangerRed,
        },
      },
    },
    "& .min-height-metadata-content-three-panel-section-hidden": {
      height: 0,
      transition: "height 0.8s, opacity 1.2s ease-in-out",
    },
    "& .min-height-metadata-content-three-panel-section-visible": {
      opacity: 1,
      height: "calc(100vh - 84px)",
      transition: "height 0.8s, opacity 1.2s ease-in-out",
    },

    "& .three-panel-section-skeleton": {
      height: 0,
      background: "white",
    },
  },
}));

export const TaskPanelStyled = styled("div")(() => ({
  "&.tasksPanelContainer": {
    position: "relative",
    borderRadius: 8,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    boxShadow: "0px 2px 3px 1px #dddddd",
    transition: "all 1s ease",
    "& .content-card-header": {
      padding: "12px 19px 0px 9px",
    },

    "& .content-card-content": {
      flex: 1,
      fontSize: 13,
      fontWeight: "normal",
      position: "relative",
      color: colors.primaryDark,
      marginBottom: 8,
      borderRadius: 10,
      padding: "20px 16px 0px 16px",
      background: "#fff",
      "& .task-category": {
        "& .task-category-title-container": {
          borderRadius: 50,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          userSelect: "none",
          marginTop: 23,
        },
        "& .task-category-title": {
          fontWeight: 600,
        },
      },
    },
  },
  "& .taskScrollContainer": {
    paddingBottom: 0,
    // margin: 8px;
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    overflowX: "hidden",
    marginRight: 3,
    "& .expand-icon": {
      transition: "all 0.5s",
    },
    "& .task-category": {
      "& .task-category-title-container": {
        borderRadius: 50,
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        userSelect: "none",
        marginTop: 23,
      },
      "& .task-category-title": {
        fontWeight: 600,
      },
    },
    "&::-webkit-scrollbar": {
      backgroundColor: "transparent",
      width: 16,
    },
    "&::-webkit-scrollbar-track:vertical": {
      marginTop: 8,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      borderRadius: 7,
    },
    "&::-webkit-scrollbar-track": {
      border: " solid 1px transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        boxShadow: `inset 0 0 10px 10px ${colors.thumbScrollColor}`,
        borderBlock: "1px solid transparent",
        borderInline: "5px solid transparent",
      },
    },
  },
}));

export const OrderDashboardNavigationStyled = styled("div")(() => ({
  "&.orderDashboardNavigationStyles": {
    right: 4,
    width: 28,
    position: "absolute",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "30px 0px",
    transition: "all 0.4s",
    height: "calc(100vh - 62px)",
    "& .dashboard-nav-button": {
      width: 22,
      height: 65,
      marginBottom: 10,
      maxHeight: 90,
      minHeight: 30,
      border: "0.5px solid #c8c8c8",
      boxSizing: "border-box",
      borderRadius: 2,
      alignItems: "center",
      display: "flex",
      transition: "width 0.3s ease-in-out",
      minWidth: 0,
      padding: 0,
      "&:hover": {
        cursor: "pointer",
        borderWidth: 2,
      },
      "&:active": {
        transform: "scale(1)",
      },

      "& .dashboard-nav-icon": {
        color: AppConfig.themeColors.blueTextColor,
        fill: AppConfig.themeColors.blueTextColor,
        margin: "0px 1px",
        width: "-webkit-fill-available",
      },
      "& .dashboard-mui-icon": {
        color: AppConfig.themeColors.blueTextColor,
        width: "-webkit-fill-available",
        height: 18,
        transform: "rotate(90deg)",
      },
    },

    "& .dashboard-nav-button-sales-order": {
      background: colors.salesOrderBackgroundColor,
      border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
      "&:hover": {
        border: `2px solid ${AppConfig.themeColors.salesOrderBorderColor}`,
        background: colors.salesOrderHoverColor,
      },
      "&:active": {
        width: 26,
      },
    },
    "& .sales-order-info-to-scroll-active": {
      background: colors.salesOrderBorderColor,
      border: `2px solid ${colors.salesOrderBorderColor}`,
      transition: "width 0.3s ease-in-out",
      width: 26,
      "&:hover": {
        background: colors.salesOrderSelectedColor,
        border: `2px solid ${colors.salesOrderSelectedColor}`,
        width: 26,
      },
    },

    "& .dashboard-nav-button-purchase-order": {
      background: colors.purchaseOrderBackgroundColor,
      border: `2px solid ${colors.purchaseOrderBorderColor}`,
      "&:hover": {
        border: `2px solid ${AppConfig.themeColors.purchaseOrderBorderColor}`,
        background: colors.purchaseOrderHoverColor,
      },
      "&:active": {
        width: 26,
      },
    },
    "& .purchase-order-info-to-scroll-active": {
      background: colors.purchaseOrderBorderColor,
      border: `2px solid ${colors.purchaseOrderBorderColor}`,
      transition: "width 0.3s ease-in-out",
      width: 26,
      "&:hover": {
        background: colors.purchaseOrderSelectedColor,
        border: `2px solid ${colors.purchaseOrderSelectedColor}`,
        width: 26,
      },
    },
    "& .dashboard-nav-button-shipment": {
      background: colors.shipmentBadge,
      border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
      "&:hover": {
        border: `2px solid ${AppConfig.themeColors.shipmentBordeColor}`,
        background: colors.shipmentHoverColor,
      },
      "&:active": {
        width: 26,
      },
    },
    "& .shipment-info-to-scroll-active": {
      background: colors.shipmentBorderColor,
      border: `2px solid ${colors.shipmentBorderColor}`,
      transition: "width 0.3s ease-in-out",
      width: 26,
      "&:hover": {
        background: colors.shipmentSelectedColor,
        border: `2px solid ${colors.shipmentSelectedColor}`,
        width: 26,
      },
    },
    "& .dashboard-nav-button-shipment-disabled": {
      cursor: "not-allowed !Important",
    },
    "& .dashboard-nav-button-three-panel": {
      background: "#fff",
      border: `2px solid ${AppConfig.themeColors.blueTextColor}`,
      "&:hover": {
        border: `2px solid ${AppConfig.themeColors.blueTextColor}`,
        background: "#BBB",
      },
      "&:active": {
        background: colors.backgroundWhiteAction,
        width: 26,
      },
      "& .dashboard-mui-icon": {
        color: `${AppConfig.themeColors.blueTextColor} !Important`,
      },
    },
    "& .three-panel-section-to-scroll-active": {
      background: "#496888",
      border: `2px solid #496888`,
      transition: "width 0.3s ease-in-out",
      width: 26,
      "& .dashboard-mui-icon": {
        color: `#FFF !Important`,
      },
      "&:hover": {
        background: "#315070",
        border: `2px solid #315070`,
        width: 26,
      },
    },
  },
}));

export const OrderDashboardHorizontalNavigationStyled = styled("div")(() => ({
  "&.title-dashboard": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 0,
    position: "absolute",
    top: 0,
    left: 0,
    height: 67,
    "& .detailInformation": {
      margin: "2px 7px 4px 9px",
      width: 16,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      height: 60,
      "& .hiddenStar": {
        opacity: 0,
      },
      "& .favorite-star-cell": {
        cursor: "pointer",
      },
      "&:hover": {
        "& .hiddenStar": {
          opacity: 1,
          cursor: "pointer",
        },
      },
    },
    "&:hover": {
      overflowX: "auto",
    },

    "& .title-list-SO": {
      display: "flex",
      flex: "0 0 auto",
      alignItems: "center",
      padding: "5px 0px 4px 3px",
      borderRadius: 5,
      marginRight: 30,
      background: colors.salesOrderBackgroundColor,
      fontSize: "calc(max(10.5px, 0.85vw))",
      height: 33,
      outline: `2px solid ${colors.salesOrderBorderColor}`,
      zIndex: 2,
      "&:hover": {
        cursor: "pointer",
        background: colors.salesOrderHoverColor,
        outline: `2px solid ${colors.salesOrderBorderColor}`,
        "& .arrowIcon": {
          opacity: 1,
          color: colors.primaryDark,
        },
      },
      "&:active": {
        transform: "scale(1)",
        background: colors.backgroundGreenAction,
        outline: `2px solid ${colors.backgroundGreenAction}`,
      },
      "& .strong-text": {
        maxWidth: "clamp(110px, calc(21vw - 242px), 320px)",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: colors.primaryDark,
      },
      "& .orderNumber": {
        color: colors.primaryDark,
      },
      "& .arrowIcon": {
        opacity: 0,
      },
      "@media screen and (max-width: 1970px)": {
        "& .strong-text": {
          maxWidth: "clamp(90px, calc(11px + 8vw), 140px)",
        },
      },
      "@media screen and (max-width: 1755px)": {
        "& .strong-text": {
          maxWidth: "clamp(80px, calc(6px + 7vw), 100px)",
        },
      },
      "@media screen and (max-width: 1600px)": {
        "& .responsiveHiddenText": {
          display: "none",
        },
        "& .strong-text": {
          maxWidth: "clamp(80px, calc(6px + 7vw), 100px)",
        },
      },
    },
    "& .title-list-PO": {
      display: "flex",
      flex: "0 0 auto",
      alignItems: "center",
      padding: "5px 0px 4px 3px",
      borderRadius: 5,
      marginRight: 30,
      background: colors.purchaseOrderBackgroundColor,
      outline: `2px solid ${colors.purchaseOrderBorderColor}`,
      zIndex: 2,
      fontSize: "calc(max(10.5px, 0.85vw))",
      "&:hover": {
        cursor: "pointer",
        background: colors.purchaseOrderHoverColor,
        outline: `2px solid ${colors.purchaseOrderBorderColor}`,
        "& .arrowIcon": {
          opacity: 1,
        },
      },
      "&:active": {
        transform: "scale(1)",
        background: colors.backgroundBlueAction,
        outline: `2px solid ${colors.backgroundBlueAction}`,
      },
      "& .strong-text": {
        maxWidth: "clamp(110px, calc(21vw - 242px), 320px)",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: colors.primaryDark,
      },
      "& .arrowIcon": {
        opacity: 0,
        color: colors.primaryDark,
      },
      "@media screen and (max-width: 1970px)": {
        "& .strong-text": {
          maxWidth: "clamp(90px, calc(11px + 7vw), 140px)",
        },
      },
      "@media screen and (max-width: 1755px)": {
        "& .strong-text": {
          maxWidth: "clamp(80px, calc(6px + 7vw), 100px)",
        },
      },
      "@media screen and (max-width: 1600px)": {
        "& .responsiveHiddenText": {
          display: "none",
        },
        "& .strong-text": {
          maxWidth: "clamp(80px, calc(20vw - 160px), 300px)",
        },
      },
    },
    "& .title-list-Shipment": {
      display: "flex",
      flex: "0 0 auto",
      alignItems: "center",
      padding: "5px 0px 4px 3px",
      borderRadius: 5,
      marginRight: 30,
      background: colors.shipmentBadge,
      outline: `2px solid ${colors.shipmentBorderColor}`,
      zIndex: 2,
      fontSize: "calc(max(10.5px, 0.85vw))",
      "&:hover": {
        cursor: "pointer",
        background: colors.shipmentHoverColor,
        outline: `2px solid ${colors.shipmentBorderColor}`,
        "& .arrowIcon": {
          opacity: 1,
        },
        "& .shipmentBadgeClasses": {
          border: `2px solid ${colors.shipmentBorderColor}`,
        },
      },
      "&:active": {
        transform: "scale(1)",
        background: colors.backgroundPinkAction,
        outline: `2px solid ${colors.backgroundPinkAction}`,
        "& .shipmentBadgeClasses": {
          border: `2px solid ${colors.backgroundPinkAction}`,
        },
      },
      "& .shipmentBadgeClasses": {
        color: colors.primaryDark,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 12,
        margin: "0px 0px 2px 4px",
        cursor: "pointer",
        border: `2px solid ${colors.shipmentBorderColor}`,
        background: "#fff",
        width: 48,
        height: 20,
        fontWeight: 900,
        fontSize: 12,
        "& p": {
          margin: 0,
          fontSize: 12,
          height: 12,
          lineHeight: 1,
        },
      },
      "& .strong-text": {
        maxWidth: "clamp(110px, calc(21vw - 242px), 320px)",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        color: colors.primaryDark,
      },
      "& .arrowIcon": {
        opacity: 0,
        color: colors.primaryDark,
      },
      "@media screen and (max-width: 1970px)": {
        "& .strong-text": {
          maxWidth: "clamp(90px, calc(11px + 7vw), 140px)",
        },
      },
      "@media screen and (max-width: 1755px)": {
        "& .strong-text": {
          maxWidth: "clamp(80px, calc(6px + 7vw), 100px)",
        },
      },
      "@media screen and (max-width: 1600px)": {
        "& .responsiveHiddenText": {
          display: "none",
        },
        "& .strong-text": {
          maxWidth: "clamp(80px, calc(20vw - 160px), 300px)",
        },
      },
    },
  },
}));
