import React, { useState, useEffect, useCallback } from "react";
import { dbTables } from "../api/types/dbTables";
import { useDispatch } from "react-redux";
import userTypes from "../api/types/userTypes";
import { useUser } from "../hooks/user";
import { statusBlockAuth, regexEmailValidator } from "../helpers/constants";
import LoadingBackdrop from "../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { firestore, auth } from "../firebase";
import { BROWSER_NAME, getBrowserFromUserAgent } from "../helpers/auth";
import chromeIcon from "../assets/flag-icons/chrome-icon.svg";
import { AuthPageStyled } from "./Styles";
import { dispatchUser } from "../actions/AuthActions";
import { signInWithCustomToken } from "firebase/auth";
import {
  collection,
  deleteField,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../constants/globalVariables";
import { useCompanies, useJSONLocalStorage } from "../hooks";
import moment from "moment";
import { TradedashLogo, TradedashLogoIcon } from "../helpers/iconSvgPath";
import { colors } from "../assets/jss/variables";
import AttentionModal from "../components/Modal/AttentionModal";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { isTradeDashEmployee } from "../helpers/helpers";

const ERROR_TEXT =
  "There is a temporary issue with the login sub-system. Please try again in 15 minutes.";

function AuthPage() {
  const dispatch = useDispatch();
  const sendUserToReducer = useCallback((user) => dispatchUser(user)(dispatch));
  const [user, setUser] = useState();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [sendMailMagic, setSendMailMagic] = useState(false);
  const [blockUser, setBlockUser] = useState("");
  const [companies] = useCompanies();
  const currentUser = useUser();
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [acceptBlue, setAcceptBlue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState("");
  const navigate = useNavigate();
  const { set: setTaskScreenFilters } =
    useJSONLocalStorage("taskScreenFilters");
  const { set: setPOPhaseScreenFilters } = useJSONLocalStorage(
    "PuchaseOrderPhaseScreenFilters"
  );

  useEffect(() => {
    if (currentUser && currentUser.active === false) {
      setBlockUser(statusBlockAuth.BLOCK_USER);
    } else if (
      companies &&
      currentUser.role &&
      currentUser.role !== userTypes.TRADEDASH_EMPLOYEE &&
      !companies.enabled &&
      blockUser !== statusBlockAuth.BLOCK_USER
    ) {
      setBlockUser(statusBlockAuth.BLOCK_COMPANY);
    }
  }, [companies, currentUser]);

  function onChangeEmail(event) {
    const value = event.target.value || "";
    setMessage(false);
    setEmail(value.toLowerCase());
  }

  function onChangeCode(event) {
    const value = event.target.value || "";
    setVerificationCode(value.trim());
  }

  async function verifyCode() {
    setIsLoading(true);
    setErrorMessage(false);
    const snapUser = await getDocs(
      query(
        collection(firestore, dbTables.USERS),
        where("password", "==", verificationCode)
      )
    );
    let currentUser = snapUser.docs[0];
    if (!currentUser) {
      setIsLoading(false);
      setErrorMessage(true);
      return;
    } else {
      signInWithCustomToken(auth, currentUser.data().token)
        .then(() => {
          updateDoc(doc(firestore, `${dbTables.USERS}/${currentUser.id}`), {
            token: deleteField(),
            password: deleteField(),
          }).then(() => {
            sendUserToReducer(currentUser.data());
            resetFilters({ userId: currentUser.id });
          });
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setMessage("Something goes wrong, please try later");
        });
    }
  }

  function resetFilters({ userId = "" }) {
    setTaskScreenFilters({
      assignedTo: [userId],
      late: true,
    });

    setPOPhaseScreenFilters({
      dateRange: {
        start: moment().subtract(1, "month").startOf("day").valueOf(),
        end: moment().add(60, "days").valueOf(),
      },
      date: "Created Date",
      sortedColumn: "shipDate",
      orderBy: "DESC",
    });
    navigate("/app/tasks");
  }

  function validateEmail(email) {
    return regexEmailValidator.test(String(email).toLowerCase());
  }

  async function handleAuthMagicEmail() {
    setIsLoading(true);
    const userList = await getDocs(
      query(collection(firestore, dbTables.USERS), where("email", "==", email))
    );
    let currentUser = userList.docs[0];
    if (!currentUser) {
      setIsLoading(false);
      setMessage("The email is not valid, please try with a valid email");
      return;
    } else {
      currentUser = currentUser.data();
      if (!currentUser.active) {
        setIsLoading(false);
        return setBlockUser(statusBlockAuth.BLOCK_USER);
      }
      setUser({
        id: currentUser.id,
        email: currentUser.email,
        name: `${currentUser.firstName} ${currentUser.lastName}`,
      });
      requestLoginMail(currentUser);
    }
  }

  const requestLoginMail = async (currentUser) => {
    const functions = getFunctions();
    try {
      const sendMail = await httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: `user`,
          env: globalEnvironment,
          params: `/magicMail`,
        })
      );
      await sendMail({
        id: currentUser.id,
        email: currentUser.email,
        companyId: currentUser.companyId,
        tradedashEmployee: isTradeDashEmployee(currentUser),
      })
        .then((result) => {
          const { data } = result;
          const { status } = data;
          if (status === 200) {
            setSendMailMagic(true);
            setIsLoading(false);
          } else {
            const error = data.error || ERROR_TEXT;
            console.log(error);
            setMessage(error);
            setSendMailMagic(false);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("ERROR:: ", error);
          setMessage(ERROR_TEXT);
          setSendMailMagic(false);
          setIsLoading(false);
        });
    } catch (error) {
      console.log("REQUEST EMAIL ERROR", error);
      setMessage(ERROR_TEXT);
      setSendMailMagic(false);
      setIsLoading(false);
    }
  };

  const getTittle = () => {
    if (errorMessage) {
      return "Your code is incorrect";
    }
    return sendMailMagic
      ? "Check your email for a code"
      : "Sign in to TradeDash";
  };
  return (
    <div className="auth-container">
      {openModalAttention && (
        <AttentionModal
          isOpen={openModalAttention}
          description={descriptionModalAttention}
          title="Attention"
          onClick={() => {
            setOpenModalAttention(false);
            setDescriptionModalAttention("");
            setAcceptBlue(false);
          }}
          onClose={() => setAcceptBlue(true)}
          acceptBlue={acceptBlue}
        />
      )}
      {isLoading && <LoadingBackdrop withLogo={true} />}

      {blockUser ? (
        <h4 style={{ color: "red" }}>
          {`This ${
            blockUser === statusBlockAuth.BLOCK_COMPANY
              ? "company is disabled"
              : "account is disabled"
          }. Contact your administrator for more information.`}
        </h4>
      ) : (
        <AuthPageStyled className="authContainer">
          <TradedashLogoIcon />
          <TradedashLogo />
          <div className={"signInText"}>{getTittle()}</div>
          {sendMailMagic && !errorMessage && (
            <div className={"informationText"}>
              {`An email with a verification code has been sent to 
              ${user ? user.email : ""}`}
              <br />
              Enter the code to continue.
            </div>
          )}
          {errorMessage && (
            <div className={"informationText"}>Please try again</div>
          )}
          {!sendMailMagic && (
            <TextField
              id="send-email-magic-link"
              autoFocus
              onChange={onChangeEmail}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  handleAuthMagicEmail();
                  ev.preventDefault();
                }
              }}
              value={email}
              variant="outlined"
              className="magicLinkField"
              placeholder="Your work email"
            />
          )}
          {!sendMailMagic && (
            <button
              className={"buttonConfirmation"}
              id="sign-in-button"
              onClick={handleAuthMagicEmail}
              disabled={!validateEmail(email)}
            >
              Send confirmation code
            </button>
          )}
          {sendMailMagic && (
            <TextField
              id="input-to-verify-code"
              autoFocus
              onChange={onChangeCode}
              value={verificationCode}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  verifyCode();
                  ev.preventDefault();
                }
              }}
              variant="outlined"
              className="magicLinkField"
              placeholder="Enter verification code"
            />
          )}
          {sendMailMagic && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className={"buttonConfirmation"}
                id="verify-button"
                onClick={verifyCode}
                type="submit"
                disabled={verificationCode.length < 6}
              >
                Continue to TradeDash
              </button>
            </div>
          )}

          {message && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 50,
                color: "red",
              }}
            >
              <span>{message}</span>
            </div>
          )}
          {sendMailMagic && (
            <a
              style={{
                color: colors.primaryDark,
                fontSize: 24,
                textDecoration: "underline",
                marginTop: 5,
              }}
              href="mailto:support@tradedash.com?subject=I did not receive my verification code"
            >
              Didn't get a verification code?
            </a>
          )}
        </AuthPageStyled>
      )}
      {getBrowserFromUserAgent() !== BROWSER_NAME.CHROME && (
        <div
          style={{
            margin: "32px 0px 16px 0px",
            fontSize: 24,
            fontWeight: 400,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span>
            TradeDash is optimized to work with the Google Chrome browser.
            Please open this link in{" "}
            <img
              style={{
                position: "relative",
                top: -3,
                width: 20,
              }}
              src={chromeIcon}
              alt="chrome"
            />
            Chrome
          </span>
          <span
            style={{
              fontWeight: 400,
              fontSize: 16,
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 25,
            }}
          >
            Download here:
            <a
              href="https://www.google.com/chrome/"
              target="_blank"
              rel="noreferrer"
              style={{
                marginLeft: 5,
                color: colors.primaryDark,
                textDecoration: "underline",
              }}
            >
              google.com/chrome/
            </a>
          </span>
        </div>
      )}
    </div>
  );
}

export default AuthPage;
