import React, { useState, useEffect } from "react";
import LateIcon from "../../assets/flag-icons/red_diamond.svg";
import NearDueIcon from "../../assets/flag-icons/orange_diamond.svg";

import {
  ExpandMore as ExpandMoreIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";

function TaskCategory({
  style = {},
  children,
  title,
  status,
  isOpen,
  onCategoryStatus,
}) {
  const [openCategory, setOpenCategory] = useState(isOpen);

  useEffect(() => {
    setOpenCategory(isOpen);
  }, [isOpen]);

  return (
    <div className="task-category">
      <div
        className="task-category-title-container"
        onClick={() => {
          setOpenCategory(!openCategory);
          onCategoryStatus(!openCategory);
        }}
        style={Object.assign({ marginLeft: 5 }, style)}
      >
        {openCategory ? (
          <ExpandMoreIcon
            className="expand-icon"
            style={{
              width: 22,
              height: 22,
              marginRight: 5,
            }}
          />
        ) : (
          <ChevronRightIcon
            className="expand-icon"
            style={{
              width: 22,
              height: 22,
              marginRight: 5,
            }}
          />
        )}

        {status && !openCategory && (
          <img
            style={{
              opacity: 1,
              top: 10,
              right: 8,
              display: "inline",
              marginRight: 4,
            }}
            src={status === "late" ? LateIcon : NearDueIcon}
            alt=""
          />
        )}
        <span className="task-category-title">{title}</span>
      </div>
      <ul>{openCategory && children}</ul>
    </div>
  );
}

export default TaskCategory;
