import { cx } from "@emotion/css";
import React from "react";

function ItemsEmptyPOCells() {
  return (
    <>
      <div
        className={cx("salesOrderCell", "cellBorderLeft", "cellBorderRight", {
          firstSO: true,
        })}
        style={{
          padding: 0,
          gridColumn: "span 3",
          textAlign: "center",
          justifyContent: "center",
          fontStyle: "italic",
        }}
      >
        Not in Sales Order
      </div>
      <div
        className={cx("shipmentCell", "cellBorderLeft", "cellBorderRight", {
          firstShipment: true,
        })}
        style={{
          padding: 0,
          gridColumn: "span 3",
        }}
      />
    </>
  );
}

export default ItemsEmptyPOCells;
