import React, { useCallback, useContext, useEffect, useState } from "react";
import FilterActivitiesv2 from "./FilterActivitiesv2";
import {
  useCompanyId,
  useJSONLocalStorage,
  useQueryParams,
} from "../../../hooks";
import { setActivities, setGlobalNoteDate } from "../../../actions/DataActions";
import { dbTables, reduxState } from "../../../api/types/dbTables";
import { debounce } from "../../../helpers/helpers";
import { useDispatch } from "react-redux";
import moment from "moment";
import {
  ACTIVITY_STREAM_ACTION,
  ActivityStreamContext,
} from "./ActivityStreamContext";

import {
  PO_STORAGE_KEY,
  getFirstNewestActivity,
  getSecondNewestActivity,
} from "../../../helpers/activitiesStream";
import {
  useCurrentActivityNote,
  useUser,
  useUserMentions,
} from "../../../hooks/user";
import { READ_FROM_TYPE, notesFilters } from "../../../helpers/constants";
import { getFilterActivities, onClearAllMention } from "./ActivityHelpers";
import { useIsAllowedFunction } from "../../../hooks/permissions";
import Mark from "mark.js";
import { useSelector } from "react-redux";
import { ACTIVITY_LOAD_FROM } from "../../../actions/types";
import { motion } from "framer-motion";
import LoadingBackdrop from "../../WholeScreenFocusBackdrop/LoadingBackdrop";

function ActivityEntriesv2({
  salesOrder = {},
  purchaseOrder = {},
  currentShipment = {},
  allActivities = [],
  loadState,
  setLoadState,
}) {
  const companyId = useCompanyId();
  const dispatch = useDispatch();
  const { get, set } = useJSONLocalStorage(purchaseOrder.id);
  const generalActivityNote = useCurrentActivityNote();
  const [coldStart, setColdStart] = useState(false);
  const userMentions = useUserMentions();
  const isAllowed = useIsAllowedFunction();
  const storage = get() || {};
  const queryParams = useQueryParams();
  const setNoteDate = useCallback(
    ({ type = "", creationDate = "", noteId = "", wait = false }) =>
      setGlobalNoteDate({
        type,
        creationDate,
        noteId,
        wait,
      })(dispatch)
  );
  const currentUser = useUser();
  const activityLoadFrom = useSelector((state) => state.data.activityLoadFrom);
  const { activityStreamState, dispatchActivityStream } = useContext(
    ActivityStreamContext
  );
  const {
    loadingPOActivities,
    loadingShipmentActivities,
    loadingSalesOrderActivities,
    salesOrderActivitySetup,
    shipmentActivitySetup,
    purchaseOrderActivitySetup,
    noteThread,
    activeTab,
    initialized,
    querySearch,
    filterByUsers,
    loadingUpdateMentions,
  } = activityStreamState;

  const onDebounceStart = useCallback(
    debounce(() => {
      setColdStart(true);
    }, 1500),
    []
  );

  const onDebouncePO = useCallback(
    debounce(() => {
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.COMMON,
        payload: {
          loadingPOActivities: true,
        },
      });
    }, 2500),
    []
  );

  const onDebounceSearch = useCallback(
    debounce(() => {
      performMark(querySearch);
    }, 2500),
    [querySearch]
  );

  const onDebounceShipment = useCallback(
    debounce(() => {
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.COMMON,
        payload: {
          loadingShipmentActivities: true,
        },
      });
    }, 2500),
    []
  );

  const getActivities = useCallback(
    ({ companyId, startDate, endDate, dbTable, dbTableId, reduxState }) => {
      setActivities({
        companyId,
        startDate,
        endDate,
        dbTable,
        dbTableId,
        reduxState,
        loadState,
        setLoadState,
      })(dispatch);
    }
  );
  useEffect(() => {
    if (coldStart) {
      const isSameProject = isInSameProject({
        activityLoadFrom,
        salesOrder,
        purchaseOrder,
        shipment: currentShipment,
      });
      const optionalDate =
        !generalActivityNote.wait && generalActivityNote.creationDate;
      if (initialized || optionalDate) {
        if (!isSameProject) {
          setupActivityDate();
        } else {
          const currentDate = generalActivityNote.creationDate
            ? Math.min(
                activityLoadFrom.creationDate,
                generalActivityNote.creationDate
              )
            : activityLoadFrom.creationDate;
          const reloadDate = optionalDate;
          const actualDate =
            salesOrderActivitySetup.startDate || moment().valueOf();
          if (reloadDate && currentDate >= actualDate) {
            console.log("stop");
            return;
          }
          dispatchActivityStream({
            type: ACTIVITY_STREAM_ACTION.COMMON,
            payload: {
              salesOrderActivitySetup: {
                id: salesOrder.id,
                startDate: currentDate,
                endDate: moment().endOf("day").valueOf(),
              },
              purchaseOrderActivitySetup: {
                id: purchaseOrder.id,
                startDate: currentDate,
                endDate: moment().endOf("day").valueOf(),
              },
              shipmentActivitySetup: {
                id: currentShipment.id,
                startDate: currentDate,
                endDate: moment().endOf("day").valueOf(),
              },
              loadingPOActivities: reloadDate,
              loadingShipmentActivities: reloadDate,
              loadingSalesOrderActivities: reloadDate,
            },
          });
        }
      }
      setColdStart(false);
    }
  }, [coldStart]);

  useEffect(() => {
    if (loadingUpdateMentions) {
      onClearAllMention({
        allActivities,
        currentShipment,
        currentUser,
        dispatch,
        purchaseOrder,
        salesOrder,
        userMentions,
        typeReadFrom: READ_FROM_TYPE.ACTIVITY_FILTER_TAB,
      });
    }
  }, [loadingUpdateMentions]);

  useEffect(() => {
    if (loadingSalesOrderActivities) {
      const { startDate, endDate } = salesOrderActivitySetup;
      getActivities({
        companyId,
        startDate: startDate,
        endDate: endDate,
        dbTable: dbTables.SALES_ORDERS,
        dbTableId: salesOrder.id,
        reduxState: reduxState.SALES_ORDER_ACTIVITIES,
      });
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.COMMON,
        payload: {
          loadingSalesOrderActivities: false,
          salesOrderActivitySetup: {
            id: salesOrder.id,
            startDate: startDate,
            endDate: endDate,
          },
        },
      });
    }
  }, [loadingSalesOrderActivities]);

  useEffect(() => {
    if (purchaseOrderActivitySetup.id !== purchaseOrder.id) {
      onDebouncePO();
    }
  }, [purchaseOrder.id]);

  useEffect(() => {
    if (loadingPOActivities) {
      let { id, startDate, endDate } = purchaseOrderActivitySetup;
      let noteThreadToUpdate = noteThread;
      if (id !== purchaseOrder.id) {
        noteThreadToUpdate = false;
      }
      if (id !== purchaseOrder.id) {
        endDate = moment().endOf("day").valueOf();
      }

      getActivities({
        companyId,
        startDate,
        endDate,
        dbTable: dbTables.PURCHASE_ORDERS,
        dbTableId: purchaseOrder.id,
        reduxState: reduxState.PURCHASE_ORDER_ACTIVITIES,
      });
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.COMMON,
        payload: {
          loadingPOActivities: false,
          purchaseOrderActivitySetup: {
            id: purchaseOrder.id,
            startDate: startDate,
            endDate: endDate,
          },
          noteThread: noteThreadToUpdate,
        },
      });
    }
  }, [loadingPOActivities]);

  useEffect(() => {
    if (shipmentActivitySetup.id !== currentShipment.id) {
      onDebounceShipment();
    }
  }, [currentShipment.id]);

  useEffect(() => {
    if (loadingShipmentActivities) {
      let { id, startDate, endDate } = shipmentActivitySetup;
      let noteThreadToUpdate = noteThread;
      if (id !== currentShipment.id) {
        noteThreadToUpdate = false;
      }
      if (id !== currentShipment.id) {
        endDate = moment().endOf("day").valueOf();
      }
      getActivities({
        companyId,
        startDate,
        endDate,
        dbTable: dbTables.SHIPMENTS,
        dbTableId: currentShipment.id,
        reduxState: reduxState.SHIPMENT_ACTIVITIES,
      });
      dispatchActivityStream({
        type: ACTIVITY_STREAM_ACTION.COMMON,
        payload: {
          loadingShipmentActivities: false,
          shipmentActivitySetup: {
            id: currentShipment.id,
            startDate: startDate,
            endDate: endDate,
          },
          noteThread: noteThreadToUpdate,
        },
      });
    }
  }, [loadingShipmentActivities]);

  useEffect(() => {
    if (querySearch) {
      performMark(querySearch);
    }
    return () => {
      setNoteDate({});
    };
  }, []);

  useEffect(() => {
    if (querySearch) {
      performMark(querySearch);
    }
  }, [querySearch]);

  const markInstance = new Mark(
    document.getElementById("activityStreamByDateContainer")
  );
  function performMark(keyword = "") {
    const options = {
      separateWordSearch: false,
      diacritics: false,
      debug: false,
      acrossElements: true,
      accuracy: "partially",
      exclude: [".scope", ".readMore"],
    };
    markInstance.unmark({
      done: () => {
        setTimeout(() => {
          markInstance.mark(keyword, options);
        }, 500);
      },
    });
  }

  function isInSameProject({
    activityLoadFrom = {},
    salesOrder = {},
    purchaseOrder = {},
    shipment = {},
  }) {
    const { salesOrderId, purchaseOrderId, shipmentId } = activityLoadFrom;
    return (
      salesOrderId === salesOrder.id &&
      purchaseOrderId === purchaseOrder.id &&
      shipmentId === shipment.id
    );
  }

  useEffect(() => {
    onDebounceStart(initialized, generalActivityNote.creationDate);
    return () => {
      onDebounceStart.cancel();
    };
  }, [initialized, generalActivityNote.creationDate]);

  useEffect(() => {
    let currentActiveTab =
      storage[PO_STORAGE_KEY.ACTIVITY_TAB] || notesFilters.NOTES;
    const { noteId } = queryParams;
    if (noteId) {
      currentActiveTab = notesFilters.NOTES;
    }
    if (currentActiveTab !== activeTab) {
      set({
        ...storage,
        [PO_STORAGE_KEY.ACTIVITY_TAB]: currentActiveTab,
      });
    }
    dispatchActivityStream({
      type: ACTIVITY_STREAM_ACTION.COMMON,
      payload: {
        activeTab: currentActiveTab,
        initialized: true,
      },
    });
  }, [queryParams.noteId, purchaseOrder.id]);

  async function setupActivityDate() {
    const salesOrderFirstActivityDate =
      salesOrderActivitySetup.startDate || moment().valueOf();
    const startDate = generalActivityNote.creationDate;
    let secondNewestDate = startDate;
    if (!!startDate) {
      if (startDate >= salesOrderFirstActivityDate) {
        setNoteDate({ wait: true });
        return;
      }
      if (startDate < salesOrderFirstActivityDate) {
        setNoteDate({ wait: true });
        secondNewestDate = startDate;
      }
    } else {
      const { salesOrderId, purchaseOrderId, shipmentId } = queryParams;
      const companyRef = `${dbTables.COMPANIES}/${companyId}`;
      const salesOrderFirstActivity = await getFirstNewestActivity({
        activeTab,
        path: `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrderId}/${dbTables.ACTIVITIES}`,
        dispatch: dispatch,
      });
      const salesOrderSecondActivity = await getSecondNewestActivity({
        activeTab,
        path: `${companyRef}/${dbTables.SALES_ORDERS}/${salesOrderId}/${dbTables.ACTIVITIES}`,
        lastDateFound: salesOrderFirstActivity.date,
        iconIndex: salesOrderFirstActivity.iconIndex,
        dispatch: dispatch,
      });

      const purchaseOrderFirstActivity = await getFirstNewestActivity({
        activeTab,
        path: `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrderId}/${dbTables.ACTIVITIES}`,
        dispatch: dispatch,
      });

      const purchaseOrderSecondActivity = await getSecondNewestActivity({
        activeTab,
        path: `${companyRef}/${dbTables.PURCHASE_ORDERS}/${purchaseOrderId}/${dbTables.ACTIVITIES}`,
        lastDateFound: purchaseOrderFirstActivity.date,
        iconIndex: purchaseOrderFirstActivity.iconIndex,
        dispatch: dispatch,
      });
      const shipmentFirstActivity = await getFirstNewestActivity({
        activeTab,
        path: `${companyRef}/${dbTables.SHIPMENTS}/${shipmentId}/${dbTables.ACTIVITIES}`,
        dispatch: dispatch,
      });
      const shipmentSecondActivity = await getSecondNewestActivity({
        activeTab,
        path: `${companyRef}/${dbTables.SHIPMENTS}/${shipmentId}/${dbTables.ACTIVITIES}`,
        lastDateFound: shipmentFirstActivity.date,
        iconIndex: shipmentFirstActivity.iconIndex,
        dispatch: dispatch,
      });

      const arrayOfDates = [
        ...new Set([
          salesOrderFirstActivity.date,
          salesOrderSecondActivity,
          purchaseOrderFirstActivity.date,
          purchaseOrderSecondActivity,
          shipmentFirstActivity.date,
          shipmentSecondActivity,
        ]),
      ]
        .filter((date) => date)
        .sort((a, b) => b - a);
      const maxOldDate =
        salesOrder.quoteCreationDate || salesOrder.creationDate;
      secondNewestDate = arrayOfDates[1] || maxOldDate;
      secondNewestDate =
        maxOldDate > secondNewestDate ? maxOldDate : secondNewestDate;
    }
    dispatchActivityStream({
      type: ACTIVITY_STREAM_ACTION.COMMON,
      payload: {
        salesOrderActivitySetup: {
          id: salesOrder.id,
          startDate: secondNewestDate,
          endDate: moment().endOf("day").valueOf(),
        },
        purchaseOrderActivitySetup: {
          id: purchaseOrder.id,
          startDate: secondNewestDate,
          endDate: moment().endOf("day").valueOf(),
        },
        shipmentActivitySetup: {
          id: currentShipment.id,
          startDate: secondNewestDate,
          endDate: moment().endOf("day").valueOf(),
        },
        loadingPOActivities: true,
        loadingShipmentActivities: true,
        loadingSalesOrderActivities: true,
      },
    });
    const { salesOrderId, purchaseOrderId, shipmentId } = queryParams;
    dispatch({
      type: ACTIVITY_LOAD_FROM,
      payload: {
        salesOrderId,
        purchaseOrderId,
        shipmentId,
        creationDate: secondNewestDate,
      },
    });
  }

  return (
    <motion.div
      key={!!noteThread}
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 50, opacity: 0 }}
      transition={{ duration: 0.5, ease: "backInOut" }}
      style={{
        height: noteThread ? "calc(100% - 88px)" : "calc(100% - 160px)",
      }}
    >
      {loadState.isLoading && <LoadingBackdrop withLogo />}
      <FilterActivitiesv2
        activities={getFilterActivities({
          activeTab,
          allActivities,
          currentUser,
          noteThread,
          querySearch,
          filterByUsers,
          date: salesOrderActivitySetup.startDate,
          isAllowed,
        })}
        currentUser={currentUser}
        currentPurchaseOrder={purchaseOrder}
        currentShipment={currentShipment}
        salesOrder={salesOrder}
        purchaseOrder={purchaseOrder}
        performMark={onDebounceSearch}
        activitiesLoading={loadState.isLoading}
      />
    </motion.div>
  );
}

export default ActivityEntriesv2;
