import React, { useState, useRef, useEffect } from "react";
import DatePicker from "../DatePicker/DatePicker";
import CalendarIcon from "../../assets/flag-icons/calendar.svg";
import PhasePicker from "../DatePicker/PhasePicker";
import IntlMessages from "../../util/IntlMessages";
import { cx } from "@emotion/css";
import UserPicker from "../DatePicker/UserPicker";
import { getTheDate, sortObjectsBy } from "../../helpers/helpers";
import AdHocTask from "../../api/model/AdHocTask";
import AttentionModal from "../Modal/AttentionModal";
import moment from "moment";
import { AdhocTaskStyled } from "./styles";
import FilterTab from "./FilterTab";
import { getUserAvatar } from "../../helpers/users";
import { typeOfTask } from "../../api/types/dbTables";
import { PO_STATUS } from "../../helpers/constants";
import { useCurrentShipment } from "../../hooks/shipments";
import { getCorrectTimezone } from "../../helpers/ganttChart";
import {
  getBackgroundColorByType,
  getBorderColorByType,
} from "../../helpers/orderDashboard";
import { DefaultUserIcon } from "../../helpers/iconSvgPath";
import { Button } from "@mui/material";
import { checkUserPermissionByEntity } from "../../helpers/tasks";

function AdHocTaskComponent({
  companyUsers,
  createAdHocTask,
  salesOrder,
  purchaseOrder,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  isEditingTask,
  panelWidth,
}) {
  const [adHocTask, setAdHocTask] = useState(new AdHocTask({}));
  const [openPicker, setOpenPicker] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [openPhase, setOpenPhase] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const phaseRef = useRef(null);
  const [lastElementId, setLastElementId] = useState("");
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const [descriptionAttentinoModal, setDescriptionAttentinoModal] = useState(
    <React.Fragment></React.Fragment>
  );
  const [tabIndicator, setTabIndicator] = useState(typeOfTask.SALES_ORDER);
  const currentShipment = useCurrentShipment({ purchaseOrder });

  useEffect(() => {
    setEnableSubmit(verifyAdHocTask(adHocTask));
  }, [adHocTask]);

  useEffect(() => {
    setAdHocTask(new AdHocTask({}));
    const { shipmentIds = [] } = purchaseOrder;
    if (shipmentIds.length === 0 && tabIndicator === typeOfTask.SHIPMENT) {
      setTabIndicator(typeOfTask.SALES_ORDER);
    }
  }, [purchaseOrder.id]);

  function changeField(value, field, elementId) {
    setLastElementId(elementId);
    setAdHocTask({ ...adHocTask, [field]: value });
    setOpenPicker(false);
    setOpenUsers(false);
    setOpenPhase(false);
  }

  function verifyAdHocTask(adHocTask) {
    if (
      adHocTask.description !== "" &&
      adHocTask.stage !== "" &&
      adHocTask.assignedTo !== "" &&
      adHocTask.finishDate !== ""
    ) {
      if (tabIndicator === typeOfTask.SHIPMENT) {
        if (currentShipment && Object.keys(currentShipment).length > 0) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  const currentOrder = {
    [typeOfTask.SALES_ORDER]: salesOrder,
    [typeOfTask.PURCHASE_ORDER]: purchaseOrder,
    [typeOfTask.SHIPMENT]: currentShipment,
  };

  function handleOpenAttentionModal(currentOrder) {
    const currentType = {
      [typeOfTask.SALES_ORDER]: "sales order",
      [typeOfTask.PURCHASE_ORDER]: "purchase order",
      [typeOfTask.SHIPMENT]: "shipment",
    };
    const type = currentType[tabIndicator];
    setDescriptionAttentinoModal(
      <React.Fragment>
        {`This ${type} is
        ${currentOrder.status === PO_STATUS.VOIDED ? "void" : "reassigned"} and
        ad-hoc tasks`}
        <br />
        {`can no longer be created for it`}
      </React.Fragment>
    );
    setOpenAttentionModal(true);
  }

  function isVoidedOrReassignedOrder(currentOrder = {}) {
    if (currentOrder.status === PO_STATUS.VOIDED) {
      return true;
    }
    return false;
  }
  const isSmall = panelWidth === "25%";
  function changeFilterTab(filter) {
    if (isVoidedOrReassignedOrder(currentOrder[filter])) {
      setAdHocTask(new AdHocTask({}));
    } else {
      let currentAssignTo = adHocTask.assignedTo;
      if (adHocTask.assignedTo) {
        const currentUser = companyUsers.find(
          (user) => user.id === adHocTask.assignedTo
        );
        const userHasPermission = checkUserPermissionByEntity({
          user: currentUser,
          customerId: salesOrder.customerId,
          factoryId: purchaseOrder.factoryId,
          tabIndicator: filter,
        });
        currentAssignTo = userHasPermission ? currentAssignTo : "";
      }
      setAdHocTask({
        ...adHocTask,
        assignedTo: currentAssignTo,
        shipmentId: "",
      });
    }
    setTabIndicator(filter);
  }
  const permissionToVerify =
    tabIndicator === typeOfTask.PURCHASE_ORDER
      ? purchaseOrder.factoryId
      : salesOrder.customerId;

  function handleCreateNewTask() {
    const isCreated = createAdHocTask({
      adHocTask: {
        ...adHocTask,
        shipmentId:
          tabIndicator === typeOfTask.SHIPMENT ? currentShipment.id : "",
      },
      type: tabIndicator,
    });
    if (isCreated) {
      setAdHocTask(new AdHocTask({}));
      setTimeout(() => {
        const element = document.getElementById("option-to-edit-container");
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 200);
    } else {
      const finishDate = getCorrectTimezone({
        date: moment().valueOf(),
      });
      setAdHocTask({
        ...adHocTask,
        finishDate: finishDate.valueOf(),
      });
    }
  }

  const isOutdated = purchaseOrder.isOutdated;
  const taskType =
    tabIndicator === typeOfTask.SALES_ORDER
      ? "Sales Order"
      : tabIndicator === typeOfTask.PURCHASE_ORDER
      ? "Purchase Order"
      : "Shipment";

  return (
    <AdhocTaskStyled
      className="adhocTaskContainer"
      style={{
        background: getBackgroundColorByType({ type: tabIndicator }),
        borderColor: getBorderColorByType({
          type: tabIndicator,
        }),
      }}
    >
      {openAttentionModal && (
        <AttentionModal
          isOpen={openAttentionModal}
          title="Attention"
          description={descriptionAttentinoModal}
          onClick={() => {
            setOpenAttentionModal(false);
            setDescriptionAttentinoModal(<React.Fragment></React.Fragment>);
          }}
        />
      )}
      <div className={"adhocTaskTypeContainer"}>
        <div className={"tabAdhocTaskType"}>
          <FilterTab
            onClick={changeFilterTab}
            activeTab={tabIndicator}
            value={typeOfTask.SALES_ORDER}
            label="order.dashboard.adhoc.task.sales.order"
            styles={{ fontSize: 11 }}
          />
          <FilterTab
            onClick={changeFilterTab}
            activeTab={tabIndicator}
            value={typeOfTask.PURCHASE_ORDER}
            label="order.dashboard.adhoc.task.purchase.order"
            noTransform={true}
            styles={{ fontSize: 11 }}
          />
          {currentShipment && Object.keys(currentShipment).length > 0 && (
            <FilterTab
              onClick={changeFilterTab}
              activeTab={tabIndicator}
              value={typeOfTask.SHIPMENT}
              label="order.dashboard.adhoc.task.shipment"
              styles={{ fontSize: 11 }}
            />
          )}
          <div className="indicator" />
        </div>
      </div>
      <div className={"adhocTaskInfoWrapper"}>
        <div
          className={"adhocTaskInfoContainer"}
          style={{ opacity: isOutdated ? 0.5 : 1 }}
        >
          <input
            disabled={isOutdated}
            className="adhoc-task-description"
            id="description"
            value={adHocTask.description}
            onChange={(ev) => {
              if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                return;
              }
              setAdHocTask({ ...adHocTask, description: ev.target.value });
              setLastElementId("date-container");
            }}
            type="text"
            placeholder={`Add a new ${
              !isSmall ? taskType : ""
            } task to this project`}
            onKeyDown={(ev) => {
              if (ev.key === "Tab") {
                const el = document.getElementById(lastElementId);
                el.click();
              } else if (
                ev.key === "Enter" &&
                !verifyAdHocTask(adHocTask) &&
                !isEditingTask
              ) {
                handleCreateNewTask();
              }
            }}
            tabIndex={0}
            onClick={() => {
              if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                handleOpenAttentionModal(currentOrder[tabIndicator]);
              } else {
                setLastElementId("date-container");
              }
            }}
          />

          <div
            id="date-container"
            style={{ cursor: isOutdated ? "initial" : "" }}
            onClick={() => {
              if (isOutdated) {
                return;
              }
              if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                handleOpenAttentionModal(currentOrder[tabIndicator]);
              } else {
                setOpenPicker(true);
                setLastElementId("users-container");
              }
            }}
            disabled={isOutdated}
            ref={dateRef}
            className={cx("adhocTaskBox", "date-box", { active: openPicker })}
            tabIndex={1}
          >
            <span>
              {adHocTask.finishDate
                ? getTheDate(adHocTask.finishDate)
                : moment(new Date()).format("M/D/YY")}
            </span>
            {!adHocTask.finishDate && <img alt="" src={CalendarIcon} />}
          </div>
          {openPicker && (
            <DatePicker
              el={dateRef.current}
              onChange={(value) =>
                changeField(value, "finishDate", "users-container")
              }
              open={openPicker}
              onClose={() => setOpenPicker(false)}
              value={adHocTask.finishDate}
              cancelIcon={true}
              minDate={moment().toDate()}
              onKeyDown={(ev) => {
                if (ev.key === "Tab") {
                  setOpenPicker(false);
                  const el = document.getElementById(lastElementId);
                  el.click();
                }
              }}
            />
          )}

          <div
            id="users-container"
            ref={userRef}
            onClick={() => {
              if (isOutdated) {
                return;
              }
              if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                handleOpenAttentionModal(currentOrder[tabIndicator]);
              } else {
                setOpenUsers(true);
                setLastElementId("phase-container");
              }
            }}
            className={cx("adhocTaskBox", "adhoc-task-user", {
              active: openUsers,
            })}
            tabIndex={2}
            style={{ cursor: isOutdated ? "initial" : "" }}
          >
            {!adHocTask.assignedTo && (
              <DefaultUserIcon className={"default-user-icon"} />
            )}
            {adHocTask.assignedTo &&
              getUserAvatar({
                user: companyUsers.find(
                  (companyUser) => companyUser.id === adHocTask.assignedTo
                ),
                styles: {
                  width: isSmall ? 17 : 24,
                  height: isSmall ? 17 : 24,
                  fontSize: 13,
                  padding: 0,
                  minWidth: 0,
                  outline: "2px solid #000",
                },
              })}
          </div>

          {openUsers && (
            <UserPicker
              el={userRef.current}
              onChange={(userId, ev, blockPermission) => {
                if (blockPermission) {
                  ev.preventDefault();
                  ev.stopPropagation();
                  return;
                }
                changeField(userId, "assignedTo", "phase-container");
              }}
              open={openUsers}
              onClose={() => setOpenUsers(false)}
              value={adHocTask.assignedTo}
              users={companyUsers
                .filter((user) => user.active)
                .sort(sortObjectsBy("displayName", false))}
              onKeyDown={(ev) => {
                if (ev.key === "Tab") {
                  setOpenUsers(false);
                  const el = document.getElementById(lastElementId);
                  el.click();
                }
              }}
              isTherePermission={true}
              permissionToVerify={permissionToVerify}
              isCustomer={tabIndicator !== typeOfTask.PURCHASE_ORDER}
            />
          )}
          <div
            id="phase-container"
            onClick={() => {
              if (isOutdated) {
                return;
              }

              if (isVoidedOrReassignedOrder(currentOrder[tabIndicator])) {
                handleOpenAttentionModal(currentOrder[tabIndicator]);
              } else {
                setOpenPhase(true);
                setLastElementId("description");
              }
            }}
            style={{
              cursor: isOutdated ? "initial" : "",
              maxWidth: panelWidth,
            }}
            ref={phaseRef}
            className={cx("adhocTaskBox", "adhoc-task-phase")}
            tabIndex={3}
          >
            {adHocTask.stage ? (
              <span>
                <IntlMessages id={adHocTask.stage} />
              </span>
            ) : (
              <span
                style={{
                  color: "#92A1B0",
                  margin: 0,
                  fontSize: 13,
                  fontWeight: 300,
                }}
              >
                Phase
              </span>
            )}
          </div>

          <PhasePicker
            el={phaseRef.current}
            onChange={(value) => changeField(value, "stage", "description")}
            open={openPhase}
            onClose={() => setOpenPhase(false)}
            value={adHocTask.stage}
            onKeyDown={(ev) => {
              if (ev.key === "Tab") {
                setOpenPhase(false);
              }
            }}
          />
        </div>
        <Button
          className={
            enableSubmit || isEditingTask ? "button-enable" : "button-disable"
          }
          variant="contained"
          disabled={enableSubmit || isEditingTask}
          onClick={() => {
            if (isReadOnly) {
              handleReadOnlyModal();
            } else {
              handleCreateNewTask();
            }
          }}
        >
          Submit
        </Button>
      </div>
    </AdhocTaskStyled>
  );
}

export default AdHocTaskComponent;
