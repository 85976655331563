import React, { useCallback, useState, useEffect } from "react";
import {
  PurchaseOrderIcon,
  SalesOrderIcon,
  ShipmentOrderIcon,
} from "../../helpers/iconSvgPath";
import { getShipmentBadge } from "../../helpers/shipments";
import { useNavigate } from "react-router-dom";
import {
  setBackdropPanelSection,
  setGlobalNoteDate,
} from "../../actions/DataActions";
import { useDispatch } from "react-redux";
import {
  getDashboardSearchPath,
  getPurchaseOrdersPermission,
  pathToString,
  sortObjectsBy,
} from "../../helpers/helpers";
import {
  BACKDROP_TYPE,
  GENERAL_PERMISSION_VALUE,
  PERMISSION_TEXT,
  TOOLTIP_TYPE,
} from "../../helpers/constants";
import { useCompanyId, useQueryParams, useStorage } from "../../hooks";
import {
  getAllOrderMentions,
  getLastActivityDate,
  getShipmentsByPOs,
} from "../../helpers/salesOrderHelpers";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { getUserAvatar } from "../../helpers/users";
import LoadingBackdrop from "../../components/WholeScreenFocusBackdrop/LoadingBackdrop";
import { dbTables } from "../../api/types/dbTables";
import { MENTION_POPOVER_SCOPES } from "../../helpers/orderDashboard";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { colors } from "../../assets/jss/variables";
import { shipmentBadgeClass } from "../StyledComponent/BadgeStyled";
import { MentionPopoverStyled } from "./styles";
import { useUserMentions } from "../../hooks/user";
import { verifyPermission } from "../../hooks/permissions";
import AttentionModal from "../../components/Modal/AttentionModal";
import POBadge from "../../components/Badge/POBadge";
import SalesOrderBadge from "../../components/Badge/SalesOrderBadge";

const REDIRECT_TO_TYPES = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
};

function MentionPopover({
  user,
  scope = MENTION_POPOVER_SCOPES.LIST_VIEW,
  shipmentsDB = [],
  currentSalesOrder,
  purchaseOrders = [],
  onClick = () => {},
}) {
  const navigate = useNavigate();
  const companyId = useCompanyId();
  const [modalDescription, setModalDescription] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userMentions = useUserMentions();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const [, setBackdropStorage] = useStorage("backdrop");
  const [, setActivityNoteDate] = useStorage("activityNoteDate");
  const [shipments, setShipments] = useState([]);
  const queryParams = useQueryParams();
  const { totalOrderMention, soMentions, posMentions, shipmentMentions } =
    getAllOrderMentions({
      userMentions,
      soId: currentSalesOrder.id,
      purchaseOrders,
    });
  const posWithMentions = purchaseOrders.filter((po) => posMentions[po.id]);
  const shipmentsWithMentions = shipments.filter(
    (shipment) => shipmentMentions[shipment.id]
  );

  const setShipmentsDB = async () => {
    setIsLoading(true);
    const newShipments = await getShipmentsByPOs({
      purchaseOrders,
      path: pathToString([dbTables.COMPANIES, companyId, dbTables.SHIPMENTS]),
      dispatch: dispatch,
    });
    if (!newShipments) {
      setIsLoading(false);
      return;
    }
    setShipments(newShipments.sort(sortObjectsBy("number", false)));
    setIsLoading(false);
  };

  useEffect(() => {
    if (anchorEl) {
      if (scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD) {
        setShipmentsDB();
      } else {
        setShipments(shipmentsDB);
      }
    }
  }, [anchorEl]);

  const handleNoteDateStorage = (data) => {
    setActivityNoteDate("lastNoteDate", {
      ...data,
    });
  };
  const setNoteDate = useCallback(({ type, creationDate, noteId }) =>
    setGlobalNoteDate({
      type,
      creationDate,
      noteId,
    })(dispatch)
  );
  const setBackdrop = (data) => {
    setBackdropPanelSection({ ...data, dispatch });
  };
  const handleBackdropStorage = (type, id, infoCard = true) => {
    setBackdropStorage("lastBackdrop", {
      type: type,
      isOpen: true,
      id: id || type,
      infoCard: infoCard,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    event.stopPropagation();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function getFirstPO({ salesOrder = {} }) {
    let firstPO = salesOrder.POsMention.sort(sortObjectsBy("number", true))[0];
    const purchaseOrders = salesOrder.purchaseOrders || [];
    if (firstPO && purchaseOrders.find((po) => po.id === firstPO)) {
      return firstPO;
    }
    firstPO = purchaseOrders.sort(sortObjectsBy("number", true))[0];
    return firstPO || {};
  }

  function getCurrentPOAndShipmentToRedirect({
    salesOrder = {},
    purchaseOrder = {},
    shipment = {},
    type = REDIRECT_TO_TYPES.SALES_ORDER,
    scope = "",
  }) {
    if (scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD) {
    }
    if (type === REDIRECT_TO_TYPES.SALES_ORDER) {
      const currentPO = getFirstPO({ salesOrder });
      return {
        salesOrderId: salesOrder.id,
        purchaseOrderId: currentPO.id,
        shipmentId: currentPO.shipmentIds[0] || "",
      };
    } else if (type === REDIRECT_TO_TYPES.PURCHASE_ORDER) {
      return {
        salesOrderId: salesOrder.id,
        purchaseOrderId: purchaseOrder.id,
        shipmentId:
          (purchaseOrder.shipmentIds && purchaseOrder.shipmentIds[0]) || "",
      };
    } else if (type === REDIRECT_TO_TYPES.SHIPMENT) {
      return {
        salesOrderId: salesOrder.id,
        purchaseOrderId: shipment.purchaseOrderId || purchaseOrder.id,
        shipmentId: shipment.id,
      };
    }
    return {
      salesOrderId: "",
      purchaseOrderId: "",
      shipmentId: "",
    };
  }

  return (
    <>
      {!!modalDescription && (
        <AttentionModal
          title="Attention"
          description={modalDescription}
          isOpen={!!modalDescription}
          onClick={() => setModalDescription(false)}
        />
      )}
      <div
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
        style={{
          textDecoration: "underline",
          display:
            scope === MENTION_POPOVER_SCOPES.LIST_VIEW &&
            totalOrderMention === 0
              ? "none"
              : "flex",
          justifyContent: "center",
          color: "#25486D",
          cursor: "pointer",
        }}
      >
        {scope === MENTION_POPOVER_SCOPES.LIST_VIEW ? (
          totalOrderMention
        ) : totalOrderMention > 0 ? (
          getUserAvatar({
            user,
            styles: {
              width: 22,
              height: 22,
              fontSize: 11,
              cursor: "pointer",
              position: "relative",
              borderRadious: "50%",
              outline: "1px solid #000",
            },
          })
        ) : (
          <div className="no-displaying-avatar" />
        )}
      </div>
      {open && (
        <MentionPopoverStyled
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          classes={{
            paper: "popoverContainer",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={"mentionPopoverHeader"}>
            <div className="developeIconContainer">
              {getUserAvatar({
                user,
                styles: {
                  width: 18,
                  height: 18,
                  fontSize: 10,
                  outline: "1px solid #000",
                },
                hasToolTip: false,
              })}
            </div>
            <div className="orderIconsContainer">
              <SalesOrderIcon width={18} height={18} />
              <PurchaseOrderIcon width={20} height={21} />
              <ShipmentOrderIcon width={20} height={21} />
            </div>
          </div>

          <div className={"mentionBodyContainer"}>
            {isLoading && <LoadingBackdrop withLogo={false} size={40} />}
            {totalOrderMention === 0 && (
              <span
                style={{
                  fontSize: 14,
                  paddingInline: 10,
                  width: 190,
                  display: "flex",
                  textAlign: "center",
                  color: colors.primaryDark,
                  fontWeight: 600,
                }}
              >
                You do no have mentions in this entire project
              </span>
            )}
            {soMentions[currentSalesOrder.id] > 0 && (
              <div className="sales-order-mention">
                <div
                  className="mention-number-container"
                  onClick={async (ev) => {
                    if (scope === MENTION_POPOVER_SCOPES.LIST_VIEW) {
                      const { salesOrderId, purchaseOrderId, shipmentId } =
                        getCurrentPOAndShipmentToRedirect({
                          salesOrder: currentSalesOrder,
                          type: REDIRECT_TO_TYPES.SALES_ORDER,
                        });
                      const path = getDashboardSearchPath({
                        salesOrderId,
                        purchaseOrderId,
                        shipmentId,
                        section:
                          ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                      });
                      navigate(path);
                      setBackdrop({
                        type: BACKDROP_TYPE.BADGE_NUMBER,
                        id: BACKDROP_TYPE.BADGE_NUMBER,
                      });
                      const orderData = await getLastActivityDate({
                        companyId,
                        user,
                        salesOrderMention: currentSalesOrder,
                        dispatch: dispatch,
                      });
                      if (orderData) {
                        setNoteDate({
                          type: orderData.scope.toLowerCase(),
                          creationDate: orderData.creationDate,
                          noteId: orderData.id,
                        });
                      }
                      if (ev.ctrlKey) {
                        handleBackdropStorage(BACKDROP_TYPE.MENTION);
                        if (orderData) {
                          handleNoteDateStorage({
                            type: orderData.scope.toLowerCase(),
                            creationDate: orderData.creationDate,
                            noteId: orderData.id,
                          });
                        }
                      }
                    } else if (
                      scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD
                    ) {
                      setBackdrop({
                        type: BACKDROP_TYPE.BADGE_NUMBER,
                        id: BACKDROP_TYPE.BADGE_NUMBER,
                        infoCard: false,
                        dashboard: true,
                      });
                      const purchaseOrderId = getPurchaseOrdersPermission({
                        purchaseOrders,
                        queryParams,
                        user,
                      });
                      const path = getDashboardSearchPath({
                        ...queryParams,
                        salesOrderId: currentSalesOrder.id,
                        purchaseOrderId,
                        section:
                          ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                      });
                      navigate(path);
                    }
                    onClick();
                    setAnchorEl(null);
                    ev.preventDefault();
                    ev.stopPropagation();
                  }}
                >
                  <div className="quantity-mention">
                    <span style={{ textDecoration: "underline" }}>
                      {soMentions[currentSalesOrder.id]}
                    </span>
                  </div>
                </div>

                <div
                  className="number-mention-container"
                  onClick={(ev) => {
                    if (scope === MENTION_POPOVER_SCOPES.LIST_VIEW) {
                      const { salesOrderId, purchaseOrderId, shipmentId } =
                        getCurrentPOAndShipmentToRedirect({
                          salesOrder: currentSalesOrder,
                          type: REDIRECT_TO_TYPES.SALES_ORDER,
                        });
                      const path = getDashboardSearchPath({
                        salesOrderId,
                        purchaseOrderId,
                        shipmentId,
                        section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                      });
                      navigate(path);
                    } else if (
                      scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD
                    ) {
                      const purchaseOrderId = getPurchaseOrdersPermission({
                        purchaseOrders,
                        queryParams,
                        user,
                      });
                      const path = getDashboardSearchPath({
                        ...queryParams,
                        salesOrderId: currentSalesOrder.id,
                        purchaseOrderId,
                        section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                      });
                      navigate(path);
                    }
                    setAnchorEl(null);
                    onClick();
                    ev.preventDefault();
                    ev.stopPropagation();
                  }}
                >
                  <div className="number-mention">
                    <span id={currentSalesOrder.id}>
                      <SalesOrderBadge number={currentSalesOrder.number} />
                    </span>
                  </div>
                </div>
              </div>
            )}

            {posWithMentions
              .sort(sortObjectsBy("number", false))
              .map((currentPO, index) => {
                const { salesOrderId, purchaseOrderId, shipmentId } =
                  getCurrentPOAndShipmentToRedirect({
                    salesOrder: currentSalesOrder,
                    purchaseOrder: currentPO,
                    type: REDIRECT_TO_TYPES.PURCHASE_ORDER,
                  });
                const hasPermission = verifyPermission({
                  user,
                  permissionToCheck: [
                    currentPO.factoryId,
                    GENERAL_PERMISSION_VALUE.ALL_VENDORS,
                  ],
                });
                return (
                  <div className="po-mention" key={currentPO.id + index}>
                    <div
                      className="mention-number-container"
                      onClick={async (ev) => {
                        if (!hasPermission) {
                          setModalDescription(
                            <React.Fragment>
                              {PERMISSION_TEXT.VENDOR}
                            </React.Fragment>
                          );
                          setAnchorEl(null);
                          ev.preventDefault();
                          ev.stopPropagation();
                          return;
                        }
                        const path = getDashboardSearchPath({
                          salesOrderId,
                          purchaseOrderId,
                          shipmentId,
                          section:
                            ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                        });

                        navigate(path);

                        if (scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD) {
                          setBackdrop({
                            type: BACKDROP_TYPE.BADGE_NUMBER,
                            id: BACKDROP_TYPE.BADGE_NUMBER,
                            infoCard: false,
                            dashboard: true,
                          });
                          setAnchorEl(null);
                          ev.preventDefault();
                          ev.stopPropagation();
                        } else if (scope === MENTION_POPOVER_SCOPES.LIST_VIEW) {
                          setBackdrop({
                            type: BACKDROP_TYPE.BADGE_NUMBER,
                            id: BACKDROP_TYPE.BADGE_NUMBER,
                          });
                          const orderData = await getLastActivityDate({
                            companyId,
                            user,
                            POsMention: posWithMentions,
                            dispatch: dispatch,
                          });
                          if (orderData) {
                            setNoteDate({
                              type: orderData.scope.toLowerCase(),
                              creationDate: orderData.creationDate,
                              noteId: orderData.id,
                            });
                          }
                          if (ev.ctrlKey) {
                            handleBackdropStorage(BACKDROP_TYPE.MENTION);
                            if (orderData) {
                              handleNoteDateStorage({
                                type: orderData.scope.toLowerCase(),
                                creationDate: orderData.creationDate,
                                noteId: orderData.id,
                              });
                            }
                          }
                        }
                        onClick();
                      }}
                    >
                      <div className="quantity-mention">
                        <span style={{ textDecoration: "underline" }}>
                          {posMentions[currentPO.id]}
                        </span>
                      </div>
                    </div>
                    <div className="number-mention-container">
                      <div
                        onClick={(ev) => {
                          if (!hasPermission) {
                            setModalDescription(
                              <React.Fragment>
                                {PERMISSION_TEXT.VENDOR}
                              </React.Fragment>
                            );
                            setAnchorEl(null);
                            ev.preventDefault();
                            ev.stopPropagation();
                            return;
                          }
                          const path = getDashboardSearchPath({
                            salesOrderId,
                            purchaseOrderId,
                            shipmentId,
                            section:
                              ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                          });
                          navigate(path);
                          setAnchorEl(null);
                          onClick();
                          ev.preventDefault();
                          ev.stopPropagation();
                        }}
                        className="number-mention"
                      >
                        <TooltipTD
                          label={currentPO.factoryName}
                          type={TOOLTIP_TYPE.BADGE}
                          showToolTip={true}
                          tooltipPlacement="top-start"
                        >
                          <span className="quantity-mention" id={currentPO.id}>
                            <POBadge number={currentPO.number} />
                          </span>
                        </TooltipTD>
                      </div>
                    </div>
                  </div>
                );
              })}

            {shipmentsWithMentions
              .sort(sortObjectsBy("number", false))
              .map((currentShipment, index) => {
                const purchaseOrderIds = currentShipment.purchaseOrderIds || [];
                const shipmentPOs = purchaseOrders.filter((po) =>
                  purchaseOrderIds.includes(po.id)
                );
                const [allowedPO] = shipmentPOs.filter((po) =>
                  verifyPermission({
                    user,
                    permissionToCheck: [
                      po.factoryId,
                      GENERAL_PERMISSION_VALUE.ALL_VENDORS,
                    ],
                  })
                );

                const { salesOrderId, purchaseOrderId, shipmentId } =
                  getCurrentPOAndShipmentToRedirect({
                    salesOrder: currentSalesOrder,
                    type: REDIRECT_TO_TYPES.SHIPMENT,
                    shipment: currentShipment,
                    purchaseOrder: allowedPO,
                  });
                return (
                  <div
                    className="shipment-mention"
                    key={currentShipment.id + index}
                  >
                    <div className="mention-number-container">
                      <div
                        className="quantity-mention"
                        onClick={async (ev) => {
                          setAnchorEl(null);
                          if (!allowedPO) {
                            setModalDescription(
                              <React.Fragment>
                                {PERMISSION_TEXT.VENDOR}
                              </React.Fragment>
                            );
                            ev.preventDefault();
                            ev.stopPropagation();
                            return;
                          }
                          const path = getDashboardSearchPath({
                            salesOrderId,
                            purchaseOrderId,
                            shipmentId,
                            section:
                              ORDER_DASHBOARD_ACTUAL_VIEWS.THREE_PANEL_SECTION,
                          });
                          navigate(path);

                          if (scope === MENTION_POPOVER_SCOPES.LIST_VIEW) {
                            setBackdrop({
                              type: BACKDROP_TYPE.BADGE_NUMBER,
                              id: BACKDROP_TYPE.BADGE_NUMBER,
                            });
                            const orderData = await getLastActivityDate({
                              companyId,
                              user,
                              shipmentsMention: currentShipment,
                              dispatch: dispatch,
                            });
                            if (orderData) {
                              setNoteDate({
                                type: orderData.scope.toLowerCase(),
                                creationDate: orderData.creationDate,
                                noteId: orderData.id,
                              });
                            }
                            if (ev.ctrlKey) {
                              handleBackdropStorage(BACKDROP_TYPE.MENTION);
                              if (orderData) {
                                handleNoteDateStorage({
                                  type: orderData.scope.toLowerCase(),
                                  creationDate: orderData.creationDate,
                                  noteId: orderData.id,
                                });
                              }
                            }
                          } else if (
                            scope === MENTION_POPOVER_SCOPES.ORDER_DASHBOARD
                          ) {
                            setBackdrop({
                              type: BACKDROP_TYPE.BADGE_NUMBER,
                              id: BACKDROP_TYPE.BADGE_NUMBER,
                              infoCard: false,
                              dashboard: true,
                            });
                          }
                          onClick();
                          ev.preventDefault();
                          ev.stopPropagation();
                        }}
                      >
                        <span style={{ textDecoration: "underline" }}>
                          {shipmentMentions[currentShipment.id]}
                        </span>
                      </div>
                    </div>
                    <div className="number-mention-container">
                      <div
                        onClick={async (ev) => {
                          setAnchorEl(null);
                          if (!allowedPO) {
                            setModalDescription(
                              <React.Fragment>
                                {PERMISSION_TEXT.VENDOR}
                              </React.Fragment>
                            );
                            ev.preventDefault();
                            ev.stopPropagation();
                            return;
                          }
                          const path = getDashboardSearchPath({
                            salesOrderId,
                            purchaseOrderId,
                            shipmentId,
                            section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
                          });
                          navigate(path);
                          onClick();
                          ev.preventDefault();
                          ev.stopPropagation();
                        }}
                        className="number-mention"
                      >
                        {getShipmentBadge({
                          shipment: currentShipment,
                          styles: { margin: 0 },
                          classes: shipmentBadgeClass.medium,
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </MentionPopoverStyled>
      )}
    </>
  );
}

export default MentionPopover;
