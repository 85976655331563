import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import TooltipTD from "../Tooltip/TooltipTD";

export function CircularStaticProgress({
  value,
  thickness,
  style,
  size,
  label,
  className,
  backgroundColor = "#eeeeee",
  withBorder,
  ...props
}) {
  const borderColor = style.color || backgroundColor;
  return (
    <TooltipTD
      label={label}
      style={{ position: "relative" }}
      showToolTip={!!label}
      className={className}
      {...props}
    >
      <CircularProgress
        variant="determinate"
        size={size}
        thickness={thickness}
        value={value}
        style={{ ...style, zIndex: 1, position: "inherit" }}
      />
      <CircularProgress
        variant="determinate"
        size={size}
        thickness={thickness}
        value={100}
        style={{
          color: backgroundColor,
          position: "absolute",
          left: 0,
          zIndex: 0,
          borderRadius: "100%",
          boxShadow: withBorder
            ? `inset 0px 0px 0px ${withBorder}px  ${borderColor}`
            : "",
          outline: withBorder ? `0.25px ${borderColor} solid` : "",
        }}
      />
    </TooltipTD>
  );
}
