import React, { useState, useEffect } from "react";
import CustomModal from "../Modal/Modal";
import CreateUserForm from "./CreateUserForm";
import CustomSnackbar from "../../routes/components/snackbar/component/CustomSnackbar";
import { dbTables } from "../../api/types/dbTables";
import { userFields } from "../../helpers/constants";
import { useCompany } from "../../hooks/company";
import { auth, firestore } from "../../firebase";
import userTypes from "../../api/types/userTypes";
import AttentionModal from "../Modal/AttentionModal";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import IntlMessages from "../../util/IntlMessages";
import { validDisplayName } from "../../helpers/users";

function CreateUser({
  currentUser,
  isCreating,
  isUpdate,
  onSaveUser = () => {},
  handleCloseModal,
  onDeleteUser,
  users,
  canChangeUserStatus,
}) {
  const [user, setUser] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const company = useCompany();
  useEffect(() => {
    setUser(currentUser);
  }, []);

  async function onSubmitAddNewUser(event) {
    console.log("ON SUBMIT NEW USER FORM:: ");
    const adminUsers = users.filter(
      (currentUser) =>
        currentUser.role === userTypes.SUPER_ADMIN && currentUser.active
    );

    event.preventDefault();
    if (
      adminUsers.length <= 1 &&
      currentUser.role === userTypes.SUPER_ADMIN &&
      user.role !== userTypes.SUPER_ADMIN
    ) {
      setOpenModal(true);
      return;
    }
    const domain = user.email.substring(user.email.lastIndexOf("@") + 1) || "";
    const companyDomain = company.domain || "";
    const filterUsers = users.filter((item) => item.id !== currentUser.id);
    const parseEmail = user.email.toLowerCase().trim();
    const parseDisplayName = user.displayName.slice(1).toLowerCase().trim();
    if (!parseDisplayName) {
      setSnackbarMessage("companyusers.validate.required.displayname");
      return setOpenSnackbar(true);
    }
    if (domain !== companyDomain) {
      setSnackbarMessage("companyusers.validate.domain");
      return setOpenSnackbar(true);
    }
    if (!validDisplayName(parseDisplayName)) {
      setSnackbarMessage("companyusers.validate.error.displayname");
      return setOpenSnackbar(true);
    }
    if (
      filterUsers.find((companyUser) => {
        return (
          companyUser.displayName.toLowerCase().trim() === parseDisplayName
        );
      })
    ) {
      setSnackbarMessage("companyusers.validate.exists.name");
      return setOpenSnackbar(true);
    }
    if (
      filterUsers.find((companyUser) => {
        return companyUser.email.toLowerCase().trim() === parseEmail;
      })
    ) {
      setSnackbarMessage("companyusers.validate.exists.email");
      return setOpenSnackbar(true);
    }
    let userPhone = await getDocs(
      query(
        collection(firestore, dbTables.USERS),
        where("cellPhone", "==", user.cellPhone),
        limit(1)
      )
    );
    [userPhone] = userPhone.docs;
    if (
      user.cellPhone !== "" &&
      user.cellPhone !== "+" &&
      userPhone &&
      userPhone.id !== user.id
    ) {
      setSnackbarMessage("companyusers.validate.exists.phonenumber");
      return setOpenSnackbar(true);
    }

    try {
      // TO REFACTOR
      const functions = getFunctions();
      const tokenId = await auth.currentUser.getIdToken();
      const onChangeEmail = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: `user`,
          env: globalEnvironment,
          params: `/updateEmail`,
        })
      );
      const response = await onChangeEmail({
        email: user.email.trim(),
        userId: user.id,
        tokenId,
      });
      const data = response.data || {};
      if (data.success) {
        onSaveUser({ ...user, email: parseEmail }, isUpdate);
      } else {
        setSnackbarMessage("companyusers.validate.exists.email");
        return setOpenSnackbar(true);
      }
    } catch (error) {
      console.log(error);
      setSnackbarMessage("please verify the auth status of user");
      return setOpenSnackbar(true);
    }
  }

  function onChange(value, name) {
    if (name === userFields.PERMISION_GROUPS) {
      let newPermissionGroups = {};
      value.forEach((userPermissionGroups) => {
        newPermissionGroups = {
          ...newPermissionGroups,
          [userPermissionGroups.id]: true,
        };
      });
      setUser({
        ...user,
        [name]: newPermissionGroups,
      });
    } else if (value[0] !== "@") {
      if (name === userFields.DISPLAY_NAME) {
        setUser({ ...user, [name]: "@" });
      } else {
        setUser({ ...user, [name]: value });
      }
    } else {
      setUser({ ...user, [name]: value });
    }
  }

  function handleChangeEnableToDisable() {
    setUser({ ...user, active: !user.active });
  }

  function handleError(error) {
    console.error(error);
  }

  function onUpload(url) {
    setUser({ ...user, avatar: url });
  }

  function onChangeCode(name, event) {
    setUser({ ...user, [name]: event });
  }

  if (!user) {
    return null;
  }

  return (
    <div>
      {openSnackbar && (
        <CustomSnackbar
          open={openSnackbar}
          message={snackbarMessage}
          variant="error"
          handleCloseSnackbar={() => setOpenSnackbar(false)}
          autoHideDuration={2500}
        />
      )}
      {openModal && (
        <AttentionModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          title="Attention"
          description={
            <span>
              {user.displayName} cannot be deactivated. There must be at least
              one user in SuperAdmin at all times
            </span>
          }
          onClick={() => setOpenModal(false)}
        />
      )}
      <CustomModal
        header={
          isUpdate ? (
            <IntlMessages
              id={"components.userManagement.userCompany.editUser"}
            />
          ) : (
            <IntlMessages
              id={"components.userManagement.userCompany.createUser"}
            />
          )
        }
        isOpen={isCreating}
        onClose={handleCloseModal}
        modalStyle={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <CreateUserForm
          setOpenModal={setOpenModal}
          user={user}
          isUpdate={isUpdate}
          handleError={handleError}
          handleSuccess={onUpload}
          handleChangeEnableToDisable={handleChangeEnableToDisable}
          onChange={onChange}
          onDeleteUser={onDeleteUser}
          onSubmitAddNewUser={onSubmitAddNewUser}
          handleCloseModal={handleCloseModal}
          onChangeCode={onChangeCode}
          canChangeUserStatus={canChangeUserStatus}
        />
      </CustomModal>
    </div>
  );
}

export default CreateUser;
