import React, { useState, useEffect, useRef } from "react";
import NotificationsHeader from "../../components/Notifications/NotificationsHeader";
import NotificationsContent from "../../components/Notifications/NotificationsContent";
import { useCompanyUsers, useUser, useUserMentions } from "../../hooks/user";
import { dbTables } from "../../api/types/dbTables";
import { sortObjectsBy } from "../../helpers/helpers";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import NotificationsFilters from "../../components/Notifications/NotificationsFilters";
import { firestore } from "../../firebase";
import { useIsAllowedFunction } from "../../hooks/permissions";
import {
  BY_FILTER_NOTIFICATION,
  FILTER_NOTIFICATION,
  GENERAL_PERMISSION_VALUE,
  READ_FROM_TYPE,
  TYPE_NOTIFICATION,
} from "../../helpers/constants";
import {
  filterNotifications,
  getNotificationsStorage,
} from "./helperNotification";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";
import { useCompanyId, useJSONLocalStorage } from "../../hooks";
import {
  LOAD_NOTIFICATIONS,
  MARK_READ_LOADED_NOTIFICATIONS,
} from "../../actions/types";
import { dateFilterOptionLabel } from "../../helpers/notifications";
import {
  getActivityByNotification,
  handleMarkAsRead,
} from "../../helpers/activitiesStream";
import ModalWarningMark from "./ModalWarningMark";
import { getNotificationText } from "../../components/Notifications/NotificationHelpers";

function NotificationsContainer({
  open = false,
  onClose = () => {},
  history,
  handleOpenModal,
  handleLoading = () => {},
}) {
  const { get, set } = useJSONLocalStorage("notifications");
  let {
    storageActiveTab,
    storageFilterUnread,
    storageAssignedTo,
    storageCustomer,
    storageFactory,
    storageDate,
    storageFilterDateOption,
    storageDateRange,
    storageSearchQuery,
  } = getNotificationsStorage({ storageNotifications: get() });
  const storageNotifications = get() || {};

  if (moment().format("M/D/YYYY") !== storageDate) {
    resetToDefaultValues();
  }
  const companyId = useCompanyId();
  const user = useUser();
  const users = useCompanyUsers({});
  const userMentions = useUserMentions();
  const [settingsAreOpen, setSettingsAreOpen] = useState(false);
  const [openAttentionModal, setOpenAttentionModal] = useState(false);
  const [unReadNotifications, setUnReadNotifications] = useState(
    !!storageFilterUnread
  );
  const [searchQuery, setSearchQuery] = useState(storageSearchQuery);
  const [activeTab, setActiveTab] = useState(storageActiveTab);
  const [filters, setFilters] = useState({
    assignedTo: storageAssignedTo,
    factory: storageFactory,
    customer: storageCustomer,
    dateRange: storageDateRange,
    filterDateOption: storageFilterDateOption,
  });
  const isAllowed = useIsAllowedFunction();
  const isAbleToModifyTaskDueDate = isAllowed(
    GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES
  );
  const dispatch = useDispatch();

  const loadedNotifications = useSelector(
    (state) => state.data.loadedNotifications
  );

  const scrollerRef = useRef(null);
  function resetToDefaultValues() {
    storageActiveTab = FILTER_NOTIFICATION.DMs;
    storageAssignedTo = [];
    storageCustomer = [];
    storageFactory = [];
    storageDateRange = { start: null, end: null };
    storageFilterUnread = "";
  }
  useEffect(() => {
    if (open) {
      markNotifications();
      loadNotifications({ filters, activeTab });
    }
  }, [open]);

  useEffect(() => {
    set({
      ...storageNotifications,
      ...filters,
      date: moment().format("M/D/YYYY"),
    });
  }, [filters]);

  const getNotification = () => {
    let loadedNotificationsCpy = [
      ...getLoadedNotifications({
        loadedNotifications,
        activeTab,
      }),
    ];
    loadedNotificationsCpy = loadedNotificationsCpy.filter((notification) => {
      const { start, end } = filters.dateRange;
      if (
        notification.creationDate >= moment(start).startOf("day").valueOf() &&
        notification.creationDate <= moment(end).endOf("day").valueOf()
      ) {
        return true;
      }
      return false;
    });
    if (unReadNotifications) {
      loadedNotificationsCpy = loadedNotificationsCpy.filter((notification) => {
        if (!notification.read) {
          return true;
        }
        return false;
      });
    }
    return loadedNotificationsCpy.sort(sortObjectsBy("creationDate", true));
  };

  function getLoadedNotifications({
    loadedNotifications = {},
    activeTab = "",
  }) {
    if (!loadedNotifications[activeTab]) {
      return [];
    }
    return loadedNotifications[activeTab].data;
  }

  const currentNotifications = getNotification();

  function markNotifications() {
    const functions = getFunctions();
    const callable = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: `userNotifications`,
        env: globalEnvironment,
        params: `/newNotifications?userId=${user.id}`,
      })
    );
    callable().then(() => {
      console.log("mark all notifications successfull");
    });
  }

  async function markShowNotifications({ skipWarning }) {
    if (!skipWarning) {
      setOpenAttentionModal(true);
      return;
    }
    handleLoading(true);
    setTimeout(() => setOpenAttentionModal(false), 200);
    // DISPATCH A REDUX TO MARK ALL AS READ::
    const filterCurrentNotifications = queryFiltered({
      searchText: searchQuery,
      notifications: filterNotifications({
        currentNotifications,
        filters,
      }),
      users,
    });
    dispatch({
      type: MARK_READ_LOADED_NOTIFICATIONS,
      payload: {
        notifications: filterCurrentNotifications,
        cleanAll: false,
        typeReadFrom: READ_FROM_TYPE.MARK_ALL_AS_READ_NOTIFICATIONS,
      },
    });

    const functions = getFunctions();

    const callableEndpoint = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: `userNotifications`,
        env: globalEnvironment,
        params: `/markAllAsRead`,
      })
    );
    let { dateRange = {} } = filters;
    dateRange = {
      start: dateRange.start ? moment(dateRange.start).format("DD/MM/YY") : "",
      end: dateRange.end ? moment(dateRange.end).format("DD/MM/YY") : "",
    };
    await callableEndpoint({
      companyId,
      userId: user.id,
      date: moment().valueOf(),
      filters: {
        ...filters,
        dateRange,
      },
      searchQuery,
    });
    handleLoading(false);
  }

  async function onToggle(notification) {
    let shouldUpdateDoc = true;
    if (
      notification.type === TYPE_NOTIFICATION.DIRECT_MESSAGE &&
      notification.userId !== user.id
    ) {
      const currentActivity = await getActivityByNotification({
        companyId: user.companyId,
        notification,
      });

      if (currentActivity) {
        const userActivityMention = currentActivity.userMentions || {};
        const activityRead = userActivityMention[user.id];
        if (activityRead === notification.read) {
          handleMarkAsRead({
            activity: currentActivity,
            userMentions,
            user,
            salesOrder: { id: notification.salesOrderId },
            currentShipment: { id: notification.shipmentId },
            purchaseOrder: { id: notification.purchaseOrderId },
            forceValue: { value: !notification.read },
            dispatch,
            type: READ_FROM_TYPE.NOTIFICATION,
          });
          shouldUpdateDoc = false;
        }
      }
    }
    if (shouldUpdateDoc) {
      updateDoc(notification.ref, {
        read: !notification.read,
      });
    }
    dispatch({
      type: LOAD_NOTIFICATIONS,
      payload: { ...notification, read: !notification.read },
      isFromListener: true,
    });
  }

  async function loadNotifications({ filters = {}, activeTab = "" }) {
    if (
      loadedNotifications &&
      loadedNotifications[activeTab] &&
      loadedNotifications[activeTab].dateRange
    ) {
      const dateRangeLoaded = loadedNotifications[activeTab].dateRange;
      const { start, end } = dateRangeLoaded;
      const { dateRange } = filters;
      const filterStart = moment(dateRange.start).startOf("day").valueOf();
      const reduxStart = moment(start).startOf("day").valueOf();
      const filterEnd = moment(dateRange.end).endOf("day").valueOf();
      const reduxEnd = moment(end).endOf("day").valueOf();
      if (filterStart >= reduxStart && filterEnd <= reduxEnd) {
        return;
      }
    }
    if (filters.dateRange && filters.dateRange.start) {
      handleLoading(true);
      const { start, end } = filters.dateRange;
      const startDate = moment(start).startOf("day").valueOf();
      const endDate = moment(end).endOf("day").valueOf();

      let notificationQuery = query(
        collection(
          firestore,
          `${dbTables.USERS}/${user.id}/${dbTables.NOTIFICATIONS}`
        ),
        where("creationDate", ">=", startDate),
        where("creationDate", "<=", endDate)
      );

      if (activeTab !== FILTER_NOTIFICATION.ALL) {
        notificationQuery = query(
          notificationQuery,
          where("type", "==", BY_FILTER_NOTIFICATION[activeTab])
        );
      }
      const notificationsByDateSnap = await getDocs(notificationQuery);
      const isOnly = activeTab === FILTER_NOTIFICATION.DMs;
      console.log(notificationsByDateSnap);
      const notificationsByDate = notificationsByDateSnap.docs.map((doc) => ({
        ...doc.data(),
        ref: doc.ref,
        userMentions: {
          ...doc.data().userMentions,
          [user.id]: isOnly
            ? true
            : !!doc.data().userMentions && doc.data().userMentions[user.id],
        },
      }));
      dispatch({
        payload: {
          data: notificationsByDate,
          dateRange: filters.dateRange,
        },
        table: activeTab,
        type: LOAD_NOTIFICATIONS,
      });
      handleLoading(false);
      return true;
    } else {
      const limitNotification = 20;
      handleLoading(true);
      let notificationQuery = collection(
        firestore,
        `${dbTables.USERS}/${user.id}/${dbTables.NOTIFICATIONS}`
      );

      if (activeTab !== FILTER_NOTIFICATION.ALL) {
        notificationQuery = query(
          notificationQuery,
          where("type", "==", BY_FILTER_NOTIFICATION[activeTab])
        );
      }
      notificationQuery = query(
        notificationQuery,
        orderBy("creationDate", "desc"),
        limit(limitNotification)
      );
      const notificationsSnapDB = await getDocs(notificationQuery);
      const notificationsDB = notificationsSnapDB.docs.map((doc) => ({
        ...doc.data(),
        ref: doc.ref,
      }));
      const endDate = moment.now();
      if (notificationsDB.length === 0) {
        handleLoading(false);
        setFilters({
          ...filters,
          dateRange: {
            start: moment().startOf("month").valueOf(),
            end: endDate,
          },
          filterDateOption: dateFilterOptionLabel.CURRENT_MONTH,
        });
        return false;
      }

      const startDate =
        notificationsDB[notificationsDB.length - 1].creationDate;
      setFilters({
        ...filters,
        dateRange: {
          start: startDate,
          end: endDate,
        },
        filterDateOption: dateFilterOptionLabel.CHOOSE_A_DATE_RANGE,
      });

      dispatch({
        payload: {
          data: notificationsDB,
          dateRange: {
            start: startDate,
            end: endDate,
          },
        },
        table: activeTab,
        type: LOAD_NOTIFICATIONS,
      });
      handleLoading(false);
    }
    return false;
  }

  async function onChangeFilters(filters) {
    setFilters({ ...filters });
    loadNotifications({ filters, activeTab });
  }

  function handleResetFilters() {
    setFilters({ ...filters, assignedTo: [], customer: [], factory: [] });
    setActiveTab(FILTER_NOTIFICATION.DMs);
    setSearchQuery("");
    setUnReadNotifications(false);
  }

  async function changeFilterTab(filter) {
    set({ ...storageNotifications, activeTab: filter });
    setActiveTab(filter);
    loadNotifications({
      filters,
      activeTab: filter,
    });
  }

  useEffect(() => {
    const notificationScrollContainer = document.getElementById(
      "notification-scroll-container"
    );
    if (notificationScrollContainer) {
      notificationScrollContainer.scrollTo({
        top: 0,
      });
    }
  }, [searchQuery]);

  function queryFiltered({ searchText, notifications, users }) {
    if (!searchText) return notifications;
    return notifications.filter((notification) => {
      return getNotificationText(
        {
          ...notification,
        },
        users
      )
        .replace(/<[^>]*>/gi, "")
        .replace(/\s+/g, " ")
        .trim()
        .toLocaleLowerCase()
        .includes(searchText.toLocaleLowerCase());
    });
  }

  return (
    <>
      {openAttentionModal && (
        <ModalWarningMark
          handleAttentionModal={() =>
            setTimeout(() => setOpenAttentionModal(false), 200)
          }
          openAttentionModal={openAttentionModal}
          onMarkAllRead={markShowNotifications}
          user={user}
        />
      )}
      <NotificationsHeader
        onGearClick={() => setSettingsAreOpen(!settingsAreOpen)}
        onChangeSearch={(searchTerm) => {
          setSearchQuery(searchTerm);
          set({ ...storageNotifications, searchQuery: searchTerm });
        }}
        searchQuery={searchQuery}
      />
      <NotificationsFilters
        activeTab={activeTab}
        changeFilterTab={changeFilterTab}
        filters={filters}
        changeFilters={onChangeFilters}
        onResetFilters={handleResetFilters}
        onFilterClick={() => {
          set({
            ...storageNotifications,
            storageFilterUnread: !unReadNotifications,
          });
          setUnReadNotifications(!unReadNotifications);
        }}
        onMarkAll={() =>
          markShowNotifications({ skipWarning: !!user.skipWarning })
        }
        unReadNotifications={unReadNotifications}
      />
      {queryFiltered({
        searchText: searchQuery,
        notifications: filterNotifications({
          currentNotifications,
          filters,
        }),
        users,
      }).length === 0 ? (
        <div
          className={"noResultText"}
          style={{
            fontSize: 26,
            textAlign: "center",
            color: "#174176",
            padding: "36px 16px",
          }}
        >
          The current filter selection does not have any results
        </div>
      ) : (
        <div
          id="notification-scroll-container"
          style={{
            overflowY: "auto",
          }}
          className="notification-scroll-container"
          ref={scrollerRef}
        >
          <NotificationsContent
            closeDrawer={() => {
              onClose();
            }}
            filteredNotifications={queryFiltered({
              searchText: searchQuery,
              notifications: filterNotifications({
                currentNotifications,
                filters,
              }),
              users,
            }).sort(sortObjectsBy("creationDate", true))}
            onToggle={onToggle}
            isAbleToModifyTaskDueDate={isAbleToModifyTaskDueDate}
            history={history}
            handleOpenModal={handleOpenModal}
            handleLoading={handleLoading}
            searchText={searchQuery}
          />
        </div>
      )}
    </>
  );
}

export default NotificationsContainer;
