import { generalPermissions, headerColumns } from "../../helpers/constants";
import { POObjFields } from "../../helpers/orderDashboard";

export const purchaseOrderListHeader = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    styles: {
      textAlign: "center",
      padding: 9,
      minWidth: 40,
    },
    exportLabel: "Star",
    key: "favorite",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    canBeSort: false,
    filter: false,
    styles: {
      textAlign: "center",
      padding: 0,
      minWidth: 50,
    },
    exportLabel: "Status",
    key: "status",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.PROGRESS]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    canBeSort: true,
    reference: "progress",
    filter: false,
    styles: {
      textAlign: "center",
      padding: "9px 4px",
      minWidth: 35,
    },
    exportLabel: "Completion",
    key: "progress",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.MENTIONS]: {
    label: "userMentionCount",
    id: 3,
    canBeSort: true,
    reference: "totalMentions",
    item: "AVATAR",
    filter: false,
    styles: {
      textAlign: "center",
      padding: "9px 0px 9px 20px",
      minWidth: 65,
    },
    exportLabel: "Mentions",
    key: "mentions",
    searchBy: false,
    resizable: false,
    disabledDraggable: true,
  },
  [headerColumns.PO]: {
    label: "PO/Purchase Quote",
    displayLabel: (
      <span>
        PO/
        <br />
        Purchase Quote
      </span>
    ),
    id: 4,
    canBeSort: true,
    reference: POObjFields.NUMBER,
    filter: false,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      minWidth: 180,
    },
    exportLabel: "PO",
    key: "PONumber",
    searchBy: POObjFields.NUMBER,
    resizable: true,
  },
  [headerColumns.SO]: {
    label: "Quote/Sales Order",
    displayLabel: (
      <span>
        Quote/
        <br />
        Sales Order
      </span>
    ),
    id: 5,
    canBeSort: true,
    reference: "SONumber",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      minWidth: 205,
    },
    exportLabel: "SO",
    key: "SONumber",
    searchBy: "SONumber",
    resizable: true,
  },
  [headerColumns.SHIPMENTS]: {
    label: headerColumns.SHIPMENT,
    id: 6,
    canBeSort: false,
    reference: "shipments",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "0px 15px 0px 34px",
      minWidth: 120,
    },
    exportLabel: "Shipments",
    key: "shipments",
    searchBy: headerColumns.SHIPMENT,
    resizable: true,
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 7,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 110,
    },
    exportLabel: headerColumns.CUSTOMER,
    key: "customerName",
    searchBy: "customerName",
    resizable: true,
  },
  [headerColumns.CREATION_DATE]: {
    label: headerColumns.CREATION_DATE,
    id: 8,
    canBeSort: true,
    reference: "creationDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 100,
    },
    exportLabel: headerColumns.CREATION_DATE,
    key: "creationDate",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.ORDER_READY_DATE]: {
    label: headerColumns.ORDER_READY_DATE,
    id: 9,
    canBeSort: true,
    reference: "orderReadyDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 130,
    },
    exportLabel: headerColumns.ORDER_READY_DATE,
    key: "orderReadyDate",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.VENDOR]: {
    label: headerColumns.VENDOR,
    id: 10,
    canBeSort: true,
    reference: "factoryName",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "9px 16px",
      minWidth: 120,
    },
    exportLabel: headerColumns.VENDOR,
    key: "factoryName",
    searchBy: "factoryName",
    resizable: true,
  },
  [headerColumns.DISCOUNT_PERCENTAGE]: {
    label: headerColumns.DISCOUNT_PERCENTAGE,
    id: 11,
    canBeSort: true,
    reference: "discountPercentage",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: headerColumns.DISCOUNT_PERCENTAGE,
    key: "discountPercentage",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.TOTAL]: {
    label: headerColumns.TOTAL,
    id: 12,
    canBeSort: true,
    reference: "total",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "9px 16px",
      minWidth: 120,
    },
    exportLabel: headerColumns.TOTAL,
    key: "total",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.POTENTIAL_LATE_FEE]: {
    label: headerColumns.POTENTIAL_LATE_FEE,
    id: 13,
    canBeSort: true,
    reference: "potentialLateFee",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 150,
    },
    exportLabel: headerColumns.POTENTIAL_LATE_FEE,
    key: "potentialLateFee",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.CBM]: {
    label: headerColumns.CBM,
    id: 14,
    canBeSort: true,
    reference: "CBM",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "9px 16px 9px 32px",
      minWidth: 100,
    },
    exportLabel: headerColumns.CBM,
    key: "CBM",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.SHIPPING_TERMS]: {
    label: headerColumns.SHIPPING_TERMS,
    id: 15,
    canBeSort: true,
    reference: "shippingTerms",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 10px",
      minWidth: 100,
    },
    exportLabel: headerColumns.SHIPPING_TERMS,
    key: "shippingTerms",
    searchBy: "shippingTerms",
    resizable: true,
  },
  [headerColumns.TOTAL_SHIPMENTS]: {
    label: headerColumns.TOTAL_SHIPMENTS,
    id: 16,
    canBeSort: false,
    reference: "totalShipments",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 7px",
      minWidth: 100,
    },
    exportLabel: headerColumns.TOTAL_SHIPMENTS,
    key: "totalShipments",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.VENDOR_INVOICE]: {
    label: headerColumns.VENDOR_INVOICE,
    id: 17,
    canBeSort: true,
    reference: "invoice",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 15px",
      minWidth: 100,
    },
    exportLabel: headerColumns.VENDOR_INVOICE,
    key: "invoice",
    searchBy: "invoice",
    resizable: true,
  },
  [headerColumns.UNIQUE_ITEMS]: {
    label: headerColumns.UNIQUE_ITEMS,
    id: 18,
    canBeSort: true,
    reference: "uniqueItems",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 90,
    },
    exportLabel: headerColumns.UNIQUE_ITEMS,
    key: "uniqueItems",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.DISCOUNT]: {
    label: headerColumns.DISCOUNT,
    id: 19,
    canBeSort: true,
    reference: "discount",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "0px 17px",
      minWidth: 110,
    },
    exportLabel: headerColumns.DISCOUNT,
    key: "discount",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.SUB_TOTAL]: {
    label: headerColumns.SUB_TOTAL,
    id: 20,
    canBeSort: true,
    reference: "subtotal",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "9px 16px",
      minWidth: 100,
    },
    exportLabel: headerColumns.SUB_TOTAL,
    key: "subtotal",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.DEPOSIT]: {
    label: headerColumns.DEPOSIT,
    id: 21,
    canBeSort: true,
    reference: "deposit",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "9px 16px",
      minWidth: 100,
    },
    exportLabel: headerColumns.DEPOSIT,
    key: "deposit",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.BALANCE]: {
    label: headerColumns.BALANCE,
    id: 22,
    canBeSort: true,
    reference: "balance",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "9px 16px",
      minWidth: 100,
    },
    exportLabel: headerColumns.BALANCE,
    key: "balance",
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.TOTAL_WEIGHT]: {
    label: headerColumns.TOTAL_WEIGHT,
    id: 23,
    canBeSort: true,
    reference: "totalWeight",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 15px 0px 26px",
      minWidth: 100,
    },
    exportLabel: headerColumns.TOTAL_WEIGHT,
    key: "totalWeight",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.DOCUMENT_DATE]: {
    label: headerColumns.DOCUMENT_DATE,
    id: 24,
    canBeSort: true,
    reference: "date",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 7px",
      minWidth: 110,
    },
    exportLabel: headerColumns.DOCUMENT_DATE,
    key: "date",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.LAST_BLANK_COLUMN]: {
    label: " ",
    disabledDraggable: true,
    id: 25,
    canBeSort: false,
    reference: "extra",
    filter: false,
    selected: true,
    styles: {
      padding: 0,
      width: 16,
    },
    exportLabel: "PO - Labels",
    key: "poLabels",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 26,
    canBeSort: false,
    reference: "",
    filter: false,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "9px 0px",
      minWidth: "auto",
    },
    exportLabel: "",
    key: "",
    searchBy: false,
    resizable: false,
  },
};

export const salesOrderHeaderCells = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    styles: {
      textAlign: "center",
      padding: 9,
      minWidth: 40,
    },
    exportLabel: "Star",
    key: "favorite",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    canBeSort: false,
    filter: false,
    styles: {
      textAlign: "center",
      padding: 0,
      minWidth: 50,
    },
    exportLabel: "Status",
    key: "status",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.PROGRESS]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    canBeSort: true,
    reference: "progress",
    filter: false,
    styles: {
      textAlign: "center",
      padding: "9px 4px",
      minWidth: 35,
    },
    exportLabel: "Completion",
    key: "progress",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.MENTIONS]: {
    label: "salesOrderMention",
    id: 3,
    canBeSort: true,
    textAlign: "center",
    item: "AVATAR",
    reference: "totalMentions",
    filter: false,
    styles: {
      textAlign: "center",
      padding: "9px 0px 9px 20px",
      minWidth: 65,
    },
    exportLabel: "Mentions",
    key: "mentions",
    searchBy: false,
    resizable: false,
    disabledDraggable: true,
  },
  [headerColumns.SO]: {
    label: "Quote/Sales Order",
    displayLabel: (
      <span>
        Quote/
        <br />
        Sales Order
      </span>
    ),
    id: 4,
    canBeSort: true,
    reference: "number",
    filter: false,
    selected: true,
    styles: {
      textAlign: "left",
      minWidth: 205,
      padding: "0px 8px 0px 24px",
      wordBreak: "break-all",
    },
    exportLabel: "SO Number",
    key: "SONumber",
    searchBy: "number",
    resizable: true,
  },
  [headerColumns.POS]: {
    label: "PO/Purchase Quote",
    displayLabel: (
      <span>
        PO/
        <br />
        Purchase Quote
      </span>
    ),
    id: 5,
    canBeSort: false,
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 8px 0px 49px",
      minWidth: 270,
    },
    exportLabel: "POs",
    key: "pos",
    dynamicPadding: ["poDiamonds", "poProgress"],
    searchBy: headerColumns.POS,
    resizable: true,
  },
  [headerColumns.SHIPMENTS]: {
    label: headerColumns.SHIPMENT,
    id: 6,
    canBeSort: false,
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 8px 0px 48px",
      minWidth: 170,
    },
    exportLabel: "Shipments",
    key: "shipments",
    dynamicPadding: ["shipmentDiamonds", "shipmentProgress"],
    searchBy: headerColumns.SHIPMENT,
    resizable: true,
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 7,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      minWidth: 110,
      padding: "0px 10px 0px 16px",
    },
    exportLabel: "Customer",
    key: "customerName",
    searchBy: "customerName",
    resizable: true,
  },
  [headerColumns.CREATION_DATE]: {
    label: headerColumns.CREATION_DATE,
    id: 8,
    canBeSort: true,
    reference: "creationDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 10px 0px 21px",
      minWidth: 100,
    },
    exportLabel: headerColumns.CREATION_DATE,
    key: "creationDate",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.SHIP_BY_DATE]: {
    label: headerColumns.SHIP_BY_DATE,
    id: 9,
    canBeSort: true,
    reference: "shipDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 10px 0px 21px",
      minWidth: 100,
    },
    exportLabel: headerColumns.SHIP_BY_DATE,
    key: "shipDate",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.DOCUMENT_DATE]: {
    label: headerColumns.DOCUMENT_DATE,
    id: 25,
    canBeSort: true,
    reference: "date",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 27px",
      minWidth: 120,
    },
    exportLabel: headerColumns.DOCUMENT_DATE,
    key: "date",

    searchBy: false,
    resizable: true,
  },
  [headerColumns.REQUIRED_DELIVERY]: {
    label: headerColumns.REQUIRED_DELIVERY,
    id: 10,
    canBeSort: true,
    reference: "deliveryDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      minWidth: 110,
    },
    exportLabel: headerColumns.REQUIRED_DELIVERY,
    key: "deliveryDate",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.PAY_TERMS_START]: {
    label: headerColumns.PAY_TERMS_START,
    id: 11,
    canBeSort: true,
    reference: "payTermsStart",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 24px",
      minWidth: 130,
    },
    exportLabel: headerColumns.PAY_TERMS_START,
    key: "payTermsStart",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.DISCOUNT_PERCENTAGE]: {
    label: headerColumns.DISCOUNT_PERCENTAGE,
    id: 12,
    canBeSort: true,
    reference: "discountPercentage",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 16px",
      minWidth: 120,
    },
    exportLabel: headerColumns.DISCOUNT_PERCENTAGE,
    key: "discountPercentage",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.TOTAL]: {
    label: headerColumns.TOTAL,
    id: 13,
    canBeSort: true,
    reference: "total",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 100,
    },
    exportLabel: headerColumns.TOTAL,
    key: "total",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.POTENTIAL_LATE_PENALTY]: {
    label: headerColumns.POTENTIAL_LATE_PENALTY,
    id: 14,
    canBeSort: true,
    reference: "potentialLateFee",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 16px",
      minWidth: 135,
    },
    exportLabel: headerColumns.POTENTIAL_LATE_PENALTY,
    key: "potentialLateFee",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.CBM]: {
    label: headerColumns.CBM,
    id: 15,
    canBeSort: true,
    reference: "CBM",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "9px 16px 9px 32px",
      minWidth: 100,
    },
    exportLabel: headerColumns.CBM,
    key: "CBM",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.TOTAL_SHIPMENTS]: {
    label: headerColumns.TOTAL_SHIPMENTS,
    id: 16,
    canBeSort: false,
    reference: "totalShipments",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 100,
    },
    exportLabel: headerColumns.TOTAL_SHIPMENTS,
    key: "totalShipments",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.SALES_INVOICE]: {
    label: headerColumns.CUSTOMER_INVOICE,
    id: 17,
    canBeSort: true,
    reference: "invoice",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 18px",
      minWidth: 115,
    },
    exportLabel: headerColumns.CUSTOMER_INVOICE,
    key: "invoice",
    searchBy: "invoice",
    resizable: true,
  },
  [headerColumns.CUSTOMER_PO]: {
    label: headerColumns.CUSTOMER_PO,
    id: 18,
    canBeSort: true,
    reference: "customerPO",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 110,
    },
    exportLabel: headerColumns.CUSTOMER_PO,
    key: "customerPO",
    searchBy: "customerPO",
    resizable: true,
  },
  [headerColumns.SALES_RESP]: {
    label: headerColumns.SALES_RESP,
    id: 19,
    canBeSort: true,
    reference: "salesRepresentative",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 100,
    },
    exportLabel: headerColumns.SALES_RESP,
    key: "salesRepresentative",
    searchBy: "salesRepresentative",
    resizable: true,
  },
  [headerColumns.UNIQUE_ITEMS]: {
    label: headerColumns.UNIQUE_ITEMS,
    id: 20,
    canBeSort: true,
    reference: "uniqueItems",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 18px",
      minWidth: 95,
    },
    exportLabel: headerColumns.UNIQUE_ITEMS,
    key: "uniqueItems",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.DISCOUNT]: {
    label: headerColumns.DISCOUNT,
    id: 21,
    canBeSort: true,
    reference: "discount",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 105,
    },
    exportLabel: headerColumns.DISCOUNT,
    key: "discount",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.SUB_TOTAL]: {
    label: headerColumns.SUB_TOTAL,
    id: 22,
    canBeSort: true,
    reference: "subtotal",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 105,
    },
    exportLabel: headerColumns.SUB_TOTAL,
    key: "subtotal",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.DEPOSIT]: {
    label: headerColumns.DEPOSIT,
    id: 23,
    canBeSort: true,
    reference: "deposit",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "9px 16px",
      minWidth: 100,
    },
    exportLabel: headerColumns.DEPOSIT,
    key: "deposit",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.BALANCE]: {
    label: headerColumns.BALANCE,
    id: 24,
    canBeSort: true,
    reference: "balance",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "9px 16px",
      minWidth: 100,
    },
    exportLabel: headerColumns.BALANCE,
    key: "balance",
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },

  [headerColumns.REFERENCE_NUMBER]: {
    label: headerColumns.REFERENCE_NUMBER,
    id: 26,
    canBeSort: true,
    reference: "referenceNumber",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 18px",
      minWidth: 115,
      marginRight: 8,
    },
    exportLabel: headerColumns.REFERENCE_NUMBER,
    key: "referenceNumber",
    searchBy: "referenceNumber",
    resizable: true,
  },
  [headerColumns.LAST_BLANK_COLUMN]: {
    label: " ",
    disabledDraggable: true,
    id: 27,
    canBeSort: false,
    reference: "extra",
    filter: false,
    selected: true,
    styles: {
      width: 16,
      textAlign: "center",
      padding: 0,
    },
    exportLabel: "",
    key: "",
    resizable: false,
    searchBy: false,
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 28,
    canBeSort: false,
    reference: "extra",
    filter: false,
    selected: true,
    styles: {
      width: "100%",
      textAlign: "center",
      padding: "2px 0px",
    },
    exportLabel: "",
    key: "",
    resizable: false,
    searchBy: false,
  },
};

export const tasksHeaderCells = {
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    selected: false,
    styles: {
      textAlign: "center",
      padding: "0px 16px",
      minWidth: 35,
      width: 35,
    },
    searchBy: false,
    resizable: false,
  },
  [headerColumns.TYPE]: {
    label: headerColumns.TYPE,
    displayLabel: "Type",
    id: 1,
    canBeSort: true,
    reference: "entityNumber",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px 0px 27px",
      minWidth: 205,
      width: 205,
    },
    searchBy: "entityNumber",
    resizable: true,
  },
  [headerColumns.TASK]: {
    label: headerColumns.TASK,
    id: 2,
    canBeSort: true,
    reference: "description",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 100,
      width: 260,
    },
    searchBy: "description",
    resizable: true,
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 3,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 110,
      width: 180,
    },
    searchBy: "customerName",
    resizable: true,
  },
  [headerColumns.VENDOR]: {
    label: headerColumns.VENDOR,
    id: 4,
    canBeSort: true,
    reference: "factoryName",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 100,
      width: 180,
    },
    searchBy: "factoryName",
    resizable: true,
  },
  [headerColumns.ASSIGNED_TO]: {
    label: headerColumns.ASSIGNED_TO,
    id: 5,
    canBeSort: true,
    reference: "assignedToName",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 110,
      width: 180,
    },
    searchBy: "assignedToName",
    resizable: true,
  },
  [headerColumns.START_DATE]: {
    label: headerColumns.START_DATE,
    id: 6,
    canBeSort: true,
    reference: "startDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 5px 0px 30px",
      minWidth: 90,
      width: 120,
    },
    searchBy: false,
    resizable: true,
  },
  [headerColumns.DUE_DATE]: {
    label: headerColumns.DUE_DATE,
    id: 7,
    canBeSort: true,
    reference: "finishDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "center",
      padding: "0px 16px",
      minWidth: 90,
      width: 120,
    },
    searchBy: false,
    resizable: true,
  },
  [headerColumns.ACTION]: {
    label: headerColumns.ACTION,
    id: 8,
    canBeSort: true,
    reference: "complete",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 150,
      width: 150,
    },
    searchBy: false,
    resizable: true,
  },
  [headerColumns.LAST_BLANK_COLUMN]: {
    label: "",
    disabledDraggable: true,
    id: 9,
    canBeSort: false,
    reference: "",
    filter: false,
    selected: false,
    styles: {
      width: 16,
      textAlign: "left",
      padding: "0px 0px",
    },
    searchBy: false,
    resizable: false,
  },
  [headerColumns.EXTRA]: {
    label: "",
    disabledDraggable: true,
    id: 10,
    canBeSort: false,
    reference: "",
    filter: false,
    selected: false,
    styles: {
      width: "100%",
      textAlign: "left",
      padding: "0px 0px",
    },
    searchBy: false,
    resizable: false,
  },
};

export const SOPhaseHeadCells = {
  [headerColumns.EXPAND]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    filter: false,
    styles: {
      width: 35,
      padding: "9px",
      zIndex: 3,
    },
    resizable: false,
  },
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    filter: false,
    styles: {
      width: 35,
      padding: "9px",
      zIndex: 3,
    },
    resizable: false,
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    filter: false,
    styles: {
      width: 35,
      padding: "9px",
      zIndex: 3,
    },
    resizable: false,
  },
  [headerColumns.SALES_ORDER]: {
    label: headerColumns.SALES_ORDER,
    id: 3,
    canBeSort: true,
    reference: "number",
    filter: true,
    autoSize: 100,
    styles: {
      width: 120,
      padding: "0px 41px",
      textAlign: "left",
      zIndex: 3,
    },
    arrowStyles: {
      left: 118,
    },
    resizable: false,
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 4,
    autoSize: 60,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    styles: {
      width: 160,
      padding: "9px 16px",
      zIndex: 3,
    },
    arrowStyles: {
      left: 82,
    },
    resizable: false,
  },
  [headerColumns.TOTAL]: {
    label: headerColumns.TOTAL,
    id: 5,
    canBeSort: true,
    reference: "total",
    filter: true,
    styles: {
      width: 150,
      padding: "0px 33px 0px 20px",
      textAlign: "right",
      zIndex: 3,
    },
    [generalPermissions.SALES_ORDER_AMOUNT]: true,
    arrowStyles: {
      left: 122,
    },
    resizable: false,
  },
  [headerColumns.SO_SHIP_DATE]: {
    label: headerColumns.SO_SHIP_DATE,
    id: 6,
    canBeSort: true,
    reference: "shipDate",
    filter: true,
    styles: {
      width: 140,
      padding: "0px 36px 0px 10px",
      textAlign: "right",
      zIndex: 3,
    },
    arrowStyles: {
      left: 109,
    },
    resizable: false,
  },
  [headerColumns.POS]: {
    label: "PO/Purchase Quote",
    displayLabel: (
      <span>
        PO/
        <br />
        Purchase Quote
      </span>
    ),
    id: 7,
    canBeSort: true,
    reference: "quantityPOs",
    filter: true,
    styles: {
      width: 165,
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
    arrowStyles: {
      top: 20,
      left: 121,
    },
    resizable: false,
  },
  [headerColumns.SHIPMENTS]: {
    label: headerColumns.SHIPMENT,
    id: 8,
    canBeSort: true,
    reference: "quantityShipments",
    filter: true,
    styles: {
      width: 100,
      padding: "0px 17px 0px 5px",
      textAlign: "right",
      zIndex: 3,
    },
    arrowStyles: {
      left: 88,
    },
    resizable: false,
  },
  [headerColumns.PROPOSAL_QUOTE]: {
    label: "",
    disabledDraggable: true,
    id: 9,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
    resizable: false,
  },
  [headerColumns.PRE_PRODUCTION]: {
    label: "",
    disabledDraggable: true,
    id: 10,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
    resizable: false,
  },
  [headerColumns.PRODUCTION]: {
    label: "",
    disabledDraggable: true,
    id: 11,
    filter: false,
    styles: {
      width: 55,
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
    resizable: false,
  },
  [headerColumns.BOOKING_TRANSIT]: {
    label: "",
    disabledDraggable: true,
    id: 12,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
    resizable: false,
  },
  [headerColumns.PAYMENT_BILLING]: {
    label: "",
    disabledDraggable: true,
    id: 13,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
    resizable: false,
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 14,
    filter: false,
    styles: {
      width: "100%",
      padding: "9px 16px",
      textAlign: "left",
      zIndex: 3,
    },
    resizable: false,
  },
};

export const POPhaseHeaderCells = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    styles: {
      width: 35,
      padding: "9px 16px",
    },
    resizable: false,
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    canBeSort: false,
    filter: false,
    styles: {
      width: 35,
      padding: "9px 16px",
    },
    resizable: false,
  },
  [headerColumns.PO]: {
    label: headerColumns.PO,
    id: 2,
    canBeSort: true,
    reference: "number",
    filter: false,
    selected: true,
    autoSize: 80,
    styles: {
      width: 120,
      textAlign: "left",
      paddingLeft: 20,
      padding: "0px 0px 0px 30px",
    },
    arrowStyles: {
      left: 63,
    },
    resizable: false,
  },
  [headerColumns.VENDOR]: {
    label: headerColumns.VENDOR,
    id: 3,
    canBeSort: true,
    reference: "factoryName",
    filter: true,
    selected: true,
    autoSize: 60,
    styles: {
      width: 100,
      padding: "9px 16px",
    },
    arrowStyles: {
      left: 66,
    },
    resizable: false,
  },
  [headerColumns.PO_VALUE]: {
    label: headerColumns.PO_VALUE,
    id: 4,
    canBeSort: true,
    reference: "total",
    filter: true,
    selected: true,
    styles: {
      width: 150,
      textAlign: "right",
      padding: "9px 16px 10px 32px",
    },
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    arrowStyles: {
      left: 139,
    },
    resizable: false,
  },
  [headerColumns.ORDER_READY_DATE]: {
    label: headerColumns.ORDER_READY_DATE,
    id: 5,
    canBeSort: true,
    reference: "shipDate",
    filter: true,
    selected: true,
    styles: {
      width: 140,
      padding: "0px 23px",
    },
    arrowStyles: {
      top: 20,
      left: 105,
    },
    resizable: false,
  },

  [headerColumns.PROPOSAL_QUOTE]: {
    label: headerColumns.PROPOSAL_QUOTE,
    id: 6,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
    resizable: false,
  },
  [headerColumns.PRE_PRODUCTION]: {
    label: headerColumns.PRE_PRODUCTION,
    id: 7,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
    resizable: false,
  },
  [headerColumns.PRODUCTION]: {
    label: headerColumns.PRODUCTION,
    id: 8,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
    resizable: false,
  },
  [headerColumns.BOOKING_TRANSIT]: {
    label: headerColumns.BOOKING_TRANSIT,
    id: 9,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
    resizable: false,
  },
  [headerColumns.PAYMENT_BILLING]: {
    label: headerColumns.PAYMENT_BILLING,
    id: 10,
    filter: true,
    selected: true,
    disabledDraggable: true,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
    resizable: false,
  },
  [headerColumns.EXTRA]: {
    label: "",
    disabledDraggable: true,
    id: 11,
    filter: false,
    styles: {
      width: "auto",
      padding: "9px 16px",
    },
    resizable: false,
  },
};

export const groupBySOHeadCellsPOs = {
  [headerColumns.EXPAND]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    styles: {
      zIndex: 2,
      width: 30,
      height: 40,
      padding: "7px 4px 0px 4px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    styles: {
      zIndex: 2,
      width: 35,
      height: 40,
      padding: "7px 10px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    styles: {
      zIndex: 2,
      width: 35,
      height: 40,
      padding: "7px 10px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PO]: {
    label: headerColumns.PO,
    id: 3,
    canBeSort: true,
    reference: "number",
    autoSize: 80,
    styles: {
      width: 130,
      zIndex: 2,
      textAlign: "left",
      height: 40,
      padding: "7px 25px 0px 43px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 22,
      left: 65,
    },
  },
  [headerColumns.VENDOR]: {
    label: headerColumns.VENDOR,
    id: 4,
    canBeSort: true,
    reference: "factoryName",
    autoSize: 60,
    styles: {
      width: 180,
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 19px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 22,
      left: 68,
    },
  },
  [headerColumns.PO_VALUE]: {
    label: headerColumns.PO_VALUE,
    id: 5,
    canBeSort: true,
    reference: "total",
    styles: {
      width: 150,
      zIndex: 2,
      textAlign: "right",
      height: 40,
      padding: "7px 28px 0px 15px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    [generalPermissions.PURCHASE_ORDER_AMOUNT]: true,
    arrowStyles: {
      top: 22,
      left: 126,
    },
  },
  [headerColumns.ORDER_READY_DATE]: {
    label: headerColumns.ORDER_READY_DATE,
    id: 6,
    canBeSort: true,
    reference: "shipDate",
    styles: {
      width: 140,
      zIndex: 2,
      height: 40,
      padding: "7px 5px 0px 5px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 22,
      left: 119,
    },
  },
  [headerColumns.POS]: {
    label: " ",
    disabledDraggable: true,
    id: 8,
    styles: {
      width: 170,
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.SHIPMENTS]: {
    label: " ",
    disabledDraggable: true,
    id: 9,
    styles: {
      width: 95,
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PROPOSAL_QUOTE]: {
    label: headerColumns.PROPOSAL_QUOTE,
    id: 10,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PRE_PRODUCTION]: {
    label: headerColumns.PRE_PRODUCTION,
    id: 11,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PRODUCTION]: {
    label: headerColumns.PRODUCTION,
    id: 12,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.BOOKING_TRANSIT]: {
    label: headerColumns.BOOKING_TRANSIT,
    id: 13,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PAYMENT_BILLING]: {
    label: headerColumns.PAYMENT_BILLING,
    id: 14,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 15,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 40,
      padding: "7px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
};

export const groupBySOHeadCellsShipments = {
  [headerColumns.EXPAND]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    styles: {
      zIndex: 2,
      width: 30,
      height: 31,
      padding: "0px 4px 0px 4px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    styles: {
      zIndex: 2,
      width: 35,
      height: 31,
      padding: "0px 10px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    styles: {
      zIndex: 2,
      width: 35,
      height: 31,
      padding: "0px 10px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.SHIPMENT]: {
    label: headerColumns.SHIPMENT,
    id: 3,
    canBeSort: true,
    reference: "number",
    autoSize: 80,
    styles: {
      width: 130,
      zIndex: 2,
      textAlign: "left",
      height: 31,
      padding: "0px 16px 0px 43px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 20,
      left: 107,
    },
  },
  [headerColumns.FINAL_DESTINATION]: {
    label: headerColumns.FINAL_DESTINATION,
    id: 4,
    canBeSort: true,
    reference: "finalDestination",
    autoSize: 60,
    styles: {
      width: 160,
      zIndex: 2,
      height: 31,
      padding: "4px 16px 4px 18px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    arrowStyles: {
      top: 20,
      left: 126,
    },
  },
  [headerColumns.SHIPMENT_VALUE]: {
    label: headerColumns.SHIPMENT_VALUE,
    id: 5,
    canBeSort: true,
    reference: "total",
    styles: {
      width: 150,
      zIndex: 2,
      textAlign: "left",
      height: 31,
      padding: "0px 16px 0px 25px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
    [generalPermissions.SHIPMENT_AMOUNT]: true,
    arrowStyles: {
      top: 20,
      left: 127,
    },
  },
  [headerColumns.SHIP_DATE]: {
    label: headerColumns.SHIP_DATE,
    id: 6,
    canBeSort: true,
    reference: "shipDate",
    styles: {
      width: 140,
      zIndex: 2,
      height: 31,
      padding: "0px 32px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      textAlign: "right",
      background: "transparent",
    },
    arrowStyles: {
      top: 20,
      left: 111,
    },
  },
  [headerColumns.DELIVERY_DATE]: {
    label: headerColumns.DELIVERY_DATE,
    canBeSort: true,
    reference: "deliveryDate",
    id: 7,
    styles: {
      width: 170,
      zIndex: 2,
      height: 31,
      padding: "0px 32px 0px 10px",
      borderBottom: "1px solid #87A3C2",
      textAlign: "right",
      background: "transparent",
    },
    arrowStyles: {
      top: 20,
      left: 111,
    },
  },
  [headerColumns.POS]: {
    label: " ",
    disabledDraggable: true,
    id: 8,
    styles: {
      width: 95,
      zIndex: 2,
      height: 40,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PROPOSAL_QUOTE]: {
    label: headerColumns.PROPOSAL_QUOTE,
    id: 9,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PRE_PRODUCTION]: {
    label: headerColumns.PRE_PRODUCTION,
    id: 10,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PRODUCTION]: {
    label: headerColumns.PRODUCTION,
    id: 11,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.BOOKING_TRANSIT]: {
    label: headerColumns.BOOKING_TRANSIT,
    id: 12,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.PAYMENT_BILLING]: {
    label: headerColumns.PAYMENT_BILLING,
    id: 13,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 14,
    styles: {
      width: "auto",
      zIndex: 2,
      height: 31,
      padding: "0px 16px 0px 16px",
      borderBottom: "1px solid #87A3C2",
      background: "transparent",
    },
  },
};

export const shipmentsHeaderCells = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    canBeSort: false,
    filter: false,
    styles: {
      textAlign: "center",
      padding: 9,
      minWidth: 40,
    },
    exportLabel: "Star",
    key: "favorite",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.DIAMOND]: {
    label: " ",
    disabledDraggable: true,
    id: 1,
    canBeSort: false,
    filter: false,
    styles: {
      textAlign: "center",
      padding: 0,
      minWidth: 50,
    },
    exportLabel: "Status",
    key: "status",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.PROGRESS]: {
    label: " ",
    disabledDraggable: true,
    id: 2,
    canBeSort: true,
    reference: "progress",
    filter: false,
    styles: {
      textAlign: "center",
      padding: "9px 4px",
      minWidth: 35,
    },
    exportLabel: "Completion",
    key: "progress",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.MENTIONS]: {
    label: "userMentionCount",
    id: 3,
    canBeSort: true,
    reference: "totalMentions",
    item: "AVATAR",
    filter: false,
    styles: {
      textAlign: "center",
      padding: "9px 0px 9px 20px",
      minWidth: 65,
    },
    exportLabel: "Mentions",
    key: "mentions",
    searchBy: false,
    resizable: false,
    disabledDraggable: true,
  },
  [headerColumns.SHIPMENT]: {
    label: headerColumns.SHIPMENT,
    id: 4,
    canBeSort: true,
    reference: "number",
    filter: false,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 24px",
      minWidth: 120,
    },
    exportLabel: "Shipment",
    key: "number",
    searchBy: "number",
    resizable: true,
  },
  [headerColumns.SALES_ORDER]: {
    label: "Quote/Sales Order",
    displayLabel: (
      <span>
        Quote/
        <br />
        Sales Order
      </span>
    ),
    id: 5,
    canBeSort: false,
    reference: "salesOrder",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 24px",
      minWidth: 205,
    },
    exportLabel: "Sales Order",
    key: "salesOrder",
    searchBy: "salesOrder",
    resizable: true,
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 6,
    canBeSort: true,
    reference: "customerName",
    filter: true,
    selected: true,
    autoSize: 40,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 110,
    },
    exportLabel: "Customer",
    key: "customerName",
    searchBy: "customerName",
    resizable: true,
  },
  [headerColumns.PO]: {
    label: `${headerColumns.PO} - ${headerColumns.ORDER_READY_DATE} - ${headerColumns.VENDOR}`,
    id: 7,
    canBeSort: false,
    reference: "PO",
    filter: true,
    selected: true,
    multipleLables: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      letterSpacing: "0.2px",
      minWidth: 320,
    },
    exportLabel: "PO",
    key: "purchaseOrder",
    searchBy: headerColumns.PO_ORDER_READY_DATE_VENDOR,
    resizable: true,
  },
  [headerColumns.CREATION_DATE]: {
    label: headerColumns.CREATION_DATE,
    id: 8,
    canBeSort: true,
    reference: "creationDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "center",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: headerColumns.CREATION_DATE,
    key: "creationDate",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.SHIP_DATE]: {
    label: headerColumns.SHIP_DATE,
    id: 9,
    canBeSort: true,
    reference: "shipDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Ship Date",
    key: "shipDate",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.DELIVERY_DATE]: {
    label: headerColumns.DELIVERY_DATE,
    id: 10,
    canBeSort: true,
    reference: "deliveryDate",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Delivery Date",
    key: "deliveryDate",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.CBM]: {
    label: headerColumns.CBM,
    id: 11,
    canBeSort: true,
    reference: "CBM",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 100,
    },
    exportLabel: "CBM",
    key: "CBM",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.BOOKING]: {
    label: headerColumns.BOOKING,
    id: 12,
    canBeSort: true,
    reference: "booking",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Booking",
    key: "booking",
    searchBy: "booking",
    resizable: true,
  },
  [headerColumns.BOL]: {
    label: headerColumns.BOL,
    id: 13,
    canBeSort: true,
    reference: "billOfLading",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "BOL",
    key: "billOfLading",
    searchBy: "billOfLading",
    resizable: true,
  },
  [headerColumns.CONTAINER]: {
    label: headerColumns.CONTAINER,
    id: 14,
    canBeSort: true,
    reference: "container",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Container",
    key: "container",
    searchBy: "container",
    resizable: true,
  },
  [headerColumns.SHIPPING_TERMS]: {
    label: headerColumns.SHIPPING_TERMS,
    id: 15,
    canBeSort: true,
    reference: "shippingTerms",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Shipping Terms",
    key: "shippingTerms",
    searchBy: "shippingTerms",
    resizable: true,
  },
  [headerColumns.TOTAL_VALUE]: {
    label: headerColumns.TOTAL_VALUE,
    id: 16,
    canBeSort: true,
    reference: "total",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Total Value",
    key: "total",
    [generalPermissions.SHIPMENT_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.POTENTIAL_LATE_FEE]: {
    label: headerColumns.POTENTIAL_LATE_FEE,
    id: 17,
    canBeSort: true,
    reference: "potentialLateFee",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Potential Late Fee",
    key: "potentialLateFee",
    [generalPermissions.SHIPMENT_AMOUNT]: true,
    searchBy: false,
    resizable: true,
  },
  [headerColumns.CUSTOMER_PO]: {
    label: headerColumns.CUSTOMER_PO,
    id: 18,
    canBeSort: true,
    reference: "customerPO",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Customer PO",
    key: "customerPO",
    searchBy: "customerPO",
    resizable: true,
  },
  [headerColumns.LOADING_PORT]: {
    label: headerColumns.LOADING_PORT,
    id: 19,
    canBeSort: true,
    reference: "loadingPort",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 150,
    },
    exportLabel: "Loading Port",
    key: "loadingPort",
    searchBy: "loadingPort",
    resizable: true,
  },
  [headerColumns.UNLOADING_PORT]: {
    label: headerColumns.UNLOADING_PORT,
    id: 20,
    canBeSort: true,
    reference: "unloadingPort",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 150,
    },
    exportLabel: "Unloading Port",
    key: "unloadingPort",
    searchBy: "unloadingPort",
    resizable: true,
  },
  [headerColumns.FINAL_DESTINATION]: {
    label: headerColumns.FINAL_DESTINATION,
    id: 21,
    canBeSort: true,
    reference: "finalDestination",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 150,
    },
    exportLabel: "Final Destination",
    key: "finalDestination",
    searchBy: "finalDestination",
    resizable: true,
  },
  [headerColumns.UNIQUE_ITEMS]: {
    label: headerColumns.UNIQUE_ITEMS,
    id: 22,
    canBeSort: true,
    reference: "uniqueItems",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 95,
    },
    exportLabel: "Unique items",
    key: "uniqueItems",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.FREIGHT_FORWARDER]: {
    label: headerColumns.FREIGHT_FORWARDER,
    id: 23,
    canBeSort: true,
    reference: "freightForwarder",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Freight Forwarder",
    key: "freightForwarder",
    searchBy: "freightForwarder",
    resizable: true,
  },
  [headerColumns.SALES_INVOICE]: {
    label: headerColumns.CUSTOMER_INVOICE,
    id: 24,
    canBeSort: true,
    reference: "invoice",
    filter: true,
    selected: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Invoice",
    key: "invoice",
    searchBy: "invoice",
    resizable: true,
  },
  [headerColumns.TOTAL_WEIGHT]: {
    label: headerColumns.TOTAL_WEIGHT,
    id: 25,
    canBeSort: true,
    reference: "totalWeight",
    filter: true,
    selected: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Total Weight",
    key: "totalWeight",
    searchBy: false,
    resizable: true,
  },
  [headerColumns.LAST_BLANK_COLUMN]: {
    label: " ",
    disabledDraggable: true,
    id: 26,
    canBeSort: false,
    reference: "extra",
    filter: false,
    selected: true,
    styles: {
      width: 16,
      padding: 0,
    },
    exportLabel: "Shipment - Labels",
    key: "shipmentLabels",
    searchBy: false,
    resizable: false,
  },
  [headerColumns.EXTRA]: {
    label: " ",
    disabledDraggable: true,
    id: 27,
    canBeSort: false,
    reference: "extra",
    filter: false,
    selected: true,
    styles: {
      width: "100%",
      textAlign: "center",
      padding: "0px 0px",
      minWidth: "100%",
    },
    exportLabel: "",
    key: "",
    searchBy: false,
    resizable: false,
  },
};

export const ExpensesHeadCells = {
  [headerColumns.FAVORITE]: {
    label: " ",
    disabledDraggable: true,
    id: 0,
    filter: false,
    styles: {
      textAlign: "left",
      padding: "0px 8px",
      minWidth: 35,
    },
    resizable: false,
  },
  [headerColumns.DOCUMENT_DATE]: {
    label: headerColumns.DOCUMENT_DATE,
    id: 1,
    canBeSort: true,
    filter: false,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 115,
    },
    exportLabel: "Document date",
    key: "documentDate",
    reference: "date",
    resizable: true,
  },
  [headerColumns.PAID_TO]: {
    label: headerColumns.SUPPLIER,
    id: 2,
    canBeSort: true,
    filter: false,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 210,
    },
    exportLabel: "Supplier",
    key: "paidTo",
    reference: "paidTo",
    resizable: true,
  },
  [headerColumns.ITEM_TYPE]: {
    label: headerColumns.ITEM_TYPE,
    id: 3,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Item Type",
    key: "itemType",
    reference: "type",
    resizable: true,
  },
  [headerColumns.ORDER_NUMBER]: {
    label: headerColumns.ORDER_NUMBER,
    id: 4,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 185,
    },
    exportLabel: "Number",
    key: "orderNumber",
    reference: "orderNumber",
    resizable: true,
  },
  [headerColumns.GL_ACCOUNT]: {
    label: headerColumns.GL_ACCOUNT,
    id: 5,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 150,
    },
    exportLabel: "G/L Account",
    key: "GLAccountName",
    reference: "currentGLAccountName",
    resizable: true,
  },
  [headerColumns.DESCRIPTION]: {
    label: headerColumns.DESCRIPTION,
    id: 6,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 150,
    },
    exportLabel: "Description",
    key: "descriptionOfCharges",
    reference: "currentDescription",
    resizable: true,
  },
  [headerColumns.NOTES]: {
    label: headerColumns.NOTES,
    id: 7,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Notes",
    key: "notes",
    reference: "currentNotes",
    resizable: true,
  },
  [headerColumns.FORECAST]: {
    label: headerColumns.FORECAST,
    id: 8,
    canBeSort: false,
    filter: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Forecast",
    key: "forecast",
    reference: "forecast",
    resizable: true,
  },
  [headerColumns.ACTUAL]: {
    label: headerColumns.ACTUAL,
    id: 9,
    canBeSort: false,
    filter: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Actual",
    key: "actual",
    reference: "actual",
    resizable: true,
  },
  [headerColumns.DELTA]: {
    label: headerColumns.DELTA,
    id: 10,
    canBeSort: false,
    filter: true,
    styles: {
      textAlign: "right",
      padding: "0px 16px",
      minWidth: 110,
    },
    exportLabel: "Delta",
    key: "delta",
    reference: "delta",
    resizable: true,
  },
  [headerColumns.CUSTOMER]: {
    label: headerColumns.CUSTOMER,
    id: 11,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Customer",
    key: "customer",
    reference: "currentCustomerName",
    resizable: true,
  },
  [headerColumns.CUSTOMER_PO]: {
    label: headerColumns.CUSTOMER_PO,
    id: 12,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 110,
    },
    exportLabel: "Customer PO",
    key: "customerPO",
    reference: "currentCustomerPO",
    resizable: true,
  },
  [headerColumns.REFERENCE_NUMBER]: {
    label: "Reference No.",
    id: 13,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 120,
    },
    exportLabel: "Reference number",
    key: "referenceNumber",
    reference: "currentReferenceNumber",
    resizable: true,
  },
  [headerColumns.CREATION_DATE]: {
    label: headerColumns.CREATION_DATE,
    id: 14,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 100,
    },
    exportLabel: "Creation date",
    key: "creationDate",
    reference: "creationDate",
    resizable: true,
  },
  [headerColumns.EXPORTED_DATE]: {
    label: headerColumns.EXPORTED_DATE,
    id: 15,
    canBeSort: true,
    filter: true,
    styles: {
      textAlign: "left",
      padding: "0px 16px",
      minWidth: 105,
    },
    exportLabel: "Exported date",
    key: "exportedDate",
    reference: "exportedDate",
    unmovable: false,
    resizable: true,
  },
  [headerColumns.ED_TOOL]: {
    label: "",
    disabledDraggable: true,
    id: 16,
    canBeSort: false,
    filter: false,
    styles: {
      textAlign: "center",
      padding: "9px 12px",
      minWidth: 110,
    },
    exportLabel: "",
    key: "edTool",
    resizable: false,
  },
};
