import { TableRow, styled } from "@mui/material";
import AppConfig from "../../constants/AppConfig";
import { colors } from "../../assets/jss/variables";

export const mainRowClasses = {
  POTaskRow: "POTaskRow",
  TaskTableRow: "TaskTableRow",
  SOTableRow: "SOTableRow",
  POTableRow: "POTableRow",
  ShipmentTableRow: "SHTableRow",
  ExpensesTableRow: "ExpensesTableRow",
  SOGroupTableRow: "SOGroupTableRow",
  POGroupTableRow: "POGroupTableRow",
  SubTableRow: "SubTableRow",
  hoverEffect: "hoverEffect",
  SearchRow: "POTaskRow",
};

export const MainRowStyled = styled(TableRow)(() => ({
  "& .customized-tooltip": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    "& .navLink": {
      color: colors.primaryDark,
      textDecoration: "underline",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      "& span": {
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  [`&.${mainRowClasses.POTaskRow}`]: {
    position: "relative",
    color: colors.primaryDark,
    "& .diamond": {
      padding: "10px 6px",
      height: 40,
      textAlign: "center",
    },
    "& .description": {
      padding: "4px 16px",
      height: 40,
      "& .description-wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        "& .shipment-badge-container": {
          width: 51,
        },
        "& .task-description-container": {
          width: "fit-content",
          position: "relative",
          overflow: "hidden",
        },
      },
    },
    "& .ellipsisText": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "100%",
      color: colors.primaryDark,
      textDecoration: "underline",
      display: "flex",
      width: "100%",
    },
    "& .assignedToName": {
      padding: "4px 16px",
      height: 40,
    },
    "& .date": {
      padding: "4px 16px",
      height: 40,
      textAlign: "center",
    },
    "& .checkBoxCell": {
      padding: "4px 16px",
      height: 40,
      "& .checkBox": {
        height: "32px",
        paddingInline: 12,
        width: "fit-content",
        borderRadius: "26px",
        display: "flex",
        alignItems: "center",
        "& .checkBoxMarkDone": {
          display: "none",
          color: "white",
          fontSize: 14,
          fontWeight: 500,
          marginLeft: 7,
        },
        "&:hover": {
          backgroundColor: "#92CA68",
          "& .checkBoxMarkDone": {
            display: "inline",
          },
        },
        "& input": {
          boxSizing: "border-box",
          height: "17px",
          width: "17px",
          borderRadius: "3px",
          backgroundColor: "#FFF",
        },
      },
    },
    "&:hover": {
      backgroundColor: "red",
      outline: `3px solid ${AppConfig.themeColors.dividerColor}`,
      outlineOffset: -3,
      color: "#25486d",
      "& .mediumInverted": {
        border: `2px solid #CB5EDE`,
      },
      "& .secondary": {
        border: `2px solid #CB5EDE`,
      },
    },
  },
  [`&.${mainRowClasses.TaskTableRow}`]: {
    position: "relative",
    color: colors.primaryDark,
    "& .diamond": {
      padding: "10px 6px",
      textAlign: "center",
      "& span": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },

    "& .SONumber": {
      padding: "4px 16px 4px 4px",
      textAlign: "right",
      "& .buttonContainerThreePanel": {
        opacity: 0,
      },
      "&:hover": {
        "& .buttonContainerThreePanel": {
          opacity: 1,
        },
      },
    },
    "& .PONumber": {
      padding: "4px 16px 4px 4px",
      textAlign: "right",
      "& .buttonContainerThreePanel": {
        opacity: 0,
      },
      "&:hover": {
        "& .buttonContainerThreePanel": {
          opacity: 1,
        },
      },
    },
    "& .description": {
      padding: "4px 16px",
      "& .description-wrapper": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        "& .shipment-badge-container": {
          width: 51,
        },
        "& .task-description-container": {
          width: "fit-content",
          position: "relative",
          overflow: "hidden",
        },
      },
    },
    "& .customerName": {
      padding: "4px 16px",
    },
    "& .factoryName": {
      padding: "4px 16px",
    },
    "& .ellipsisText": {
      textOverflow: "ellipsis",
      overflow: "hidden",
      maxWidth: "100%",
      textDecoration: "underline",
      display: "flex",
      width: "100%",
    },
    "& .assignedToName": {
      padding: "4px 16px",
    },
    "& .date": {
      padding: "4px 16px",
      textAlign: "center",
    },
    "& .checkBoxCell": {
      padding: "4px 16px",
      "& .checkBox": {
        height: "32px",
        paddingInline: 12,
        width: "fit-content",
        borderRadius: "26px",
        display: "flex",
        alignItems: "center",
        "& .checkBoxMarkDone": {
          display: "none",
          color: "white",
          fontSize: 14,
          fontWeight: 500,
          marginLeft: 7,
        },
        "&:hover": {
          backgroundColor: "#92CA68",
          "& .checkBoxMarkDone": {
            display: "inline",
          },
        },
        "& input": {
          boxSizing: "border-box",
          height: "17px",
          width: "17px",
          borderRadius: "3px",
          backgroundColor: "#FFF",
        },
      },
    },
    "&:hover": {
      outline: `3px solid ${AppConfig.themeColors.dividerColor}`,
      outlineOffset: -3,
      color: "#25486d",
      "& .mediumInverted": {
        border: `2px solid #CB5EDE`,
      },
      "& .secondary": {
        border: `2px solid #CB5EDE`,
      },
      "& td": {
        borderBottom: "0px",
      },
    },
  },
  [`&.${mainRowClasses.SOTableRow}`]: {
    transition: "height 0.3s ease-in-out",
    "& td": {
      paddingBlock: "15px 0px",
      paddingInline: 0,
      height: 50,
    },
    "& .favorite": {
      paddingBlock: "12px 0px",
      paddingInline: 9,
      verticalAlign: "top",
    },
    "& .diamond": {
      textAlign: "center",
      verticalAlign: "top",
    },
    "& .circularProgress": {
      verticalAlign: "top",
      textAlign: "center",
    },
    "& .mention": {
      verticalAlign: "top",
    },
    "& .number": {
      paddingBlock: "14px 0px",
      paddingInline: "0px 16px",
      textAlign: "left",
      verticalAlign: "top",
      "& .buttonContainerThreePanel": {
        opacity: 0,
      },
      "&:hover": {
        "& .buttonContainerThreePanel": {
          opacity: 1,
        },
      },
    },
    "& .navLink": {
      display: "flex",
      "& .span-description": {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .POs": {
      paddingBlock: "14px 10px",
      paddingInline: "17px 5px",
      textAlign: "left",
      verticalAlign: "top",
      "& .poVoided": {
        height: 14,
        width: 27,
        padding: 4,
        background: "#FF5F58",
        color: "white",
        fontSize: 8,
        fontWeight: 700,
        lineHeight: "9px",
        marginLeft: 9,
        marginBottom: 2,
      },
    },
    "& .shipmentNumber": {
      paddingBlock: "14px 10px",
      paddingInline: "1px 5px",
      textAlign: "left",
      verticalAlign: "top",
    },
    "& .customerName": {
      paddingInline: "17px 5px",
      textAlign: "left",
      verticalAlign: "top",
    },
    "& .date": {
      paddingInline: "5px 24px",
      textAlign: "right",
      verticalAlign: "top",
    },
    "& .deposit": {
      textAlign: "right",
      paddingInline: "5px 20px",
      verticalAlign: "top",
    },

    "& .CBM": {
      textAlign: "right",
      paddingInline: "5px 17px",
      verticalAlign: "top",
    },

    "& .finalDestination": {
      textAlign: "left",
      paddingInline: "17px 5px",
      verticalAlign: "top",
    },
    "& .discountPercentage": {
      textAlign: "right",
      paddingInline: "5px 32px",
      verticalAlign: "top",
    },
  },
  [`&.${mainRowClasses.POTableRow}`]: {
    "& td": {
      paddingBlock: "15px 0px",
      paddingInline: 0,
      height: 50,
    },
    "& .favorite": {
      paddingBlock: "12px 0px",
      paddingInline: 9,
      verticalAlign: "top",
      height: 48,
    },
    "& .diamond": {
      textAlign: "center",
      verticalAlign: "top",
    },
    "& .circularProgress": {
      verticalAlign: "top",
      textAlign: "center",
    },
    "& .mention": {
      verticalAlign: "top",
      textAlign: "center",
    },
    "& .number": {
      padding: "14px 16px 11px 0px",
      textAlign: "left",
      verticalAlign: "top",
      "& .buttonContainerThreePanel": {
        opacity: 0,
      },
      "&:hover": {
        "& .buttonContainerThreePanel": {
          opacity: 1,
        },
      },
      "& .SOs-container": {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        position: "relative",
        "& .iconContainerDisplay": {
          display: "flex",
          justifyContent: "left",
          paddingLeft: 24,
          transition: "opacity 0.8s ease-in-out",
          top: 0,
          position: "absolute",
        },
        "& .soListanimated": {
          gap: 5,
          display: "flex",
          flexDirection: "column",
          transition: "opacity 0.8s ease-in-out",
        },
      },
    },
    "& .soNumber": {
      paddingBlock: "14px 10px",
      paddingInline: "0px 16px",
      textAlign: "left",
      verticalAlign: "top",
    },

    "& .shipmentNumber": {
      paddingBlock: "14px 10px",
      paddingInline: "0px 9px",
      textAlign: "left",
      verticalAlign: "top",
    },
    "& .customerName": {
      paddingInline: "16px 5px",
      textAlign: "left",
      verticalAlign: "top",
    },
    "& .date": {
      paddingInline: "5px 24px",
      textAlign: "right",
      verticalAlign: "top",
    },

    "& .deposit": {
      textAlign: "right",
      paddingInline: "5px 20px",
      verticalAlign: "top",
    },

    "& .CBM": {
      textAlign: "right",
      paddingInline: "5px 17px",
      verticalAlign: "top",
    },
    "& .shippingTerms": {
      textAlign: "left",
      paddingInline: "9px 5px",
      verticalAlign: "top",
    },
    "& .vendorInvoice": {
      textAlign: "left",
      padding: "9px 13px 10px 15px",
      verticalAlign: "top",
    },

    "& .discountPercentage": {
      textAlign: "right",
      paddingInline: "5px 32px",
      verticalAlign: "top",
    },
    "& .navLink": {
      display: "flex",
      "& .span-description": {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  },
  [`&.${mainRowClasses.ShipmentTableRow}`]: {
    "& td": {
      paddingBlock: "15px 0px",
      paddingInline: 0,
      height: 50,
    },
    "& .navLink": {
      display: "flex",
      "& .span-description": {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    "& .favorite": {
      paddingBlock: "12px 0px",
      verticalAlign: "top",
      height: 48,
    },
    "& .diamond": {
      textAlign: "center",
      verticalAlign: "top",
    },
    "& .circularProgress": {
      verticalAlign: "top",
      textAlign: "center",
    },
    "& .mention": {
      verticalAlign: "top",
      textAlign: "center",
    },
    "& .poNumber": {
      paddingBlock: "14px 12px",
      textAlign: "right",
      verticalAlign: "top",
      "& .poDetailItems": {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        transition: "height 0.8s ease-in-out",
        "& .iconAnimated": {
          display: "flex",
          color: colors.primaryDark,
          justifyContent: "left",
          transition: "opacity 0.8s ease-in-out",
          top: 0,
          position: "absolute",
        },
        "& .poListanimated": {
          gap: 5,
          display: "flex",
          flexDirection: "column",
          transition: "opacity 0.8s ease-in-out",
          "& .orderList": {
            "& .buttonContainerThreePanel": {
              opacity: 0,
            },
            "&:hover": {
              "& .buttonContainerThreePanel": {
                opacity: 1,
              },
            },
          },
        },
      },
      "& .po-iconContainerDisplay": {
        display: "flex",
        justifyContent: "left",
        paddingLeft: 28,
        position: "absolute",
        overflow: "hidden",
        transition: "opacity 0.8s ease-in-out",
      },
      "& .POs-container": {
        alignItems: "flex-start !important",
      },
    },
    "& .number": {
      paddingBlock: "14px 12px",
      textAlign: "right",
      verticalAlign: "top",
      "& .SOs-container": {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        position: "relative",
        "& .iconContainerDisplay": {
          display: "flex",
          color: colors.primaryDark,
          justifyContent: "left",
          paddingLeft: 24,
          transition: "opacity 0.8s ease-in-out",
          top: 0,
          position: "absolute",
        },
        "& .soListanimated": {
          gap: 5,
          display: "flex",
          flexDirection: "column",
          transition: "opacity 0.8s ease-in-out",
        },
      },

      "& .diamond-container": {
        minWidth: 10,
        width: 10,
        marginRight: 10,
        height: 20,
      },
      "& .progress-container": {
        minWidth: 10,
        width: 10,
        marginRight: 10,
        height: 20,
      },
      "& .buttonContainerThreePanel": {
        opacity: 0,
      },
      "&:hover": {
        "& .buttonContainerThreePanel": {
          opacity: 1,
        },
      },
    },
    "& .POs": {
      paddingBlock: 13,
      textAlign: "left !Important",
      verticalAlign: "top",
      "& .po-progress-diamonds-container": {
        textOverflow: "unset",
        display: "flex",
        width: 40,
        height: 25,
        justifyContent: "space-between",
      },
    },
    "& .customerName": {
      paddingInline: "16px 5px",
      textAlign: "left",
      verticalAlign: "top",
    },
    "& .dateDelivery": {
      padding: "9px 19px 4px 5px",
      textAlign: "right",
      verticalAlign: "top",
    },
    "& .date": {
      paddingInline: "5px 24px",
      textAlign: "right",
      verticalAlign: "top",
    },
    "& .amount": {
      textAlign: "right",
      paddingInline: "5px 20px",
      verticalAlign: "top",
    },
    "& .CBM": {
      textAlign: "right",
      paddingInline: "5px 17px",
      verticalAlign: "top",
    },
  },
  [`&.${mainRowClasses.ExpensesTableRow}`]: {
    "& td": {
      paddingBlock: "13px 7px",
      height: 50,
    },
    "& .favorite": {
      paddingInline: 9,
      verticalAlign: "top",
      paddingTop: 12,
    },
    "& .date": {
      paddingInline: 15,
      textAlign: "left",
      verticalAlign: "top",
    },
    "& .text": {
      textAlign: "left",
      paddingInline: 15,
      verticalAlign: "top",
    },
    "& .orderNumber": {
      textAlign: "right",
      paddingInline: 15,
      verticalAlign: "top",
    },
    "& .amount": {
      textAlign: "right",
      paddingInline: 0,
      verticalAlign: "top",
    },
    "& .customerName": {
      paddingInline: 15,
      verticalAlign: "top",
    },
    "& .number": {
      paddingInline: 15,
      height: 48,
      textAlign: "right",
      verticalAlign: "top",
    },
    "& .tools": {
      textAlign: "left",
      paddingInline: 9,
      verticalAlign: "top",
      paddingTop: 12,
    },
    "& .navLink": {
      display: "flex",
      "& .span-description": {
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      verticalAlign: "top",
    },
  },
  [`&.${mainRowClasses.SOGroupTableRow}`]: {
    "& .expandAndCollapse": {
      padding: "0px 10px",
    },
    "& .diamond": {
      padding: "0px 4px",
    },
    "& .favorite": {
      padding: "0px 8px",
    },
    "& .number": {
      padding: "0px 16px ",
      textAlign: "right ",
      "& .buttonContainerThreePanel": {
        opacity: 0,
      },
      "&:hover": {
        "& .buttonContainerThreePanel": {
          opacity: 1,
        },
      },
    },
    "& .customerName": {
      padding: "0px 16px ",
    },
    "& .amount": {
      textAlign: "right",
      padding: "0px 32px 0px 16px",
    },
    "& .shipDate": {
      padding: "0px 16px",
      textAlign: "center",
    },
    "& .quantityPOs": {
      textAlign: "right",
      padding: "0px 18px",
    },
    "& .proposalTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },
    "& .preProductionTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },
    "& .productionTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },
    "& .bookingTransitTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },
    "& .paymentBillingTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },
    "& .extra": { padding: 0 },

    "& td": {
      height: 60,
      fontSize: 16,
      fontWeight: 400,
    },
  },
  [`&.${mainRowClasses.POGroupTableRow}`]: {
    "& .favorite": {
      padding: "0px 10px",
    },
    "& .diamond": {
      padding: "0px 5px",
    },
    "& .number": {
      padding: "0px 5px",
      textAlign: "right",
      "& .buttonContainerThreePanel": {
        opacity: 0,
      },
      "&:hover": {
        "& .buttonContainerThreePanel": {
          opacity: 1,
        },
      },
    },
    "& .factoryName": {
      padding: "8px 16px",
    },
    "& .amount": {
      textAlign: "right",
      padding: "0px 16px 0px 14px",
    },
    "& .shipDate": {
      padding: "0px 5px",
      textAlign: "center",
    },
    "& .taskStageValue": {
      width: "fit-conten",
      padding: "0px 5px",
      height: 48,
    },

    "& td": {
      height: 48,
      fontSize: 14,
    },
  },
  [`&.${mainRowClasses.SubTableRow}`]: {
    "& .favorite": {
      padding: "4px 8px",
    },
    "& .diamond": {
      padding: "4px 6px",
    },

    "& .poNumber": {
      padding: "0px 1px ",
      textAlign: "right ",
    },
    "& .factoryName": {
      padding: "0px 10px 0px 20px",
    },

    "& .amount": {
      textAlign: "right",
      padding: "0px 27px 0px 16px",
    },
    "& .shipDate": {
      padding: "0px 32px 0px 10px",
      textAlign: "right",
    },
    "& .emptyTableCell": {
      padding: "0px 16px",
    },
    "& .proposalTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },
    "& .preProductionTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },

    "& .productionTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },
    "& .bookingTransitTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },
    "& .paymentBillingTasks": {
      width: "fit-content",
      padding: "0px 16px",
    },
    "& .number": {
      padding: "4px 16px ",
      textAlign: "right",
      "& .folder-shipment-icon": {
        opacity: 0,
        transition: "none",
      },
      "&:hover": {
        "& .folder-shipment-icon": {
          opacity: 1,
        },
      },
    },
    "& .finalDestination": {
      padding: "0px 10px 0px 19px",
    },
    "& .deliveryDate": {
      padding: "0px 16px",
      textAlign: "center",
    },
    "& td": {
      height: 48,
      fontSize: 14,
    },
  },
  [`&.${mainRowClasses.SearchRow}`]: {
    position: "relative",
    color: colors.primaryDark,
    "& .centerContainer": {
      display: "flex",
      alignItems: "center",
      gap: 5,
    },
    "& .tooltipContainer": {
      display: "flex",
      overflow: "hidden",
    },

    "& .number": {
      paddingLeft: 24,
      paddingRight: 0,
      "& .buttonContainerThreePanel": {
        opacity: 0,
      },
      "&:hover": {
        "& .buttonContainerThreePanel": {
          opacity: 1,
        },
      },
    },

    "& td": {
      paddingBlock: 4,
    },
  },
  "& a": {
    color: colors.primaryDark,
    textDecoration: "underline",
  },
  "& td": {
    color: colors.primaryDark,
    height: 42,
    fontWeight: 400,
    fontSize: "0.875rem",
  },
  "& .number-table-container": {
    display: "flex",
    alignItems: "center",
    gap: 5,
    "& .folder-shipment-icon": {
      opacity: 0,
    },
    "& .tagContainer": {
      margin: 0,
    },
    "& .buttonContainerThreePanel": {
      opacity: 0,
    },

    "&:hover": {
      "& .buttonContainerThreePanel": {
        opacity: 1,
      },
    },
  },
  "& .disclouserArrowStyle": {
    borderRadius: 5,
    cursor: "pointer",
    fontSize: 20,
    width: 24,
    height: 24,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    "& .arrow": {
      transition: "transform 0.3s ease-in-out",
    },
    "&:hover": {
      background: "#E5E5E5",
    },
  },

  [`&.${mainRowClasses.hoverEffect}`]: {
    "&:hover": {
      background: "#fffce6",
      "& .starContainer": {
        opacity: 1,
      },
    },
  },
}));
export const StarStyled = styled(TableRow)(() => ({
  "&.starContainer": {
    display: "flex",
    alignItems: "center",
    transition: "all 0.2s",
    cursor: "pointer",
    "& .svgIcon": {
      stroke: colors.yellowStar,
      storkeWidth: 1,
    },
  },
  "&.favorite-inactive-star": {
    opacity: 0,
    cursor: "pointer",
    "& .svgIcon": {
      fill: "transparent",
      stroke: "gray",
    },
  },
  "&:hover": {
    "&.starContainer": {
      opacity: 1,
    },
  },
}));

export const FooterRowStyled = styled(TableRow)(() => ({
  "& .customized-tooltip": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    "& span": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  "& .totals": {
    fontSize: 16,
    color: colors.primaryDark,
    fontWeight: 500,
    position: "relative",
    float: "right",
  },
  "& .dollars": {
    fontSize: 16,
    color: "#1D9531",
    fontWeight: 500,
    position: "relative",
    float: "right",
    padding: 0,
    textAlign: "right",
    whiteSpace: "nowrap",
    width: "100%",
  },
  "& .blueDivider": {
    borderTop: "3px solid #578EC7",
  },
  "& .grayDivider": {
    borderTop: "3px solid #E5EDF7",
  },
}));
