import React from "react";
import { SalesOrderBadgeStyled } from "./styles";

function SalesOrderBadge({ id = "", number, className = "sales-order-badge" }) {
  return (
    <SalesOrderBadgeStyled className={className} id={id}>
      <span>{number}</span>
    </SalesOrderBadgeStyled>
  );
}

export default SalesOrderBadge;
