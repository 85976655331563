import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REPORTS_VIEW } from "../../actions/types";
import { dbTables, reduxState } from "../../api/types/dbTables";
import {
  useCompanyId,
  useJSONLocalStorage,
  usePermissionGroups,
  useQueryParams,
} from "../../hooks";
import ReportDrawer from "../DrawerContainer/ReportDrawer";
import { ReportStyled } from "./styles";
import axios from "axios";
import Loader from "../../components/General/Loader";
import AttentionModal from "../../components/Modal/AttentionModal";
import moment from "moment";
import { updateDoc } from "firebase/firestore";
import { useUser } from "../../hooks/user";
import { canSeeReport, filterReports } from "../../helpers/reports";
import { useNavigate } from "react-router-dom";
import { REPORT_EMBED_TYPES } from "../../helpers/constants";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../../constants/globalVariables";

function ReportScreen() {
  const [open, setOpen] = useState(false);
  const [currentReport, setCurrentReport] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalAttention, setModalAttention] = useState({
    open: false,
    description: <React.Fragment></React.Fragment>,
  });
  const [errorLoadingReport, setErrorLoadingReport] = useState("");
  const [showReport, setShowReport] = useState(true);
  const user = useUser();
  const companyId = useCompanyId();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  let reports =
    useSelector((state) => state.data[dbTables.REPORTS][companyId]) || [];
  const accessGroups = usePermissionGroups({
    companyId,
    filteringByInactive: true,
  });

  const { get: getReportViewStorage, set: setReportViewStorage } =
    useJSONLocalStorage("reports_view");
  const reports_view = useSelector(
    (state) => state.data[reduxState.REPORTS_VIEW]
  ) || { open: false };
  const dispatch = useDispatch();

  useEffect(() => {
    const reportIdStorage =
      getReportViewStorage() && getReportViewStorage().report_id;
    const { report } = queryParams;
    if (!report && reportIdStorage) {
      verifyPermissionsToSetReport({
        reports,
        reportId: reportIdStorage,
        user,
        accessGroups,
      });
    }

    setOpen(reports_view.open);
  }, [reports_view.open]);

  useEffect(() => {
    const { report } = queryParams;
    if (report === "") {
      setErrorLoadingReport("Select a report from the list of the left");
      return;
    } else if (report) {
      verifyPermissionsToSetReport({
        reports,
        reportId: report,
        user,
        accessGroups,
      });
    }
  }, [queryParams, reports]);

  function verifyPermissionsToSetReport({
    reports = [],
    reportId = "",
    user = {},
    accessGroups = [],
  }) {
    const reportDB = reports.find((reportDB) => reportDB.id === reportId);
    if (!reportDB) {
      setCurrentReport({});
      setReportViewStorage({
        report_id: "",
      });
      if (errorLoadingReport === "" && reports.length > 0) {
        setErrorLoadingReport("This report does not exist");
      }
      return;
    }
    if (!reportDB.active) {
      setCurrentReport({});
      setReportViewStorage({
        report_id: "",
      });
      if (errorLoadingReport === "" && reports.length > 0) {
        setErrorLoadingReport("This report is inactive");
      }
      return;
    }
    if (!reportDB.parentId) {
      console.log("ROOT LEVEL REPORT");
      setCurrentReport({ ...reportDB });
      return;
    } else {
      console.log("CHILD LEVEL REPORT");
      const parentReport = reports.find((el) => el.id === reportDB.parentId);
      const hasPermissionToSeeReport = canSeeReport({
        folder: parentReport,
        user,
        accessGroups,
      });
      if (!hasPermissionToSeeReport) {
        console.log("NO PERMISSIONS");
        setCurrentReport({});
        setReportViewStorage({
          report_id: "",
        });
        setErrorLoadingReport("You do not have permission to see this report");
      } else {
        setCurrentReport({ ...reportDB });
      }
    }
  }

  function updateReport({ currentReport = {} }) {
    updateDoc(currentReport.ref, {
      lastUpdated: moment.now(),
    });
    setCurrentReport({
      ...currentReport,
      lastUpdated: moment.now(),
    });
  }

  async function embedTypeHandler({ currentReport = {} }) {
    const { embedType, URLToUpdateReport } = currentReport;
    setLoading(true);
    if (embedType === REPORT_EMBED_TYPES.GOOGLE_SHEET) {
      const response = await axios.get(URLToUpdateReport);
      if (response.status === 200) {
        updateReport({ currentReport });
        setLoading(false);
        setShowReport(false);
        setModalAttention({
          open: true,
          description: (
            <React.Fragment>
              The report has been updated successfully
            </React.Fragment>
          ),
        });
      } else {
        setLoading(false);
        setModalAttention({
          open: true,
          description: (
            <React.Fragment>
              Something went wrong updating the report, contact with TradeDash
              support
            </React.Fragment>
          ),
        });
      }
    } else if (embedType === REPORT_EMBED_TYPES.DOMO) {
      const functions = getFunctions();
      const domoEndpoint = httpsCallableFromURL(
        functions,
        getFunctionByName({
          name: "updateDomoReport",
          env: globalEnvironment,
        })
      );

      domoEndpoint({
        dataSourceIds: URLToUpdateReport,
      })
        .then((result) => {
          console.log({ result });
          const { status } = result.data;
          if (status === 200) {
            updateReport({ currentReport });
            setLoading(false);
            setShowReport(false);
            setModalAttention({
              open: true,
              description: (
                <React.Fragment>
                  The report has been updated successfully
                </React.Fragment>
              ),
            });
          } else {
            setLoading(false);
            setModalAttention({
              open: true,
              description: (
                <React.Fragment>
                  Something went wrong updating the report, contact with
                  TradeDash support
                </React.Fragment>
              ),
            });
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setModalAttention({
            open: true,
            description: (
              <React.Fragment>
                Something went wrong updating the report, contact with TradeDash
                support
              </React.Fragment>
            ),
          });
        });
    }
  }

  return (
    <ReportStyled className="reportContainer">
      {currentReport.URLToUpdateReport && (
        <div className="URL-to-update-report-content">
          <button
            onClick={async () => {
              console.log("UPDATE REPORT", currentReport);
              embedTypeHandler({ currentReport });
            }}
          >
            Update Report
          </button>

          {currentReport.lastUpdated && (
            <div>
              <span>
                Last updated{" "}
                {moment(currentReport.lastUpdated).format(
                  "MMM DD, YYYY h:mm a"
                )}
              </span>
            </div>
          )}
        </div>
      )}
      {currentReport.URLToDownloadReport && (
        <div className="URL-to-update-report-content" style={{ left: 272 }}>
          <button
            onClick={async () => {
              try {
                const element = document.getElementById(
                  "report-to-download-csv"
                );
                element.click();
                setModalAttention({
                  open: true,
                  description: (
                    <React.Fragment>
                      The report has been downloaded successfully
                    </React.Fragment>
                  ),
                });
              } catch (error) {
                console.log(error);
                setModalAttention({
                  open: true,
                  description: (
                    <React.Fragment>
                      Something went wrong downloading the report, contact with
                      TradeDash support
                    </React.Fragment>
                  ),
                });
              }
            }}
          >
            Download Report Data
          </button>
          <a
            id="report-to-download-csv"
            href={currentReport.URLToDownloadReport}
            style={{ display: "none" }}
          >
            Link
          </a>
        </div>
      )}

      {loading && (
        <Loader
          style={{
            top: 0,
            left: 0,
            background: "rgb(0,0,0,0.15)",
          }}
        />
      )}
      {modalAttention.open && (
        <AttentionModal
          title=""
          description={modalAttention.description}
          isOpen={modalAttention.open}
          onClick={() => {
            setModalAttention({ open: false, description: "" });
            setShowReport(true);
          }}
          onClose={() => {
            setModalAttention({ open: false, description: "" });
            setShowReport(true);
          }}
        />
      )}
      <ReportDrawer
        open={open}
        handleIsOpen={() => {
          dispatch({
            type: REPORTS_VIEW,
            table: reduxState.REPORTS_VIEW,
            payload: {
              open: false,
            },
          });
        }}
        onChange={({ report }) => {
          setReportViewStorage({
            report_id: report.id,
          });
          navigate(`/app/report/${report.id}`);
        }}
        reports={filterReports({ reports, user, accessGroups })}
        currentReport={currentReport}
      />
      {currentReport.reportEmbed && showReport ? (
        <iframe
          id={"report-embed-id"}
          title="title"
          style={{ width: "100%", height: "100%" }}
          src={currentReport.reportEmbed}
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            fontSize: "1.5rem",
            fontWeight: 500,
          }}
        >
          {errorLoadingReport}
        </div>
      )}
    </ReportStyled>
  );
}

export default ReportScreen;
