import { now } from "moment";
import React, { useState, useEffect, useContext } from "react";
import useUndo from "use-undo";
import { direction as globalDirection } from "../../helpers/constants";
import { getCorrectTimezone } from "../../helpers/ganttChart";
import { getStatusTask } from "../../helpers/tasks";
import {
  actionType,
  dependencyTypesOnMove,
  getAvailableTasksToMove,
  getDependencyTasks,
  getTasksAjustedByPhase,
} from "../../helpers/timelineModal";
import AttentionModal from "../Modal/AttentionModal";
import AttentionModalDescription from "./AttentionModalDescription";
import GanttChartV2 from "./GanttChratV2";
import { GANTT_CHART_ACTION, GanttChartContext } from "./GanttChartContext";
import PurchaseOrderBadges from "./PurchaseOrderBadges";
import SalesOrderBadged from "./SalesOrderBadged";
import {
  changeTaskDurationList,
  getStarEndTaksDate,
  getTaskConfirmationChange,
  getTasksFiltered,
  movedTasksList,
} from "./timelineHelper";

function TimelineModal({
  milestonesArray,
  allTasks,
  salesOrder,
  purchaseOrders,
  factories,
  customers,
  onCancelTimeLineModal,
  onSubmitTimeLineModal,
  shipments,
}) {
  //
  const { ganttChartState, dispatchGanttChart } = useContext(GanttChartContext);
  const {
    viewOptions,
    taskPhases,
    currentPOs,
    currentShipments,
    milestonesToSave,
  } = ganttChartState;

  const [currentSO, setCurrentSO] = useState({});
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] =
    useState(null);
  const [modifiedTask, setModifiedTask] = useState(null);
  const [pressedOneTime, setPressedOneTime] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);
  const [isMovingDependantTasks, setIsMovingDependantTasks] = useState(false);
  const [
    undoRedoState,
    {
      set: setTasksState,
      // reset: resetTasksState,
      undo: undoTasksState,
      redo: redoTasksState,
      canUndo,
      canRedo,
    },
  ] = useUndo({ tasks: allTasks, confirmedActions: [] });
  const { present: presentUndoRedoState } = undoRedoState;
  const { tasks, confirmedActions } = presentUndoRedoState;

  useEffect(() => {
    if (
      Object.keys(salesOrder).length > 0 &&
      JSON.stringify({ ...salesOrder, ref: "" }) !==
        JSON.stringify({ ...currentSO, ref: "" })
    ) {
      setCurrentSO(salesOrder);
    }
    return;
  }, [salesOrder]);

  useEffect(() => {
    setCurrentSO((prev) => ({
      ...prev,
      milestones: milestonesToSave,
    }));
  }, [milestonesToSave]);

  useEffect(() => {
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        currentPOs: purchaseOrders,
      },
    });
    return;
  }, [purchaseOrders]);

  useEffect(() => {
    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        milestones: milestonesArray,
      },
    });
    return;
  }, [milestonesArray]);

  useEffect(() => {
    const { endDate, startDate } = getStarEndTaksDate({ allTasks });

    dispatchGanttChart({
      type: GANTT_CHART_ACTION.COMMON,
      payload: {
        startDate: startDate,
        endDate: endDate,
      },
    });

    return;
  }, []);

  function handleDropTask(taskId, offset) {
    let tasksCpy = [...tasks];
    const movedTask = tasksCpy.find((task) => task.id === taskId);
    setModifiedTask({
      ...movedTask,
      offset,
      typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
    });
    if (movedTask) {
      tasksCpy = movedTasksList({ offset, taskId, tasks: tasksCpy });
      tasksCpy = getTasksAjustedByPhase({
        tasks: tasksCpy,
      });
      const updatedTask = tasksCpy.find((task) => task.id === taskId);
      const confirmedActionsCpy = [...confirmedActions];
      confirmedActionsCpy.push({
        ...updatedTask,
        [actionType.MOVED_TASK]: true,
        offsetDaysMoved: offset,
        typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
        creationDate: now(),
      });
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
      setPressedOneTime(false);
      const dependencyTasks = getDependencyTasks({
        task: movedTask,
        tasks: tasksCpy,
        dependencyTasks: [],
        remainingDayOffset: 0,
      });
      if (dependencyTasks.length === 0) {
        return;
      }
      setOpenModalAttention(true);
      const descriptionModalAttention = getDescription({ offset });
      setDescriptionModalAttention(descriptionModalAttention);
    }
  }

  function handleChangeTaskDuration(taskId, offset, direction) {
    let tasksCpy = [...tasks];
    const movedTask = tasksCpy.find((task) => task.id === taskId);

    setModifiedTask({
      ...movedTask,
      direction,
      offset,
      typeChange:
        direction === globalDirection.LEFT
          ? dependencyTypesOnMove.START_DATE_CHANGED
          : dependencyTypesOnMove.FINISH_DATE_CHANGED,
    });
    const newDuration = parseInt(movedTask.duration) + parseInt(offset);
    if (movedTask) {
      tasksCpy = changeTaskDurationList({
        direction,
        newDuration,
        offset,
        taskId,
        tasks,
      });
      tasksCpy = getTasksAjustedByPhase({
        tasks: tasksCpy,
      });

      const updatedTask = tasksCpy.find((task) => task.id === taskId);
      const confirmedActionsCpy = [...confirmedActions];

      confirmedActionsCpy.push({
        ...updatedTask,
        [actionType.CHANGED_DURATION_TASK]: true,
        newDuration,
        offsetDaysChangedDuration: offset,
        typeChange:
          direction === globalDirection.LEFT
            ? dependencyTypesOnMove.START_DATE_CHANGED
            : dependencyTypesOnMove.FINISH_DATE_CHANGED,
        creationDate: now(),
      });
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
      const dependencyTasks = getDependencyTasks({
        task: movedTask,
        tasks: tasksCpy,
        dependencyTasks: [],
        remainingDayOffset: 0,
        type:
          direction === globalDirection.LEFT
            ? dependencyTypesOnMove.START_DATE_CHANGED
            : dependencyTypesOnMove.FINISH_DATE_CHANGED,
      });
      if (dependencyTasks.length === 0) {
        return;
      }
      setOpenModalAttention(true);
      setPressedOneTime(false);
      const descriptionModalAttention = getDescription({
        offset:
          direction === globalDirection.LEFT
            ? -parseInt(offset)
            : parseInt(offset),
      });
      setDescriptionModalAttention(descriptionModalAttention);
    }
  }

  function handleChangeTask(task, offset, actionTypeTask) {
    let confirmedActionsCpy = [...confirmedActions];
    let tasksCpy = [...tasks];
    if (actionTypeTask === actionType.CHANGED_ASSIGNED_TO) {
      tasksCpy = tasksCpy.map((item) => {
        if (item.id === task.id) {
          return {
            ...task,
            hasBeenUpdated: true,
          };
        }
        return item;
      });
      confirmedActionsCpy.push({
        ...task,
        [actionTypeTask]: true,
        creationDate: now(),
      });
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
      return;
    } else {
      const typeChange =
        actionTypeTask === actionType.CHANGED_DURATION_TASK
          ? dependencyTypesOnMove.FINISH_DATE_CHANGED
          : dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED;
      if (offset) {
        const movedTask = tasksCpy.find((el) => el.id === task.id);
        const dependencyTasks = getDependencyTasks({
          task: movedTask,
          tasks: tasksCpy,
          dependencyTasks: [],
          remainingDayOffset: 0,
          type: typeChange,
        });
        if (dependencyTasks.length > 0) {
          setModifiedTask({ ...movedTask, offset });
          setOpenModalAttention(true);
          setPressedOneTime(false);
          const descriptionModalAttention = getDescription({
            offset,
          });
          setDescriptionModalAttention(descriptionModalAttention);
        }
      }
      tasksCpy = tasksCpy.map((item) => {
        if (item.id === task.id) {
          const taskCpy = {
            ...task,
            startDate: getCorrectTimezone({
              date: task.startDate,
              isServerTime: true,
            }).valueOf(),
            finishDate: getCorrectTimezone({
              date: task.finishDate,
              isServerTime: true,
            }).valueOf(),
          };
          return {
            ...taskCpy,
            hasBeenUpdated: true,
            status: getStatusTask(task),
          };
        }
        return item;
      });

      tasksCpy = getTasksAjustedByPhase({
        tasks: tasksCpy,
      });
      const confirmedChange = getTaskConfirmationChange({
        actionTypeTask,
        offset,
        task,
      });
      confirmedActionsCpy.push(confirmedChange);
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
    }
  }

  function getDescription({ offset }) {
    return (
      <AttentionModalDescription
        isMovingForward={offset > 0 ? true : false}
        offset={offset}
      />
    );
  }

  function handleMovingRemainingTasks({ modifiedTask, tasks, offsetDays }) {
    let tasksCpy = [...tasks];
    const availableTasksToMove = getAvailableTasksToMove({ tasks: tasksCpy });
    const dependencyTasks =
      getDependencyTasks({
        task: modifiedTask,
        tasks: availableTasksToMove,
        dependencyTasks: [],
        remainingDayOffset: modifiedTask.offset > 0 ? offsetDays : -offsetDays,
        type: modifiedTask.typeChange,
      }) || [];
    tasksCpy = tasksCpy.map((task) => {
      if (modifiedTask.isCompletedTaskBadge && task.id === modifiedTask.id) {
        return { ...task, moved: true };
      }
      if (task.isPhase) {
        return task;
      }
      const taskToMove = dependencyTasks.find(
        (dependencyTask) => dependencyTask.id === task.id
      );
      if (taskToMove) {
        return {
          ...taskToMove,
          hasBeenUpdated: true,
          status: getStatusTask(taskToMove),
        };
      }
      return task;
    });
    return tasksCpy;
  }

  function handleMoveRemainingTasks(task) {
    let tasksCpy = [...tasks];
    const dependencyTasks = getDependencyTasks({
      task,
      tasks,
      dependencyTasks: [],
      remainingDayOffset: 0,
    });

    if (dependencyTasks.length === 0) {
      let confirmedActionsCpy = [...confirmedActions];
      confirmedActionsCpy.push({
        ...task,
        [actionType.DENIED_REMAINING_DAY_OFFSET_MOVED]: true,
        // typeChange: dependencyTypesOnMove.FINISH_DATE_CHANGED,
        creationDate: now(),
      });
      tasksCpy = tasksCpy.map((taskCpy) => {
        if (taskCpy.id === task.id) {
          return { ...taskCpy, moved: true, hasBeenUpdated: true };
        }
        return taskCpy;
      });
      setTasksState({ tasks: tasksCpy, confirmedActions: confirmedActionsCpy });
      return;
    }
    setIsMovingDependantTasks(true);

    setModifiedTask({
      ...task,
      offset: task.dayOffset,
      isCompletedTaskBadge: true,
    });
    setOpenModalAttention(true);
    setPressedOneTime(false);
    const descriptionModalAttention = getDescription({
      offset: task.dayOffset,
    });
    setDescriptionModalAttention(descriptionModalAttention);
  }

  return (
    <div className="timeLineContainerV2">
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          isOpen={openModalAttention}
          description={descriptionModalAttention}
          cancellable
          onClick={() => {
            if (isConfirmationModal) {
              onCancelTimeLineModal();
              setDescriptionModalAttention(null);
              setIsConfirmationModal(false);
              setOpenModalAttention(false);
              return;
            } else {
              setPressedOneTime(true);
              if (!pressedOneTime) {
                const offsetDays = document.getElementById(
                  "remaining-offset-days"
                ).value;

                let tasksCpy = handleMovingRemainingTasks({
                  modifiedTask,
                  tasks,
                  offsetDays:
                    modifiedTask.direction === globalDirection.LEFT
                      ? -parseInt(offsetDays)
                      : parseInt(offsetDays),
                });

                tasksCpy = getTasksAjustedByPhase({
                  tasks: tasksCpy,
                });

                let confirmedActionsCpy = [...confirmedActions];
                if (isMovingDependantTasks) {
                  tasksCpy = tasksCpy.map((task) => {
                    if (task.id === modifiedTask.id) {
                      return { ...task, moved: true, hasBeenUpdated: true };
                    }
                    return task;
                  });
                  confirmedActionsCpy.push({
                    ...modifiedTask,
                    [actionType.CONFIRMED_REMAINING_DAY_OFFSET_MOVED]: true,
                    creationDate: now(),
                    offsetRemainingTask: parseInt(offsetDays),
                    moved: true,
                  });
                } else {
                  confirmedActionsCpy = confirmedActionsCpy.map(
                    (confirmedAction, index) => {
                      if (index === confirmedActionsCpy.length - 1) {
                        return {
                          ...confirmedAction,
                          [actionType.MOVED_REMAINING_TASK]: true,
                          offsetRemainingTask: parseInt(offsetDays),
                        };
                      }
                      return confirmedAction;
                    }
                  );
                }
                setTasksState({
                  tasks: tasksCpy,
                  confirmedActions: confirmedActionsCpy,
                });
                setOpenModalAttention(false);
                setDescriptionModalAttention(null);
              }
            }
          }}
          onClose={() => {
            if (!pressedOneTime) {
              setOpenModalAttention(false);
              setDescriptionModalAttention(null);
              if (isConfirmationModal) {
                setPressedOneTime(false);
                return setIsConfirmationModal(false);
              }
              if (isMovingDependantTasks) {
                let confirmedActionsCpy = [...confirmedActions];
                confirmedActionsCpy.push({
                  ...modifiedTask,
                  [actionType.DENIED_REMAINING_DAY_OFFSET_MOVED]: true,
                  // typeChange: dependencyTypesOnMove.FINISH_DATE_CHANGED,
                  creationDate: now(),
                  moved: true,
                });
                let tasksCpy = [...tasks];
                tasksCpy = tasksCpy.map((taskCpy) => {
                  if (taskCpy.id === modifiedTask.id) {
                    return { ...taskCpy, moved: true, hasBeenUpdated: true };
                  }
                  return taskCpy;
                });
                setTasksState({
                  tasks: tasksCpy,
                  confirmedActions: confirmedActionsCpy,
                });
              }
            }
          }}
          confirmationText="Yes"
          cancelText="No"
        />
      )}
      <SalesOrderBadged
        salesOrder={currentSO}
        customers={customers}
        tasks={tasks}
        viewOptions={viewOptions}
        onCancelTimeLineModal={({ projectVoided }) => {
          if (projectVoided) {
            onCancelTimeLineModal();
            return;
          }
          setIsConfirmationModal(true);
          setDescriptionModalAttention(
            <React.Fragment>
              Are you sure to discard these changes?
            </React.Fragment>
          );
          setOpenModalAttention(true);
          setPressedOneTime(false);
        }}
        onSubmitTimeLineModal={() =>
          onSubmitTimeLineModal({
            tasks: tasks.filter((task) => task.hasBeenUpdated),
            confirmedActions,
            salesOrder: currentSO,
            purchaseOrders: currentPOs,
            shipments: currentShipments,
            viewOptions,
          })
        }
        showButtonHiddenPOs
      />
      <PurchaseOrderBadges
        factories={factories}
        shipments={shipments}
        tasks={tasks}
      />
      <GanttChartV2
        allTasks={allTasks}
        tasks={getTasksFiltered({
          tasks,
          currentSO,
          currentPOs,
          currentShipments,
          viewOptions,
          taskPhases,
        })}
        unfilteredTasks={tasks}
        onChangeTask={handleChangeTask}
        onChangeTaskDuration={handleChangeTaskDuration}
        onDropTask={handleDropTask}
        canRedo={canRedo}
        canUndo={canUndo}
        redoTasksState={redoTasksState}
        undoTasksState={undoTasksState}
        handleMoveRemainingTasks={handleMoveRemainingTasks}
        shipments={shipments}
        purchaseOrders={purchaseOrders}
      />
    </div>
  );
}

export default TimelineModal;
