import moment from "moment";
import {
  USER_CUSTOM_SETTINGS,
  dbTables,
  typeOfTask,
} from "../api/types/dbTables";
import taskStages from "../api/types/taskStages";
import { sortObjectsBy } from "./helpers";
import { orderTypeSort } from "./salesOrderHelpers";
import { actionType, dependencyTypesOnMove } from "./timelineModal";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../firebase";
import { getFilterTasksByStatus } from "../container/TaskListScreen/TaskScreenHelper";
import userTypes from "../api/types/userTypes";
import { checkSpecificPermissions } from "../hooks/permissions";
import { GENERAL_PERMISSION_VALUE } from "./constants";

function getDependantTaskDescriptionToMove({
  diffDaysRemainingTasks,
  dayStatus,
}) {
  return `,<br/> and, <br/> the dependant tasks in this project were also moved to be <strong>${Math.abs(
    diffDaysRemainingTasks
  )} ${
    Math.abs(diffDaysRemainingTasks) === 1
      ? `day ${dayStatus}`
      : `days ${dayStatus}`
  } </strong>`;
}

export function getDetailFromMoveDependantTask({
  type,
  task,
  companyUsers,
  dayOffsetAbs,
  remainingOffset,
  user,
}) {
  const completedByUser =
    companyUsers.find((user) => user.id === task.completedBy)?.displayName ||
    "User Employee";
  switch (type) {
    case actionType.CONFIRMED_REMAINING_DAY_OFFSET_MOVED: {
      return task.dayOffset > 0
        ? `<strong>@${
            user.displayName
          }</strong> Moved this project's tasks <span class='styles-activity-stream-from-backend-red'>${remainingOffset} ${
            remainingOffset === 1 ? "day" : "days"
          } later </span> after <strong>@${completedByUser}
          </strong> completed <strong>${
            task.description
          }</strong>  <span class='styles-activity-stream-from-backend-red'> ${dayOffsetAbs} ${
            dayOffsetAbs === 1 ? "day" : "days"
          } late </span> `
        : `<strong>@${
            user.displayName
          }</strong> Moved this project's tasks <span class='styles-activity-stream-from-backend-green'> ${remainingOffset} ${
            remainingOffset === 1 ? "day" : "days"
          } earlier </span> after <strong>@${completedByUser}</strong> completed <strong>${
            task.description
          }</strong> <span class='styles-activity-stream-from-backend-green'> ${dayOffsetAbs} ${
            dayOffsetAbs === 1 ? "day" : "days"
          } early </span>`;
    }

    case actionType.DENIED_REMAINING_DAY_OFFSET_MOVED:
      return task.dayOffset > 0
        ? `<p>The badge to shift <strong>${
            task.description
          }</strong> forward ${dayOffsetAbs} ${
            dayOffsetAbs === 1 ? "day" : "days"
          } was dismissed with no change</p>`
        : `<p>The badge to shift <strong>${
            task.description
          }</strong> back ${dayOffsetAbs} ${
            dayOffsetAbs === 1 ? "day" : "days"
          } was dismissed with no change</p>`;

    default:
      break;
  }
}

export function getDetailFromMovedTask({
  task,
  typeChange,
  diffDaysTask,
  isMovingForward,
  isDependantTaskMoved = false,
  diffDaysRemainingTasks,
}) {
  const dayStatus = isMovingForward ? "later" : "earlier";
  const measure = diffDaysTask > 0 ? "longer" : "shorter";
  switch (typeChange) {
    case dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED:
      return `<strong>${
        task.description
      }</strong> is now due on <strong>${moment(task.finishDate).format(
        "dddd M/D/YY"
      )}</strong>, <span class='styles-activity-stream'> ${Math.abs(
        diffDaysTask
      )} ${
        Math.abs(diffDaysTask) === 1 ? `day ${dayStatus}` : `days ${dayStatus}`
      }</span>${
        isDependantTaskMoved
          ? getDependantTaskDescriptionToMove({
              diffDaysRemainingTasks,
              dayStatus,
            })
          : ""
      }`;
    case dependencyTypesOnMove.START_DATE_CHANGED:
      return `<strong>${
        task.description
      }</strong> now starts ${dayStatus}, <strong>${moment(
        task.startDate
      ).format(
        "dddd M/D/YY"
      )}</strong>, <span class='styles-activity-stream'>it is ${Math.abs(
        diffDaysTask
      )} ${
        Math.abs(diffDaysTask) === 1 ? `day ${measure}` : `days ${measure}`
      }</span>${
        isDependantTaskMoved
          ? getDependantTaskDescriptionToMove({
              diffDaysRemainingTasks,
              dayStatus,
            })
          : ""
      }`;
    case dependencyTypesOnMove.FINISH_DATE_CHANGED:
      return `<strong>${
        task.description
      }</strong> is now due on <strong>${moment(task.finishDate).format(
        "dddd M/D/YY"
      )}</strong>, <span class='styles-activity-stream'>it is ${Math.abs(
        diffDaysTask
      )} ${
        Math.abs(diffDaysTask) === 1 ? `day ${measure}` : `days ${measure}`
      }</span>${
        isDependantTaskMoved
          ? getDependantTaskDescriptionToMove({
              diffDaysRemainingTasks,
              dayStatus,
            })
          : ""
      }`;

    default:
      return "couldn't get the detail";
  }
}

export function getAssignedTaskDetail({ description, lastUser, currentUser }) {
  return `<p><strong>${description}</strong> from <strong>${lastUser}</strong> to <strong>${currentUser}</strong></p>`;
}

export function getCategoryStatus(value) {
  return {
    "Proposal/Quote": value,
    "Pre-production": value,
    Production: value,
    "Booking & Transit": value,
    "Payment & Billing": value,
  };
}

export const CATEGORY_STATUS_BY_STAGE = {
  [taskStages.PROPOSAL]: "Proposal/Quote",
  [taskStages.PRE_PRODUCTION]: "Pre-production",
  [taskStages.PRODUCTION]: "Production",
  [taskStages.BOOKING_TRANSIT]: "Booking & Transit",
  [taskStages.PAYMENT_BILLING]: "Payment & Billing",
};

export const avoidingTaskColumnToSearch = [
  undefined,
  "startDate",
  "finishDate",
  "",
  "complete",
];

const taskStatus = {
  LATE: "late",
  NOT_STARTED: "notStarted",
  IN_PROGRESS: "inProgress",
  NEAR_DUE: "nearDue",
  COMPLETE: "complete",
};

function isDueDate(task) {
  const today = moment().startOf("day").valueOf();
  const tomorrow = moment().add(1, "day").endOf("day").valueOf();
  if (
    moment(task.finishDate).valueOf() >= today &&
    moment(task.finishDate).valueOf() <= tomorrow
  ) {
    return true;
  }
  return false;
}

function isLate(task) {
  const today = moment().startOf("day").valueOf();
  if (moment(task.finishDate).valueOf() < today) {
    return true;
  }
  return false;
}

export function getStatusTask(task) {
  const now = moment.now();
  if (!task.startDate || !task.finishDate) {
    return "";
  }
  if (isLate(task) && !task.complete) {
    return taskStatus.LATE;
  } else if (isDueDate(task) && !task.complete) {
    return taskStatus.NEAR_DUE;
  } else if (
    now >= moment(task.startDate).startOf("day") &&
    now <= moment(task.finishDate).endOf("day") &&
    !task.complete
  ) {
    return taskStatus.IN_PROGRESS;
  } else if (now < moment(task.startDate).startOf("day") && !task.complete) {
    return taskStatus.NOT_STARTED;
  } else if (task.complete && task.status !== taskStatus.COMPLETE) {
    return taskStatus.COMPLETE;
  }
}

export function getNumberOfLateDays({ task }) {
  const offset = moment()
    .startOf("day")
    .diff(moment(task.finishDate).startOf("day"), "days");
  return `${offset} ${offset === 1 ? "day" : "days"} late`;
}

export const triggerTaskTypes = {
  COMPLETE: "COMPLETE",
  FINISH_DATE: "FINISH_DATE",
  ASSIGNED_TO: "ASSIGNED_TO",
  AVOID_ACTIVITY_ENTRY: "AVOID_ACTIVITY_ENTRY",
  DISMISS_TRIGGER: "DISMISS_TRIGGER",
  MOVED_REMAINING_TASK: "MOVED_REMAINING_TASK",
};

export { taskStatus };

export function filterTaskList({
  list = [],
  nonLookUpfilters = {},
  headerCells = {},
}) {
  let filteredList = [...list];
  // if (!filters.completed) {
  //   filteredList = filteredList.filter((el) => !el.complete);
  // }
  if (nonLookUpfilters.query) {
    const enabledFieldToSearch = [];
    headerCells.forEach((header) => {
      if (header.enabled && !!header.searchBy) {
        enabledFieldToSearch.push(header.searchBy);
      }
    });

    filteredList = filteredList.filter((el) => {
      return enabledFieldToSearch.some((field) => {
        if (
          field &&
          el[field] &&
          el[field]
            .toString()
            .toLowerCase()
            .includes(nonLookUpfilters.query.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    });
  }
  filteredList.sort(
    sortObjectsBy(
      nonLookUpfilters.sortedColumn,
      nonLookUpfilters.orderBy === orderTypeSort.ASC ? true : false
    )
  );
  return filteredList;
}

export async function updateColumnHeaders({
  columnHeaders = [],
  header = "taskHeaderSetup",
  user = {},
}) {
  if (columnHeaders.length === 0) {
    console.log("Column headers are empty");
    return true;
  } else {
    return updateDoc(
      doc(
        firestore,
        `${dbTables.USERS}/${user.id}/${dbTables.CUSTOM_SETTINGS}/${USER_CUSTOM_SETTINGS.SCREEN_HEADERS}`
      ),
      {
        [header]: columnHeaders,
      }
    ).then(() => {
      console.log("Column headers updated successfully");
      return true;
    });
  }
}

export function getTotalTasksByStatus({
  tasksObj = {},
  filters,
  companyUsers,
}) {
  let totalLateTasks = 0;
  let totalNearDueTasks = 0;
  let totalInProgressTasks = 0;
  let totalCompletedTasks = 0;
  let totalNotStartedTasks = 0;
  let totalTasks = 0;
  Object.keys(tasksObj).forEach((key) => {
    let tasks = tasksObj[key];

    tasks = getFilterTasksByStatus({
      filters: filters,
      tasks: tasks,
      companyUsers,
    });
    totalLateTasks += tasks.filter(
      (task) => task.status === taskStatus.LATE
    ).length;
    totalNearDueTasks += tasks.filter(
      (task) => task.status === taskStatus.NEAR_DUE
    ).length;
    totalInProgressTasks += tasks.filter(
      (task) => task.status === taskStatus.IN_PROGRESS
    ).length;
    totalCompletedTasks += tasks.filter(
      (task) => task.status === taskStatus.COMPLETE
    ).length;
    totalNotStartedTasks += tasks.filter(
      (task) => task.status === taskStatus.NOT_STARTED
    ).length;
    totalTasks += tasks.length;
  });
  return {
    totalLateTasks,
    totalNearDueTasks,
    totalInProgressTasks,
    totalCompletedTasks,
    totalNotStartedTasks,
    totalTasks,
  };
}

export function entitiesFiltered({
  entities = [],
  tasks = {},
  filters = {},
  searchFields = [],
}) {
  let entitiesCpy = [
    ...entities.map((entity) => {
      let entityTasks = tasks[entity.id] || [];
      const tasksNames = entityTasks.map((task) => task.description);
      let tasksNamesString = tasksNames.join(" ");
      return {
        ...entity,
        tasksNamesString,
      };
    }),
  ];
  if (filters.searchText) {
    entitiesCpy = entitiesCpy.filter((entity) => {
      return searchFields.some((field) => {
        return String(entity[field])
          ?.toLowerCase()
          ?.includes(filters?.searchText?.toLowerCase());
      });
    });
  }
  return entitiesCpy;
}

export function purchaseOrdersFilteredByVendor({
  purchaseOrders = [],
  user = {},
}) {
  purchaseOrders = purchaseOrders.filter((po) => {
    return (
      user.role === userTypes.SUPER_ADMIN ||
      user.role === userTypes.TRADEDASH_EMPLOYEE ||
      checkSpecificPermissions({
        userPermissions: user.permissions,
        permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
      })
    );
  });
  return purchaseOrders;
}

export function entitiesFilteredByCustomer({ entities = [], user = {} }) {
  const filteredEntities = entities.filter((entity) => {
    return (
      user.role === userTypes.SUPER_ADMIN ||
      user.role === userTypes.TRADEDASH_EMPLOYEE ||
      checkSpecificPermissions({
        userPermissions: user.permissions,
        permissionToCheck: [
          entity.customerId,
          GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
        ],
      })
    );
  });
  return filteredEntities;
}

export const TASK_FIELDS = {
  FINISH_DATE: "finishDate",
  START_DATE: "startDate",
  ASSIGNED_TO: "assignedTo",
  COMPLETED_BY: "completedBy",
  DESCRIPTION: "description",
  STATUS: "status",
  TYPE: "type",
};

export const checkUserPermissionByEntity = ({
  user = {},
  tabIndicator,
  factoryId,
  customerId,
}) => {
  const userPermissions = user.permissions || {};
  const globalPermission =
    tabIndicator !== typeOfTask.PURCHASE_ORDER
      ? GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS
      : GENERAL_PERMISSION_VALUE.ALL_VENDORS;
  const permissionToVerify =
    tabIndicator === typeOfTask.PURCHASE_ORDER ? factoryId : customerId;
  return (
    user.role === userTypes.SUPER_ADMIN ||
    userPermissions[permissionToVerify] ||
    userPermissions[globalPermission] ||
    false
  );
};
