import React, { useRef, useState } from "react";
import { getTheDate } from "../../../helpers/helpers";
import { getUserAvatar } from "../../../helpers/users";
import { activityType } from "../../../helpers/activitiesStream";
import { ExpandedActivityModalStyled } from "./styles";
import { DraggableIcon, LockNoteIcon } from "../../../helpers/iconSvgPath";
import TooltipTD from "../../Tooltip/TooltipTD";
import { NOTES_SCOPE } from "../../../helpers/constants";
import { useCompanyUsers } from "../../../hooks/user";
import { cx } from "@emotion/css";
import DOMPurify from "dompurify";
import { colors } from "../../../assets/jss/variables";
import {
  PO_SCOPE,
  SO_SCOPE,
  getLabelScope,
  getTextFromType,
  hasActivityReadUnread,
} from "../../PurchaseOrderDashboard/ActivityStream/ActivityHelpers";
import { CircularProgress } from "@mui/material";

function ExpandActivityModal({
  openModalActivity,
  onCloseModalNotes,
  activity,
  onMarkRead = () => {},
  onAddMentionBackdrop,
  noteIsReaded,
  user,
}) {
  const users = useCompanyUsers({});
  const hasActivityReadPoint = hasActivityReadUnread({
    activity,
    currentUser: user,
  });
  const [loadingRead, setLoadingRead] = useState(false);
  const [mouseIsDown, setMouseIsDown] = useState(false);
  const [currentWidth, setCurrentWidth] = useState(800);
  const containerRef = useRef();
  const username = `@${
    users.find((user) => user.id === activity.user)
      ? users.find((user) => user.id === activity.user).displayName
      : "userdefault"
  }`;

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  function onMouseDown(ev, direction) {
    ev.preventDefault();
    setMouseIsDown(direction);
  }
  function onMouseUp(ev) {
    ev.preventDefault();
    setMouseIsDown(false);
  }

  const onMouseMove = (ev) => {
    if (!mouseIsDown) {
      return;
    }
    if (mouseIsDown === "L") {
      const addWidth = currentWidth - ev.movementX * 3;
      const maxWidth = window.screen.width;
      if (addWidth >= 800 && addWidth < maxWidth) {
        setCurrentWidth(addWidth);
      }
    }
    if (mouseIsDown === "R") {
      const addWidth = currentWidth + ev.movementX * 3;
      const maxWidth = window.screen.width;
      if (addWidth >= 800 && addWidth < maxWidth) {
        setCurrentWidth(addWidth);
      }
    }
  };

  const getLockIcon = () => {
    if (activity.lockNote) {
      return (
        <TooltipTD
          hasArrow={true}
          label={"Only users tagged in this note can see it"}
          style={{ display: "flex" }}
        >
          <LockNoteIcon size={16} />
        </TooltipTD>
      );
    } else {
      return <div style={{ width: 16 }} />;
    }
  };

  const isElementAnAnchor = (element) => {
    while (element) {
      if (
        element.tagName === "A" ||
        element.classList.contains("documentsMentionClass")
      ) {
        return true;
      }
      element = element.parentElement;
    }
    return false;
  };

  const getActivityClass = () => {
    return SO_SCOPE.includes(activity.scope)
      ? NOTES_SCOPE.SALES_ORDER
      : PO_SCOPE.includes(activity.scope)
      ? NOTES_SCOPE.PURCHASE_ORDER
      : NOTES_SCOPE.SHIPMENT;
  };

  return (
    <ExpandedActivityModalStyled
      open={openModalActivity}
      onClose={onCloseModalNotes}
      className={"modalActivity"}
      onMouseUp={onMouseUp}
      style={{ cursor: mouseIsDown ? "grabbing" : "" }}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseUp}
    >
      <div
        id={"activity-backdrop-" + activity.id}
        className={cx("activityStyle", getActivityClass(), "expandModalBody")}
        style={{ width: currentWidth }}
        ref={containerRef}
      >
        {activity.type !== activityType.WITHOUT_TITLE ? (
          <div className={cx("headerNote", "headerTitle")}>
            <div className="left-handle-and-activity-type">
              <DraggableIcon
                onMouseDown={(ev) => onMouseDown(ev, "L")}
                svgClass={"iconResize"}
                color={colors.primaryDark}
              />
              <span className="type">{getTextFromType(activity.type)}</span>
            </div>
            <span className="timestamp">
              {getTheDate(activity.creationDate, "MMMM D, YYYY h:mma")}
            </span>

            <div className="right-handle-and-scope">
              <div className="header-icons-container">
                {getLockIcon()}
                <span className="scope">{getLabelScope(activity.scope)}</span>
              </div>
              <DraggableIcon
                onMouseDown={(ev) => onMouseDown(ev, "R")}
                svgClass={"iconResize"}
                color={colors.primaryDark}
              />
            </div>
          </div>
        ) : (
          <div className="activity-scope-label">
            {getLabelScope(activity.scope)}
          </div>
        )}
        <div className={"bodyNote"}>
          <div className="avatarNote">
            {activity.user &&
              getUserAvatar({
                user: users.find((user) => user.id === activity.user),
                styles: {
                  outline: "2px solid #000",
                  zIndex: 1,
                  top: activity.type !== activityType.WITHOUT_TITLE ? 0 : 20,
                },
              })}
          </div>
          <div
            id={"note-detail" + activity.id}
            className="note-detail"
            dangerouslySetInnerHTML={createMarkup(activity.detail)}
            onMouseDown={(ev) => {
              if (!window.getSelection) {
                return;
              }
              if (ev.button === 0) {
                const selection = window.getSelection();
                if (
                  selection.toString().trim() &&
                  !isElementAnAnchor(ev.target)
                ) {
                  selection.removeAllRanges();
                }
              }
            }}
            onClick={(ev) => {
              const element = ev.target || {};
              const elementClassname = element.className || "";
              if (elementClassname.includes("documentsMentionClass")) {
                onCloseModalNotes();
                onAddMentionBackdrop({
                  ev,
                  activity,
                });
              }
            }}
          />
          <div
            className="readGrid"
            style={{ cursor: !hasActivityReadPoint && "unset" }}
            onClick={async () => {
              setLoadingRead(true);
              await onMarkRead();
              setLoadingRead(false);
            }}
          >
            {hasActivityReadPoint && !loadingRead && (
              <div
                className={cx("activityReadStatus", {
                  activityPointRead: !noteIsReaded,
                })}
              />
            )}
            {loadingRead && <CircularProgress size={12} />}
          </div>
        </div>
        <div className={"footer"}>
          {activity.user && <span className="username">{username}</span>}
        </div>
      </div>
    </ExpandedActivityModalStyled>
  );
}

export default ExpandActivityModal;
