import React, { useState } from "react";
import TaskCategory from "./TaskCategory";
import taskStages from "../../api/types/taskStages";
import Task from "./Task";
import { typeOfTask } from "../../api/types/dbTables";
import { sortObjectsBy } from "../../helpers/helpers";

import { getCurrentOrderTask } from "../../container/TaskListScreen/TaskScreenHelper";
import RemainingBadgeModal from "../Modal/RemainingBadgeModal";
import { MOVE_TASK_TYPES } from "../../helpers/constants";

function TaskListView({
  taskList,
  companyId,
  onClickTask,
  purchaseOrder,
  salesOrder,
  companyUsers = [],
  allTaskList,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleUpdateTotalTask = () => {},
  categoriesStatus = {},
  handleCategoriesStatus,
  currentShipment = {},
  onChangeShipment,
  onCleanPendingTask,
  pendingToCompleted,
  showCompleted,
  taskFilter,
  setPendingToComplete = () => {},
  handleIsEditingTask,
  backdropId,
  isSmallPanel,
}) {
  const [modalToAdjustTasks, setModalToAdjustTasks] = useState({
    open: false,
    task: {},
    mainEntity: {},
    parentCollection: "",
  });

  function onClickOffset({ task, changeStyleTo }) {
    if (isReadOnly) {
      handleReadOnlyModal();
      return;
    }
    setPendingToComplete((oldPending) => ({
      ...oldPending,
      [task.id]: true,
    }));
    changeStyleTo("project-task pendingSize");

    const { currentOrder: mainEntity, orderDB: parentCollection } =
      getCurrentOrderTask({
        task,
        salesOrder,
        purchaseOrder,
        currentShipment,
      });
    setModalToAdjustTasks({ open: true, task, mainEntity, parentCollection });
  }

  function getSortedTasksByPhases({ tasks, stage, listIndexes = {} }) {
    const stageTasks = tasks
      .filter((task) => task.stage === stage)
      .map((item) =>
        listIndexes[item.taskTemplateId]
          ? {
              ...item,
              listIndex: listIndexes[item.taskTemplateId],
            }
          : item
      )
      .sort(sortObjectsBy("listIndex"));

    return stageTasks.map((item) => {
      return (
        <Task
          key={
            item.type === typeOfTask.SALES_ORDER
              ? item.id + salesOrder.id
              : item.id + salesOrder.id + purchaseOrder.id
          }
          onChangeShipment={onChangeShipment}
          item={item}
          onClickOffset={onClickOffset}
          handleUpdateTotalTask={handleUpdateTotalTask}
          completeTask={onClickTask}
          user={companyUsers.find((user) => user.id === item.assignedTo)}
          companyId={companyId}
          salesOrder={salesOrder}
          purchaseOrder={purchaseOrder}
          isReadOnly={isReadOnly}
          handleReadOnlyModal={handleReadOnlyModal}
          companyUsers={companyUsers}
          backdropId={backdropId}
          allTaskList={allTaskList}
          permissionToVerify={
            item.type === typeOfTask.PURCHASE_ORDER
              ? item.factoryId
              : item.customerId
          }
          currentShipment={currentShipment}
          onCleanPendingTask={onCleanPendingTask}
          pendingToCompleted={pendingToCompleted}
          showCompleted={showCompleted}
          taskFilter={taskFilter}
          setPendingToComplete={setPendingToComplete}
          handleIsEditingTask={handleIsEditingTask}
          isSmallPanel={isSmallPanel}
        />
      );
    });
  }

  return (
    <ul>
      {modalToAdjustTasks.open && (
        <RemainingBadgeModal
          open={modalToAdjustTasks.open}
          onCloseModal={() => {
            setModalToAdjustTasks({ open: false });
          }}
          actionType={MOVE_TASK_TYPES.BADGE_REMAINIG_DAYS}
          mainEntity={modalToAdjustTasks.mainEntity}
          parentCollection={modalToAdjustTasks.parentCollection}
          task={modalToAdjustTasks.task}
          taskDayOffset={modalToAdjustTasks.task.dayOffset}
        />
      )}
      <TaskCategory
        title={
          "Proposal/Quote  (" +
          taskList.filter((task) => task.stage === taskStages.PROPOSAL).length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.PROPOSAL
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.PROPOSAL
              )
            ? "nearDue"
            : false
        }
        style={{ marginTop: 0 }}
        isOpen={categoriesStatus["Proposal/Quote"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Proposal/Quote", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.PROPOSAL,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
      <TaskCategory
        title={
          "Pre-production  (" +
          taskList.filter((task) => task.stage === taskStages.PRE_PRODUCTION)
            .length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.PRE_PRODUCTION
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.PRE_PRODUCTION
              )
            ? "nearDue"
            : false
        }
        isOpen={categoriesStatus["Pre-production"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Pre-production", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.PRE_PRODUCTION,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
      <TaskCategory
        title={
          "Production  (" +
          taskList.filter((task) => task.stage === taskStages.PRODUCTION)
            .length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.PRODUCTION
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.PRODUCTION
              )
            ? "nearDue"
            : false
        }
        isOpen={categoriesStatus["Production"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Production", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.PRODUCTION,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
      <TaskCategory
        title={
          "Booking & Transit  (" +
          taskList.filter((task) => task.stage === taskStages.BOOKING_TRANSIT)
            .length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.BOOKING_TRANSIT
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.BOOKING_TRANSIT
              )
            ? "nearDue"
            : false
        }
        isOpen={categoriesStatus["Booking & Transit"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Booking & Transit", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.BOOKING_TRANSIT,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
      <TaskCategory
        title={
          "Payment & Billing  (" +
          taskList.filter((task) => task.stage === taskStages.PAYMENT_BILLING)
            .length +
          ")"
        }
        status={
          taskList.some(
            (task) =>
              task.status === "late" &&
              !task.complete &&
              task.stage === taskStages.PAYMENT_BILLING
          )
            ? "late"
            : taskList.some(
                (task) =>
                  task.status === "nearDue" &&
                  !task.complete &&
                  task.stage === taskStages.PAYMENT_BILLING
              )
            ? "nearDue"
            : false
        }
        isOpen={categoriesStatus["Payment & Billing"]}
        onCategoryStatus={(value) =>
          handleCategoriesStatus("Payment & Billing", value)
        }
      >
        {getSortedTasksByPhases({
          tasks: taskList,
          stage: taskStages.PAYMENT_BILLING,
          listIndexes: purchaseOrder.vendorTemplateIndexes,
        })}
      </TaskCategory>
    </ul>
  );
}

export default TaskListView;
