import { typeOfTask } from "../../api/types/dbTables";
import { colors } from "../../assets/jss/variables";
import AppConfig from "../../constants/AppConfig";
import { TASK_TYPE, dayHeight, dayWidth } from "../../helpers/constants";
import { GANTT_CHART_SETTINGS } from "../../helpers/ganttChart";
import { hexToRgbA } from "../../helpers/helpers";
import { onHoverBackground } from "../../helpers/variableColors";
import { Paper, Popover, styled } from "@mui/material";
import { GANTTCHART_TABLE_SIZE } from "./GanttChartContext";

export const GanttChartStructureStyled = styled("div")(() => ({
  "&.ganttChartContainer": {
    height: "-webkit-fill-available",
    background: "#fff",
    margin: 16,
  },
}));

const bigGrid = "35px 20px 20px 1fr 25px 25px 40px 60px 60px 95px 28px";

const scrollButtonContainerStyles = {
  width: 80,
  height: 80,
  position: "absolute",
  zIndex: 10,
  background: "#8D8D8D",
  opacity: 0.3,
  borderRadius: 15,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
export const GanttChartPopover = styled(Popover)(({ theme }) => ({
  "&.ganttChartPopover": {
    pointerEvents: "none",
    zIndex: 1300,
  },
  "& .calendarPaper": {
    padding: theme.spacing(1),
    borderRadius: 5,
    border: "2px solid white",
    borderLeft: "5px solid ",
    right: 36,
  },
  [`& .paper_${typeOfTask.PURCHASE_ORDER}`]: {
    background: colors.purchaseOrderBackgroundColor,
  },
  [`& .paper_${typeOfTask.SALES_ORDER}`]: {
    background: colors.salesOrderBackgroundColor,
    borderColor: colors.salesOrderBorderColor,
  },
  [`& .paper_${typeOfTask.SHIPMENT}`]: {
    background: colors.shipmentBadge,
    borderColor: colors.shipmentBorderColor,
  },
}));

export const GanttChartCalendarStyled = styled("div")(({ theme }) => ({
  "&.calendarContainer": {
    width: "100%",
    marginLeft: "auto",
    overflow: "hidden",
    boxShadow: "3px 2px 10px 0px #ccc",
    height: "auto",
    position: "relative",
    background: "#fff",
    padding: 12,
  },
  "& .ghostTask": {
    height: 30,
    opacity: 0.25,
    top: -8,
    position: "absolute",
    borderRadius: 5,
    zIndex: 1,
  },
  "& .gridTableTask": {
    willChange: "no !important",
    overflowY: "auto !Important",
    "&::-webkit-scrollbar": {
      width: 0,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
      display: "none !Important",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        background: "#CCC",
      },
    },
    "& .startPhase": {
      zIndex: 3,
    },
    "& .descriptionPhase": {
      zIndex: 4,
      borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
    },
    "& .endPhase": {
      zIndex: 3,
      borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
    },
  },
  "& .gridHeaderCalendarDate": {
    willChange: "no !important",

    background: "#fff",
    "& .weekend": {
      background: "#F9F9F9",
    },
  },
  "& .gridVirtualized": {
    willChange: "no !important",
    background: "#fff",
    overflow: "auto !Important",
    "&::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        background: "#CCC",
      },
    },
    "& .weekend": {
      background: "#F9F9F9",
    },
    "& .task-middleSALES_ORDER": {
      background: hexToRgbA(
        AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        0.2
      ),
    },
    "& .task-endSALES_ORDER": {
      background: hexToRgbA(
        AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        0.2
      ),
      borderTopRightRadius: "4px !Important",
      borderBottomRightRadius: "4px !Important",
    },
    "& .task-startSALES_ORDER": {
      background: hexToRgbA(
        AppConfig.themeColors.salesOrderBackgroundGanttChartColor,
        0.2
      ),
      borderTopLeftRadius: "5px !Important",
      borderBottomLeftRadius: "5px !Important",
      height: "31px !Important ",
    },
    "& .task-middlePURCHASE_ORDER": {
      background: "#fff",
      borderBottom: "1px solid",
      borderTop: "1px solid",
      opacity: 0.4,
    },
    "& .task-endPURCHASE_ORDER": {
      background: "#fff",
      borderTopRightRadius: "4px !Important",
      borderBottomRightRadius: "4px !Important",
      borderBottom: "1px solid",
      borderTop: "1px solid",
      borderRight: "1px solid",
      opacity: 0.5,
    },
    "& .task-startPURCHASE_ORDER": {
      background: "#fff",
      borderTopLeftRadius: "5px !Important",
      borderBottomLeftRadius: "5px !Important",
      height: "31px !Important ",
    },
    "& .task-middleSHIPMENT": {
      background: hexToRgbA(
        AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        0.2
      ),
    },
    "& .task-endSHIPMENT": {
      background: hexToRgbA(
        AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        0.2
      ),
      borderTopRightRadius: "4px !Important",
      borderBottomRightRadius: "4px !Important",
    },
    "& .task-startSHIPMENT": {
      background: hexToRgbA(
        AppConfig.themeColors.shipmentBackgroundGanttChartColor,
        0.2
      ),
      borderTopLeftRadius: "5px !Important",
      borderBottomLeftRadius: "5px !Important",
      height: "31px !Important ",
    },
    "& .task-start-with-duration-1": {
      borderRadius: "5px !Important",
      background: "#D8E3F0",
      height: "31px !Important ",
    },
    "& svg": {
      zIndex: "1 !Important",
    },
  },
  "& .calendarHeader": {
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: 0,
    height: dayHeight,
    width: dayWidth,
    "& svg": {
      zIndex: "1 !Important",
    },
  },

  "& .phase": {
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: 0,
    height: dayHeight,
    width: dayWidth,
    justifyContent: "flex-start !Important",
    "& svg": {
      zIndex: "1 !Important",
    },
    "& .start-phase": {
      "&::before": {
        background: "rgb(141, 156, 177)",
        display: "flex",
        width: 3,
        height: 16,
        content: '""',
        position: "absolute",
        top: -6,
      },
    },
    "& .finish-phase": {
      "&::after": {
        background: "rgb(141, 156, 177)",
        display: "flex",
        width: 3,
        height: 16,
        content: '""',
        position: "absolute",
        top: -6,
        right: 0,
      },
    },
    "& .middle-phase": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#334C73",
      fontWeight: 700,
      fontSize: 12,
      background: "transparent !Important",
    },
    "& .middle-phase-range": {
      background: "transparent !Important",
    },
  },
  "& .draggable": {
    textAlign: "center",
    color: theme.palette.text.secondary,
    borderRadius: 0,
    height: dayHeight,
    width: dayWidth,
    justifyContent: "flex-start !Important",
    "& svg": {
      zIndex: "1 !Important",
    },
  },
  "& .paperTask": {
    textAlign: "center",
    borderRadius: 0,
    height: dayHeight,
    width: dayWidth,
    display: "flex",
    alignItems: "center",
    // THIS IS WHAT I CHANGE
    justifyContent: "flex-start !Important",
    "& svg": {
      zIndex: "1 !Important",
    },
  },

  "& .scrollToRightButtonContainer": {
    ...scrollButtonContainerStyles,
    right: 20,
    top: 120,
    "& .img-scroll-to-right": {
      cursor: "pointer",
    },
  },
  "& .scrollToLeftButtonContainer": {
    ...scrollButtonContainerStyles,
    right: 105,
    top: 120,
    "& .img-scroll-to-left": {
      transform: "rotate(180deg)",
      cursor: "pointer",
    },
  },
}));

export const ResizableComponentStyled = styled("div")(() => ({
  "&.resizableContainer": {
    background: "white",
    zIndex: 2,
    "& .rndContainer": {
      display: "flex !Important",
      alignItems: "center",
      justifyContent: "flex-start",
      border: "solid 1px transparent",
      zIndex: 22,
      borderRadius: 5,
      cursor: "ew-resize !Important",
      "& .text-description": {
        overflow: "hidden",
        height: 15,
        position: "absolute",
        margin: "0px 8px",
        textAlign: "left",
        fontSize: 12,
        maxWidth: "-webkit-fill-available",
        whiteSpace: "nowrap",
      },
      "& .resizeHandleWrapper": {
        "& div": {
          width: "6px !Important",
        },
      },
    },
    "& .blockRndContainer": {
      cursor: "unset !Important",
    },
    "& .taskContainerBox": {
      width: "100%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: dayHeight,
    },
  },
}));

export const TaskTableHeaderStyled = styled(Paper)(() => ({
  "&.taskTableHeaderContainer": {
    "& .tableHeaderText": {
      zIndex: 1,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: 12,
      color: "#395989",
      fontWeight: 400,
    },
    "& .headerText": {
      zIndex: 1,
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      textAlign: "center",
      fontSize: 12,
      color: "#395989",
      fontWeight: 400,
      width: "fit-content",
    },
  },
  "&.textHeader": {
    display: "grid",
    gridTemplateColumns: "27px 77px 51px 114px 37px 54px 36px 85px 80px 1fr",
    alignItems: "center",
    justifyContent: "start",
    height: 32,
    overflow: "hidden",
    transition: "all 0.5s",
    marginTop: 5,
  },
  [`&.${GANTTCHART_TABLE_SIZE.LARGE}HeaderTemplate`]: {
    gridTemplateColumns:
      "59px 103px 1fr 147px 0.77fr 99px 60px 100px 109px 125px 45px",
    margin: 0,
    background: colors.tableHeaderColor,
    "& .headerText": {
      color: colors.primaryDark,
      fontWeight: 700,
    },
  },
  [`&.${GANTTCHART_TABLE_SIZE.MEDIUM}HeaderTemplate`]: {
    gridTemplateColumns: "53px 71px 0.7fr 72px 0.75fr 56px 33px 75px",
    margin: 0,
    background: colors.tableHeaderColor,
    "& .headerText": {
      color: colors.primaryDark,
      fontWeight: 700,
    },
  },
  "&.bigLargeTemplate": {
    gridTemplateColumns:
      "57px 108px 0.86fr 145px 0.67fr 97px 60px 107px 109px 121px 86px 30px",
  },
}));

export const TablePhaseStyled = styled(Paper)(() => ({
  "& .PhaseText": {
    position: "absolute",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    color: "#334C73",
    textAlign: "left",
    fontSize: 14,
    fontWeight: 900,
    fontFamily: "Roboto",
    background: "#FFF",
    width: dayWidth,
    height: dayHeight,
    borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
  },
  "& .expandOrCollapseContainer": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    "& .disclosure-triangle": {
      cursor: "pointer",
    },
  },
}));

export const TaskPopoverStyled = styled(Paper)(() => ({
  "&.taskPopoverContainer": {
    padding: "0px 16px 16px 16px",
    position: "relative",
    zIndex: 10000,
  },
  "& .taskDescriptionBold": {
    fontSize: 16,
    fontWeight: 700,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 282,
    display: "inline-block",
  },
  "& .taskInfo": {
    fontSize: 14,
    fontWeight: 700,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 90,
    display: "inline-block",
    paddingLeft: 4,
  },
  "& .taskDescriptionNormal": {
    fontSize: 14,
    fontWeight: 400,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 210,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  "& .taskDescriptionContainer": {
    minHeight: 50,
    borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
    display: "flex",
    alignItems: "center",
    width: 300,
    padding: "12px 0px",
  },
  "& .taskInfoContainer": {
    height: 30,
    borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
    display: "flex",
    alignItems: "center",
  },
  "& .linkedPOsContainer": {
    height: "auto",
    padding: "4px 0px",
    borderBottom: "1px solid #C6D9EE",
    display: "flex",
    alignItems: "center",
  },
  "& .linkedPOsDescription": {
    fontSize: 14,
    fontWeight: 400,
    color: "#334C73",
    fontFamily: "Roboto",
    width: 210,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
}));

export const OptionsTableContainerPaperStyled = styled(Paper)(() => ({
  "&.optionsTableContainer": {
    "& .buttonViewOptions": {
      width: "100%",
      height: 30,
      background: "#fff",
      border: "1px solid #D0DCEA",
      fontSize: 13,
      color: "#334C73",
      fontWeight: "bold !Important",
      padding: 0,
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "transparent !Important",
        boxShadow: "none",
      },
      "&:focus": {
        border: "1px solid #D0DCEA",
      },
    },
    "& .undoAndRedoContainer": {
      width: 135,
      display: "flex",
      justifyContent: "space-between",
      border: "1px solid #D0DCEA",
      height: 30,
      alignItems: "center",
      borderRadius: 4,
    },
    "& .undoContainer": {
      width: "50%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      margin: 0,
      borderRight: "1px solid #D0DCEA",
      color: "#334C73",
      fontSize: 13,
      fontWeight: 400,
      cursor: "pointer",
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      background: "#fff",
      boxShadow: "none",
      "&:hover": {
        background: "#fff",
      },
    },
    "& .redoContainer": {
      width: "50%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      margin: 0,
      color: "#334C73",
      fontSize: 13,
      fontWeight: 400,
      cursor: "pointer",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      background: "#fff",
      boxShadow: "none",
      "&:hover": {
        background: "#fff",
      },
    },
    "& .viewOptionsAndMilestonesContainer": {
      height: 32,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

export const ViewOptionsPopoverStyled = styled(Popover)(() => ({
  "&.viewOptionsPopoverContainer": {
    "& .viewOptionsPopover": {
      width: 354,
      padding: 12,
      display: "flex",
      "& .view-options-left-container": {
        width: "50%",
      },
      "& .view-options-right-container": {
        width: "50%",
      },
      "& .view-option-item-container": {
        display: "flex",
        alignItems: "center",
        padding: "6px 0px",
        gap: 6,
        fontSize: 12,
        height: 30,
        "& span": {
          color: "#334C73",
          cursor: "pointer",
        },
      },
    },
  },
}));

export const MilestonePopoverStyled = styled(Popover)(() => ({
  "&.milestonesPopoverContainer": {},
  "& .milestonesPopover": {
    width: 365,
  },
  "& .milestoneHeader": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid #E0E9F8",
    height: 33,
    padding: 12,
    "& .text-container": {
      fontSize: 12,
      color: "#334C73",
      fontWeight: 400,
    },
  },
  "& .milestoneBody": {
    width: "100%",
    "& .milestone-container": {
      display: "flex",
      alignItems: "center",
      padding: "6px 12px",
      borderBottom: "1px solid #E0E9F8",
      "& span": {
        fontSize: 12,
        color: "#334C73",
        height: 12,
        width: "100%",
      },
    },
  },
  "& .milestoneFooter": {
    "& .add-milestone": {
      color: "#334C73",
      fontSize: 11,
      fontWeight: 700,
      padding: "4px 20px",
    },
  },
}));

export const MilestonesContainerStyled = styled("div")(() => ({
  "&.milestonesContainer": {
    "& .buttonViewOptions": {
      width: "100%",
      height: 30,
      background: "#fff",
      border: `1px solid ${colors.whiteBlue}`,
      padding: "10px 12px",
      fontSize: 13,
      color: "#334C73",
      fontWeight: "bold !Important",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "transparent !Important",
        boxShadow: "none",
      },
      "&:focus": {
        border: "1px solid #D0DCEA",
      },
    },
  },
}));

export const ColorPickerPopoverContainerStyled = styled(Popover)(() => ({
  "&.colorPickerPopoverContainer": {
    "& .colorPickerPaperContainer": {
      padding: 12,
    },
  },
}));

export const ColorPickerStyled = styled("div")(() => ({
  "&.colorPickerContainer": {
    display: "flex",
    position: "relative",
    zIndex: 1,
    width: 24,
    height: 24,
  },
  "& .arrowDropDownCircleWrapper": {
    position: "absolute",
    background: "white",
    borderRadius: "50%",
    width: 24,
    height: 24,
    "& svg": {
      width: "auto",
      height: "auto",
    },
  },
}));

export const ShipmentPickerPopoverContainerStyled = styled(Popover)(() => ({
  "&.shipmentPickerPopoverContainer": {
    maxHeight: "calc(100vh - 200px)",
    "& .shipmentPickerPaperContainer": {
      "& .shipment-item-container": {
        display: "flex",
        alignItems: "center",
        padding: "2px 6px",
        fontSize: 12,
        color: "#25486D",
        fontWeight: 400,
        borderBottom: "1px solid #F2F2F2",
        "&:hover": {
          background: onHoverBackground,
          fontWeight: 600,
        },
      },
    },
  },
}));

export const ShipmentPickerContainerStyled = styled("div")(() => ({
  shipmentPickerContainer: {
    margin: "0px 8px",
    display: "flex",
    position: "relative",
    zIndex: 1,
    width: 24,
    height: 24,
  },
}));

export const SalesOrderBadgeContainerStyled = styled("div")(() => ({
  "&.salesOrderBadgedWrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 12px 12px 20px",
    background: "#EBF4FF",
  },
  "& .salesOrderBadgedContainer": {
    display: "flex",
    alignItems: "center",
    gap: 40,
    "& .salesOrderNumber": {
      fontSize: 20,
      fontWeight: 900,
      display: "flex",
      gap: 7,
    },
  },
  "& .salesOrderButtonContainer": {
    "& .sobc-button-save-changes": {
      background: "#218Cff",
      color: "#fff",
      borderRadius: 5,
      fontSize: 14,
      fontWeight: 700,
      width: 160,
      height: 38,
    },
    "& .sobc-button-cancel": {
      border: "1px solid #8D9CB1",
      height: 38,
      width: 88,
      color: "#334C73",
      fontSize: 14,
      fontWeight: 700,
      marginLeft: 13,
      borderRadius: 5,
      background: "#FFF",
    },
  },
  "& .buttonRoot": {
    fontSize: 11,
    border: `1px solid ${colors.primaryDark}`,
    borderRadius: 5,
    height: 29,
    background: "#fff",
    color: "#334C73",
    fontWeight: 500,
    padding: 0,

    "& .verticalArrowsIcon": {
      transition: "all 0.6s",
      "& .topArrow": {
        transform: "translateY(0px)",
        transition: "all 0.6s",
      },
      "& .bottomArrow": {
        transform: "translateY(0px)",
        transition: "all 0.6s",
      },
    },
    "& .showPoIcon": {
      "& .topArrow": {
        transform: "translateY(-9px)",
      },
      "& .bottomArrow": {
        transform: "translateY(9px)",
      },
    },
    "&:active": {
      "& .verticalArrowsIcon": {
        "& .topArrow": {
          transform: "translateY(-1px)",
        },
        "& .bottomArrow": {
          transform: "translateY(1px)",
        },
      },
      "& .showPoIcon": {
        "& .topArrow": {
          transform: "translateY(-11px)",
        },
        "& .bottomArrow": {
          transform: "translateY(11px)",
        },
      },
    },
  },
  "& .buttonLabel": {
    padding: "8px 11px",
    display: "flex",
    alignItems: "center",
    gap: 6,
    fontWeight: 500,
  },
}));

export const PurchaseOrderBadgeStyled = styled("div")(() => ({
  "&.purchaseOrderBadgedContainer": {
    display: "flex",
    alignItems: "center",
    padding: "12px 20px",
    background: "#EBF4FF",
    flexWrap: "wrap",
    transition: "all 0.8s",
    opacity: 1,
  },
  "&.hiddenPos": {
    maxHeight: 0,
    padding: 0,
    opacity: 0,
  },
}));

export const POBadgeContainerStyled = styled("div")(() => ({
  "&.POBadgeContainer": {
    display: "flex",
    alignItems: "center",
    padding: "4px 6px",
    border: "2px solid",
    borderRadius: 5,
    borderLeft: "6px solid",
    margin: "5px 8px 5px 0px",
    gap: 8,
    "& .check-box": {},
    "& .description": {
      fontSize: 12,
      fontWeight: 700,
      color: "#334C73",
      cursor: "pointer",
      maxWidth: 160,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      "& span": {
        textOverflow: "ellipsis",
        overflow: "hidden",
      },
    },
  },
}));

export const TaskTableContainerStyled = styled("div")(() => ({
  "&.taskTableContainer": {
    "& .taskStatus": {
      zIndex: "4 !Important",
      "& .task-state-moved": {
        color: "#334C73",
        fontSize: 12,
        fontWeight: 400,
        opacity: 0.3,
        margin: "auto",
      },
      "& .task-state-to-move-forward": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 32,
        height: 25,
        textAlign: "center",
        boxShadow: "0px 2px 5px rgb(0 0 0 / 25%)",
        borderRadius: 33,
        color: "#E02020",
        fontSize: 14,
        fontWeight: 400,
        cursor: "pointer",
        marginLeft: 8,
        background: "#FFF",
      },
      "& .task-state-to-move-backward": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 32,
        height: 21,
        textAlign: "center",
        boxShadow: "0px 2px 5px rgb(0 0 0 / 25%)",
        borderRadius: 10,
        color: "#30D536",
        fontSize: 14,
        fontWeight: 400,
        cursor: "pointer",
        marginLeft: 8,
        background: "#FFF",
      },
    },
    "& .taskTableContentFirstColumn": {},
    "& .taskTableContent": {
      borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
      "& .task-start-date": {
        "& svg": {
          opacity: 0,
        },
        "&:hover": {
          "& svg": {
            opacity: 1,
          },
        },
      },
      "& .task-finish-date": {
        "& svg": {
          opacity: 0,
        },
        "&:hover": {
          "& svg": {
            opacity: 1,
          },
        },
      },
      "& .task-assigned-to": {
        "& .arrowButton": {
          opacity: 0,
        },
        "&:hover": {
          "& .arrowButton": {
            opacity: 1,
          },
        },
      },
      "& .task-duration": {
        "& .edit-icon": {
          opacity: 0,
        },
        "&:hover": {
          "& .edit-icon": {
            opacity: 1,
          },
        },
      },
    },
  },
}));

export const GanttChartStyled = styled("div")(() => ({
  "&.ganttChartContainer": {
    display: "flex",
    overflow: "hidden",
    height: "100%",
    padding: 12,
    flexDirection: "column",
    "& .task-content-ganttchart-header-container": {
      display: "flex",
      width: "100%",
      transition: "height 0.6s",
    },
    "& .task-content-and-ganttchart-container": {
      display: "flex",
      gap: 1,
      width: "100%",
      overflow: "hidden",
      height: "auto",
      position: "relative",
      "& .ganttchart-row-container": {
        position: "absolute",
        height: GANTT_CHART_SETTINGS.ROW_HEIGHT,
      },
      "& .expandCollapseContainer": {
        width: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        zIndex: 5,
        transition: "all 0.5s",
        height: 20,
        top: "48%",
        "& .expand-collapse-icon": {
          background: "#fff",
          borderRadius: "50%",
          color: colors.primaryDark,
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.5) !Important",
            transition: "all 0.5s",
          },
        },
      },
      "& .SMALL": {
        left: 130,
        transform: "rotate(270deg)",
      },
      "& .MEDIUM": {
        left: 570,
        transform: "rotate(90deg)",
      },
      "& .LARGE": {
        left: 690,
        transform: "rotate(90deg)",
      },
    },
    "& .scrollToRightButtonContainer": {
      ...scrollButtonContainerStyles,
      right: 50,
      top: 30,
      "& .img-scroll-to-right": {
        cursor: "pointer",
      },
    },
    "& .scrollToLeftButtonContainer": {
      ...scrollButtonContainerStyles,
      right: 135,
      top: 30,
      "& .img-scroll-to-left": {
        transform: "rotate(180deg)",
        cursor: "pointer",
      },
    },
  },
}));

export const GanttChartProviderStyled = styled("div")(() => ({
  "&.ganttChartContainer": {
    display: "block",
    overflow: "hidden",
    height: "calc(100vh - 307px)",
  },
  "& .timeLineContainerV2": {
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 40px)",
  },
}));

export const ViewOptionsContainerStyled = styled("div")(() => ({
  "&.viewOptionsContainer": {
    "& .buttonViewOptions": {
      width: "100%",
      height: 30,
      background: "#fff",
      border: `1px solid ${colors.whiteBlue}`,
      fontSize: 13,
      color: "#334C73",
      fontWeight: "bold !Important",
      boxShadow: "none",
      padding: 0,
      display: "flex",
      borderRadius: 5,
      "& .tooltipContainer": {
        display: "flex",
        gap: 6,
        alignItems: "center",
        padding: "10px 12px",
      },
      "&:hover": {
        backgroundColor: "transparent !Important",
        boxShadow: "none",
      },
      "&:focus": {
        border: "1px solid #D0DCEA",
      },
    },

    "& .checkboxRoot": {
      padding: 0,
      "& span": {
        "& svg": {
          width: 16,
          height: 16,
        },
      },
    },
  },
}));

export const GanttChartHeaderContainerStyled = styled("div")(() => ({
  "&.ganttchart-header-container": {
    gap: 1,
    transition: "width 0.6s, opacity 0.6s, height 0.6s",
    overflowX: "hidden",
    overflowY: "scroll",
    "& .calendar-header-container": {
      height: "100%",
      "& .header-content": {
        display: "flex",
        height: "40%",
        "& .calendar-header-cell": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "#E9E9E9",
          marginRight: 1,
          "& .calendar-header-span": {
            width: "inherit",
            height: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          },
        },
      },
      "& .subheader-content": {
        display: "flex",
        position: "relative",
        height: "60%",
        "& .calendar-header-cell": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          marginRight: 1,
          "& .calendar-header-span": {
            width: "inherit",
            height: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          },
        },
        "& .calendar-header-cell-daily": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          "& .calendar-header-span": {
            width: "inherit",
            height: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          },
        },
        "& .calendar-header-cell-weekend": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
          background: "rgba(0, 0, 0, 0.04)",
          "& .calendar-header-span": {
            width: "inherit",
            height: "inherit",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 12,
          },
        },
        "& .calendar-highligthed-range": {
          position: "absolute",
          background: colors.primaryDark,
          height: 32,
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          color: "white",
          minWidth: 20,
          fontSize: 11,
          zIndex: 2,
          "& span": {
            padding: "0px 4px",
          },
        },
        "& .milestone-header-displayed": {
          position: "absolute",
          top: 0,
          height: "100%",
          fontSize: 11,
          width: 27,
          borderTopLeftRadius: 5,
          borderTopRightRadius: 5,
          transform: "translate(-90%, 0)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          fontWeight: 600,
          textWrap: "wrap",
          zIndex: 1,
        },
      },
    },
  },
}));

export const TableContentSetupStyled = styled("div")(() => ({
  "&.tableContentSetup": {
    position: "sticky",
    top: 0,
    transition: "all 0.5s",
    flexDirection: "column",
    display: "flex",
    gap: 10,
    paddingBottom: 5,
    "& .setup-container": {
      display: "grid",
      paddingInline: 5,
      alignItems: "center",
      gap: 8,
      transition: "all 0.6s ease-in-out",
      "& .labelOption": {
        transition: "all 0.8s",
        whiteSpace: "nowrap",
      },
      "& .buttonViewOptions": {
        height: 30,
        background: "#fff",
        border: `1px solid ${colors.whiteBlue}`,
        fontSize: 13,
        color: "#334C73",
        fontWeight: "bold !Important",
        boxShadow: "none",
        display: "flex",
        borderRadius: 5,
        padding: "0px 3px",
        maxWidth: 130,
        "& .chrevronClass": {
          transition: "all 0.6s",
        },
        "& .tooltipContainer": {
          display: "flex",
          gap: 6,
          alignItems: "center",
          padding: 0,
        },
        "& .verticalArrowsIcon": {
          transition: "all 0.6s",
          "& .topArrow": {
            transform: "translateY(0px)",
            transition: "all 0.6s",
          },
          "& .bottomArrow": {
            transform: "translateY(0px)",
            transition: "all 0.6s",
          },
        },
        "& .showPoIcon": {
          "& .topArrow": {
            transform: "translateY(-9px)",
          },
          "& .bottomArrow": {
            transform: "translateY(9px)",
          },
        },
        "&:active": {
          "& .verticalArrowsIcon": {
            "& .topArrow": {
              transform: "translateY(-1px)",
            },
            "& .bottomArrow": {
              transform: "translateY(1px)",
            },
          },
          "& .showPoIcon": {
            "& .topArrow": {
              transform: "translateY(-11px)",
            },
            "& .bottomArrow": {
              transform: "translateY(11px)",
            },
          },
        },
        "&:hover": {
          backgroundColor: "transparent !Important",
          boxShadow: "none",
        },
        "&:focus": {
          border: "1px solid #D0DCEA",
        },
        "& .labelOption": {
          maxWidth: 120,
          opacity: 1,
        },
      },
      "& .smallButton": {
        width: "auto",
        minWidth: "unset",
        "& .tooltipContainer": {
          gap: 3,
          padding: 0,
        },
        "& .labelOption": {
          maxWidth: 0,
          overflow: "hidden",
          opacity: 0,
        },
      },

      "& .buttonGroup": {
        padding: "0px 7px",
      },
      "& .firstButton": {
        borderBottomRightRadius: "unset",
        borderTopRightRadius: "unset",
      },
      "& .lastButton": {
        borderBottomLeftRadius: "unset",
        borderTopLeftRadius: "unset",
      },
      "& .midPosition": {
        gridRow: 2,
        gridColumn: "span 4",
        justifyContent: "center",
        display: "flex",
        transition: "all 0.6s ease-in-out",
      },

      "& .openButton": {
        "& .chrevronClass": {
          transform: "rotate(180deg)",
        },
      },
      "& .checkboxRoot": {
        padding: 0,
        "& span": {
          "& svg": {
            width: 16,
            height: 16,
          },
        },
      },

      "& .undoAndRedoContainer": {
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid #D0DCEA",
        alignItems: "center",
        borderRadius: 4,
        fontSize: 13,
        fontWeight: "bold",
      },
      "& .milestonesContainer": {
        "& .buttonViewOptions": {
          padding: 6,
          "& .img-milestone": {
            display: "none",
          },
        },
      },
    },
    "& .setup-container-collapse": {
      gap: 3,
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    "& .setup-container-big": {
      gridTemplateColumns: "repeat(5, 1fr)",
      gap: 10,
    },
    "& .setup-container-mid": {
      gridTemplateColumns: "repeat(4, 1fr)",
      gap: 5,
    },
    "& .setup-template-big": {
      gridTemplateColumns: "repeat(2, 130px)",
      gap: 10,
    },
    "& .setup-template-small": {
      gridTemplateColumns: "repeat(2, 1fr)",
      gap: 5,
    },

    "& .task-content-headers": {
      background: "lightgreen",
      height: "50%",
      width: "100%",
    },
  },
}));

export const GanttChartWrapperStyled = styled("div")(() => ({
  "&.ganttchart-wrapper": {
    overflow: "scroll",
    display: "flex",
    gap: 1,
    position: "relative",
    transition: "0.6s width",
    "&::-webkit-scrollbar": {
      width: 6,
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
      borderRadius: 7,
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.thumbScrollColor,
      },
    },
    "& .phase-divider": {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 2,
      "&::before": {
        content: "''",
        width: 2,
        height: 12,
        background: "#8D9CB1",
      },
      "&::after": {
        content: "''",
        width: 2,
        height: 12,
        background: "#8D9CB1",
      },
      "& .phase-divider-text-container": {
        height: 2,
        width: "100%",
        background: "#8D9CB1",
        color: colors.primaryDark,
        fontWeight: 700,
        fontSize: 12,
        display: "flex",
        justifyContent: "center",
        "& span": {
          background: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: 100,
          height: "fit-content",
          transform: "translateY(-50%)",
        },
      },
    },
    "& .vertical-highlighted-task": {
      position: "absolute",
      top: 0,
      background: "#CDE7FF",
      opacity: 0.3,
      zIndex: 2,
      borderLeft: `1px solid #7E94AC`,
      borderRight: `1px solid #7E94AC`,
    },
    "& .milestone-ganttchart-displayed": {
      position: "absolute",
      top: 0,
      width: 2,
      zIndex: 0,
    },
    "& .weekend-ganttchart-displayed": {
      position: "absolute",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
}));

export const TaskTableStyled = styled("div")(() => ({
  "&.soTaskTable": {
    overflowY: "hidden",
    overflowX: "scroll",
    transition: "all 0.5s",
    color: colors.primaryDark,
    "& .taskList": {
      overflow: "hidden",
      transition: "all 0.5s",
      padding: "0px 0px 0px 24px",
    },
    "& .stageTitle": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#334C73",
      textAlign: "left",
      fontSize: 14,
      fontWeight: 900,
      fontFamily: "Roboto",
      background: "#FFF",
      overflow: "hidden",
      height: dayHeight,
      marginLeft: -23,
      "& .titleStage": {
        borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        opacity: 1,
        transition: "all 0.5s",
        justifyContent: "space-between",
        "& .addTask": {
          cursor: "pointer",
          marginRight: 11,
        },
      },
      "& .collapseListTask": {
        opacity: 0,
        width: 15,
      },
      "& .itemDisclouser": {
        borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      "& .arrowContainer": {
        borderRadius: 5,
        display: "flex",
        marginRight: 5,
        "& .arrow": {
          fontSize: "1.5rem",
          cursor: "pointer",
          transition: "transform 0.3s ease-in-out",
        },
        "&:hover": {
          background: "#E5E5E5",
        },
      },
      "& .expandOrCollapseContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 15,
        cursor: "pointer",
        transition: "transform 0.5s ease-in-out",
        "& disclosure-triangle": {
          transition: "transform 0.5s ease-in-out",
        },
      },
    },
    "& .taskColorColumn": {
      width: 28,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      zIndex: 2,
      cursor: "alias",
    },

    "&::-webkit-scrollbar": {
      width: 6,
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "transparent",
      borderRadius: 7,
    },
    "&:hover": {
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: colors.thumbScrollColor,
      },
    },
  },
  "&.taskTable": {
    overflowY: "hidden",
    overflowX: "scroll",
    transition: "all 0.5s",
    "& .taskList": {
      overflow: "hidden",
      transition: "all 0.5s",
      padding: "0px 0px 0px 24px",
    },
    "& .stageTitle": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      color: "#334C73",
      textAlign: "left",
      fontSize: 14,
      fontWeight: 900,
      fontFamily: "Roboto",
      background: "#FFF",
      overflow: "hidden",
      height: dayHeight,
      "& .titleStage": {
        borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        opacity: 1,
        transition: "all 0.5s",
        "& .diamondPhase": {
          opacity: 1,
          marginRight: 4,
          width: 16,
          transition: "all 0.5s",
        },
        "& .diamondHidden": {
          opacity: 0,
          marginRight: 0,
          width: 0,
        },
      },
      "& .collapseListTask": {
        opacity: 0,
        width: 15,
      },
      "& .itemDisclouser": {
        borderBottom: `1px solid ${AppConfig.themeColors.dividerColor}`,
        height: "100%",
        display: "flex",
        alignItems: "center",
      },
      "& .arrowContainer": {
        borderRadius: 5,
        display: "flex",
        marginRight: 5,
        "& .arrow": {
          fontSize: "1.5rem",
          cursor: "pointer",
          transition: "transform 0.3s ease-in-out",
        },
        "&:hover": {
          background: "#E5E5E5",
        },
      },
      "& .expandOrCollapseContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 15,
        cursor: "pointer",
        transition: "transform 0.5s ease-in-out",
        "& disclosure-triangle": {
          transition: "transform 0.5s ease-in-out",
        },
      },
    },
  },
  "& .taskItem": {
    height: GANTT_CHART_SETTINGS.ROW_HEIGHT,
    borderBottom: `1px solid ${colors.dividerColor}`,
    fontSize: 12,
    display: "grid",
    alignItems: "center",
    gap: 15,
    position: "relative",
    transition: "all 0.5s ease",
    "& .calendarIcon": {
      opacity: 0,
      minWidth: 10,
      minHeight: 11,
      marginTop: -2,
      marginLeft: 2,
    },
    "& .description": {
      display: "flex",
      overflow: "hidden",
      paddingInline: 11,
      transition: "all 0.6s ease",
      alignItems: "center",
      gap: 5,
      zIndex: 2,
      "& .tooltTipSpan": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        transition: "all 0.6s ease",
      },
    },
    "& .iconBadge": {
      display: "flex",
      justifyContent: "center",
      "& .task-state-moved": {
        color: "#334C73",
        fontSize: 12,
        fontWeight: 400,
        margin: "auto",
      },
      "& .task-state-to-move-forward": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 32,
        height: 25,
        textAlign: "center",
        boxShadow: "0px 2px 5px rgb(0 0 0 / 25%)",
        borderRadius: 33,
        color: "#E02020",
        fontSize: 14,
        fontWeight: 400,
        cursor: "pointer",
        background: "#FFF",
        zIndex: 1,
      },
      "& .task-state-to-move-backward": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 32,
        height: 21,
        textAlign: "center",
        boxShadow: "0px 2px 5px rgb(0 0 0 / 25%)",
        borderRadius: 10,
        color: "#30D536",
        fontSize: 14,
        fontWeight: 400,
        cursor: "pointer",
        background: "#FFF",
        zIndex: 1,
      },
    },
    "& .date": {
      fontWeight: 700,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: 5,
      "&:hover": {
        "& .calendarIcon": {
          opacity: 1,
        },
      },
    },
    "& .userData": {
      display: "flex",
      alignItems: "center",
      fontWeight: 700,
      cursor: "pointer",
      gap: 5,
      position: "relative",
      "& .avatarArrow": {
        opacity: 0,
        minWidth: 12,
        width: 12,
        position: "absolute",
        right: -13,
      },
      "& .userName": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      "&:hover": {
        "& .avatarArrow": {
          opacity: 1,
        },
      },
    },
    "& .taskColorColumn": {
      width: 28,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      zIndex: 2,
      cursor: "alias",
    },
    [`& .border${TASK_TYPE.SALES_ORDER}`]: {
      background: colors.salesOrderSelectedBorderColor,
    },
    [`& .border${TASK_TYPE.SHIPMENT}`]: {
      background: colors.shipmentSelectedBorderColor,
    },
  },

  "& .bigGrid": {
    gridTemplateColumns: bigGrid,
  },

  "& .completedTaskContainer": {
    opacity: 0.55,
    position: "absolute",
    width: "100%",
    background: "white",
    height: "100%",
    zIndex: 2,
  },
  [`& .task${TASK_TYPE.SALES_ORDER}`]: {
    background: colors.salesOrderBackgroundColor,
  },
  [`& .task${TASK_TYPE.PURCHASE_ORDER}`]: {
    background: colors.purchaseOrderActivityBackgroundColor,
  },
  [`& .task${TASK_TYPE.SHIPMENT}`]: {
    background: colors.shipmentActivityBackgroundColor,
  },
  "& .regularGrid": {
    gridTemplateColumns: "35px 20px 1fr 60px 80px 28px",
  },

  "& .collapseListGrid": {
    gridTemplateColumns: "25px 1fr 28px",
    gap: 8,
  },
}));

export const TemplateItemStyled = styled("div")(() => ({
  "&.itemTemplate": {
    height: GANTT_CHART_SETTINGS.ROW_HEIGHT,
    borderBottom: `1px solid ${colors.dividerColor}`,
    fontSize: 12,
    display: "grid",
    alignItems: "center",
    gap: 15,
    position: "relative",
    transition: "all 0.5s ease",
    "& .taskNumber": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover, &:active": {
        "& .dragClass": {
          "& circle": {
            fill: "black",
            r: 1.5,
          },
        },
      },
    },
    "& .userData": {
      display: "flex",
      alignItems: "center",
      fontWeight: 700,
      cursor: "pointer",
      gap: 5,
      position: "relative",
      "& .avatarArrow": {
        opacity: 0,
        minWidth: 12,
        width: 12,
      },
      "& .userName": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      "&:hover": {
        "& .avatarArrow": {
          opacity: 1,
        },
      },
    },
  },
  [`&.${GANTTCHART_TABLE_SIZE.LARGE}Grid`]: {
    gridTemplateColumns:
      "20px 90px 1fr 130px 0.75fr 70px 70px 90px 95px 95px 20px 0px",
  },
  [`&.${GANTTCHART_TABLE_SIZE.MEDIUM}Grid`]: {
    gridTemplateColumns: "20px 60px 1fr 61px 1fr 46px 45px 25px 28px",
    gap: 10,
  },
  [`&.${GANTTCHART_TABLE_SIZE.SMALL}Grid`]: {
    gridTemplateColumns: "20px 33px 1fr 28px",
    gap: 5,
  },
  "&.bigLargeTemplate": {
    gridTemplateColumns:
      "20px 90px 1fr 130px 0.75fr 70px 70px 90px 110px 110px 30px 20px 0px",
  },
  "& .selectedGrid": {
    display: "flex",
    justifyContent: "center",
    background: "white",
    fontSize: 12,
  },

  "& .MuiButtonBase-root": {
    fontSize: 12,
  },
}));
