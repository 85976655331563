import React, { useContext, useEffect, useState } from "react";
import { GanttChartStyled } from "./styles";
import GanttChartHeader from "./GanttChartHeader";
import TaskContent from "./TaskContent";
import {
  GANTTCHART_TABLE_SIZE,
  GANTT_CHART_ACTION,
  GanttChartContext,
} from "./GanttChartContext";
import TableContentSetup from "./TableContentSetup";
import GanttChart from "./GanttChart";
import { sortObjectsBy } from "../../helpers/helpers";
import TooltipTD from "../Tooltip/TooltipTD";
import { cx } from "@emotion/css";
import { ExpandCircleDown } from "@mui/icons-material";
const CALENDAR_SETUP = {
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
  QUARTERLY: "QUARTERLY",
};

function GanttChartV2({
  allTasks = [],
  tasks = [],
  onChangeTaskDuration = () => {},
  onDropTask = () => {},
  canRedo,
  canUndo,
  redoTasksState,
  undoTasksState,
  onChangeTask,
  handleMoveRemainingTasks,
  shipments,
  purchaseOrders,
  unfilteredTasks,
}) {
  const { ganttChartState, dispatchGanttChart } = useContext(GanttChartContext);
  const { calendarSetup, startDate, endDate, collapseListTask, tableSize } =
    ganttChartState;
  const [calendarRange, setCalendarRange] = useState({
    headers: [],
  });

  useEffect(() => {
    const days = [];
    const startDateCpy = startDate.clone();
    while (startDateCpy.isBefore(endDate)) {
      const startOfWeek = startDateCpy.clone().startOf("isoWeek");
      const endOfWeek = startDateCpy.clone().endOf("isoWeek");
      const day = startDateCpy.format("D");
      const dayCharacter = startDateCpy.format("dd")[0];
      const week = `${startOfWeek.format("MMM")} ${startOfWeek.format(
        "D"
      )} - ${endOfWeek.format("D")}`;
      const month = startDateCpy.format("MMM YYYY");
      const monthLabel = startDateCpy.format("MMMM");
      const quarter = `Q${startDateCpy.format("Q YYYY")}`;
      const isSaturday = startDateCpy.day() === 6 || startDateCpy.day() === 0;
      days.push({
        day,
        dayCharacter,
        [CALENDAR_SETUP.WEEKLY]: week,
        [CALENDAR_SETUP.MONTHLY]: month,
        monthLabel,
        [CALENDAR_SETUP.QUARTERLY]: quarter,
        fullDate: startDateCpy.format("YYYY-MM-DD"),
        isSaturday,
      });
      startDateCpy.add(1, "days");
    }
    setCalendarRange({
      headers: days,
    });
  }, [startDate, endDate]);

  return (
    <GanttChartStyled className="ganttChartContainer">
      <div
        className="task-content-ganttchart-header-container"
        style={{
          height:
            tableSize === GANTTCHART_TABLE_SIZE.LARGE && !collapseListTask
              ? 86
              : 72,
          minHeight:
            tableSize === GANTTCHART_TABLE_SIZE.LARGE && !collapseListTask
              ? 86
              : 72,
        }}
      >
        <TableContentSetup
          canRedo={canRedo}
          canUndo={canUndo}
          redoTasksState={redoTasksState}
          undoTasksState={undoTasksState}
        />
        <GanttChartHeader
          calendarRange={calendarRange}
          calendarSetup={calendarSetup}
        />
      </div>
      <div className="task-content-and-ganttchart-container">
        <TaskContent
          tasks={tasks}
          onChangeTask={onChangeTask}
          handleMoveRemainingTasks={handleMoveRemainingTasks}
          unfilteredTasks={unfilteredTasks}
        />
        <div
          className={cx(
            "expandCollapseContainer",
            collapseListTask ? GANTTCHART_TABLE_SIZE.SMALL : tableSize
          )}
        >
          <TooltipTD
            label={!collapseListTask ? "Collapse Table" : "Expand Table"}
          >
            <ExpandCircleDown
              onClick={() => {
                dispatchGanttChart({
                  type: GANTT_CHART_ACTION.COMMON,
                  payload: {
                    collapseListTask: !collapseListTask,
                  },
                });
              }}
              className={"expand-collapse-icon"}
            />
          </TooltipTD>
        </div>
        <GanttChart
          allTasks={allTasks}
          tasks={tasks.sort(sortObjectsBy("numberIndex", false))}
          calendarRange={calendarRange}
          onChangeTaskDuration={onChangeTaskDuration}
          onDropTask={onDropTask}
          shipments={shipments}
          purchaseOrders={purchaseOrders}
        />
      </div>
    </GanttChartStyled>
  );
}

export default GanttChartV2;
