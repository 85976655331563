import React from "react";
import DeleteIcon from "../../assets/flag-icons/delete-placeholder.png";
import moment, { now } from "moment";
// import { trackEvent } from "../../helpers/analytics";
import { dbTables, reduxState, typeOfTask } from "../../api/types/dbTables";
import { getCorrectTimezone } from "../../helpers/ganttChart";
import { salesOrderTriggerTypes } from "../../helpers/salesOrder";
import { purchaseOrderTriggerTypes } from "../../helpers/purchaseOrder";
import { shipmentTriggerTypes } from "../../helpers/shipment";
import { firestore } from "../../firebase";
import { getStatusTask } from "../../helpers/tasks";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { DELETE_DATA_WITH_CHILD } from "../../actions/types";
import { useDispatch } from "react-redux";
import TimerLoader from "../Loaders/TimerLoader";

function TaskOptions({
  item,
  newClassToDessapear,
  countingSeconds,
  currentUser,
  onEditTask,
  editingAdHocTask,
  recoverLastTask,
  companyUsers,
  handleUpdateTotalTask = () => {},
  userEditId,
}) {
  const dispatch = useDispatch();

  function getSecond(second) {
    const parseSecond = second < 0 ? 0 : second;
    return second > 9 ? parseSecond : "0" + parseSecond;
  }
  return (
    <li className={newClassToDessapear} id={newClassToDessapear}>
      <div>
        {editingAdHocTask ? (
          <div>
            <button
              style={{
                background: "rgba(168,182,200,0.15)",
                color: "#6F7E8E",
                height: "23px",
                width: "61px",
                borderRadius: "4px",
                border: "1px solid #6F7E8E",
              }}
              onClick={() => {
                updateDoc(item.ref, { creationDate: now() });
                onEditTask(false);
                recoverLastTask();
              }}
            >
              CANCEL
            </button>
            <button
              style={{
                background: "#39F",
                height: "23px",
                width: "98px",
                borderRadius: "4px",
              }}
              disabled={!(item.description || "").trim()}
              onClick={() => {
                updateDoc(
                  doc(
                    firestore,
                    `${item.ref.parent.parent.path}/${dbTables.ACTIVITIES}/${item.id}`
                  ),
                  {
                    creationDate: now(),
                    detail: `<strong>${
                      item.description
                    }</strong> and assigned it to <strong>@${
                      companyUsers.find((user) => user.id === item.assignedTo)
                        .displayName
                    }</strong>`,
                  }
                );
                const finishDate = getCorrectTimezone({
                  date: moment(item.finishDate).endOf("day").valueOf(),
                });
                const startDate = getCorrectTimezone({
                  date: moment(item.startDate).startOf("day").valueOf(),
                });
                const diffDays = finishDate.diff(startDate, "day");
                updateDoc(item.ref, {
                  creationDate: now(),
                  description: item.description,
                  assignedTo: item.assignedTo,
                  finishDate: finishDate.valueOf(),
                  duration: diffDays,
                  status: getStatusTask({
                    ...item,
                    creationDate: now(),
                    description: item.description,
                    assignedTo: item.assignedTo,
                    finishDate: finishDate.valueOf(),
                  }),
                });
                if (item.type === typeOfTask.SALES_ORDER) {
                  updateDoc(
                    doc(
                      firestore,
                      `${dbTables.COMPANIES}/${item.companyId}/${dbTables.SALES_ORDERS}/${item.salesOrderId}`
                    ),
                    {
                      triggerType: salesOrderTriggerTypes.COMPLETED_TASKS,
                    }
                  );
                } else if (item.type === typeOfTask.PURCHASE_ORDER) {
                  updateDoc(
                    doc(
                      firestore,
                      `${dbTables.COMPANIES}/${item.companyId}/${dbTables.PURCHASE_ORDERS}/${item.purchaseOrderId}`
                    ),
                    {
                      triggerType: purchaseOrderTriggerTypes.COMPLETED_TASKS,
                    }
                  );
                } else {
                  updateDoc(
                    doc(
                      firestore,
                      `${dbTables.COMPANIES}/${item.companyId}/${dbTables.SHIPMENTS}/${item.shipmentId}`
                    ),
                    {
                      triggerType: shipmentTriggerTypes.COMPLETED_TASKS,
                    }
                  );
                }

                // trackEvent(
                //   `PO Dashboard - Tasks - ${
                //     item.SOT ? "SO" : "PO"
                //   } - Edited Ad-Hoc`
                // );
                onEditTask(false);
              }}
            >
              SAVE
            </button>
          </div>
        ) : (
          <div
            className="task-edit-and-delete-option"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TimerLoader
              onEditTask={() => onEditTask(true)}
              seconds={countingSeconds}
              userEditId={userEditId}
              userId={currentUser.id}
            />
            <label
              className="edit-task"
              onClick={
                userEditId === currentUser.id
                  ? () => {
                      onEditTask(true);
                    }
                  : () => {}
              }
            >
              Edit Task
            </label>
            <label style={{ margin: 0, padding: "0 8px", color: "gray" }}>
              {" "}
              00:{getSecond(countingSeconds)} to edit
            </label>
          </div>
        )}
      </div>
      <div>
        <img
          src={DeleteIcon}
          alt="delete"
          className="delete-task"
          onClick={(ev) => {
            deleteDoc(
              doc(
                firestore,
                `${item.ref.parent.parent.path}/${dbTables.ACTIVITIES}/${item.id}`
              )
            );
            onEditTask(false);
            deleteDoc(item.ref);
            dispatch({
              type: DELETE_DATA_WITH_CHILD,
              childKey:
                item.type === typeOfTask.SALES_ORDER
                  ? item.salesOrderId
                  : item.type === typeOfTask.PURCHASE_ORDER
                  ? item.purchaseOrderId
                  : item.shipmentId,
              table:
                item.type === typeOfTask.SALES_ORDER
                  ? reduxState.SALES_ORDER_ACTIVITIES
                  : item.type === typeOfTask.PURCHASE_ORDER
                  ? reduxState.PURCHASE_ORDER_ACTIVITIES
                  : reduxState.SHIPMENT_ACTIVITIES,
              payload: item.id,
            });

            if (item.type === typeOfTask.SHIPMENT) {
              handleUpdateTotalTask({
                type: typeOfTask.SHIPMENT,
                offset: -1,
                shipmentId: item.shipmentId,
              });
            } else if (item.type === typeOfTask.SALES_ORDER) {
              handleUpdateTotalTask({
                type: typeOfTask.SALES_ORDER,
                offset: -1,
              });
            } else {
              handleUpdateTotalTask({
                type: typeOfTask.PURCHASE_ORDER,
                offset: -1,
              });
            }
            ev.stopPropagation();
          }}
        />
      </div>
    </li>
  );
}

export default TaskOptions;
