export const dbTables = {
  USERS: "users",
  FACTORIES: "factories",
  COMPANIES: "companies",
  CUSTOMERS: "customers",
  SALES_ORDER_TASKS: "sales_order_tasks",
  SALES_ORDER_TEMPLATE: "sales_order_template",
  SALES_ORDER_TASKS_TEMPLATE: "sales_order_tasks_template",
  SALES_ORDERS: "sales_orders",
  PURCHASE_ORDERS: "purchase_orders",
  DOCUMENTS: "documents",
  DOCUMENT_VERSIONS: "document_versions",
  MINI_PROJECT_TEMPLATE: "mini_project_template",
  PURCHASE_ORDER_TASKS: "purchase_order_tasks",
  SALES_ORDER_DOCUMENTS_TEMPLATE: "sales_order_documents_template",
  SALES_ORDER_DOCUMENTS: "sales_order_documents",
  PURCHASE_ORDER_DOCUMENTS_TEMPLATE: "purchase_order_documents_template",
  PURCHASE_ORDER_DOCUMENTS: "purchase_order_documents",
  FACTORY_DOCUMENTS: "factory_documents",
  CUSTOMER_DOCUMENTS: "customer_documents",
  ROLES: "roles",
  PERMISSIONS: "permissions",
  PERMISSION_GROUPS: "permission_groups",
  COMPANY_PERMISSION: "company_permission",
  DOCUMENT_USERS: "document_users",
  NOTIFICATIONS: "notifications",
  ACTIVITIES: "activities",
  IMPORTED_DATA_QB: "imported_data_QB",
  API_ERROR_LOG: "api_error_log",
  BACKEND_ERROR_LOG: "backend_error_log",
  API_IMPORTED_DATA: "api_imported_data",
  TAGS: "tags",
  FEATURE_FLAGS: "feature_flags",
  CUSTOMERS_SUMMARY: "customers_summary",
  FACTORIES_SUMMARY: "factories_summary",
  SHIPMENTS: "shipments",
  CURRENT_SHIPMENT: "currentShipment",
  CURRENT_CUSTOMER: "currentCustomer",
  CURRENT_FACTORY: "currentFactory",
  SHIPMENT_TASKS: "shipment_tasks",
  ITEM_TABLE: "item_table",
  ITEMS: "items",
  SHIPMENT_DOCUMENTS_TEMPLATE: "shipment_documents_template",
  PRODUCTS: "products",
  SHIPMENT_DOCUMENTS: "shipment_documents",
  SHARED_LINKS: "shared_links",
  CUSTOM_SETTINGS: "custom_settings",
  REPORTS: "reports",
  GL_ACCOUNTS: "GL_accounts",
  AUTOCOMPLETE_LISTS: "autocomplete_lists",
  EXPENSES: "expenses",
  DOCUMENTS_TO_TRAIN: "documents_to_train",
  DRAFTS: "drafts",
  SALES_ORDER_FAVORITES: "sales_order_favorites",
  PURCHASE_ORDER_FAVORITES: "purchase_order_favorites",
  SHIPMENT_FAVORITES: "shipment_favorites",
  REPORT_FAVORITES: "report_favorites",
  TEMPORAL_NOTE: "temporal_note",
  CATEGORIES: "categories",
  MISCELLANEOUS: "miscellaneous",
  MENTIONS: "mentions",
  SALES_ORDER_TEMPLATE_CHANGE_LOG: "sales_order_template_change_log",
  FACTORY_TEMPLATE_CHANGE_LOG: "factory_template_change_log",
  FACTORY_TASK_TEMPLATE_VIEW: "factory_task_template_view",
};

export const dbDocuments = {
  UNREAD_ACTIVITY_USERS: "unread_activity_users",
};
export const typeModalPermission = {
  FILE_ROOT_UPLOAD: "FILE_ROOT_UPLOAD",
  FILE_CHILD_UPLOAD: "FILE_CHILD_UPLOAD",
};

export const apiImportDataType = {
  CREATE_QUOTE: "CREATE_QUOTE",
  UPDATE_QUOTE: "UPDATE_QUOTE",
  CREATE_SALES_ORDER: "CREATE_SALES_ORDER",
  UPDATE_SALES_ORDER: "UPDATE_SALES_ORDER",
  CREATE_PURCHASE_ORDER: "CREATE_PURCHASE_ORDER",
  UPDATE_PURCHASE_ORDER: "UPDATE_PURCHASE_ORDER",
  QUOTE_TO_SALES_ORDER: "QUOTE_TO_SALES_ORDER",
  RFQ_TO_PURCHASE_ORDER: "RFQ_TO_PURCHASE_ORDER",
};

export const globalScopes = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
};

export const typeOfTask = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
};

export const reduxState = {
  PURCHASE_ORDER_ACTIVITIES: "purchase_order_activities",
  SALES_ORDER_ACTIVITIES: "sales_order_activities",
  SHIPMENT_ACTIVITIES: "shipment_activities",
  REPORTS_VIEW: "reports_view",
  SALES_ORDER_UNREAD_ACTIVITY_USERS: "sales_order_unread_activity_users",
  PURCHASE_ORDER_UNREAD_ACTIVITY_USERS: "purchase_order_unread_activity_users",
  SHIPMENT_UNREAD_ACTIVITY_USERS: "shipment_unread_activity_users",
};

export const USER_CUSTOM_SETTINGS = {
  ITEM_TABLE_HEADERS: "item_table_headers",
  SCREEN_HEADERS: "screen_headers",
};

export const typeOfExpense = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
};

export const ORDER_TYPE = {
  SALES_ORDER: "SALES_ORDER",
  PURCHASE_ORDER: "PURCHASE_ORDER",
  SHIPMENT: "SHIPMENT",
  QUOTE: "QUOTE",
  PURCHASE_QUOTE: "PURCHASE_QUOTE",
};
