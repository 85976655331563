/**
 * Nav Menu Item
 */
import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
// intl messages
import IntlMessages from "../../util/IntlMessages";
import { usePermissions } from "../../hooks/permissions";
import userTypes from "../../api/types/userTypes";
import { useCompanies, useCompanyId } from "../../hooks";
import { useUser } from "../../hooks/user";
import { REPORTS_VIEW } from "../../actions/types";
import { reduxState } from "../../api/types/dbTables";
import { WarningVendorIcon } from "../../helpers/iconSvgPath";
import { useSummaryFactories } from "../../hooks/factories";
import TooltipTD from "../Tooltip/TooltipTD";
import { useFeatureFlags } from "../../hooks/featureFlags";
import { hasFeatureFlagPermission } from "../../helpers/helpers";
import { Badge, ListItemButton } from "@mui/material";
import { cx } from "@emotion/css";
import { NavigationLinkStyled } from "./styles";

function NavMenuItem({ menu }) {
  const user = useUser() || {};
  const location = useLocation();
  const companyId = useCompanyId();
  const [company = {}] = useCompanies();
  const dispatch = useDispatch();
  const summaryFactories = useSummaryFactories();
  const featureFlags = useFeatureFlags({ companyId });

  const permissions = usePermissions();
  if (!user.active) {
    return null;
  }
  const checkFeatureFlag = menu.permissionFlag
    ? hasFeatureFlagPermission({
        featureFlags,
        user,
        featureFlagName: menu.permissionFlag,
      })
    : true;
  if (!checkFeatureFlag && user.role !== userTypes.TRADEDASH_EMPLOYEE) {
    return null;
  }
  function verifyPermissions(permissions, itemId) {
    if (user.role === userTypes.TRADEDASH_EMPLOYEE) {
      if (!user.observedCompany && itemId === "settings") {
        return true;
      } else if (user.observedCompany) {
        return true;
      } else {
        return false;
      }
    }
    if (!company.enabled) return false;
    if (user.role === userTypes.SUPER_ADMIN) return true;
    return permissions.some(
      (permission) => permission.enabled && permission.id === itemId
    );
  }

  const getBadge = () => {
    if (menu.menu_title !== "Vendors") {
      return 0;
    } else {
      let hiddenBadge = false;
      summaryFactories.forEach((summary) => {
        const factories = summary.factories;
        const hasOutdated = factories.some((factory) => factory.isOutdated);
        hiddenBadge = hiddenBadge || hasOutdated;
      });
      return (
        hiddenBadge && (
          <TooltipTD
            label={
              <span>
                One or more vendor templates need <br /> to be updated
              </span>
            }
          >
            <WarningVendorIcon
              width={17}
              height={15}
              style={{ marginLeft: 7, marginTop: 5 }}
            />
          </TooltipTD>
        )
      );
    }
  };

  function getAllowedRoutes() {
    if (verifyPermissions(permissions, menu.permission_name)) {
      return false;
    }
    return true;
  }

  let path = menu.path;
  if (
    menu.path.includes("/app/report") &&
    window.location.href.includes("/app/report")
  ) {
    path = window.location.href;
  }
  return (
    <ListItemButton
      disabled={getAllowedRoutes()}
      style={{
        flexDirection: "column",
        display: "flex",
        padding: 0,
      }}
      button
      component="li"
    >
      <NavigationLinkStyled
        className={cx(
          "navigation-link-container",
          location.pathname.includes(menu.path) ? "active" : ""
        )}
        to={path}
        onClick={() => {
          if (menu.menu_title === "Reports") {
            dispatch({
              type: REPORTS_VIEW,
              table: reduxState.REPORTS_VIEW,
              payload: {
                open: true,
              },
            });
          }
        }}
      >
        <Badge
          badgeContent={getBadge()}
          classes={{ badge: "navLink-badge" }}
          overlap="circular"
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
        >
          {menu.svgIcon && <div>{menu.menu_icon}</div>}
          {!menu.svgIcon && (
            <img
              id="sidebar-menu-icons-img"
              width={28}
              height={28}
              src={menu.menu_icon}
              style={{ objectFit: "center" }}
              alt={"menu_icon"}
            />
          )}
        </Badge>
        <span
          style={{ textAlign: "center", fontSize: 10, marginTop: 4 }}
          className="menu"
        >
          <IntlMessages id={menu.menu_title} />
        </span>
        {location.pathname.includes(menu.path) && <div className="blue-bar" />}
      </NavigationLinkStyled>
    </ListItemButton>
  );
}

export default NavMenuItem;
