import React, { useState, useEffect } from "react";
import moment from "moment";
import { useUser } from "../../hooks/user";

import AttentionModal from "../Modal/AttentionModal";
import { TASK_FIELDS, getStatusTask } from "../../helpers/tasks";
// import { trackEvent } from "../../helpers/analytics";
import TaskItem from "./TaskItem";
import TaskOptions from "./TaskOptions";
import { createActivityEntryFromMovedTask } from "../../helpers/orderDashboard";
import {
  dependencyTypesOnMove,
  getAvailableTasksToMove,
  getDependencyTasks,
} from "../../helpers/timelineModal";
import Loader from "../General/Loader";
import { salesOrderTriggerTypes } from "../../helpers/salesOrder";
import { ORDER_TYPE, typeOfTask } from "../../api/types/dbTables";
import {
  MOVE_TASK_TYPES,
  PO_DIAMOND_STATUS,
  PO_STATUS,
  SALES_ORDER_DIAMOND_STATUS,
  SHIPMENT_DIAMOND_STATUS,
  taskStatus,
} from "../../helpers/constants";
import { useShipments } from "../../hooks/shipments";
import { updateDoc } from "firebase/firestore";
import {
  callMoveDependencyTasks,
  getCurrentOrderTask,
} from "../../container/TaskListScreen/TaskScreenHelper";
import RemainingBadgeModal from "../Modal/RemainingBadgeModal";

function Task({
  item,
  user,
  completeTask = () => {},
  onClickOffset = () => {},
  companyId,
  salesOrder,
  purchaseOrder,
  companyUsers,
  allTaskList,
  permissionToVerify,
  isReadOnly = false,
  handleReadOnlyModal = () => {},
  handleUpdateTotalTask = () => {},
  currentShipment,
  onChangeShipment,
  onCleanPendingTask = () => {},
  pendingToCompleted = {},
  showCompleted,
  taskFilter,
  handleIsEditingTask,
  backdropId,
  isSmallPanel,
}) {
  const authUser = useUser();
  const [userEditId, setUserEditId] = useState(item.enableToEdit);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [openModalToAdjustTasks, setOpenModalToAdjustTasks] = useState({
    open: false,
    daysOffset: 0,
  });
  const [timer, setTimer] = useState("");
  const [modalDescriptionNew, setModalDescriptionNew] = useState(false);

  const [countingSeconds, setCountingSeconds] = useState(
    parseInt(
      (moment(item.creationDate).add(7, "seconds").valueOf() -
        moment().valueOf()) /
        1000
    )
  );
  const [classesTask, setClassesTask] = useState("project-task");
  const [newClassToDessapear, setNewClassToDissapear] = useState(
    "option-to-edit-container"
  );
  const [openPicker, setOpenPicker] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [editingAdHocTask, setEditingAdHocTask] = useState(false);
  const [currentTask, setCurrentTask] = useState(item);
  const [lastElementId, setLastElementId] = useState("");
  const [loader, setLoader] = useState(false);
  const currentUser = useUser();
  const shipments = useShipments();

  useEffect(() => {
    setCurrentTask({ ...item });
  }, [item]);

  useEffect(() => {
    if (userEditId) {
      if (!editingAdHocTask && !timer) {
        setTimer(
          setInterval(() => {
            setCountingSeconds((t) => (t === 6 ? 6 - 1 : t - 1));
          }, 1000)
        );
      } else if (editingAdHocTask && timer) {
        clearInterval(timer);
        setCountingSeconds(6);
        setTimer(false);
      }
    }
  }, [editingAdHocTask]);

  const createUpdateActivityDate = ({ currentDayOffset }) => {
    createActivityEntryFromMovedTask({
      task: item,
      dayOffsetTask: currentDayOffset,
      dayOffsetRemainigTasks: Math.abs(currentDayOffset),
      diffDaysTask: currentDayOffset,
      isMovingForward: currentDayOffset > 0,
      typeChange: dependencyTypesOnMove.START_DATE_AND_FINISH_DATE_CHANGED,
      user: currentUser,
      companyUsers,
      mainEntity: {
        ...mainEntity,
        parentCollection,
        taskScope: item.type || ORDER_TYPE.SHIPMENT,
      },
    });
  };

  useEffect(() => {
    if (userEditId && !editingAdHocTask && timer && countingSeconds <= -1) {
      setNewClassToDissapear("option-to-edit-container dissapear");
      clearInterval(timer);
      setTimeout(() => {
        setUserEditId(false);
        if (item.type === typeOfTask.SALES_ORDER) {
          if (
            salesOrder.status !== SALES_ORDER_DIAMOND_STATUS.LATE &&
            item.status === taskStatus.LATE
          ) {
            updateDoc(salesOrder.ref, {
              diamondStatus: SALES_ORDER_DIAMOND_STATUS.LATE,
              triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
            });
          } else if (
            !salesOrder.status &&
            item.status === taskStatus.NEAR_DUE
          ) {
            updateDoc(salesOrder.ref, {
              diamondStatus: SALES_ORDER_DIAMOND_STATUS.NEAR_DUE,
              triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
            });
          }
        } else if (item.type === typeOfTask.PURCHASE_ORDER) {
          if (
            purchaseOrder.status !== PO_DIAMOND_STATUS.LATE &&
            item.status === taskStatus.LATE
          ) {
            updateDoc(purchaseOrder.ref, {
              diamondStatus: PO_DIAMOND_STATUS.LATE,
              triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
            });
            if (salesOrder.status !== SALES_ORDER_DIAMOND_STATUS.LATE) {
              updateDoc(salesOrder.ref, {
                diamondStatus: SALES_ORDER_DIAMOND_STATUS.LATE,
                triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
              });
            }
          } else if (
            !purchaseOrder.status &&
            item.status === taskStatus.NEAR_DUE
          ) {
            updateDoc(purchaseOrder.ref, {
              diamondStatus: PO_DIAMOND_STATUS.NEAR_DUE,
              triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
            });
            if (!salesOrder.status) {
              updateDoc(salesOrder.ref, {
                diamondStatus: SALES_ORDER_DIAMOND_STATUS.NEAR_DUE,
                triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
              });
            }
          }
        } else if (item.type === typeOfTask.SHIPMENT) {
          const shipment = shipments.find(
            (shipment) => shipment.id === item.shipmentId
          );
          if (shipment) {
            if (
              shipment.status !== SHIPMENT_DIAMOND_STATUS.LATE &&
              item.status === taskStatus.LATE
            ) {
              updateDoc(shipment.ref, {
                diamondStatus: SHIPMENT_DIAMOND_STATUS.LATE,
                triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
              });
            } else if (
              !shipment.status &&
              item.status === taskStatus.NEAR_DUE
            ) {
              updateDoc(shipment.ref, {
                diamondStatus: SHIPMENT_DIAMOND_STATUS.NEAR_DUE,
                triggerType: salesOrderTriggerTypes.DISMISS_TRIGGER,
              });
            }
          }
        }

        setClassesTask("project-task");
        updateDoc(item.ref, { enableToEdit: false });
        clearInterval(timer);
      }, 2000);
    }
  }, [countingSeconds]);

  useEffect(() => {
    item.complete
      ? setClassesTask("project-task complete")
      : userEditId && editingAdHocTask
      ? setClassesTask("project-task to-edit")
      : setClassesTask("project-task");
  }, [editingAdHocTask]);

  useEffect(() => {
    const isCompleted = item.complete && item.status === "complete";
    if (
      (classesTask.includes("pendingSize") ||
        classesTask.includes("pendingOpacity")) &&
      isCompleted
    ) {
      if (showCompleted) {
        setClassesTask("project-task completeOpacity");
      } else {
        if (item.moved === true) {
          setClassesTask("project-task completeSize");
          setTimeout(() => {
            if (pendingToCompleted[item.id]) {
              onCleanPendingTask(item);
            }
          }, 2000);
        } else {
          setClassesTask("project-task pendingOpacity");
        }
      }
    }
  }, [item.complete, item.status, item.moved]);

  useEffect(() => {
    if (
      classesTask.includes("completeSize") ||
      classesTask.includes("pendingOpacity")
    ) {
      setClassesTask("project-task complete");
    }
  }, [showCompleted, taskFilter]);

  function changeStyleTo(value) {
    setClassesTask(value);
  }

  async function changeField({ taskToUpdate, field, triggerType }) {
    if (field === TASK_FIELDS.ASSIGNED_TO) {
      // trackEvent("PO Dashboard - Tasks - Reassigned", {
      //   from: item.assignedTo,
      //   to: value
      // });
    }
    if (field === TASK_FIELDS.FINISH_DATE) {
      if (taskToUpdate.currentDiffDays === 0) {
        setOpenPicker(false);
        return;
      }
      updateDoc(item.ref, {
        updated_by: authUser.id,
        status: getStatusTask(taskToUpdate),
        triggerType: triggerType,
        finishDate: taskToUpdate.finishDate,
        startDate: taskToUpdate.startDate,
      });
      setOpenPicker(false);
      const currentDayOffset = taskToUpdate.currentDiffDays;
      if (taskToUpdate.type === typeOfTask.SALES_ORDER) {
        setLoader(true);
        const result = await callMoveDependencyTasks({
          companyId,
          salesOrder,
          task: taskToUpdate,
        });
        const { data } = result;
        if (data.length > 0) {
          setOpenModalToAdjustTasks({
            open: true,
            daysOffset: currentDayOffset,
          });
        } else {
          createUpdateActivityDate({ currentDayOffset });
        }
        setLoader(false);
        // trackEvent("PO Dashboard - Tasks - Updated Due Date", {
        //   description: item.description,
        //   dayOffset: dayOffsetRemainigTasks
        // });
      } else {
        const availableTasksToMove = getAvailableTasksToMove({
          tasks: allTaskList,
        });
        let dependencyTasks = getDependencyTasks({
          task: taskToUpdate,
          tasks: availableTasksToMove,
          dependencyTasks: [],
          remainingDayOffset: 1,
        });
        if (dependencyTasks.length > 0) {
          setOpenModalToAdjustTasks({
            open: true,
            daysOffset: currentDayOffset,
          });
        } else {
          createUpdateActivityDate({ currentDayOffset });
        }
      }
    } else {
      updateDoc(item.ref, {
        ...taskToUpdate,
        updated_by: authUser.id,
        triggerType: triggerType,
      });
    }
    setOpenPicker(false);
    setOpenUsers(false);
  }

  function showModalNoPermissionToReassign() {
    setModalDescriptionNew("You do not have permission to reassing a task");
    setOpenModalAttention(true);
  }

  const isVoided = () => {
    const orderByType = {
      [typeOfTask.SALES_ORDER]: salesOrder,
      [typeOfTask.PURCHASE_ORDER]: purchaseOrder,
      [typeOfTask.SHIPMENT]: currentShipment,
    };
    const order = orderByType[item.type] || {};
    return (
      salesOrder.status === PO_STATUS.VOIDED ||
      order.status === PO_STATUS.VOIDED
    );
  };

  const isVoid = isVoided();

  function handleChange(field, value, triggerType) {
    setCurrentTask({
      ...currentTask,
      [field]: value,
      triggerType: triggerType,
    });
    setOpenPicker(false);
    setOpenUsers(false);
  }

  const { currentOrder: mainEntity, orderDB: parentCollection } =
    getCurrentOrderTask({
      task: currentTask,
      salesOrder,
      purchaseOrder,
      currentShipment,
    });
  return (
    <React.Fragment>
      {loader && (
        <Loader
          style={{
            justifyContent: "center",
            display: "flex",
            zIndex: 1000,
            alignItems: "center",
            top: 0,
            left: 0,
          }}
        />
      )}
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={modalDescriptionNew}
          onClick={() => {
            setModalDescriptionNew(false);
            setOpenModalAttention(false);
          }}
          isOpen={openModalAttention}
          confirmationText="Ok"
        />
      )}
      {openModalToAdjustTasks.open && (
        <RemainingBadgeModal
          open={openModalToAdjustTasks.open}
          onCloseModal={() => {
            console.log("onCloseModal");
            setOpenModalToAdjustTasks({ open: false });
          }}
          actionType={MOVE_TASK_TYPES.START_DUE_DATE_CHANGE}
          mainEntity={mainEntity}
          parentCollection={parentCollection}
          task={currentTask}
          taskDayOffset={openModalToAdjustTasks.daysOffset}
        />
      )}
      <TaskItem
        item={currentTask}
        classesTask={classesTask}
        isVoid={isVoid}
        backdropId={backdropId}
        changeField={changeField}
        companyUsers={companyUsers}
        user={user}
        changeStyleTo={changeStyleTo}
        completeTask={completeTask}
        onChangeShipment={onChangeShipment}
        onCleanPendingTask={onCleanPendingTask}
        onLastFinishDate={(value, enabled) => {
          if (enabled) {
            setEditingAdHocTask(true);
          }
        }}
        onOpenPicker={(value) => {
          if (isReadOnly) {
            handleReadOnlyModal();
          } else {
            setOpenPicker(value);
          }
        }}
        openPicker={openPicker}
        openUsers={openUsers}
        onOpenUsers={(value) => {
          if (isReadOnly) {
            handleReadOnlyModal();
          } else {
            setOpenUsers(value);
          }
        }}
        showModalNoPermissionToReassign={showModalNoPermissionToReassign}
        purchaseOrder={purchaseOrder}
        onClickOffset={onClickOffset}
        editingAdHocTask={editingAdHocTask}
        handleChange={handleChange}
        lastElementId={lastElementId}
        onLastElementId={(value) => setLastElementId(value)}
        permissionToVerify={permissionToVerify}
        isSmallPanel={isSmallPanel}
      />
      {userEditId === currentUser.id && (
        <TaskOptions
          item={currentTask}
          newClassToDessapear={newClassToDessapear}
          countingSeconds={countingSeconds}
          currentUser={currentUser}
          handleUpdateTotalTask={handleUpdateTotalTask}
          onEditTask={(value) => {
            clearInterval(timer);
            setEditingAdHocTask(value);
            handleIsEditingTask(value);
          }}
          editingAdHocTask={editingAdHocTask}
          recoverLastTask={() => setCurrentTask(item)}
          companyUsers={companyUsers}
          permissionToVerify={permissionToVerify}
          userEditId={userEditId}
          handleIsEditingTask={handleIsEditingTask}
        />
      )}
    </React.Fragment>
  );
}

export default Task;
