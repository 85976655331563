import React, { useRef, useState, useEffect } from "react";
import {
  sortObjectsBy,
  isTradeDashEmployee,
  getDateByTimezone,
} from "../../helpers/helpers";
import DatePicker from "../DatePicker/DatePicker";
import moment from "moment";
import ClearIcon from "@mui/icons-material/Cancel";
import UserPicker from "../DatePicker/UserPicker";
import LateIcon from "../../assets/flag-icons/red_diamond.svg";
import NearDueIcon from "../../assets/flag-icons/orange_diamond.svg";
import IntlMessages from "../../util/IntlMessages";
import { useIsAllowedFunction } from "../../hooks/permissions";
import { ArrowDownIcon, CalendarIcon } from "../../helpers/iconSvgPath";
import PersonIcon from "@mui/icons-material/Person";
import { useUser } from "../../hooks/user";
import { getUserAvatar } from "../../helpers/users";
import TooltipTD from "../Tooltip/TooltipTD";
import {
  TASK_FIELDS,
  getNumberOfLateDays,
  triggerTaskTypes,
} from "../../helpers/tasks";
import {
  GENERAL_PERMISSION_VALUE,
  taskStatus,
  TASK_MOVED_STATUS,
  TASK_TYPE,
  TOOLTIP_TYPE,
} from "../../helpers/constants";
import { TaskItemContainerStyled } from "./styles";
import { getShipmentBadge } from "../../helpers/shipments";
import CustomCheckbox from "../Inputs/CustomCheckbox";
import AttentionModal from "../Modal/AttentionModal";
import { useShipments } from "../../hooks/shipments";
import { cx } from "@emotion/css";
import { CircularProgress } from "@mui/material";
import { shipmentBadgeClass } from "../../container/StyledComponent/BadgeStyled";
import { moveStartFinishDate } from "../../container/TaskListScreen/TaskScreenHelper";

function getDiamond(status) {
  switch (status) {
    case "late":
      return (
        <img
          style={{
            opacity: 1,
            top: 10,
            right: 8,
            display: "inline",
            width: 12,
            height: 15,
          }}
          src={LateIcon}
          alt={"LateIcon"}
        />
      );
    case "nearDue":
      return (
        <img
          style={{
            opacity: 1,
            top: 10,
            right: 8,
            display: "inline",
            width: 12,
            height: 15,
          }}
          src={NearDueIcon}
          alt="nearDue"
        />
      );
    default:
      return <span style={{ minWidth: 12 }}></span>;
  }
}

function TaskItem({
  item,
  classesTask,
  isVoid,
  isReassigned,
  changeField,
  companyUsers,
  user,
  changeStyleTo,
  completeTask,
  onLastFinishDate,
  openPicker,
  onOpenPicker,
  openUsers,
  onOpenUsers,
  showModalNoPermissionToReassign,
  onClickOffset,
  editingAdHocTask,
  handleChange,
  onLastElementId,
  lastElementId,
  permissionToVerify,
  onChangeShipment,
  onCleanPendingTask,
  backdropId = "",
  isSmallPanel,
}) {
  const dateRef = useRef(null);
  const userRef = useRef(null);
  const isAllowed = useIsAllowedFunction();
  const currentUser = useUser();
  const isReadOnly = isTradeDashEmployee(currentUser);
  const [pending, setPending] = useState(false);
  const [openModalAttention, setOpenModalAttention] = useState(false);
  const [descriptionModalAttention, setDescriptionModalAttention] = useState(
    <React.Fragment></React.Fragment>
  );
  const userTask = companyUsers.find(
    (companyUser) => companyUser.id === item.assignedTo
  );
  const activeCompanyUsers = companyUsers.filter((user) => user.active);
  const shipments = useShipments();

  function liStyles(item) {
    const enableToEdit =
      item.enableToEdit === currentUser.id &&
      item.type === TASK_TYPE.PURCHASE_ORDER
        ? { borderInline: "1px solid #d4dfed" }
        : {};

    return {
      padding: "0px 15px 0px 32px",
      zIndex: backdropId.includes(item.id) ? 1202 : "",
      ...enableToEdit,
    };
  }

  function getDisplayName(userCompleteTask) {
    const currentUser = userCompleteTask || userTask;
    const errorMessage = userCompleteTask ? (
      <IntlMessages id="components.purchaseorderdashboards.task.notassigned" />
    ) : (
      "@User"
    );
    return currentUser && currentUser.displayName
      ? "@" + currentUser.displayName
      : errorMessage;
  }

  function getTaskStatusBadgeInfo({ task, isVoid, isReassigned }) {
    const otherClass = classesTask.includes("completeSize") ? "hiddenText" : "";
    const zIndex = backdropId.includes(item.id) ? 1203 : 3;
    if (task.moved === TASK_MOVED_STATUS.WAITING) {
      return (
        <div
          style={{
            position: "absolute",
            zIndex: 2,
            left: 2,
            margin: "0px 5px",
          }}
        >
          <CircularProgress
            style={{ position: "relative", width: 20, height: 20 }}
          />
        </div>
      );
    } else if (isVoid || isReassigned) {
      return <div className={cx("taskStatusContainer", otherClass)} />;
    } else if (task.status === taskStatus.LATE && !task.complete) {
      return (
        <div
          className={cx("taskStatusContainer", otherClass)}
          style={{ zIndex }}
        >
          <TooltipTD
            type={TOOLTIP_TYPE.DIAMOND}
            label={getNumberOfLateDays({ task: item })}
          >
            {getDiamond(item.status)}
          </TooltipTD>
        </div>
      );
    } else if (task.status === taskStatus.NEAR_DUE && !task.complete) {
      return (
        <div
          className={cx("taskStatusContainer", otherClass)}
          style={{ zIndex }}
        >
          {getDiamond(item.status)}
        </div>
      );
    } else if (task.dayOffset && task.dayOffset !== 0 && item.complete) {
      return (
        <div
          className={cx("taskStatusContainer", otherClass)}
          style={{ cursor: "pointer" }}
        >
          <span
            onClick={(ev) => {
              ev.stopPropagation();
              if (isVoid) {
                return;
              }
              if (!isAllowed(GENERAL_PERMISSION_VALUE.REASSIGN_TASK)) {
                handleOpenModalAttention();
              } else if (!item.moved) {
                onClickOffset({ task: item, changeStyleTo });
              }
            }}
            className={cx("dayoffset", {
              early: !item.moved && item.dayOffset < 0,
              late: !item.moved && item.dayOffset > 0,
              disabled: item.moved,
            })}
          >
            <div
              style={{ marginTop: 1, marginRight: item.dayOffset > 9 ? 1 : 0 }}
            >
              {item.dayOffset
                ? item.dayOffset > 0
                  ? `+${Math.abs(item.dayOffset)}`
                  : `-${Math.abs(item.dayOffset)}`
                : ""}
            </div>
          </span>
        </div>
      );
    } else {
      return <div className={cx("taskStatusContainer", otherClass)}></div>;
    }
  }
  const fontWeight = item.complete ? 400 : 700;

  function handleOpenModalAttention() {
    setDescriptionModalAttention(
      <React.Fragment>
        You do not have permission to change task due dates
      </React.Fragment>
    );
    setOpenModalAttention(true);
  }

  function handleCloseModalAttention() {
    setOpenModalAttention(false);
    setDescriptionModalAttention(<React.Fragment></React.Fragment>);
  }

  useEffect(() => {
    if (item.complete) {
      setPending(false);
    }
  }, [item.complete]);

  const handleUserPicker = () => {
    if (
      isVoid ||
      (item.enableToEdit && !editingAdHocTask) ||
      item.status === taskStatus.COMPLETE
    ) {
      return;
    }
    const canReassignTask = isAllowed(GENERAL_PERMISSION_VALUE.REASSIGN_TASK);
    if (canReassignTask || editingAdHocTask) {
      onOpenUsers(true);
    } else {
      showModalNoPermissionToReassign();
    }
  };

  const handleDatePicker = () => {
    if (
      isVoid ||
      (item.enableToEdit && !editingAdHocTask) ||
      item.status === taskStatus.COMPLETE
    ) {
      return;
    }

    const canReassignTask = isAllowed(
      GENERAL_PERMISSION_VALUE.MODIFY_DUE_DATES
    );
    if (canReassignTask || editingAdHocTask) {
      onLastFinishDate(item.finishDate);
      onOpenPicker(true);
      onLastElementId("users-container-task-item" + item.id);
    } else {
      handleOpenModalAttention();
    }
  };

  return (
    <TaskItemContainerStyled className="taskListContainer">
      {getTaskStatusBadgeInfo({ task: item, isVoid, isReassigned })}
      {openUsers && (
        <UserPicker
          el={userRef.current}
          onChange={(userId, ev, blockPermission) => {
            if (blockPermission) {
              ev.preventDefault();
              ev.stopPropagation();
              return;
            } else if (editingAdHocTask) {
              handleChange("assignedTo", userId);
            } else {
              changeField({
                taskToUpdate: { ...item, assignedTo: userId },
                field: "assignedTo",
                triggerType: triggerTaskTypes.ASSIGNED_TO,
              });
            }
          }}
          open={openUsers}
          onClose={() => onOpenUsers(false)}
          value={item.assignedTo}
          users={activeCompanyUsers.sort(sortObjectsBy("displayName", false))}
          onKeyDown={(ev) => {
            if (ev.key === "Tab") {
              onLastElementId("");
              onOpenUsers(false);
            }
          }}
          permissionToVerify={permissionToVerify}
          isTherePermission={true}
          isCustomer={!item.factoryId}
        />
      )}
      {openModalAttention && (
        <AttentionModal
          title="Attention"
          description={descriptionModalAttention}
          onClick={handleCloseModalAttention}
          onClose={handleCloseModalAttention}
          isOpen={openModalAttention}
        />
      )}
      <li
        id={item.id + "task-item"}
        className={cx(classesTask, item.type)}
        key={item.id}
        style={liStyles(item)}
      >
        <div style={{ flex: 1, marginLeft: 4 }} className={"taskItemContainer"}>
          <React.Fragment>
            <CustomCheckbox
              checked={item.complete || false}
              onClick={(ev) => ev.stopPropagation()}
              onChange={(ev) => {
                if (isReadOnly) {
                  return;
                }
                if (!pending) {
                  setPending(true);
                  changeStyleTo("project-task completing");
                  completeTask({
                    task: !item.factoryId ? { ...item } : item,
                    changeStyleTo,
                  });
                  ev.stopPropagation();
                }
              }}
              disabled={
                item.complete ||
                isVoid ||
                isReassigned ||
                item.enableToEdit ||
                pending
              }
              size="small"
              color={"#6B7A89"}
              styles={{
                margin: 0,
                marginLeft: 12,
                padding: 0,
              }}
              iconSize={14}
            />
            {pending && (
              <ClearIcon
                fontSize="small"
                className="cancelIcon"
                onClick={() => {
                  setPending(false);
                  onCleanPendingTask(!item.factoryId ? { ...item } : item);
                  changeStyleTo("project-task");
                }}
              />
            )}
          </React.Fragment>
          {editingAdHocTask ? (
            <div className="description-task-container">
              <input
                id="description-task-item"
                value={item.description}
                style={{ width: "100%", height: "100%", background: "white" }}
                onChange={(ev) =>
                  handleChange(
                    "description",
                    ev.target.value,
                    triggerTaskTypes.AVOID_ACTIVITY_ENTRY
                  )
                }
                onKeyDown={(ev) => {
                  if (ev.key === "Tab") {
                    const el = document.getElementById(lastElementId);
                    el.click();
                  }
                }}
                onClick={() =>
                  onLastElementId("date-container-task-item" + item.id)
                }
              />
            </div>
          ) : (
            <label
              id={item.id + "input"}
              style={{
                margin: "12px 16px 12px 8px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                gap: 5,
              }}
              className="task-description-wrapper"
            >
              {item.shipmentId &&
                getShipmentBadge({
                  shipment: shipments.find(
                    (shipment) => shipment.id === item.shipmentId
                  ),
                  styles: { margin: "0px 0px 2px 0px" },
                  classes: shipmentBadgeClass.secondary,
                  onChangeShipment: (shipment) => {
                    onChangeShipment(shipment.id);
                  },
                  id: "mediumInverted",
                })}
              <span className="span-task-description">{item.description}</span>
            </label>
          )}
        </div>
        <div
          id={"date-container-task-item" + item.id}
          onClick={handleDatePicker}
          ref={dateRef}
          className={cx("date-box", { active: openPicker })}
          style={
            editingAdHocTask
              ? {
                  height: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }
              : {
                  pointerEvents:
                    isVoid || item.status === taskStatus.COMPLETE ? "none" : "",
                }
          }
        >
          <span className="date-container" style={{ fontWeight, fontSize: 13 }}>
            {item.finishDate
              ? getDateByTimezone({
                  date: item.finishDate,
                })
              : moment(new Date()).format("M/D/YY")}
            <CalendarIcon
              style={{ marginBottom: -1, marginLeft: 4 }}
              svgClass="calendarIcon"
            />
          </span>
        </div>
        {openPicker && (
          <DatePicker
            el={dateRef.current}
            onChange={(newDate) => {
              if (editingAdHocTask) {
                onLastElementId("users-container-task-item" + item.id);
                if (
                  moment(newDate, "MM/DD/YY").startOf("day") >=
                  moment().startOf("day")
                ) {
                  handleChange(
                    TASK_FIELDS.FINISH_DATE,
                    newDate,
                    triggerTaskTypes.FINISH_DATE
                  );
                }
              } else {
                const { startDate, finishDate, diffDays } = moveStartFinishDate(
                  {
                    newDate,
                    task: item,
                  }
                );
                changeField({
                  taskToUpdate: {
                    ...item,
                    finishDate: finishDate,
                    startDate: startDate,
                    currentDiffDays: diffDays,
                  },
                  field: TASK_FIELDS.FINISH_DATE,
                  triggerType: triggerTaskTypes.FINISH_DATE,
                });
              }
            }}
            withFormat="MM/DD/YY"
            open={openPicker}
            onClose={() => {
              onOpenPicker(false);
            }}
            value={moment(
              getDateByTimezone({
                date: item.finishDate,
              })
            )}
            cancelIcon={true}
            onKeyDown={(ev) => {
              if (ev.key === "Tab") {
                onLastElementId("users-container-task-item" + item.id);
                onOpenPicker(false);
                const el = document.getElementById(lastElementId);
                el.click();
              }
            }}
          />
        )}

        <div
          className="task-user"
          style={{ width: "23%", opacity: item.complete ? 0.6 : 1 }}
        >
          {!item.complete ? (
            <>
              <TooltipTD
                label={getDisplayName()}
                type={TOOLTIP_TYPE.TASK}
                tooltipClick={handleUserPicker}
                style={{ overflow: "hidden" }}
              >
                <div
                  id={"users-container-task-item" + item.id}
                  ref={userRef}
                  className={cx("user", { active: openUsers })}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    margin: 0,
                  }}
                >
                  {item.assignedTo &&
                    userTask &&
                    getUserAvatar({
                      user: userTask,
                      styles: {
                        width: 20,
                        height: 20,
                        fontSize: 13,
                        outline: "2px solid #000",
                        marginLeft: 2,
                      },
                    })}
                  {!item.assignedTo && <img alt="" src={PersonIcon} />}
                  {!isSmallPanel && (
                    <span
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight,
                        fontSize: 13,
                        marginLeft: 5,
                      }}
                    >
                      {item.assignedTo && getDisplayName()}
                    </span>
                  )}
                </div>
              </TooltipTD>
              {!pending && (
                <ArrowDownIcon
                  svgClass={"arrowButton"}
                  onClick={handleUserPicker}
                />
              )}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                margin: 0,
              }}
              className="data"
            >
              <TooltipTD label={getDisplayName()} style={{ display: "flex" }}>
                {getUserAvatar({
                  user: user,
                  styles: {
                    width: 24,
                    height: 24,
                    fontSize: 13,
                    outline: "2px solid #000",
                  },
                })}
                {!isSmallPanel && (
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "auto",
                      fontWeight,
                      fontSize: 13,
                      marginLeft: 5,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {user && getDisplayName(user)}
                  </span>
                )}
              </TooltipTD>
            </div>
          )}
        </div>
      </li>
    </TaskItemContainerStyled>
  );
}

export default TaskItem;
