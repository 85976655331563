import React, { useState, useEffect } from "react";
import ProgressStage from "./ProgressStage";
import moment from "moment";
import { groupBySOHeadCellsShipments } from "../../components/Managers/TableHeaderProvider";
import numeral from "numeral";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import { addFavorite, removeFavorite } from "../../helpers/favoritesHelper";
import { useUser } from "../../hooks/user";
import FooterTable from "../../components/Tables/FooterTable";
import { footerOrdersByPhaseShipmentsWithinSO } from "../../components/Managers/FooterTableManager";
import MainTableRefactor from "../../components/filterTableRefactor/MainTableRefactor";
import { TableCell } from "@mui/material";
import { orderByPhaseShipmentGroupBySOSetup } from "../../helpers/screenHeaderSetup";
import {
  generalPermissions,
  headerColumns,
  notAllowedToSee,
} from "../../helpers/constants";
import ShipmentItem from "./ShipmentItem";
import { useFavorites, useFavoritesList } from "../../hooks";
import { dbTables } from "../../api/types/dbTables";
import {
  getDiamondStatus,
  getStarFavorite,
} from "../../helpers/ScreenComponetHelper/componetHelper";
import {
  MainRowStyled,
  mainRowClasses,
} from "../../components/filterTableRefactor/MainRowStyled";
import { cx } from "@emotion/css";

function FilterTableShipment({
  shipmentList,
  headerStages,
  filtersTable,
  onUpdateFilters,
  tags,
  onUpdateItem,
  isAllowed,
  isThereProposalQuoteColumn,
  filters = { factory: [] },
  topHeader = [],
  stylesTable,
  tableRowHeaderStyles = {},
  salesOrder,
}) {
  const user = useUser();
  const [headerCells, setHeaderCells] = useState([]);
  const filterTag = filters.tag || [];
  const favorites = useFavoritesList({ dbTable: dbTables.SHIPMENT_FAVORITES });
  const favoritesDB = useFavorites({ dbTable: dbTables.SHIPMENT_FAVORITES });

  function zeroIsBlank(item, isCBM) {
    let dformat = "$0,0.00";
    if (isCBM) {
      dformat = "0.00";
    }
    return numeral(item).format("$0,0.00") === "$0.00"
      ? " "
      : numeral(item).format(dformat);
  }

  function getShipmentsTotalAmount(shipments) {
    let sum = 0;
    shipments.forEach((po) => (sum += numeral(po.total).value()));
    return numeral(sum).format("$ 0,0.00");
  }

  useEffect(() => {
    // console.log("USER HEADER CELL");
    let cpyHeaderCell = orderByPhaseShipmentGroupBySOSetup.map((header) => {
      const itemTable = groupBySOHeadCellsShipments[header.name];
      const style = itemTable.styles;
      let widthSize = style.width;
      if (itemTable.reference === "number") {
        const po =
          topHeader.find((header) => header.reference === "number") || {};
        widthSize = po.headerWidth || style.width;
      }
      if (itemTable.reference === "finalDestination") {
        const po =
          topHeader.find((header) => header.reference === "customerName") || {};
        widthSize = po.headerWidth || style.width;
      }

      if (
        itemTable[generalPermissions.SHIPMENT_AMOUNT] &&
        !isAllowed(generalPermissions.SHIPMENT_AMOUNT)
      ) {
        return {
          ...header,
          ...itemTable,
          enabled:
            header.name === headerColumns.PROPOSAL_QUOTE
              ? isThereProposalQuoteColumn
              : header.enabled,
          styles: {
            ...itemTable.styles,
            width: widthSize,
          },
        };
      } else {
        return {
          ...header,
          ...itemTable,
          styles: {
            ...itemTable.styles,
            width: widthSize,
          },
          enabled:
            header.name === headerColumns.PROPOSAL_QUOTE
              ? isThereProposalQuoteColumn
              : header.enabled,
        };
      }
    });
    setHeaderCells(cpyHeaderCell);
  }, [isAllowed]);

  function filterTableShipmentSelector({
    column,
    enabled,
    index,
    itemShipment,
  }) {
    const purchaseOrderId = itemShipment.purchaseOrderIds[0];
    switch (column) {
      case headerColumns.EXPAND:
        return enabled && <TableCell className="emptyTableCell"></TableCell>;
      case headerColumns.FAVORITE:
        return (
          enabled && (
            <TableCell key={index} center classes={{ root: "favorite" }}>
              {getStarFavorite({
                favorites,
                itemId: itemShipment.id,
                addFavorite: () =>
                  addFavorite({
                    dbTable: dbTables.SHIPMENT_FAVORITES,
                    favoritesDB,
                    id: itemShipment.id,
                    user,
                  }),
                removeFavorite: () =>
                  removeFavorite({ favoritesDB, id: itemShipment.id }),
              })}
            </TableCell>
          )
        );

      case headerColumns.DIAMOND:
        return (
          enabled && (
            <TableCell key={index} className="diamond">
              {getDiamondStatus({
                diamondStatus: itemShipment.diamondStatus,
                itemStatus: itemShipment.status,
              })}
            </TableCell>
          )
        );

      case headerColumns.SHIPMENT: {
        return (
          enabled && (
            <TableCell key={index} className="number">
              <ShipmentItem
                tags={tags}
                itemShipment={itemShipment}
                filterTag={filterTag}
                isAllowed={isAllowed}
                user={user}
                onUpdateItem={onUpdateItem}
                salesOrder={salesOrder}
                purchaseOrderId={purchaseOrderId}
              />
            </TableCell>
          )
        );
      }

      case headerColumns.FINAL_DESTINATION:
        return (
          enabled && (
            <TableCell key={index} className="finalDestination">
              <TooltipTD
                label={itemShipment.finalDestination}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  display: "flex",
                }}
              >
                {itemShipment.finalDestination}
              </TooltipTD>
            </TableCell>
          )
        );

      case headerColumns.SHIPMENT_VALUE:
        return (
          enabled && (
            <TableCell key={index} className="amount" id="balance">
              {isAllowed(generalPermissions.SHIPMENT_AMOUNT)
                ? zeroIsBlank(itemShipment.total)
                : notAllowedToSee}
            </TableCell>
          )
        );

      case headerColumns.SHIP_DATE:
        return (
          enabled && (
            <TableCell key={index} className="shipDate" id="shipDate">
              {itemShipment.shipDate
                ? moment(itemShipment.shipDate).format("M/D/YY")
                : ""}
            </TableCell>
          )
        );

      case headerColumns.DELIVERY_DATE:
        return (
          enabled && (
            <TableCell key={index} className="shipDate" id="deliveryDate">
              {itemShipment.deliveryDate
                ? moment(itemShipment.deliveryDate).format("M/D/YY")
                : ""}
            </TableCell>
          )
        );

      case headerColumns.POS:
        return enabled && <TableCell key={index} className="emptyTableCell" />;

      case headerColumns.PROPOSAL_QUOTE:
        return (
          enabled &&
          isThereProposalQuoteColumn && (
            <TableCell key={index} className="proposalTasks">
              <ProgressStage
                tasks={itemShipment.proposalTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.PRE_PRODUCTION:
        return (
          enabled && (
            <TableCell key={index} className="preProductionTasks">
              <ProgressStage
                tasks={itemShipment.preProductionTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.PRODUCTION:
        return (
          enabled && (
            <TableCell key={index} className="productionTasks">
              <ProgressStage
                tasks={itemShipment.productionTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.BOOKING_TRANSIT:
        return (
          enabled && (
            <TableCell key={index} className="bookingTransitTasks">
              <ProgressStage
                tasks={itemShipment.bookingTransitTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.PAYMENT_BILLING:
        return (
          enabled && (
            <TableCell key={index} className="paymentBillingTasks">
              <ProgressStage
                tasks={itemShipment.paymentBillingTasks}
                purchaseOrderId={purchaseOrderId}
                salesOrderId={itemShipment.salesOrderId}
                shipment={itemShipment}
              />
            </TableCell>
          )
        );

      case headerColumns.EXTRA:
        return enabled && <TableCell key={index} />;

      default:
        return <TableCell key={index} />;
    }
  }

  return (
    <MainTableRefactor
      headCells={headerCells.map((cell) => {
        if (cell.label === headerColumns.PROPOSAL_QUOTE) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.proposalQuoteMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PRE_PRODUCTION) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.preProductionMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PRODUCTION) {
          return {
            ...cell,
            styles: { ...cell.styles, width: headerStages.productionMinWidh },
          };
        } else if (cell.label === headerColumns.BOOKING_TRANSIT) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.bookingTransitMinWidh,
            },
          };
        } else if (cell.label === headerColumns.PAYMENT_BILLING) {
          return {
            ...cell,
            styles: {
              ...cell.styles,
              width: headerStages.paymentBillingMinWidh,
            },
          };
        } else {
          return cell;
        }
      })}
      stylesTable={{
        ...stylesTable,
        boxShadow: "0px 2px 14px 0px rgba(27,64,100,0.2)",
      }}
      bodyRows={shipmentList}
      filters={filtersTable}
      onUpdateFilters={onUpdateFilters}
      footer={
        <FooterTable
          footer={footerOrdersByPhaseShipmentsWithinSO(
            shipmentList.length,
            getShipmentsTotalAmount(shipmentList),
            isAllowed
          )}
          headerCells={headerCells}
        />
      }
      renderRow={(itemShipment) => (
        <MainRowStyled
          className={cx(mainRowClasses.SubTableRow, mainRowClasses.hoverEffect)}
          key={
            itemShipment.purchaseOrderId
              ? itemShipment.id + itemShipment.purchaseOrderId
              : itemShipment.id + itemShipment.salesOrderId
          }
        >
          {headerCells.map((header) =>
            filterTableShipmentSelector({
              column: header.name,
              enabled: header.enabled,
              index: itemShipment.id + header.name,
              itemShipment,
            })
          )}
        </MainRowStyled>
      )}
      tableRowHeaderStyles={tableRowHeaderStyles}
      key={"shipment-table"}
      sortArrowsColor="#FFF"
    />
  );
}

export default FilterTableShipment;
