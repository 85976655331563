import React from "react";
import { getUserByGroupPermission } from "../components/Notes/ComponentHelper";
import { getUserAvatar } from "./users";
import { dbTables } from "../api/types/dbTables";

export function getToolTipArrayValue(
  arrayFilters = [],
  arrayValue = [],
  field = "name"
) {
  const value = arrayFilters.map((item) =>
    arrayValue.find((elem) => elem.id === item)
      ? arrayValue.find((elem) => elem.id === item)[field]
      : ""
  );
  const list = (
    <ul
      style={{
        color: "white",
        margin: 0,
        listStyleType: "disc",
        padding: "6px 12px 6px 20px",
      }}
    >
      {value.sort().map((name) => (
        <li>{name}</li>
      ))}
    </ul>
  );

  return {
    tooltip: list,
    value: value
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .join(", "),
  };
}

export function getNewToolTipByPermissionGroup({ permission, users }) {
  const currentUsers = getUserByGroupPermission({
    permissionGroup: permission,
    users,
  });
  if (currentUsers.length === 0) {
    return "This group is empty";
  }
  return (
    <ul
      style={{
        display: "flex",
        margin: 0,
        gap: 10,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        paddingBlock: 10,
      }}
    >
      {currentUsers
        .sort((a, b) =>
          a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
        )
        .map((user) => (
          <li
            style={{
              display: "flex",
              margin: 0,
              gap: 2,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {getUserAvatar({
              user: user,
              styles: {
                width: 24,
                height: 24,
                fontSize: 13,
                outline: "2px solid #000",
                marginRight: 4,
              },
            })}
            <span
              style={{
                fontSize: 13,
              }}
            >
              @{user.displayName}
            </span>
          </li>
        ))}
    </ul>
  );
}

export function getToolTipSingleArrayValue(arrayValue = [], ulStyle = {}) {
  const list = (
    <ul
      style={{
        color: "white",
        margin: 0,
        listStyleType: "disc",
        padding: "6px 12px 6px 20px",
        lineHeight: "17px",
        ...ulStyle,
      }}
    >
      {arrayValue
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map((name) => (
          <li>{name}</li>
        ))}
    </ul>
  );

  return {
    tooltip: list,
    value: arrayValue
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .join(", "),
  };
}

export function getToolTipMixArrayValue({
  arrayFilters,
  arrayValue = [],
  fields = ["name"],
}) {
  const value = arrayFilters.map((item) => {
    const foundElement = arrayValue.find((elem) => elem.id === item) || {};
    const fieldValues = [];
    fields.forEach((field) => {
      fieldValues.push(foundElement[field] || "");
    });
    return fieldValues.join(" — ");
  });

  const list = (
    <ul
      style={{
        color: "white",
        margin: 0,
        listStyleType: "disc",
        padding: "6px 12px 6px 20px",
      }}
    >
      {value.sort().map((name) => (
        <li>{name}</li>
      ))}
    </ul>
  );
  // return value.join(" — ");
  return {
    tooltip: list,
    value: value
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .join(", "),
  };
}

export function getListValuesFromArray({ list = [], arr = [], field = "" }) {
  let result = [...list];
  result = result.filter((item) => arr.includes(item.id));

  result = result.map((el) => el[field]);
  result.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  return result.join(", ");
}

export const getStatusSuffix = (filters, scope) => {
  const {
    inProgress,
    completed,
    assignedTo = [],
    dateRange = {},
    date,
  } = filters;
  const hasDateRange = date && dateRange.start;
  let suffix = "";

  if (scope === dbTables.PURCHASE_ORDER_TASKS) {
    if (completed && assignedTo.length === 0) {
      suffix = "completed_and_unassigned_user";
    } else if (completed) {
      suffix = "completed";
    } else if (assignedTo.length === 0) {
      suffix = "unassigned_user";
    }
  } else {
    if (inProgress && completed) {
      suffix = "in_progress_and_completed";
    } else if (inProgress) {
      suffix = "in_progress";
    } else if (completed) {
      suffix = "in_complete";
    }
  }

  if (hasDateRange && suffix) {
    suffix = `${suffix}_with_date_range`;
  }

  return suffix;
};

export const getScreenTraceName = ({ filters, scope }) => {
  const statusSuffix = getStatusSuffix(filters, scope);
  if (!statusSuffix) {
    return "";
  }
  switch (scope) {
    case dbTables.SALES_ORDERS:
      return `sales_order_list_${statusSuffix}`;
    case dbTables.PURCHASE_ORDERS:
      return `purchase_order_list_${statusSuffix}`;
    case dbTables.SHIPMENTS:
      return `shipment_list_${statusSuffix}`;
    case dbTables.PURCHASE_ORDER_TASKS:
      return `task_list_${statusSuffix}`;
    default:
      return "";
  }
};
