import React from "react";
import { NavLink } from "react-router-dom";
import userTypes from "../../api/types/userTypes";
import TagsModal from "../../components/Modal/TagsModal";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  getProgressValue,
  getDashboardSearchPath,
  getTagsLabel,
  getTheDate,
  sortObjectsBy,
} from "../../helpers/helpers";

import { dbTables } from "../../api/types/dbTables";
import { SmallVoidLabel, VendorsIcon } from "../../helpers/iconSvgPath";
import { GENERAL_PERMISSION_VALUE, PO_STATUS } from "../../helpers/constants";
import { checkSpecificPermissions } from "../../hooks/permissions";
import { getTagStatus } from "../../helpers/salesOrder";
import { colors } from "../../assets/jss/variables";
import HiddenDataIconAnimated from "../../helpers/iconAnimation/HiddenDataIconAnimated";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { AnimatedListStyled } from "../StyledComponent/AnimatedOpenList";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import { getDiamondStatus } from "../../helpers/ScreenComponetHelper/componetHelper";
import POBadge from "../../components/Badge/POBadge";

function POsItem({
  item,
  user,
  onUpdateItem,
  isTradeDashEmployee,
  filters = {},
  poProgress,
  poDiamonds,
  tags,
  isAllowed,
  salesOrderSetup = {},
  factories,
  onChangeSalesOrderSetup = () => {},
  iconStyle = {},
  query = "",
}) {
  const currentTags = filters.tag || [];
  const purchaseOrders = item.purchaseOrders
    .filter(
      (purchaseOrder) =>
        user.role === userTypes.SUPER_ADMIN ||
        user.role === userTypes.TRADEDASH_EMPLOYEE ||
        checkSpecificPermissions({
          userPermissions: user.permissions,
          permissionToCheck: [
            purchaseOrder.factoryId,
            GENERAL_PERMISSION_VALUE.ALL_VENDORS,
          ],
        })
    )
    .sort(sortObjectsBy("number", false));
  let hasTag = false;
  const isOpen =
    (salesOrderSetup[item.id] && salesOrderSetup[item.id].POs) ||
    purchaseOrders.length <= 1 ||
    query;
  if (!isOpen) {
    purchaseOrders.forEach((po) => {
      const poTags = po.tags || [];
      hasTag = poTags.some((tag) => currentTags.includes(tag)) || hasTag;
    });
  }
  const getPOMetadada = ({
    factoryName = "",
    shipmentIds = [],
    orderReadyDate = "",
  }) => {
    const text = [];
    if (factoryName) {
      text.push(factoryName);
    }
    if (orderReadyDate) {
      text.push(`Order Ready Date: ${getTheDate(orderReadyDate, "M/D/YY")}`);
    }
    if (shipmentIds.length) {
      text.push(
        `${shipmentIds.length} ${
          shipmentIds.length === 1 ? "shipment" : "shipments"
        }`
      );
    }
    return text.join(",\u00A0");
  };

  const getProgress = (purchaseOrder) => {
    if (!poProgress) {
      return <div />;
    } else {
      if (purchaseOrder.status === PO_STATUS.VOIDED) {
        return <div className="progressContainer" />;
      }

      return (
        <CircularStaticProgress
          label={`${purchaseOrder.completedTasks}/${purchaseOrder.totalTasks} Tasks`}
          value={+getProgressValue(purchaseOrder)}
          thickness={16}
          style={{
            transform: "rotate(270deg)",
            color: "#39F",
          }}
          size={15}
          className="progressContainer"
        />
      );
    }
  };
  let paddingValue = 0;
  if (!poDiamonds) {
    paddingValue = paddingValue + 15;
  }
  if (!poProgress) {
    paddingValue = paddingValue + 15;
  }
  const hiddenStyle = { paddingRight: paddingValue };
  const factoryFilter = filters.factory || [];
  const hasFactory = purchaseOrders.some((po) => {
    return factoryFilter.includes(po.factoryId);
  });
  return (
    <AnimatedListStyled
      className="listContainer"
      style={{
        height: isOpen
          ? purchaseOrders.length * 22.1 + (purchaseOrders.length - 1) * 5
          : 24,
      }}
    >
      <div
        className="displayItems"
        style={{ opacity: isOpen ? 1 : 0, zIndex: isOpen ? 1 : 0 }}
      >
        {purchaseOrders.map((purchaseOrder, index) => {
          const currentVendor = factories.find(
            (factory) => factory.id === purchaseOrder.factoryId
          );
          const shipmentIds = purchaseOrder.shipmentIds || [];
          const factoryFilter = filters.factory;
          const showIconVendor =
            !!currentVendor && factoryFilter.includes(purchaseOrder.factoryId);
          const salesOrders = item.salesOrders || [];
          const salesOrder =
            salesOrders.find((so) => {
              const purchaseOrderIds = so.purchaseOrderIds || [];
              return purchaseOrderIds.includes(purchaseOrder.id);
            }) || {};

          const salesOrderId = salesOrder.id || item.id;
          const purchaseOrderTags = purchaseOrder.tags || [];
          return (
            <div
              key={purchaseOrder.id + index}
              style={{
                ...hiddenStyle,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 5,
                width: "100%",
                paddingLeft: 4,
              }}
              className="orderList"
            >
              <div
                className="labelText"
                style={{
                  width: "100%",
                }}
              >
                {poDiamonds &&
                  getDiamondStatus({
                    diamondStatus: purchaseOrder.diamondStatus,
                    itemStatus: purchaseOrder.status,
                  })}
                {getProgress(purchaseOrder)}
                {isAllowed("see_tags") && (
                  <TagsModal
                    key={purchaseOrder.id}
                    label={getTagsLabel(tags, purchaseOrderTags).tagsString}
                    tags={getTagsLabel(tags, purchaseOrderTags).tagsArray}
                    allTags={tags}
                    item={{ ...purchaseOrder, salesOrderId }}
                    currentUser={user}
                    tagReference={dbTables.PURCHASE_ORDERS}
                    onUpdateItem={onUpdateItem}
                    isThereItemRef={false}
                    containerStyle={{ margin: 0, top: 0 }}
                    isReadOnly={isTradeDashEmployee(user)}
                    status={getTagStatus({
                      tags: purchaseOrderTags,
                      currentTags: currentTags,
                    })}
                  />
                )}
                {showIconVendor && (
                  <TooltipTD
                    label={currentVendor.name}
                    style={{
                      display: "flex",
                      marginInline: 5,
                      marginBotton: 2,
                    }}
                  >
                    <VendorsIcon style={{}} width={11} height={15} />
                  </TooltipTD>
                )}
                {!showIconVendor && hasFactory && (
                  <span style={{ width: 11, height: 15, marginInline: 5 }} />
                )}
                <TooltipTD
                  label={getPOMetadada(purchaseOrder)}
                  className="customized-tooltip"
                >
                  <NavLink
                    to={
                      !(
                        user.role === userTypes.SUPER_ADMIN ||
                        user.role === userTypes.TRADEDASH_EMPLOYEE ||
                        checkSpecificPermissions({
                          userPermissions: user.permissions,
                          permissionToCheck: [
                            purchaseOrder.factoryId,
                            GENERAL_PERMISSION_VALUE.ALL_VENDORS,
                          ],
                        })
                      )
                        ? "#"
                        : getDashboardSearchPath({
                            salesOrderId: salesOrderId,
                            purchaseOrderId: purchaseOrder.id,
                            shipmentId: shipmentIds[0],
                            section:
                              ORDER_DASHBOARD_ACTUAL_VIEWS.PURCHASE_ORDER,
                          })
                    }
                    id="list-view-content-purchaseorder"
                    className={"navLink"}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <POBadge number={purchaseOrder.number} />
                  </NavLink>
                </TooltipTD>
                {purchaseOrder.status === PO_STATUS.VOIDED && (
                  <SmallVoidLabel />
                )}
                <ThreePanelButton
                  soId={salesOrderId}
                  poId={purchaseOrder.id}
                  shipmentId={shipmentIds[0]}
                  tab={1}
                />
              </div>
            </div>
          );
        })}
      </div>
      {purchaseOrders.length > 0 && (
        <div
          className="iconAnimated"
          style={{
            opacity: !isOpen ? 1 : 0,
            zIndex: !isOpen ? 1 : 0,
            ...iconStyle,
          }}
        >
          {HiddenDataIconAnimated({
            styles: {
              cursor: "pointer",
              display: "flex",
            },
            color: hasTag ? colors.diamondRed : "",
            onClick: (ev) => {
              onChangeSalesOrderSetup({
                ...salesOrderSetup,
                [item.id]: {
                  ...salesOrderSetup[item.id],
                  POs: !salesOrderSetup[item.id]
                    ? true
                    : !salesOrderSetup[item.id].POs,
                },
              });
              ev.stopPropagation();
            },
          })}
          <span
            style={{
              marginLeft: 6,
              fontWeight: 500,
              color: hasTag ? colors.diamondRed : "",
            }}
          >
            {purchaseOrders.length}
          </span>
        </div>
      )}
    </AnimatedListStyled>
  );
}

export default POsItem;
