import { cx } from "@emotion/css";
import React, { useState } from "react";
import { getShipmentBadge } from "../../../helpers/shipments";
import { shipmentBadgeClass } from "../../../container/StyledComponent/BadgeStyled";
import ListPicker from "../../DatePicker/ListPicker";
import { updateDoc } from "firebase/firestore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { uniq } from "lodash";
import {
  ITEM_TABLE_ACTION,
  MISMATCH_STATUS,
  PO_STATUS,
  itemTableReference,
} from "../../../helpers/constants";
import {
  HiddenDataIcon,
  ShipmentOrderIcon,
} from "../../../helpers/iconSvgPath";
import { NavLink } from "react-router-dom";
import {
  formatWithComma,
  getDashboardSearchPath,
} from "../../../helpers/helpers";
import itemStatus from "../../../api/types/itemStatus";
import TooltipTD from "../../Tooltip/TooltipTD";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../sections/helpers";
import SKUEditableLabelV2 from "./SKUEditableLabelV2";

function ShipmentItemsCells({
  shipment = {},
  shipmentsDB = [],
  skuIndex,
  totalRows,
  salesOrdersList,
  shipmentList,
  shipmentIds,
  itemSKU,
  purchaseOrder,
  salesOrderItem = {},
  onChangeData,
  isAllowedToCreate = false,
  onOpenModal = () => {},
  queryParams,
  isCompanyAllowed,
  lastItem,
  showIcon,
  onOpenRow,
  shipmentIndex,
  lastSO,
  showOnlySOIcon,
}) {
  const [openPicker, setOpenPicker] = useState(false);
  const currentShipment =
    shipmentsDB.find((shipmentDB) => shipmentDB.id === shipment.id) || {};

  const onSelectData = (shipmentItem) => {
    const rowToUpdate = {
      ...shipment,
      id: shipmentItem.id,
      number: shipmentItem.number,
      customerId: shipmentItem.customerId,
      prevId: shipment.id,
      action: ITEM_TABLE_ACTION.ASSIGNED_SHIPMENT,
    };
    onChangeData({
      item: itemSKU,
      row: rowToUpdate,
      parentRow: salesOrderItem,
      reference: itemTableReference.SHIPMENTS,
      shipmentReference: shipmentItem,
    });
  };

  const isReadOnly =
    itemSKU.status === itemStatus.READ_ONLY ||
    purchaseOrder.status === PO_STATUS.VOIDED ||
    showIcon ||
    showOnlySOIcon;
  const hiddenClass = showIcon || showOnlySOIcon ? "hiddenClass" : "";

  return (
    <div  className={'shipmentRowContainer'} >
      {openPicker && (
        <ListPicker
          anchorEl={openPicker}
          onChange={async (shipmentItem) => {
            let { purchaseOrderIds, salesOrderIds = [] } = shipmentItem;
            const itemSalesOrderIds = itemSKU.salesOrderIds;
            await updateDoc(shipmentItem.ref, {
              purchaseOrderIds: uniq([...purchaseOrderIds, purchaseOrder.id]),
              salesOrderIds: uniq([...salesOrderIds, ...itemSalesOrderIds]),
            });
            onSelectData(shipmentItem);
            setOpenPicker(null);
          }}
          open={!!openPicker}
          onClose={() => setOpenPicker(null)}
          showNewShipment={isAllowedToCreate}
          filterBy={shipmentIds}
          customerId={salesOrderItem.customerId}
          onOpenModal={() => {
            onOpenModal({
              item: itemSKU,
              row: shipment,
              parentRow: salesOrderItem,
              reference: itemTableReference.SHIPMENTS,
            });
            setOpenPicker(null);
          }}
        />
      )}
      <div
        className={cx("shipmentCell", "cellBorderLeft", "alignRight", {
          lastItem,
        })}
        style={{ paddingRight: 0 }}
      >
        <SKUEditableLabelV2
          className={hiddenClass}
          value={shipment.allocation}
          disabled={!isCompanyAllowed || isReadOnly}
          labelFormat={(value) => formatWithComma(value)}
          previewColor={
            shipment.id === MISMATCH_STATUS.NEW &&
            shipment.status === MISMATCH_STATUS.NEW
          }
          onChangeLabel={(temporalLabel) => {
            onChangeData({
              item: itemSKU,
              row: {
                ...shipment,
                allocation: temporalLabel,
              },
              reference: itemTableReference.SHIPMENTS,
              parentRow: salesOrderItem,
            });
          }}
        />
      </div>
      <div
        className={cx("shipmentCell", "alignLeft", {
          lastItem,
        })}
      >
        {shipment.id === MISMATCH_STATUS.NEW &&
          !hiddenClass &&
          shipment.status === MISMATCH_STATUS.INCOMPLETE && (
            <div
              className={cx("newShipmentBadge")}
              onClick={(ev) => {
                setOpenPicker(ev.currentTarget);
                ev.stopPropagation();
              }}
            >
              <ShipmentOrderIcon
                width={14}
                height={14}
                svgClass={"shipmentIcon"}
              />
              <span>Shipment</span>
              <ArrowDropDownIcon style={{ width: 18, height: 18 }} />
            </div>
          )}
        {shipment.id !== MISMATCH_STATUS.NEW && (
          <NavLink
            to={getDashboardSearchPath({
              ...queryParams,
              shipmentId: shipment.id,
              section: ORDER_DASHBOARD_ACTUAL_VIEWS.SHIPMENT,
            })}
            className={hiddenClass}
          >
            {getShipmentBadge({
              shipment: currentShipment,
              styles: { margin: 0 },
              classes: shipmentBadgeClass.mediumInverted,
              showToolTip: false,
            })}
          </NavLink>
        )}
        <div />
        {skuIndex === 0 && (
          <div
            className="expandIconContainer"
            style={{
              opacity: showIcon ? 1 : 0,
              zIndex: showIcon ? 1 : -1,
            }}
            onClick={() => onOpenRow(itemSKU.id, true)}
          >
            <HiddenDataIcon svgClass={cx("expandRow")} />
            {shipmentIds.length}
          </div>
        )}
      </div>
      <div
        className={cx("customized-tooltip", "cellBorderRight", "shipmentCell", {
          lastItem,
        })}
      >
        <TooltipTD
          label={currentShipment.finalDestination}
          className={cx("tooltTipSpan", hiddenClass)}
          showToolTip={!hiddenClass}
        >
          {currentShipment.finalDestination}
        </TooltipTD>
      </div>
      {skuIndex === 0 && (
        <div
          className="totalNumber"
          style={{
            gridColumn: "7 span",
            gridRow: `${totalRows - 1} span`,
          }}
        >
          <span className="totalText">
            Total Customers: {salesOrdersList.length}
          </span>
          <span className="totalText">
            Total Shipments: {shipmentIds.length}
          </span>
        </div>
      )}
      {shipmentIndex === 0 && shipmentList.length > 1 && (
        <div
          className={cx("salesOrderCell", "cellBorderLeft", "cellBorderRight", {
            cellBorderBottom: !lastSO,
          })}
          style={{
            gridRow: `${shipmentList.length - 1} span`,
            paddingRight: 0,
            gridColumn: "span 3",
          }}
        />
      )}
    </div>
  );
}

export default ShipmentItemsCells;
