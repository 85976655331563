import React, { useContext, useEffect, useRef, useState } from "react";
import { ActivityStyled, ActivityTimelineStyled } from "./styles";
import { useCompanyUsers, useUser } from "../../../hooks/user";
import { activityType } from "../../../helpers/activitiesStream";
import { getUserAvatar } from "../../../helpers/users";
import DOMPurify from "dompurify";
import { cx } from "@emotion/css";
import moment from "moment";
import { Button, Collapse } from "@mui/material";
import ActivityFooter from "./ActivityFooter";
import ActivityHead from "./ActivityHeader";
import {
  ACTIVITY_STREAM_ACTION,
  ActivityStreamContext,
} from "../../CompanyTabs/DeleteNotesContext";
import ExpandActivityModal from "../../Widgets/Activities/ExpandActivityModal";
import AttentionModal from "../../Modal/AttentionModal";
import { deleteDoc, doc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import { dbTables } from "../../../api/types/dbTables";
import { ACTIVITY_DELETE_STATUS } from "../../../helpers/constants";
const initialValue = {
  showAll: false,
  showReadMore: true,
  maxHeigth: 100,
};

function ActivityToDelete({
  activity,
  hasThreadPermission,
  handleShowThread,
  newActivityApper = false,
  sizeThreadChilds = 0,
  handleDelete,
}) {
  const [modalAttention, setModalAttention] = useState({
    open: false,
    description: "",
  });
  const [readMoreStatus, setReadMoreStatus] = useState(initialValue);

  const { activityStreamState, dispatchActivityStream } = useContext(
    ActivityStreamContext
  );
  const { activityModal = {} } = activityStreamState;
  const currentUser = useUser();

  const bodyRef = useRef();
  const activityRef = useRef();
  const companyUsers = useCompanyUsers({
    showBotUser: true,
    showInactiveUsers: true,
  });

  const createMarkup = (html) => {
    return {
      __html: DOMPurify.sanitize(html, { ADD_ATTR: ["target"] }),
    };
  };

  useEffect(() => {
    if (activity.type === activityType.NOTE) {
      const element = bodyRef.current || {};
      const heightClient = element?.clientHeight || 23;
      if (heightClient >= 240) {
        setReadMoreStatus({
          showAll: false,
          showReadMore: true,
          maxHeigth: 235,
        });
      } else {
        setReadMoreStatus({
          showAll: true,
          showReadMore: false,
          maxHeigth: "auto",
        });
      }
    }
  }, [activity.detail]);

  function deleteActivityNotification({ activity }) {
    const { notificationId = "", userMentions = {} } = activity;
    if (!notificationId || Object.keys(userMentions).length === 0) {
      return console.log("No notifications to delete");
    } else {
      Object.keys(userMentions).forEach((userId) => {
        try {
          deleteDoc(
            doc(
              firestore,
              `${dbTables.USERS}/${userId}/${dbTables.NOTIFICATIONS}/${notificationId}`
            )
          );
        } catch (error) {
          console.error("Error deleting notification", error);
        }
      });
    }
  }

  return (
    <div
      style={{
        display: "flex",
        width: 900,
      }}
    >
      {modalAttention.open && (
        <AttentionModal
          isOpen={modalAttention.open}
          title={"Attention"}
          description={modalAttention.description}
          onClose={() => setModalAttention({ open: false })}
          onClick={() => {
            setModalAttention({ open: false });
            console.log("activity", activity);
            const { id: activityId, deletedStatus, numberOfReplies } = activity;
            if (!deletedStatus) {
              let newDeletedStatus = ACTIVITY_DELETE_STATUS.DELETED;
              if (numberOfReplies > 0) {
                newDeletedStatus = ACTIVITY_DELETE_STATUS.HIDDEN;
              }
              handleDelete({
                activityId: activityId,
                deletedStatus: newDeletedStatus,
              });
              deleteActivityNotification({ activity });
            } else {
              handleDelete({
                activityId: activityId,
                deletedStatus: "",
              });
            }
          }}
          cancellable={true}
          cancelText="Cancel"
          confirmationText="Yes"
        />
      )}
      {activityModal.id === activity.id && (
        <ExpandActivityModal
          activity={activity}
          openModalActivity={!!activity.id}
          onCloseModalNotes={() => {
            dispatchActivityStream({
              type: ACTIVITY_STREAM_ACTION.COMMON,
              payload: {
                activityModal: {},
              },
            });
          }}
          user={currentUser}
        />
      )}
      <ActivityTimelineStyled className="activity-timeline">
        <div className="time-info">
          <span>{moment(activity.creationDate).format("h:mm a")}</span>
        </div>
      </ActivityTimelineStyled>
      <ActivityStyled
        key={activity.id}
        className={cx("activity-container", activity.scope, {
          "activity-newContainer": newActivityApper,
        })}
        ref={activityRef}
        id={"activity-backdrop-" + activity.id}
      >
        <ActivityHead
          activity={activity}
          users={companyUsers}
          hasThreadPermission={hasThreadPermission}
          handleShowThread={handleShowThread}
          sizeThreadChilds={sizeThreadChilds}
          activityStreamState={activityStreamState}
          dispatchActivityStream={dispatchActivityStream}
          threadTitle="Part of this thread"
        />
        <div className="activity-content">
          <div className="created-by-avatar">
            {activity.user &&
              getUserAvatar({
                user: companyUsers.find((user) => user.id === activity.user),
                styles: {
                  outline: "2px solid #000",
                  boxShadow: "0px 0px 1px 2px #f5f5f5",
                },
              })}
          </div>
          <div className="activity-center">
            <Collapse
              in={readMoreStatus.showAll}
              collapsedSize={readMoreStatus.maxHeigth}
            >
              <div
                className="activity-body"
                ref={bodyRef}
                dangerouslySetInnerHTML={createMarkup(activity.detail)}
              />
            </Collapse>
            <ActivityFooter
              activity={activity}
              activityRef={activityRef}
              readMoreStatus={readMoreStatus}
              handleReadMoreStatus={setReadMoreStatus}
              dispatchActivityStream={dispatchActivityStream}
            />
          </div>
        </div>
      </ActivityStyled>
      <div className="button-to-delete-container">
        <Button
          type="button"
          variant="contained"
          color={activity.deletedStatus ? "error" : "info"}
          onClick={() =>
            setModalAttention({
              open: true,
              description: activity.deletedStatus ? (
                <React.Fragment>
                  Are you sure you want to undelete this note?
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Are you sure you want to delete this note? <br />
                  All notifications will be deleted permanently
                </React.Fragment>
              ),
            })
          }
        >
          {activity.deletedStatus ? "Undelete Note" : "Delete Note"}
        </Button>
      </div>
    </div>
  );
}

export default ActivityToDelete;
