import React from "react";
import { TableCell } from "@mui/material";
import { FooterRowStyled } from "../filterTableRefactor/MainRowStyled";
import TooltipTD from "../Tooltip/TooltipTD";

function FooterTable({
  footer = [],
  headerCells,
  isThereColumnSetting = false,
}) {
  function footerText(value) {
    if (value === "Totals:") {
      return <span className="totals">{value}</span>;
    } else {
      return <span className="dollars">{value}</span>;
    }
  }
  return (
    <FooterRowStyled>
      {headerCells.map((cell) => {
        let response = null;
        if (cell.enabled) {
          const footerCell = footer[cell.name];
          if (footerCell) {
            response = (
              <TableCell
                key={cell.id}
                style={{
                  ...footerCell.styles,
                  borderBottom: "white 1px solid",
                }}
                className={footerCell.divider ? "blueDivider" : "grayDivider"}
                colSpan={footerCell.colSpan ? footerCell.colSpan : 1}
                id={"footerCell"}
              >
                <TooltipTD
                  className="customized-tooltip"
                  label={footerText(footerCell.value)}
                >
                  <span>{footerText(footerCell.value)}</span>
                </TooltipTD>
              </TableCell>
            );
          }
        }
        return response;
      })}
      {isThereColumnSetting && (
        <TableCell
          style={{
            borderBottom: "white 1px solid",
          }}
          className="grayDivider"
        />
      )}
    </FooterRowStyled>
  );
}

export default FooterTable;
