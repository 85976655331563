import React from "react";
import TooltipTD from "../components/Tooltip/TooltipTD";
import { Avatar } from "@mui/material";
import { cx } from "@emotion/css";
import { DefaultUserIcon } from "./iconSvgPath";
import { colors } from "../assets/jss/variables";

const userAvatarStyles = {
  width: 40,
  height: 40,
  outline: "3px solid #000",
  color: "#000",
  backgroundColor: "transparent",
  fontWeight: 500,
  textTransform: "capitalize",
};

export function getUserAvatar({
  user,
  styles = {},
  hasToolTip = false,
  initialStyles = {},
  className = "",
  clickAction,
  avatarClassName = "",
  showEmptyAvatar,
}) {
  if (user && user.avatar) {
    return (
      <TooltipTD
        label={`@${user.displayName}`}
        key={user.id}
        showToolTip={hasToolTip}
        className={className}
        tooltipClick={clickAction}
      >
        <Avatar
          alt="Avatar"
          src={user.avatar}
          style={{ ...styles, outline: "0px" }}
          className={cx(className, avatarClassName)}
        />
      </TooltipTD>
    );
  } else if (user && user.firstName && user.lastName) {
    return (
      <TooltipTD
        label={`@${user.displayName}`}
        key={user.id}
        showToolTip={hasToolTip}
        className={className}
      >
        <Avatar
          variant="circular"
          className={avatarClassName}
          style={{ ...userAvatarStyles, ...styles, ...initialStyles }}
        >
          {user.firstName[0]}
          {user.lastName[0]}
        </Avatar>
      </TooltipTD>
    );
  }
  if (showEmptyAvatar) {
    return (
      <TooltipTD
        key={"emptyUser"}
        className={className}
        showToolTip={hasToolTip}
      >
        <DefaultUserIcon color={colors.primaryDark} />
      </TooltipTD>
    );
  }
  return <div> </div>;
}

export function validDisplayName(value) {
  const regex = /^[a-zA-Z0-9_-]{2,24}$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
}

export function validAccessGroup(value) {
  const regex = /^[a-zA-Z0-9._-\s]{2,50}$/;
  if (regex.test(value)) {
    return true;
  } else {
    return false;
  }
}
