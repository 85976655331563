import React, { useState } from "react";
import EmptyMessage from "../General/EmptyMessage";
import { colors } from "../../assets/jss/variables";
import { ArrowsToSortIcon } from "../../helpers/iconSvgPath";
import {
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { cx } from "@emotion/css";
import { MainTableStyled } from "./tableStyles";
import { Rnd } from "react-rnd";

function MainTableRefactor({
  headCells,
  bodyRows,
  renderRow = () => {},
  footer,
  enabledToSee = true,
  noMessage,
  stylesTable = {},
  filters = {},
  onUpdateFilters = () => {},
  isThereColumnSetting = false,
  tableRowHeaderStyles = {},
  nonLookUpfilters = {},
  key = "",
  bodyTableId = "",
  sortArrowsColor = "#EEEEEE",
  onResizeColumn = () => {},
  emptyFilter = false,
  emptyText,
}) {
  const [isResizing, setIsResizing] = useState(false);
  function sortActualColumn(actualColumn) {
    if (filters.sortedColumn === actualColumn) {
      if (filters.orderBy === "ASC") {
        onUpdateFilters({ ...filters, orderBy: "DESC" });
      } else {
        onUpdateFilters({ ...filters, orderBy: "ASC" });
      }
    } else {
      onUpdateFilters({
        ...filters,
        sortedColumn: actualColumn,
        orderBy: "ASC",
      });
    }
  }
  function renderArrow(headerLabel) {
    if (filters.sortedColumn === headerLabel) {
      if (filters.orderBy === "ASC") {
        return (
          <ArrowsToSortIcon
            style={{
              opacity: 1,
            }}
            leftColor={colors.primaryDark}
            rightColor={sortArrowsColor}
          />
        );
      }
      return (
        <ArrowsToSortIcon
          style={{
            opacity: 1,
          }}
          rightColor={colors.primaryDark}
          leftColor={sortArrowsColor}
        />
      );
    }
    return (
      <ArrowsToSortIcon
        leftColor={sortArrowsColor}
        rightColor={sortArrowsColor}
      />
    );
  }

  return (
    <MainTableStyled
      stickyHeader
      className="mainTable"
      style={{ ...stylesTable }}
      key={key}
    >
      <TableHead>
        <TableRow
          classes={{ root: "tableHeaderRow" }}
          style={tableRowHeaderStyles}
        >
          {headCells.map((cell) => {
            const styles = cell.styles || {};
            let paddingLeft;
            let paddingRight;
            let paddings = { padding: styles.padding };
            const isSortHeader =
              filters.sortedColumn === cell.label ||
              filters.sortedColumn === cell.reference;
            if (cell.dynamicPadding) {
              let totalDynamic = cell.dynamicPadding.reduce((acc, key) => {
                if (nonLookUpfilters[key]) {
                  return acc + 20;
                } else {
                  return acc;
                }
              }, 0);
              paddingLeft = styles.paddingLeft + totalDynamic;
              paddingRight = styles.paddingRight - totalDynamic;
              paddings = { paddingLeft, paddingRight };
            }
            return cell.enabled ? (
              <TableCell
                id={`headerCell-${cell.name}`}
                key={cell.id}
                className={cx("headerCell", {
                  sortedHeaderCell: isSortHeader,
                })}
                style={{
                  ...styles,
                  ...paddings,
                  opacity: isResizing ? 0.8 : 1,
                }}
                onClick={() => {
                  if (!cell.canBeSort) {
                    return;
                  }
                  sortActualColumn(cell.reference || cell.label);
                }}
              >
                <Rnd
                  size={{
                    width: styles.width,
                    height: "100%",
                    minWidth: 150,
                  }}
                  resizeGrid={[1, 1]}
                  dragAxis="none"
                  enableResizing={{
                    left: false,
                    right: cell.resizable || !cell.disabledDraggable,
                  }}
                  className={"rndContainer"}
                  onResizeStop={(ev, dir, ref, delta) => {
                    const offset = Math.round(delta.width);
                    onResizeColumn({
                      cell,
                      offset: styles.width + offset,
                    });
                    setIsResizing(false);
                    return;
                  }}
                  style={{
                    ...styles,
                    display: "flex",
                    alignItems: "center",
                  }}
                  disableDragging={true}
                  minWidth={styles.minWidth}
                  onResizeStart={() => {
                    setIsResizing(true);
                  }}
                >
                  <span
                    className="headerLabel"
                    style={{ width: cell.labelWidth }}
                  >
                    {cell.displayLabel || cell.label}
                  </span>
                  <span className="headerArrow">
                    {cell.canBeSort &&
                      renderArrow(cell.reference || cell.label)}
                  </span>
                  {cell.resizable && <div className={"resize-icon"} />}
                </Rnd>
              </TableCell>
            ) : null;
          })}
          {isThereColumnSetting && <TableCell className="columnSettings" />}
        </TableRow>
      </TableHead>
      <TableBody
        id={bodyTableId}
        style={{
          opacity: isResizing ? 0.2 : 1,
        }}
      >
        {enabledToSee && bodyRows && bodyRows.length
          ? bodyRows.map(renderRow)
          : bodyRows.length === 0 &&
            !noMessage && (
              <TableRow>
                <TableCell
                  colSpan={headCells.length}
                  style={{
                    padding: 0,
                  }}
                  rowSpan={5}
                  id="emptyMessage"
                >
                  <EmptyMessage
                    emptyFilter={emptyFilter}
                    emptyText={emptyText}
                  />
                </TableCell>
              </TableRow>
            )}
      </TableBody>
      {footer && <TableFooter>{footer}</TableFooter>}
    </MainTableStyled>
  );
}

export default MainTableRefactor;
