/**
 * Helpers Functions
 */
import React from "react";
import { useCallback } from "react";
import moment, { now } from "moment";
import taskStages from "../api/types/taskStages";
import {
  cleanPOsRedux,
  cleanShipmentRedux,
  clearSKURedux,
  getDocumentMiscellaneous,
  getFirestoreDocument,
  getListenerPOs,
  getListenerShipments,
  getListenerSKU,
  getListenerSO,
  getListenRolesPermissions,
  getListenUserMentions,
  getNestedCollectionFromFirestoreTable,
  getNestedCollectionFromFirestoreTableRefactored,
  getNotificationsFromFirestoreTable,
} from "../actions/DataActions";
import File from "../api/model/file";
import { dbTables, typeOfTask } from "../api/types/dbTables";
import {
  searchableProperties,
  typeTaskTemplateError,
  BACKDROP_TYPE,
  featureFlagNames,
  regexEmailValidator,
  NOTIFICATION_SCOPE,
  TYPE_OF_FILE,
  taskStatus,
  AUTOMATIC_SYSTEM_LABEL,
  GENERAL_PERMISSION_VALUE,
  TYPE_NOTIFICATION,
} from "./constants";
import { useUser, useCompanyUsers } from "../hooks/user";
import numeral from "numeral";
import Notification from "../api/model/Notification";
import userTypes from "../api/types/userTypes";
import { useLocation } from "react-router-dom";
import { useCustomers } from "../hooks/customers";
import { useFactories } from "../hooks/factories";
import CustomerSummary from "../api/model/customerSummary";
import FactorySummary from "../api/model/factorySummary";
import CustomerItem from "../api/model/customerItem";
import FactoryItem from "../api/model/factoryItem";
import { getCorrectTimezone } from "./ganttChart";
import { firestore, storage } from "../firebase";
import { colors } from "../assets/jss/variables";
import {
  getDoc,
  setDoc,
  doc,
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  increment,
  limit,
} from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { verifyPermission } from "../hooks/permissions";
import { CLIENT_IS_OFFLINE } from "../actions/types";
import { activityType } from "./activitiesStream";
import { getFunctions, httpsCallableFromURL } from "firebase/functions";
import {
  getFunctionByName,
  globalEnvironment,
} from "../constants/globalVariables";
// import { trackEvent } from "./analytics";
/**import { useUser } from '../hooks/user';
 *
 * @param {Number} timestamp
 */

export function isTimestamp(timestamp) {
  return new Date(timestamp).getTime() > 0;
}

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      alpha +
      ")"
    );
  }
  throw new Error("Bad Hex");
}

/**
 * Invoke function the second time
 */
export function handleSubscription(fn) {
  let isFirstTime = true;
  return (args) => {
    if (isFirstTime) {
      isFirstTime = false;
      return true;
    } else {
      return fn(args);
    }
  };
}
/**
 * Random Id Generator
 */
export function getRandomId() {
  const collectionRef = collection(firestore, "generic");
  const docRef = doc(collectionRef);
  return docRef && docRef.id;
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
  let time = timestamp;
  if (!timestamp) {
    return "";
  }
  let formatDate = format ? format : "M/D/YY";
  return moment(time).format(formatDate);
}
/**
 * Sort List of Object
 */
export function sortObjectsBy(property, reverse, isDate = false) {
  let order = 1;
  if (reverse) {
    order = -1;
  }
  return function (a, b) {
    let result = "";
    let aProperty = a[property];
    let bProperty = b[property];
    if (isDate) {
      aProperty = moment(aProperty).valueOf();
      bProperty = moment(bProperty).valueOf();
    }
    if (typeof aProperty === "string" && typeof bProperty === "string") {
      const stringA = aProperty.toLowerCase().trim();
      const stringB = bProperty.toLowerCase().trim();
      result = stringA > stringB ? 1 : stringA < stringB ? -1 : 0;
    } else {
      if (!aProperty && !bProperty) {
        result = 0;
        return result;
      }
      if (!aProperty && bProperty) {
        result = -1;
        return result * order;
      }
      if (aProperty && !bProperty) {
        result = 1;
        return result * order;
      }

      result = aProperty > bProperty ? 1 : aProperty < bProperty ? -1 : 0;
    }
    return result * order;
  };
}

export const getDashboardSearchPath = ({
  salesOrderId = "",
  purchaseOrderId = "",
  shipmentId = "",
  noteId,
  taskId,
  fileId,
  section = "",
}) => {
  let search;
  let aditionalParam = "";
  if (noteId && noteId !== "undefined") {
    aditionalParam += `&&noteId=${noteId}`;
  }
  if (taskId && taskId !== "undefined") {
    aditionalParam += `&&taskId=${taskId}`;
  }
  if (fileId && fileId !== "undefined") {
    aditionalParam += `&&fileId=${fileId}`;
  }
  if (section && section !== "undefined") {
    aditionalParam += `&&section=${section}`;
  }
  if (!shipmentId) {
    search = `salesOrderId=${salesOrderId}&&purchaseOrderId=${purchaseOrderId}${aditionalParam}`;
    return {
      pathname: "/app/dashboard",
      search: `?${search}`,
    };
  } else if (!purchaseOrderId) {
    search = `salesOrderId=${salesOrderId}`;
    return {
      pathname: "/app/dashboard",
      search: `?${search}`,
    };
  }
  search = `salesOrderId=${salesOrderId}&&purchaseOrderId=${purchaseOrderId}&&shipmentId=${shipmentId}${aditionalParam}`;
  return {
    pathname: "/app/dashboard",
    search: `?${search}`,
  };
};

export const getPurchaseOrdersPermission = ({
  purchaseOrders,
  user,
  queryParams,
}) => {
  const filterPurchaseOrders = purchaseOrders.filter((po) =>
    verifyPermission({
      user,
      permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
    })
  );
  const firstPO = filterPurchaseOrders[0];
  const purchaseOrderId = queryParams.purchaseOrderId;
  const currentPurchaseOrder = filterPurchaseOrders.find(
    (po) => po.id === purchaseOrderId
  );
  if (currentPurchaseOrder || filterPurchaseOrders.length === 0 || !firstPO) {
    return purchaseOrderId;
  } else {
    return firstPO.id;
  }
};

export function sortSalesOrdersBy(
  columnName,
  isDescending,
  customers = [],
  screenName,
  companyUsers,
  factories = []
) {
  // console.log("Customers: ", customers);
  // console.log("ScreenName: ", screenName);
  // console.log("CompanyUsers: ", companyUsers);
  // console.log("Factories: ", factories);
  // console.log("====================================");
  return function (a, b) {
    let valueOfA = "";
    let valueOfB = "";

    let order = isDescending ? 1 : -1;
    let result = "";
    if (
      columnName === "amount" ||
      columnName === "deposit" ||
      columnName === "potentialLateFee"
    ) {
      result =
        (!a[columnName] ? 0 : parseFloat(a[columnName])) >
        (!b[columnName] ? 0 : parseFloat(b[columnName]))
          ? 1
          : (!a[columnName] ? 0 : parseFloat(a[columnName])) <
            (!b[columnName] ? 0 : parseFloat(b[columnName]))
          ? -1
          : 0;
    } else if (columnName === "purchaseOrders") {
      result =
        (!a[columnName][0].number ? "0" : a[columnName][0].number) >
        (!b[columnName][0].number ? "0" : b[columnName][0].number)
          ? 1
          : (!a[columnName][0].number ? "0" : a[columnName][0].number) <
            (!b[columnName][0].number ? "0" : b[columnName][0].number)
          ? -1
          : 0;
    } else if (columnName === "customer") {
      let customerA = "";
      let customerB = "";
      if (screenName === "Logistics") {
        customerA = customers.find(
          (customer) => customer.id === a.salesOrders[0].customerId
        );
        customerB = customers.find(
          (customer) => customer.id === b.salesOrders[0].customerId
        );
      } else {
        customerA = customers.find((customer) => customer.id === a.customerId);
        customerB = customers.find((customer) => customer.id === b.customerId);
      }
      result =
        (!customerA["name"] ? "0" : customerA["name"]) >
        (!customerB["name"] ? "0" : customerB["name"])
          ? 1
          : (!customerA["name"] ? "0" : customerA["name"]) <
            (!customerB["name"] ? "0" : customerB["name"])
          ? -1
          : 0;
    } else if (columnName === "CBM" && screenName !== "TaskListScreen") {
      let CBM_A = "";
      let CBM_B = "";
      if (screenName === "Logistics") {
        CBM_A = a.salesOrders[0].purchaseOrders[0].CBM;
        CBM_B = b.salesOrders[0].purchaseOrders[0].CBM;
        result =
          (!CBM_A ? 0 : parseFloat(CBM_A)) > (!CBM_B ? 0 : parseFloat(CBM_B))
            ? 1
            : (!CBM_A ? 0 : parseFloat(CBM_A)) <
              (!CBM_B ? 0 : parseFloat(CBM_B))
            ? -1
            : 0;
      } else {
        CBM_A = a.purchaseOrders;
        CBM_B = b.purchaseOrders;
        result =
          (!CBM_A[0].CBM ? 0 : parseFloat(CBM_A[0].CBM)) >
          (!CBM_B[0].CBM ? 0 : parseFloat(CBM_B[0].CBM))
            ? 1
            : (!CBM_A[0].CBM ? 0 : parseFloat(CBM_A[0].CBM)) <
              (!CBM_B[0].CBM ? 0 : parseFloat(CBM_B[0].CBM))
            ? -1
            : 0;
      }
    } else if (columnName === "factoryName") {
      valueOfB = factories.find((factory) => factory.id === b.factoryId);
      valueOfA = factories.find((factory) => factory.id === a.factoryId);

      result =
        (!valueOfA ? "0" : valueOfA.name) > (!valueOfB ? "0" : valueOfB.name)
          ? 1
          : (!valueOfA ? "0" : valueOfA.name) <
            (!valueOfB ? "0" : valueOfB.name)
          ? -1
          : 0;
    } else if (columnName === "assignedTo") {
      let companyUserA = companyUsers.find(
        (companyUser) => companyUser.id === a.assignedTo
      );
      let companyUserB = companyUsers.find(
        (companyUser) => companyUser.id === b.assignedTo
      );

      result =
        (!companyUserA ? "0" : companyUserA["displayName"]) >
        (!companyUserB ? "0" : companyUserB["displayName"])
          ? 1
          : (!companyUserA ? "0" : companyUserA["displayName"]) <
            (!companyUserB ? "0" : companyUserB["displayName"])
          ? -1
          : 0;
    } else if (
      screenName === "OrdersByPhase" &&
      columnName === "purchaseOrderNumber"
    ) {
      result =
        (!a["number"] ? "0" : a["number"]) > (!b["number"] ? "0" : b["number"])
          ? 1
          : (!a["number"] ? "0" : a["number"]) <
            (!b["number"] ? "0" : b["number"])
          ? -1
          : 0;
    } else if (screenName === "OrdersByPhase" && columnName === "PO Value") {
      result =
        (!a["amount"] ? "0" : a["amount"]) > (!b["amount"] ? "0" : b["amount"])
          ? 1
          : (!a["amount"] ? "0" : a["amount"]) <
            (!b["amount"] ? "0" : b["amount"])
          ? -1
          : 0;
    } else if (
      (columnName === "shippingTerms" && screenName === "SalesOrdersScreen") ||
      (columnName === "loadingPort" && screenName === "SalesOrdersScreen") ||
      (columnName === "unloadingPort" && screenName === "SalesOrdersScreen") ||
      (columnName === "finalDestination" && screenName === "SalesOrdersScreen")
    ) {
      result =
        (!a[columnName] ? "0" : a[columnName]) >
        (!b[columnName] ? "0" : b[columnName])
          ? 1
          : (!a[columnName] ? "0" : a[columnName]) <
            (!b[columnName] ? "0" : b[columnName])
          ? -1
          : 0;
    } else if (columnName === "number" && screenName === "OrdersByPhase") {
      result =
        (!a[columnName] ? "0" : a[columnName]) >
        (!b[columnName] ? "0" : b[columnName])
          ? 1
          : (!a[columnName] ? "0" : a[columnName]) <
            (!b[columnName] ? "0" : b[columnName])
          ? -1
          : 0;
    } else if (columnName === "number" && screenName === "TaskListScreen") {
      result =
        (!a["salesOrderNumber"] ? "0" : a["salesOrderNumber"]) >
        (!b["salesOrderNumber"] ? "0" : b["salesOrderNumber"])
          ? 1
          : (!a["salesOrderNumber"] ? "0" : a["salesOrderNumber"]) <
            (!b["salesOrderNumber"] ? "0" : b["salesOrderNumber"])
          ? -1
          : 0;
    } else if (columnName === "number" && screenName === "SalesOrdersScreen") {
      result =
        (!a[columnName] ? "0" : a[columnName].toString()) >
        (!b[columnName] ? "0" : b[columnName].toString())
          ? 1
          : (!a[columnName] ? "0" : a[columnName].toString()) <
            (!b[columnName] ? "0" : b[columnName].toString())
          ? -1
          : 0;
    } else if (
      columnName === "booking" ||
      columnName === "freightForwarder" ||
      columnName === "container" ||
      columnName === "shippingTerms" ||
      columnName === "loadingPort" ||
      columnName === "unloadingPort" ||
      columnName === "finalDestination" ||
      columnName === "number" ||
      columnName === "invoice"
    ) {
      valueOfA = a.salesOrders[0][columnName];
      valueOfB = b.salesOrders[0][columnName];

      result =
        (!valueOfA ? "0" : valueOfA) > (!valueOfB ? "0" : valueOfB)
          ? 1
          : (!valueOfA ? "0" : valueOfA) < (!valueOfB ? "0" : valueOfB)
          ? -1
          : 0;
    } else if (columnName === "Purchase Order" && screenName === "Logistics") {
      valueOfA = a.salesOrders[0].purchaseOrders[0].number;
      valueOfB = b.salesOrders[0].purchaseOrders[0].number;
      result =
        (!valueOfA ? "0" : valueOfA) > (!valueOfB ? "0" : valueOfB)
          ? 1
          : (!valueOfA ? "0" : valueOfA) < (!valueOfB ? "0" : valueOfB)
          ? -1
          : 0;
    } else if (
      (columnName === "requiredShipDate" && screenName === "Logistics") ||
      (columnName === "revisedShipDate" && screenName === "Logistics")
    ) {
      if (columnName === "requiredShipDate") {
        valueOfA = a.salesOrders[0].purchaseOrders[0].requiredShipDate;
        valueOfB = b.salesOrders[0].purchaseOrders[0].requiredShipDate;
      }
      if (columnName === "revisedShipDate") {
        valueOfA = a.salesOrders[0].purchaseOrders[0].revisedShipDate;
        valueOfB = b.salesOrders[0].purchaseOrders[0].revisedShipDate;
      }

      result =
        (!valueOfA ? "0" : valueOfA) > (!valueOfB ? "0" : valueOfB)
          ? 1
          : (!valueOfA ? "0" : valueOfA) < (!valueOfB ? "0" : valueOfB)
          ? -1
          : 0;
    } else if (
      columnName === "Purchase Orders" &&
      screenName === "OrdersByPhase"
    ) {
      valueOfA = a.purchaseOrderList.length;
      valueOfB = b.purchaseOrderList.length;
      result =
        (!valueOfA ? "0" : valueOfA) > (!valueOfB ? "0" : valueOfB)
          ? 1
          : (!valueOfA ? "0" : valueOfA) < (!valueOfB ? "0" : valueOfB)
          ? -1
          : 0;
    } else if (columnName === "Total" && screenName === "OrdersByPhase") {
      let sumA = 0;
      a.purchaseOrderList.forEach((element) => {
        sumA += !element.amount ? 0 : numeral(element.amount).value();
      });
      let sumB = 0;
      b.purchaseOrderList.forEach((element) => {
        sumB += !element.amount ? 0 : numeral(element.amount).value();
      });
      result =
        (!sumA ? 0 : sumA) > (!sumB ? 0 : sumB)
          ? 1
          : (!sumA ? 0 : sumA) < (!sumB ? 0 : sumB)
          ? -1
          : 0;
    } else if (columnName === "Balance" && screenName === "SalesOrdersScreen") {
      valueOfA = numeral(a.amount).value() - numeral(a.deposit).value();
      valueOfB = numeral(b.amount).value() - numeral(b.deposit).value();
      result =
        (!valueOfA ? 0 : valueOfA) > (!valueOfB ? 0 : valueOfB)
          ? 1
          : (!valueOfA ? 0 : valueOfA) < (!valueOfB ? 0 : valueOfB)
          ? -1
          : 0;
    } else {
      result =
        (!a[columnName] ? "0" : a[columnName]) >
        (!b[columnName] ? "0" : b[columnName])
          ? 1
          : (!a[columnName] ? "0" : a[columnName]) <
            (!b[columnName] ? "0" : b[columnName])
          ? -1
          : 0;
    }
    return result * order;
  };
}

export function sortPurchaseOrdersBy(
  columnName,
  isDescending,
  salesOrders,
  customers,
  factories
) {
  let order = isDescending ? 1 : -1;
  return function (a, b) {
    let result = "";
    let salesOrderRelatedToPOa = salesOrders.find(
      (SO) => SO.id === a.salesOrderId
    );
    let salesOrderRelatedToPOb = salesOrders.find(
      (SO) => SO.id === b.salesOrderId
    );
    let customerRelatedToPOa = customers.find(
      (customer) => customer.id === a.customerId
    );
    let customerRelatedToPOb = customers.find(
      (customer) => customer.id === b.customerId
    );
    let factoryRelatedToPOa = factories.find(
      (factory) => factory.id === a.factoryId
    );
    let factoryRelatedToPOb = factories.find(
      (factory) => factory.id === b.factoryId
    );

    if (
      columnName === "deposit" ||
      columnName === "amount" ||
      columnName === "potentialLateFee" ||
      columnName === "po_value" ||
      columnName === "CBM" ||
      columnName === "userMentionCount"
    ) {
      result =
        (!a[columnName] ? 0 : parseFloat(a[columnName])) >
        (!b[columnName] ? 0 : parseFloat(b[columnName]))
          ? 1
          : (!a[columnName] ? 0 : parseFloat(a[columnName])) <
            (!b[columnName] ? 0 : parseFloat(b[columnName]))
          ? -1
          : 0;
    } else if (columnName === "SO_number" || columnName === "SO_deliveryDate") {
      const columnNameSO = columnName.split("_").pop();
      result =
        (!salesOrderRelatedToPOa[columnNameSO]
          ? "0"
          : salesOrderRelatedToPOa[columnNameSO]) >
        (!salesOrderRelatedToPOb[columnNameSO]
          ? "0"
          : salesOrderRelatedToPOb[columnNameSO])
          ? 1
          : (!salesOrderRelatedToPOa[columnNameSO]
              ? "0"
              : salesOrderRelatedToPOa[columnNameSO]) <
            (!salesOrderRelatedToPOb[columnNameSO]
              ? "0"
              : salesOrderRelatedToPOb[columnNameSO])
          ? -1
          : 0;
    } else if (columnName === "CUSTOMER_name") {
      const columnNameCUSTOMER = columnName.split("_").pop();
      result =
        (!customerRelatedToPOa[columnNameCUSTOMER]
          ? "0"
          : customerRelatedToPOa[columnNameCUSTOMER]) >
        (!customerRelatedToPOb[columnNameCUSTOMER]
          ? "0"
          : customerRelatedToPOb[columnNameCUSTOMER])
          ? 1
          : (!customerRelatedToPOa[columnNameCUSTOMER]
              ? "0"
              : customerRelatedToPOa[columnNameCUSTOMER]) <
            (!customerRelatedToPOb[columnNameCUSTOMER]
              ? "0"
              : customerRelatedToPOb[columnNameCUSTOMER])
          ? -1
          : 0;
    } else if (columnName === "balance") {
      const amountA = !a["amount"] ? 0 : a["amount"];
      const amountB = !b["amount"] ? 0 : b["amount"];
      const depositA = !a["deposit"] ? 0 : a["deposit"];
      const depositB = !b["deposit"] ? 0 : b["deposit"];
      let balanceA = parseFloat(amountA) - parseFloat(depositA);
      let balanceB = parseFloat(amountB) - parseFloat(depositB);
      result = balanceA > balanceB ? 1 : balanceA < balanceB ? -1 : 0;
    } else if (columnName === "factory") {
      result =
        (!factoryRelatedToPOa["name"] ? "0" : factoryRelatedToPOa["name"]) >
        (!factoryRelatedToPOb["name"] ? "0" : factoryRelatedToPOb["name"])
          ? 1
          : (!factoryRelatedToPOa["name"] ? "0" : factoryRelatedToPOa["name"]) <
            (!factoryRelatedToPOb["name"] ? "0" : factoryRelatedToPOb["name"])
          ? -1
          : 0;
    } else {
      result =
        (!a[columnName] ? "0" : a[columnName]) >
        (!b[columnName] ? "0" : b[columnName])
          ? 1
          : (!a[columnName] ? "0" : a[columnName]) <
            (!b[columnName] ? "0" : b[columnName])
          ? -1
          : 0;
    }
    return result * order;
  };
}

export function documentListener(
  listenerType = "",
  callbackFunction = () => {}
) {
  document.addEventListener(listenerType, callbackFunction);
  return document.removeEventListener(listenerType, callbackFunction);
}

/**
 * Convert Date To Timestamp
 */
export function convertDateToTimeStamp(date, format) {
  let formatDate = format ? format : "YYYY/MM/DD";
  return moment(date, formatDate).unix() * 1000;
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
  let location = url.pathname;
  let path = location.split("/");
  return path[1];
}

export function inputTypeByField(field) {
  switch (field) {
    case "shippingTerms":
      return "select";
    case "shipDate":
    case "deliveryDate":
    case "date":
    case "orderReadyDate":
    case "payTermsStart":
      return "date";
    case "CBM":
      return "number";
    case "totalUniqueItems":
      return "number";
    default:
      return "text";
  }
}

export function getListFromId(stage) {
  switch (stage) {
    case taskStages.PROPOSAL:
      return { list: "proposalList", setter: "setProposalList" };
    case taskStages.PRE_PRODUCTION:
      return { list: "preProductionList", setter: "setPreProductionList" };
    case taskStages.PRODUCTION:
      return { list: "productionList", setter: "setProductionList" };
    case taskStages.BOOKING_TRANSIT:
      return { list: "bookingList", setter: "setBookingList" };
    case taskStages.PAYMENT_BILLING:
      return { list: "paymentList", setter: "setPaymentList" };
    default:
      return null;
  }
}
export const reorder = (list, startIndex, endIndex) => {
  console.log(list);
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// Function to convert documents to tree documents according his respective childs
// TODO TEST
export function convertToTree(documents = []) {
  if (!documents || !Array.isArray(documents) || documents.length === 0) {
    return [];
  }

  const documentMap = new Map();
  const roots = [];

  documents.forEach((document) => {
    const { id } = document;
    document.child = [];
    documentMap.set(id, document);
  });

  documents.forEach((document) => {
    const { parentId } = document;
    const parent = documentMap.get(parentId);
    const path = document.path || "";
    const arrayPath = path.split("/");
    const level = arrayPath.length;
    document.arrayPath = arrayPath;
    document.level = level;
    if (parent) {
      parent.child.push(document);
    } else {
      roots.push(document);
    }
  });
  return roots;
}

export function convertToTreeRefactored({
  documents = [],
  parentDocumentId = "",
}) {
  const rootDocuments = documents.filter((document) => {
    return parentDocumentId
      ? document.parentId === parentDocumentId
      : !document.parentId;
  });

  function getChildrenDocuments({ documents = [], document }) {
    const childrenDocuments = documents.filter((childDocument) => {
      return childDocument.parentId === document.id;
    });

    if (childrenDocuments.length > 0) {
      childrenDocuments.forEach((childDocument) => {
        if (childDocument.type === TYPE_OF_FILE.FOLDER) {
          childDocument.child = getChildrenDocuments({
            documents,
            document: childDocument,
          });
        } else {
          const versions = childDocument.versions || [];
          childDocument.child = versions.map((docVersion) => ({
            ...childDocument,
            ...docVersion,
            id: childDocument.id + docVersion.version,
            child: [],
            isVersion: true,
            name: replaceFileExtension(childDocument.name, docVersion.type),
          }));
        }
      });
    }
    return childrenDocuments;
  }

  const documentTree = rootDocuments.map((rootDocument) => {
    if (rootDocument.type === TYPE_OF_FILE.FOLDER) {
      return {
        ...rootDocument,
        child: getChildrenDocuments({ documents, document: rootDocument }),
      };
    } else {
      return {
        ...rootDocument,
        child: rootDocument.versions?.map((docVersion) => ({
          ...rootDocument,
          ...docVersion,
          id: rootDocument.id + docVersion.version,
          versionId: rootDocument.id,
          child: [],
          versions: [],
          isVersion: true,
          name: replaceFileExtension(rootDocument.name, docVersion.type),
        })),
      };
    }
  });

  return documentTree;
}

export function replaceFileExtension(name, extension) {
  const elements = name.split(".");
  if (elements.length === 1) {
    return `${name}.${extension}`;
  }
  elements.pop();
  return `${elements.join(".")}.${extension}`;
}

// function that return a type file
export function getTypeFile(name) {
  const elements = name.split(".");
  if (elements.length === 1) {
    return TYPE_OF_FILE.FOLDER;
  }
  const extension = `${elements[elements.length - 1] || ""}`;
  return extension.toLowerCase();
}

// function that return a new folderName if it already exists in document

export function verifyDuplicateTemplateNames(folderName, document, type) {
  let name = folderName;
  let auxName = name;
  let countRepeatName = 0;
  let found = true;
  const findFolderName = (folder) =>
    folder.name === name && folder.type === type;

  do {
    found = document.find(findFolderName);
    if (found) {
      countRepeatName++;
      name = auxName;
      name = `${folderName}(${countRepeatName})`;
    }
  } while (found);
  return name;
}

export function pathToString(arrayPath = []) {
  return arrayPath.join("/");
}

export function listenToData({ query, path }) {
  return (dispatch) =>
    getNestedCollectionFromFirestoreTable({
      path: pathToString([...path]),
      query,
    })(dispatch);
}

//REFACTORING LISTE TO DATA
export function listenToDataRefactored({
  query,
  path,
  limit,
  orderBy,
  stateReference,
}) {
  return (dispatch) =>
    getNestedCollectionFromFirestoreTableRefactored({
      path: pathToString([...path]),
      queryParams: query,
      limitParams: limit,
      orderByParams: orderBy,
      stateReference,
    })(dispatch);
}
//

//REFACTORING LISTE TO DATA
export function listenToDataMiscellaneous({
  path,
  stateReference,
  referenceId,
}) {
  return (dispatch) =>
    getDocumentMiscellaneous({
      path: pathToString([...path]),
      stateReference,
      referenceId,
    })(dispatch);
}
//

export function listenSKUData({ poId, skuVersion, companyId }) {
  return (dispatch) =>
    getListenerSKU({ poId, skuVersion, companyId })(dispatch);
}

export function listenToDataSO({ salesOrderId, companyId }) {
  return (dispatch) => getListenerSO({ salesOrderId, companyId })(dispatch);
}
export function listenToDataMentions({ userId }) {
  return (dispatch) => getListenUserMentions({ userId })(dispatch);
}
export function listenRolePermissions({ roleId, companyId }) {
  return (dispatch) =>
    getListenRolesPermissions({ roleId, companyId })(dispatch);
}

export function listenToDataPOs({ salesOrderId, companyId }) {
  return (dispatch) => getListenerPOs({ salesOrderId, companyId })(dispatch);
}
export function listenToDataShipments({ purchaseOrderId, companyId }) {
  return (dispatch) =>
    getListenerShipments({ purchaseOrderId, companyId })(dispatch);
}

export function clearPurchaseOrders({ companyId }) {
  return (dispatch) => cleanPOsRedux({ companyId })(dispatch);
}

export function clearSKUItems({ companyId }) {
  return (dispatch) => clearSKURedux({ companyId })(dispatch);
}

export function clearShipmentOrders({ companyId }) {
  return (dispatch) => cleanShipmentRedux({ companyId })(dispatch);
}

export function listenToDocument({ table, path, keyName, limit, orderBy }) {
  return (dispatch) =>
    getFirestoreDocument({
      table,
      path: pathToString([...path]),
      keyName,
      limit,
      orderBy,
    })(dispatch);
}

export function listenSODocument({ table, path, keyName }) {
  return (dispatch) =>
    getFirestoreDocument({
      table,
      path: pathToString([...path]),
      keyName,
    })(dispatch);
}

export function listenToNotifications(...path) {
  return (dispatch) =>
    getNotificationsFromFirestoreTable(pathToString([...path]))(dispatch);
}

export function ObjectSet(array) {
  var flags = [],
    output = [],
    l = array.length,
    i;
  for (i = 0; i < l; i++) {
    if (flags[array[i].id]) continue;
    flags[array[i].id] = true;
    output.push(array[i]);
  }
  return output;
}

export function getIdByURL(location) {
  const locationArray = location.pathname.split("/");
  const urlId = locationArray[locationArray.length - 1];
  return urlId;
}

export function getFolderSize(total, document = []) {
  const sum = 0;
  document.forEach((element) => {
    const size = parseInt(element.size || 0);
    if (element.child && element.child.length > 0) {
      total += getFolderSize(sum, element.child);
    } else {
      total +=
        parseInt(size) === 0 && element.type !== TYPE_OF_FILE.FOLDER ? 1 : size;
    }
  });
  return total;
}

export function getSizeTransformedAccordingKBSize(size, type) {
  if (+size >= 0 && +size <= 1023) {
    if (type !== TYPE_OF_FILE.FOLDER) {
      return Math.floor(size) === 0 ? 1 + " KB" : Math.floor(size) + " KB";
    }
    return Math.floor(size) + " KB";
  } else if (+size >= 1024 && +size <= 1048576) {
    return Math.floor(+size / 1024) + " MB";
  } else if (+size >= 1048577) {
    return Math.floor(+size / 1048577) + " GB";
  }
}

export function cleanObject(object = {}) {
  let cleanObject = { ...object };
  let stringArray = [];
  Object.keys(cleanObject).forEach((property) => {
    if (!searchableProperties.includes(property)) {
      delete cleanObject[property];
    }
  });
  Object.keys(cleanObject).forEach((property) => {
    if (typeof cleanObject[property] === "string") {
      stringArray.push(...cleanObject[property].split(/\s\s+/g));
    }
  });
  const containerValues = [];
  if (cleanObject.container) {
    containerValues.push(
      ...cleanObject.container.split(",").map((str) => str.trim())
    );
  }
  // console.log(
  //   [
  //     ...Object.values(cleanObject).map(value =>
  //       value.toString().toLowerCase()
  //     ),
  //     ...containerValues,
  //     ...stringArray
  //   ].toString()
  // );
  return [
    ...Object.values(cleanObject).map((value) =>
      value.toString().toLowerCase()
    ),
    ...containerValues,
    ...stringArray,
  ].toString();
}

export async function getDataFromFirestore1({ arrayPath, dispatch }) {
  try {
    const path = pathToString([...arrayPath]);
    if (arrayPath.length % 2 === 0) {
      const snapshot = await getDoc(doc(firestore, path));
      return { ...snapshot.data(), ref: snapshot.ref };
    } else {
      let ref = collection(firestore, path);
      let snapshot = await getDocs(ref);
      return snapshot.docs.map((element) => ({
        ...element.data(),
        ref: element.ref,
      }));
    }
  } catch (error) {
    console.log("ERROR getDataFromFirestore", error);
    dispatch({
      type: CLIENT_IS_OFFLINE,
      payload: {
        value: true,
        text: "Please wait a moment and try again",
      },
    });
  }
}

export const replacePathReference = ({ oldPath, newId }) => {
  const originalNote = oldPath.split("/");
  originalNote[originalNote.length - 1] = newId;
  return originalNote.join("/");
};

export async function getDataFromUploadFile(
  newFile,
  pathUrl,
  currentUser,
  currentDocument,
  uploadFileVersion = false
) {
  const {
    documentVersionId,
    name,
    documentId,
    version,
    lastModified,
    lastModifiedDate,
  } = newFile;
  let { type, size } = newFile;
  type = getTypeFile(name);
  const mainFile = true;
  const user = currentUser.id;
  let path = currentDocument ? `${currentDocument.path}/${name}` : name;
  let parentId =
    currentDocument && currentDocument.type === TYPE_OF_FILE.FOLDER
      ? currentDocument.id
      : "";

  //
  if (currentDocument && currentDocument.type === TYPE_OF_FILE.FOLDER) {
    path = `${currentDocument.path}/${name}`;
    parentId = currentDocument.id;
  } else {
    if (currentDocument && currentDocument.parentId) {
      parentId = currentDocument.parentId;
      path = currentDocument.path;
    } else {
      path = name;
    }
  }
  if (uploadFileVersion) {
    parentId = currentDocument.parentId;
    if (currentDocument.parentId) {
      path = currentDocument.path;
    } else {
      path = name;
    }
  }
  const created = currentDocument ? currentDocument.created : now();
  const placeholder = false;
  const url = await getDownloadURL(ref(storage, `${pathUrl}/${name}`));
  let id = documentVersionId;
  const fileVersion = new File({
    id,
    name,
    path,
    url,
    type,
    version,
    parentId,
    created,
    mainFile,
    placeholder,
    lastModified,
    lastModifiedDate,
    size,
    user,
    createdBy: user,
    storageRef: pathUrl + "/" + name,
    scope: newFile.scope,
    shipmentId: newFile.shipmentId || "",
  });

  id = documentId;
  const file = new File({
    id,
    name,
    path,
    url,
    type,
    version,
    lastModified,
    lastModifiedDate,
    size,
    user,
    createdBy: user,
    storageRef: pathUrl + "/" + name,
    scope: newFile.scope,
    shipmentId: newFile.shipmentId,
  });
  return { file, fileVersion };
}

export function getDayOffset(notification) {
  return Math.abs(notification.dayOffset);
}

export function getUserDisplayName(users, userId) {
  const user = users.find((user) => user.id === userId);
  if (user) {
    return user.displayName;
  }
  return "company_user";
}

const taskNotificationType = {
  ADHOC_TASK: "ADHOC_TASK",
  COMPLETED: "COMPLETED",
};

const notificationLateScopes = {
  PURCHASE_ORDER: NOTIFICATION_SCOPE.PO_TASK_LATE,
  SALES_ORDER: NOTIFICATION_SCOPE.SO_TASK_LATE,
  SHIPMENT: NOTIFICATION_SCOPE.SHIPMENT_TASK_LATE,
};
const notificationEarlyScopes = {
  PURCHASE_ORDER: NOTIFICATION_SCOPE.PO_TASK_EARLY,
  SALES_ORDER: NOTIFICATION_SCOPE.SO_TASK_EARLY,
  SHIPMENT: NOTIFICATION_SCOPE.SHIPMENT_TASK_EARLY,
};

const notificationCreateScopes = {
  PURCHASE_ORDER: NOTIFICATION_SCOPE.PO_TASK_CREATED,
  SALES_ORDER: NOTIFICATION_SCOPE.SO_TASK_CREATED,
  SHIPMENT: NOTIFICATION_SCOPE.SHIPMENT_TASK_CREATED,
};

export function isAbleToNotifyUser({ user = {}, task = {}, late = false }) {
  function ObjectInObject({ object1 = {}, object2 = {} }) {
    let found = false;
    Object.keys(object1).forEach((key) => {
      if (object2[key]) {
        found = true;
      }
    });
    return found;
  }

  if (
    !late &&
    ((task.userNotificationEarly && task.userNotificationEarly[user.id]) ||
      ObjectInObject({
        object1: task.groupNotificationEarly,
        object2: user.permissionGroups,
      }))
  ) {
    return true;
  } else if (
    late &&
    ((task.userNotificationLate && task.userNotificationLate[user.id]) ||
      ObjectInObject({
        object1: task.groupNotificationLate,
        object2: user.permissionGroups,
      }))
  ) {
    return true;
  }
  return false;
}

export function useTaskNotificationCreator() {
  const companyUsers = useCompanyUsers({});
  const customers = useCustomers();
  const factories = useFactories();
  const user = useUser();

  const cb = useCallback(
    ({ task, randomId, type = taskNotificationType.ADHOC_TASK }) => {
      const fieldsToUpdate = {
        complete: !task.complete,
        completedBy: user.id,
      };
      const finishDate = task.finishDate;
      if (!task.customerName) {
        fieldsToUpdate.customerName = getCustomerName(
          customers,
          task.customerId
        );
      }
      if (!task.factoryName && task.factoryId) {
        fieldsToUpdate.factoryName = getFactoryName(factories, task.factoryId);
      }
      if (!task.complete) {
        if (type === taskNotificationType.ADHOC_TASK) {
          const notification = new Notification({
            type: "adHocTask",
            scope: notificationCreateScopes[task.type],
            id: randomId,
            read: false,
            newNotification: true,
            creationDate: moment().valueOf(),
            salesOrderId: task.salesOrderId,
            shipmentId: task.shipmentId,
            customerId: task.customerId,
            customerName: fieldsToUpdate.customerName,
            SONumber: task.SONumber,
            PONumber: task.PONumber,
            shipmentNumber: task.shipmentNumber,
            purchaseOrderId: task.purchaseOrderId,
            factoryId: task.factoryId,
            factoryName: fieldsToUpdate.factoryName,
            complete: fieldsToUpdate.complete,
            completedBy: fieldsToUpdate.completedBy,
            companyId: task.companyId,
            createdBy: task.createdBy,
            finishDate: finishDate.valueOf(),
            description: task.description,
            mainDocumentId: task.id,
          });
          return setDoc(
            doc(
              firestore,
              `${dbTables.USERS}/${task.assignedTo}/${dbTables.NOTIFICATIONS}/${notification.id}`
            ),
            { ...notification }
          );
        }
        const dayOffset = moment()
          .startOf("day")
          .diff(moment(finishDate.valueOf()).startOf("day"), "days");
        // trackEvent("PO Dashboard - Tasks - Done", {
        //   days: dayOffset,
        //   task_status: task.status
        // });
        if (
          finishDate.valueOf() - moment().valueOf() < 0 ||
          task.status === taskStatus.LATE
        ) {
          companyUsers.forEach(async (companyUser) => {
            fieldsToUpdate.dayOffset = dayOffset;
            const isAbleToNofity = isAbleToNotifyUser({
              user: companyUser,
              task,
              late: true,
            });
            if (isAbleToNofity) {
              const notification = new Notification({
                id: randomId,
                scope: notificationLateScopes[task.type],
                type: "completeLate",
                read: false,
                newNotification: true,
                creationDate: moment().valueOf(),
                dayOffset: fieldsToUpdate.dayOffset,
                companyId: task.companyId,
                salesOrderId: task.salesOrderId,
                purchaseOrderId: task.purchaseOrderId,
                shipmentId: task.shipmentId,
                SONumber: task.SONumber,
                PONumber: task.PONumber,
                shipmentNumber: task.shipmentNumber,
                customerName: task.customerName || fieldsToUpdate.customerName,
                factoryName: task.factoryName || fieldsToUpdate.factoryName,
                customerId: task.customerId,
                factoryId: task.factoryId,
                completedBy: task.completedBy,
                assignedTo: task.assignedTo,
                confirmedTask: task.confirmedTask,
                description: task.description,
                finishDate: finishDate.valueOf(),
                mainDocumentId: task.id,
                createdBy: task.createdBy,
                shipment: { finalDestination: task.finalDestination },
              });
              sendCompletedTaskEmail({
                notification,
                currentUser: companyUser,
                users: companyUsers,
                type: TYPE_NOTIFICATION.COMPLETE_LATE,
              });
              setDoc(
                doc(
                  firestore,
                  `${dbTables.USERS}/${companyUser.id}/${dbTables.NOTIFICATIONS}/${randomId}`
                ),
                {
                  ...notification,
                }
              );
            }
          });
        }
        if (moment().isBefore(moment(finishDate.valueOf()), "day")) {
          companyUsers.forEach(async (companyUser) => {
            const isAbleToNofity = isAbleToNotifyUser({
              user: companyUser,
              task,
            });
            if (isAbleToNofity) {
              fieldsToUpdate.dayOffset = dayOffset;
              const notification = new Notification({
                id: randomId,
                scope: notificationEarlyScopes[task.type],
                type: "completeEarly",
                read: false,
                newNotification: true,
                creationDate: moment().valueOf(),
                dayOffset: fieldsToUpdate.dayOffset,
                companyId: task.companyId,
                salesOrderId: task.salesOrderId,
                purchaseOrderId: task.purchaseOrderId,
                shipmentId: task.shipmentId,
                SONumber: task.SONumber,
                PONumber: task.PONumber,
                shipmentNumber: task.shipmentNumber,
                customerName: task.customerName
                  ? task.customerName
                  : fieldsToUpdate.customerName,
                factoryName: task.factoryName
                  ? task.factoryName
                  : fieldsToUpdate.factoryName,
                customerId: task.customerId,
                factoryId: task.factoryId,
                completedBy: task.completedBy,
                assignedTo: task.assignedTo,
                confirmedTask: task.confirmedTask,
                description: task.description,
                finishDate: finishDate.valueOf(),
                mainDocumentId: task.id,
                createdBy: task.createdBy,
                shipment: { finalDestination: task.finalDestination },
              });
              sendCompletedTaskEmail({
                notification,
                currentUser: companyUser,
                users: companyUsers,
                type: TYPE_NOTIFICATION.COMPLETE_EARLY,
              });
              setDoc(
                doc(
                  firestore,
                  `${dbTables.USERS}/${companyUser.id}/${dbTables.NOTIFICATIONS}/${randomId}`
                ),
                { ...notification }
              );
            }
          });
        }
      }
    }
  );
  return cb;
}

export function debounce(func, wait, immediate) {
  let timeout;

  let debouncedFunction = function () {
    let context = this;
    let args = arguments;

    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };

  debouncedFunction.cancel = function () {
    clearTimeout(timeout);
  };

  return debouncedFunction;
}

export function getDataFromFile(file, currentDocument) {
  const { lastModified, lastModifiedDate, size } = file;
  let documentId = getRandomId();
  let documentVersionId = getRandomId();
  const type = getTypeFile(file.name);
  let version = 1;
  let name = file.name;
  if (currentDocument.type === TYPE_OF_FILE.FOLDER) {
    currentDocument.child.forEach((element) => {
      if (element.name === name) {
        documentVersionId = element.id;
        version = element.version + 1;
        name = file.name;
      }
    });
  } else {
    version = currentDocument.version + 1;
    let nameWithoutExtension = "";
    const elements = currentDocument.name.split(".");
    if (elements.length === 1) {
      nameWithoutExtension = currentDocument.name + ".";
    } else {
      elements.forEach((value, index) => {
        if (index !== elements.length - 1) {
          nameWithoutExtension = nameWithoutExtension + value + ".";
        }
      });
    }
    name = nameWithoutExtension + type;
    documentVersionId = currentDocument.id;
  }
  return {
    documentId,
    documentVersionId,
    version,
    type,
    name,
    size: size / 1000,
    lastModified,
    lastModifiedDate,
    scope: currentDocument.scope,
  };
}

export function getFileName(name = "") {
  const splitName = name.split(".");
  if (splitName.length === 1) {
    return name;
  }
  splitName.pop();
  return splitName.join(".");
}

export async function getSpreadSheetByName(SHEET_ID, ACCESS_TOKEN, SHEET_NAME) {
  const request = await window.fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${SHEET_ID}/values/${SHEET_NAME}!A1:Z300`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESS_TOKEN}`,
      },
    }
  );
  const sheet = await request.json();
  console.log("SHEET", sheet);
  return sheet;
}

export function getCustomerName(
  customers = [],
  customerId = "",
  shortResponse
) {
  const customer = customers.find((customer) => customer.id === customerId);
  if (customer) {
    return customer.name;
  }
  return shortResponse ? "N/A" : "No customer found";
}

export function getCustomerField({
  customers = [],
  field = "",
  value = "",
  shortResponse,
}) {
  const customer = customers.find((customer) => customer[field] === value);
  if (customer) {
    return customer.name;
  }
  return shortResponse ? "N/A" : "No customer found";
}

export function getShipmentDestination(
  shipments = [],
  shipmentId = "",
  shortResponse
) {
  const currentShipment = shipments.find(
    (shipment) => shipment.id === shipmentId
  );
  if (currentShipment) {
    return currentShipment.finalDestination;
  }
  return shortResponse ? "" : "No Final Destination found";
}

export function getFactoryName(factories, factoryId = "", withNumber = false) {
  const factory = factories.find((factory) => factory.id === factoryId);
  if (factory) {
    let name = factory.name;
    if (withNumber) {
      name = `${factory.number || ""}    ${factory.name}`;
    }
    return name;
  }
  return "No factory found";
}

export function getFactoryByField({ factories, factoryId, field }) {
  const factory = factories.find((factory) => factory.id === factoryId);
  if (factory) {
    return factory[field];
  }
  return `Vendor ${field} not found`;
}

export function getUserByField({ users = [], userId = "", field = "" }) {
  const user = users.find((user) => user.id === userId);
  if (user) {
    return user[field];
  }
  return `User ${field} not found`;
}

export function getTagsLabel(tags = [], itemTags = []) {
  let tagsString = [];
  let tagsArray = [];
  const typeOfLabelArr = [];
  Object.values(AUTOMATIC_SYSTEM_LABEL).forEach((value) => {
    typeOfLabelArr.push(value);
  });
  itemTags.forEach((tag) => {
    const tagDB = tags.find((el) => el.id === tag);
    if (tagDB) {
      tagsString.push(tagDB.name);
      tagsArray.push(tagDB);
    }
  });
  const list = (
    <ul
      style={{
        color: "white",
        marginLeft: 15,
        marginBottom: 0,
        listStyle: "disc",
      }}
    >
      {tagsString.sort().map((name) => {
        const fontStyle = typeOfLabelArr.includes(name) ? "italic" : "normal";
        return (
          <li
            style={{
              fontStyle: fontStyle,
            }}
          >
            {name}
          </li>
        );
      })}
    </ul>
  );
  return { tagsString: list, tagsArray };
}

export function getCompanyUserDisplayName(companyUsers, userId) {
  if (!userId) {
    return "";
  }
  const user = companyUsers.find((user) => user.id === userId);
  if (user) {
    return "@" + user.displayName;
  } else {
    return "@companyUser";
  }
}

export function verifyCircularMultiDependency(
  task,
  taskTemplateCpy = [],
  counter
) {
  if (!task) {
    return false;
  }
  const dependencyTask = taskTemplateCpy.find(
    (el) => el.id === task.dependency
  );
  if (dependencyTask) {
    if (counter > taskTemplateCpy.length) {
      return true;
    }
    const newDependencyTask = taskTemplateCpy.find(
      (el) => el.id === dependencyTask.dependency
    );
    let counterCpy = counter + 1;
    return verifyCircularMultiDependency(
      newDependencyTask,
      taskTemplateCpy,
      counterCpy
    );
  }
  return false;
}

export function verifyTaskTemplateErrors({
  SOTaskTemplate = [],
  POTaskTemplate = [],
  isPOVerifier = false,
}) {
  let taskTemplateCpy = isPOVerifier
    ? [...POTaskTemplate]
    : [...SOTaskTemplate];

  let circularDependency = false;
  const circularDependencyTasks = [];
  let dependsOnItself = false;
  const dependsOnItselfTasks = [];
  let noDependencyTaskFound = false;
  const noDependencyTaskFoundArr = [];

  const leafTasks = [];
  taskTemplateCpy.forEach((task) => {
    if (!taskTemplateCpy.some((item) => item.dependency === task.id)) {
      leafTasks.push(task);
    }
  });
  if (leafTasks.length === 0) {
    return {
      status: 400,
      errorMessage: typeTaskTemplateError.THERE_IS_NO_LEAF_TASK,
      type: typeTaskTemplateError.THERE_IS_NO_LEAF_TASK,
    };
  }

  taskTemplateCpy.forEach((task) => {
    const counter = 0;
    const isMultiCircularDependency = verifyCircularMultiDependency(
      task,
      taskTemplateCpy,
      counter
    );
    if (task.dependency && task.dependency === task.id) {
      dependsOnItself = true;
      dependsOnItselfTasks.push({ id: task.id, description: task.description });
    } else if (task.dependency) {
      let dependencyTask = null;
      if (task.dependsOnSOT) {
        dependencyTask = SOTaskTemplate.find(
          (ttask) => ttask.id === task.dependency
        );
      } else {
        dependencyTask = taskTemplateCpy.find(
          (ttask) => ttask.id === task.dependency
        );
      }
      if (!dependencyTask) {
        noDependencyTaskFound = true;
        noDependencyTaskFoundArr.push({
          id: task.id,
          description: task.description,
        });
      } else if (
        (task.dependency === dependencyTask.id &&
          task.id === dependencyTask.dependency) ||
        isMultiCircularDependency
      ) {
        circularDependency = true;
        circularDependencyTasks.push({
          id: task.id,
          description: task.description,
        });
      }
    }
  });

  if (circularDependency) {
    return {
      status: 400,
      type: typeTaskTemplateError.CIRCULAR_DEPENDENCY,
      errorTasks: circularDependencyTasks,
    };
  } else if (dependsOnItself) {
    return {
      status: 400,
      type: typeTaskTemplateError.TASK_DEPENDS_ON_ITSELF,
      errorTasks: dependsOnItselfTasks,
    };
  } else if (noDependencyTaskFound) {
    return {
      status: 400,
      type: typeTaskTemplateError.THERE_IS_NO_DEPENDENCY_TASK,
      errorTasks: noDependencyTaskFoundArr,
    };
  } else
    return {
      status: 200,
      type: typeTaskTemplateError.SUCCESS,
      errorTasks: [],
    };
}

export function getCompanyUserField({ companyUsers, userId, field }) {
  if (!userId || !field || !companyUsers) {
    return "";
  }
  const user = companyUsers.find((user) => user.id === userId);
  if (user) {
    return user[field];
  } else {
    return "";
  }
}

export function verifyVendorIcon({ factoryFilter = [], item, currentVendor }) {
  if (!item || !currentVendor || factoryFilter.length === 0) {
    return false;
  }
  return factoryFilter.includes(item.factoryId);
}

export function isTradeDashEmployee(user = {}) {
  return user.role === userTypes.TRADEDASH_EMPLOYEE;
}

export function isSuperAdmin(user) {
  return user.role === userTypes.SUPER_ADMIN;
}

export function getWeekTabScreenFormatted({ date }) {
  return moment(date).format("MM/DD");
}

export function useLocationPath() {
  const location = useLocation();
  return location.pathname;
}

export function getPathDocumentLocalStorage(path) {
  if (path.includes("/factories")) {
    return "Vendor";
  }
  if (path.includes("/customers")) {
    return "Customers";
  }
  return "OD";
}

export function getIdFromStructureFolders(documents) {
  let ids = [];
  documents.forEach((doc) => {
    if (doc.type === TYPE_OF_FILE.FOLDER) {
      ids.push(doc.id);
    }
  });
  return ids;
}

export function searchDocumentInTree(document, query = "") {
  let isDocumentFound = false;
  const childs = document.child || [];
  const parseQuery = query.toLowerCase();
  const parseNameDocument = (document.name || "").toLowerCase();
  if (parseNameDocument.includes(parseQuery) && childs.length === 0) {
    isDocumentFound = true;
  } else {
    for (let i = 0; i < childs.length; i++) {
      const childDocument = childs[i];
      const parseNameChildDocument = (childDocument.name || "").toLowerCase();
      if (parseNameChildDocument.includes(parseQuery)) {
        isDocumentFound = true;
      }
      if (isDocumentFound) {
        i = childs.length;
      } else {
        isDocumentFound = searchDocumentInTree(childDocument, query);
      }
    }
  }

  return isDocumentFound;
}

export const formatWithComma = (number = 0) => {
  const parseNumber = parseInt(number);
  return parseNumber.toLocaleString("en-US");
};

export const formatCash = (value) => {
  if (!value) return "$0";
  const newFormat = new Intl.NumberFormat("en", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: "currency",
    currency: "USD",
  }).format(value);
  return newFormat;
};

export function getProgressValue(value = {}) {
  let { completedTasks = 0, totalTasks = 0 } = value;
  if (!completedTasks || !totalTasks) {
    return 0;
  }
  const progress = (completedTasks * 100) / totalTasks;
  return progress.toFixed(0);
}

export function getDateByTimezone({
  date,
  format = "M/D/YY",
  timestamp = false,
  serverTime = false,
}) {
  const finishDate = getCorrectTimezone({
    date,
    isShowingDate: true,
    isServerTime: serverTime,
  });
  if (timestamp) {
    return finishDate.valueOf();
  } else {
    return finishDate.format(format);
  }
}

export const backdropInfo = async ({
  variant,
  reset,
  elementId,
  documentScope,
  documentType,
}) => {
  let zIndexValue = reset ? 2 : 15;
  switch (variant) {
    case BACKDROP_TYPE.SALES_ORDER_INFO:
      const classInfoSO = reset
        ? "sales-order-info-container"
        : "so-info-backdrop";
      const classTableSO = reset ? "" : "so-table-backdrop";
      const elementSO = document.getElementById(BACKDROP_TYPE.SALES_ORDER_INFO);
      elementSO.className = classInfoSO;
      const tableSO = document.getElementById("item-table-SO");
      if (tableSO) {
        tableSO.className = classTableSO;
      }
      const headerElement = document.getElementById("salesOrderHeader");
      if (headerElement && headerElement.style) {
        headerElement.style.zIndex = zIndexValue + 1;
      }
      break;
    case BACKDROP_TYPE.PURCHASE_ORDER_INFO:
      const bodyPO = document.getElementById("purchase-order-backdrop");
      if (bodyPO) bodyPO.className = reset ? "" : "purchase-order-backdrop";

      break;
    case BACKDROP_TYPE.FOLDER:
      const colorBackground = {
        PURCHASE_ORDER: colors.purchaseOrderBackgroundColor,
        SALES_ORDER: colors.salesOrderBackgroundColor,
      };
      const elementFolder = document.getElementById(elementId);
      if (elementFolder && elementFolder.style) {
        elementFolder.style.zIndex = zIndexValue;
        elementFolder.style.position = reset ? "initial" : "inherit";
        if (documentType === "folder" && colorBackground[documentScope]) {
          elementFolder.style.background = reset
            ? "unset"
            : colorBackground[documentScope];
        } else if (
          documentType === "folder" &&
          !colorBackground[documentScope]
        ) {
          elementFolder.style.background = colors.shipmentBadge;
        }
      }
      break;

    case BACKDROP_TYPE.ITEM_TABLE_PO:
      const itemRow = document.getElementById(elementId);
      if (itemRow && itemRow.style) {
        itemRow.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        itemRow.style.zIndex = zIndexValue;
        itemRow.style.position = reset ? "initial" : "relative";
      }
      if (reset) {
        let index = 0;
        let search = true;
        while (search) {
          const subRowId = `sub${elementId}${index}`;
          const subItemRow = document.getElementById(subRowId);
          if (subItemRow && subItemRow.style) {
            subItemRow.style.zIndex = zIndexValue;
            subItemRow.style.position = reset ? "initial" : "relative";
          } else {
            search = false;
          }
          index++;
        }
      }
      break;
    default:
      break;
  }
};

export const createSummaryData = async ({
  sumamryList,
  newItem,
  dbCompanieRef,
  target,
}) => {
  const limitList = 100;
  const isCustomer = target === "customers";
  const keySummaryId = isCustomer ? "customersSummaryId" : "factoriesSummaryId";
  const pathSummary = isCustomer
    ? dbTables.CUSTOMERS_SUMMARY
    : dbTables.FACTORIES_SUMMARY;
  const newItemSummary = isCustomer
    ? { ...new CustomerItem({ ...newItem }) }
    : { ...new FactoryItem({ ...newItem }) };
  const pathNewItem = isCustomer ? dbTables.CUSTOMERS : dbTables.FACTORIES;
  const filterSummary = sumamryList.filter(
    (summary) => summary.size <= limitList
  );
  if (filterSummary.length === 0) {
    const newSummary = isCustomer
      ? { ...new CustomerSummary({}) }
      : { ...new FactorySummary({}) };
    await setDoc(
      doc(firestore, `${dbCompanieRef}/${pathNewItem}/${newItem.id}`),
      {
        ...newItem,
        [keySummaryId]: newSummary.id,
      }
    );
    await setDoc(
      doc(firestore, `${dbCompanieRef}/${pathSummary}/${newSummary.id}`),
      {
        ...newSummary,
        [target]: [newItemSummary],
      }
    );
  } else {
    const firstSummary = filterSummary[0];
    const list = firstSummary[target];
    list.push(newItemSummary);
    await setDoc(
      doc(firestore, `${dbCompanieRef}/${pathNewItem}/${newItem.id}`),
      {
        ...newItem,
        [keySummaryId]: firstSummary.id,
      }
    );
    await updateDoc(
      doc(firestore, `${dbCompanieRef}/${pathSummary}/${firstSummary.id}`),
      {
        [target]: list,
        size: increment(1),
      }
    );
  }
};

export { taskNotificationType };

export function isSplitShipmentFeature({ featureFlags, user }) {
  if (featureFlags.length === 0 || !user) {
    return null;
  }
  const splitShipmentFeatureV1 = featureFlags.find(
    (feature) => feature.name === featureFlagNames.SPLIT_SHIPMENTS_V1
  );
  if (
    splitShipmentFeatureV1 &&
    splitShipmentFeatureV1.users &&
    splitShipmentFeatureV1.users[user.id]
  ) {
    return true;
  }
  return false;
}

export function hasFeatureFlagPermission({
  featureFlags = [],
  user = {},
  featureFlagName = "",
}) {
  if (featureFlags.length === 0) {
    return false;
  }
  const splitShipmentFeatureV1 =
    featureFlags.find((feature) => feature.name === featureFlagName) || {};
  if (splitShipmentFeatureV1.users && splitShipmentFeatureV1.users[user.id]) {
    return true;
  }
  return false;
}

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const validateEmail = (email) => {
  return regexEmailValidator.test(String(email).toLowerCase());
};

const permissionRolesNote = [
  userTypes.SUPER_ADMIN,
  userTypes.TRADEDASH_EMPLOYEE,
];

export function getFilterActivitiesByDate({
  activitiesList = [],
  currentDate,
  currentUser = {},
  permissionToSeeLockNote = false,
}) {
  const filterList = activitiesList.filter((activity) => {
    let isActivityLock = false;
    let canReadNote =
      activity.user === currentUser.id ||
      permissionRolesNote.includes(currentUser.role) ||
      permissionToSeeLockNote;
    if (
      activity.type === activityType.NOTE &&
      activity.lockNote &&
      !canReadNote
    ) {
      const userMentions = activity.userMentions || {};
      if (userMentions[currentUser.id] === undefined) {
        return false;
      }
    }
    return (
      activity.creationDate >= moment(currentDate).valueOf() && !isActivityLock
    );
  });
  return filterList;
}

export function activitiesFilteredByPermission({
  activityList = [],
  permissionEnabled = true,
  orderFieldsToVerify = [],
}) {
  const filterList = activityList.map((activity) => {
    if (permissionEnabled) {
      return activity;
    } else if (
      activity.hiddenValueDetail &&
      orderFieldsToVerify.some((field) =>
        activity.involvedFields.includes(field)
      )
    ) {
      return { ...activity, detail: activity.hiddenValueDetail };
    } else {
      return activity;
    }
  });
  return filterList;
}

export const sortObjectsByStage = ({ stageTasks = [], numberTask = 0 }) => {
  let poSize = numberTask;
  const sortedTask = stageTasks
    .sort(sortObjectsBy("listIndex", false))
    .map((task) => {
      if (task.type !== typeOfTask.SALES_ORDER) {
        poSize += 1;
        return { ...task, number: poSize };
      }
      return { ...task };
    });
  return { sortedTask, poSize };
};

export const addTaskListStage = ({
  listObject = { empty: [] },
  combinedLists = [],
  salesOrderTasks = [],
  stage = "empty",
  task = {},
}) => [
  ...listObject[stage],
  {
    ...task,
    stage: stage,
    listIndex:
      salesOrderTasks.filter((item) => item.stage === stage).length +
      listObject[stage].length,
    number:
      combinedLists.filter((item) => item.type !== typeOfTask.SALES_ORDER)
        .length + 1,
    duration: 1,
    offset: 0,
  },
];
export const updateTaskDataStage = ({
  listObject,
  field,
  salesOrderTasks,
  event,
  value,
}) => {
  let listObjectCpy = { ...listObject };
  Object.keys(listObjectCpy).forEach((phase) => {
    listObjectCpy[phase] = listObjectCpy[phase].map((task) => {
      if (
        (field === "notificationEarly" || field === "notificationLate") &&
        task.id === event.sourceItem.id
      ) {
        return {
          ...value,
        };
      } else if (field === "dependency" && task.id === event.sourceItem.id) {
        const dependsOnSOT = salesOrderTasks
          .map((task) => task.id)
          .includes(value);
        return {
          ...task,
          [field]: value,
          dependsOnSOT: dependsOnSOT,
        };
      } else if (task.id === event.sourceItem.id) {
        return {
          ...task,
          [field]: value,
        };
      } else if (field === "type" && task.dependency === event.sourceItem.id) {
        return {
          ...task,
          dependency: "",
          dependsOnSOT: false,
        };
      }
      return task;
    });
  });
  return listObjectCpy;
};

export const validateDataTaskTemplate = ({
  poTasks = [{}],
  users = [],
  salesOrderTasks = [],
  poList,
}) =>
  !poTasks.some((task) => {
    const soTask = salesOrderTasks.find(
      (soTask) => soTask.id === task.dependency
    );
    const currentPOList = poList || poTasks;
    const poTask = currentPOList.find(
      (soTask) => soTask.id === task.dependency
    );
    const existDependency = !!soTask || !!poTask;
    const userAssigned = users.find((user) => user.id === task.assignedTo);
    return (
      !task.dependencyType ||
      !existDependency ||
      !task.duration ||
      !userAssigned
    );
  });

export const validateTaskTemplate = ({ templateTasks = [{}], users }) => {
  const poTasks = templateTasks.filter(
    (item) => item.type !== typeOfTask.SALES_ORDER
  );
  const salesOrderTasks = templateTasks.filter(
    (item) => item.type === typeOfTask.SALES_ORDER
  );
  const isDependencyOnSOT = !poTasks
    .sort(sortObjectsBy("listIndex"))
    .every((task, index) =>
      task.dependsOnSOT
        ? templateTasks.map((item) => item.id).includes(task.dependency) ||
          index === 0
        : true
    );
  if (
    !validateDataTaskTemplate({ poTasks, users, salesOrderTasks }) ||
    isDependencyOnSOT
  ) {
    return false;
  }
  return true;
};

export const moveTaskTemplate = ({ result = {}, listObject = [] }) => {
  const { destination, source } = result;
  let newList = [];
  if (!destination) {
    return false;
  }

  if (
    destination.droppableId === taskStages.PROPOSAL &&
    destination.index === 0
  ) {
    return false;
  }
  if (destination.droppableId === source.droppableId) {
    newList = reorder(
      listObject[destination.droppableId],
      source.index,
      destination.index
    );
    newList = newList.map((item, index) => ({
      ...item,
      listIndex: index + 1,
    }));
    return {
      ...listObject,
      [destination.droppableId]: newList,
    };
  }
  let oldList = Array.from(listObject[source.droppableId]);
  newList = Array.from(listObject[destination.droppableId]);
  //Sales order tasks trying to change phase
  const oldItem = oldList[source.index]
    ? oldList[source.index]
    : oldList[source.index - 1];
  if (oldItem.type === typeOfTask.SALES_ORDER) {
    return false;
  }
  newList.splice(destination.index, 0, {
    ...oldList[source.index],
    stage: destination.droppableId,
  });
  oldList.splice(source.index, 1);
  oldList = oldList.map((item, index) => ({ ...item, listIndex: index + 1 }));
  newList = newList.map((item, index) => ({ ...item, listIndex: index + 1 }));
  return {
    ...listObject,
    [source.droppableId]: oldList,
    [destination.droppableId]: newList,
  };
};

export const getActivitiesNotes = async ({
  companyId,
  orderId,
  userId,
  orderTable,
  dispatch = () => {},
}) => {
  try {
    const activityMentionSnap = await getDocs(
      query(
        collection(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${orderTable}/${orderId}/${dbTables.ACTIVITIES}`
        ),
        where("userMentions." + userId, "==", false),
        limit(1)
      )
    );
    const activityMention = activityMentionSnap.docs.map((doc) => doc.data());
    if (activityMention <= 0) {
      return false;
    }
    return activityMention.sort(sortObjectsBy("creationDate", false))[0];
  } catch (error) {
    console.log("ERROR getActivitiesNotes", error);
    dispatch({
      type: CLIENT_IS_OFFLINE,
      payload: {
        value: true,
        text: "Please wait a moment and try again",
      },
    });
    return [];
  }
};

export async function handleUploadPermissionDependencies(
  document,
  permissionUsers,
  permissionGroups,
  permissionGroupsDB
) {
  for (let index = 0; index < document.child.length; index++) {
    if (document.child[index].child.length > 0) {
      handleUploadPermissionDependencies(
        document.child[index],
        permissionUsers,
        permissionGroups,
        permissionGroupsDB
      );
    }
    let permissionUsersCpy = document.child[index].permissionUsers;
    let permissionGroupsCpy = document.child[index].permissionGroups;
    let allUsersToMove = {};
    Object.keys(permissionGroupsCpy).forEach((group) => {
      if (!permissionGroups[group]) {
        const permissionGroupDB = permissionGroupsDB.find(
          (pGroup) => pGroup.id === group
        );
        Object.keys(permissionGroupDB.users).forEach(
          (user) => (allUsersToMove[user] = true)
        );
        delete permissionGroupsCpy[group];
      }
    });
    Object.keys(allUsersToMove).forEach(
      (user) => (permissionUsersCpy[user] = true)
    );
    Object.keys(permissionUsersCpy).forEach((user) => {
      if (!permissionUsers[user]) {
        delete permissionUsersCpy[user];
      }
    });
    updateDoc(document.child[index].ref, {
      permissionUsers: permissionUsersCpy,
      permissionGroups: permissionGroupsCpy,
    });
  }
}
export const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) {
    return true;
  } else if (isChromium && isOpera === false && isIEedge === false) {
    return true;
  } else {
    return false;
  }
};

export async function handleUpdateNotePermissionDependencies({
  documentData,
  permissionUsers = {},
  permissionGroups = {},
  rootDocument = "",
  permissionGroupsDB = [],
  users = [],
  currentUser = {},
}) {
  if (!documentData) {
    return [];
  }
  const child = documentData.child || [];
  const documentsUpdated = [];
  for (let index = 0; index < child.length; index++) {
    const subChildren = child[index].child || [];
    if (subChildren.length > 0) {
      const childUpdated = await handleUpdateNotePermissionDependencies({
        documentData: child[index],
        permissionUsers,
        permissionGroups,
        rootDocument,
        permissionGroupsDB,
        users,
        currentUser,
      });
      documentsUpdated.push(...childUpdated);
    }
    const originalChildUser = child[index].permissionUsers || {};
    let childPermissionUsers = child[index].permissionUsers || {};
    let childPermissionGroups = child[index].permissionGroups || {};
    const parentGroupsList = Object.keys(permissionGroups).map(
      (group) => group
    );
    Object.keys(childPermissionGroups).forEach((group) => {
      if (!permissionGroups[group]) {
        delete childPermissionGroups[group];
      }
    });

    Object.keys(childPermissionUsers).forEach((userId) => {
      let erase = true;
      const currentUserDB = users.find((user) => user.id === userId);
      if (originalChildUser[userId] && permissionUsers[userId]) {
        erase = false;
      } else if (currentUserDB) {
        const userPermissionGroups = currentUserDB.permissionGroups || {};
        const userGroupsList = Object.keys(userPermissionGroups).map(
          (group) => group
        );
        const found = userGroupsList.some((permission) =>
          parentGroupsList.includes(permission)
        );
        if (found) {
          erase = false;
        }
      }
      if (erase) {
        delete childPermissionUsers[userId];
      }
    });
    if (child[index].ref) {
      updateDoc(child[index].ref, {
        permissionUsers: { ...childPermissionUsers, [currentUser.id]: true },
        permissionGroups: childPermissionGroups,
        hasPermissionActivity: false,
      });
    }
    if (child[index].id) {
      documentsUpdated.push({
        id: child[index].id,
        permissionUsers: { ...childPermissionUsers, [currentUser.id]: true },
        permissionGroups: childPermissionGroups,
        name: child[index].name,
      });
    }
  }

  if (documentData.id === rootDocument) {
    documentsUpdated.push({
      ...documentData,
      permissionUsers,
      permissionGroups,
    });
    if (documentData.ref) {
      updateDoc(documentData.ref, {
        permissionUsers,
        permissionGroups,
        hasPermissionActivity: true,
        user: currentUser.id,
      });
    }
  }
  return documentsUpdated;
}

export async function getPurchaseOrders({
  purchaseOrderIds = [],
  companyId,
  user,
}) {
  const POPromisses = [];
  purchaseOrderIds.forEach((poId) => {
    POPromisses.push(
      getDoc(
        doc(
          firestore,
          `${dbTables.COMPANIES}/${companyId}/${dbTables.PURCHASE_ORDERS}/${poId}`
        )
      )
    );
  });
  const purchaseOrdersSnapDB = await Promise.all(POPromisses);
  let purchaseOrdersDB = purchaseOrdersSnapDB.map((doc) => doc.data());
  purchaseOrdersDB = purchaseOrdersDB.filter((po) => {
    if (!po) return false;
    const hasPermission = verifyPermission({
      user: user,
      permissionToCheck: [po.factoryId, GENERAL_PERMISSION_VALUE.ALL_VENDORS],
    });
    return hasPermission;
  });
  return purchaseOrdersDB;
}

export const getEmailDataTaksCompleted = ({
  notification,
  users,
  currentUser,
}) => {
  const {
    completedBy,
    assignedTo,
    description,
    SONumber,
    customerName,
    isAbleToModifyTaskDueDate,
    confirmedTask,
    PONumber,
    factoryName,
    shipmentNumber,
    shipment = {},
    salesOrderId,
    purchaseOrderId,
    scope,
    shipmentId,
  } = notification;
  console.log(notification, "-------");
  return {
    completedBy: getUserDisplayName(users, completedBy),
    assignedTo: getUserDisplayName(users, assignedTo),
    completedBySameUser: assignedTo === completedBy,
    description,
    SONumber,
    customerName,
    dayOffset: getDayOffset(notification),
    isAbleToModifyTaskDueDate,
    confirmedTask,
    PONumber,
    factoryName,
    shipmentNumber,
    finalDestination: shipment.finalDestination,
    salesOrderId,
    purchaseOrderId,
    shipmentId: shipmentId,
    scope,
    taskId: notification.mainDocumentId,
    email: currentUser.email,
  };
};
export const sendCompletedTaskEmail = ({
  notification,
  currentUser,
  users,
  type,
}) => {
  const isAbleToSendEmail =
    type === TYPE_NOTIFICATION.COMPLETE_LATE
      ? currentUser.lateTaskEmail
      : currentUser.earlyTaskEmail;
  if (isAbleToSendEmail) {
    const data = getEmailDataTaksCompleted({
      notification,
      users,
      currentUser,
    });
    const functions = getFunctions();
    const callableEndpoint = httpsCallableFromURL(
      functions,
      getFunctionByName({
        name: "usernotifications",
        env: globalEnvironment,
        params: `/sendCompletedTaskEmail`,
      })
    );
    try {
      callableEndpoint({
        ...data,
        email: currentUser.email,
      });
    } catch (error) {
      console.log({ error });
    }
  }
};

export const stringLowerCase = (data = "") => data.toLowerCase();

export const getMaxDate = (typeDate) => {
  const limitDatebyType = ["Created Date"];
  return limitDatebyType.includes(typeDate) ? new Date() : "";
};

export const getScreenListGrid = ({ grid, filtersActive = [] }) => {
  const currentFilters = filtersActive.filter((filter) => filter);
  const repetFilterGrid =
    currentFilters.filter((filter) => filter).length === 0
      ? " "
      : ` repeat(${
          filtersActive.filter((filter) => filter).length
        }, minmax(0, 270px)) `;
  console.log(`${grid}${repetFilterGrid}minmax(0, 175px) 1fr`);
  return `${grid}${repetFilterGrid}minmax(0, 175px) 1fr`;
};
