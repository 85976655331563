import React from "react";
import { NavLink } from "react-router-dom";
import userTypes from "../../api/types/userTypes";
import TagsModal from "../../components/Modal/TagsModal";
import TooltipTD from "../../components/Tooltip/TooltipTD";
import {
  getProgressValue,
  getDashboardSearchPath,
  getTagsLabel,
  sortObjectsBy,
  verifyVendorIcon,
} from "../../helpers/helpers";

import FactoryIcon from "../../assets/flag-icons/sidebar-factories.svg";
import { dbTables } from "../../api/types/dbTables";
import {
  GENERAL_PERMISSION_VALUE,
  SALES_ORDER_STATUS,
} from "../../helpers/constants";
import { colors } from "../../assets/jss/variables";
import { getTagStatus } from "../../helpers/salesOrder";
import { checkSpecificPermissions } from "../../hooks/permissions";
import HiddenDataIconAnimated from "../../helpers/iconAnimation/HiddenDataIconAnimated";
import { ORDER_DASHBOARD_ACTUAL_VIEWS } from "../../components/PurchaseOrderDashboard/sections/helpers";
import { CircularStaticProgress } from "../../components/CircularProgress/CircularStaticProgress";
import { AnimatedListStyled } from "../StyledComponent/AnimatedOpenList";
import ThreePanelButton from "../../components/Buttons/ThreePanelButton";
import SalesOrderBadge from "../../components/Badge/SalesOrderBadge";

function SalesOrdersItem({
  item,
  user,
  onUpdateItem,
  isTradeDashEmployee,
  filters,
  poProgress,
  poDiamonds,
  tags,
  isAllowed,
  salesOrderSetup = {},
  onChangeSalesOrderSetup = () => {},
  iconStyle = {},
  query = "",
}) {
  const filterTag = filters.tag || [];
  const salesOrders =
    item.salesOrders ||
    []
      .filter(
        () =>
          user.role === userTypes.SUPER_ADMIN ||
          user.role === userTypes.TRADEDASH_EMPLOYEE ||
          checkSpecificPermissions({
            userPermissions: user.permissions,
            permissionToCheck: [
              item.customerId,
              GENERAL_PERMISSION_VALUE.ALL_CUSTOMERS,
            ],
          })
      )
      .sort(sortObjectsBy("number", false));

  let hasTag = false;
  if (
    !(salesOrderSetup[item.id] && salesOrderSetup[item.id].SOs) ||
    salesOrders.length <= 1
  ) {
    salesOrders.forEach((so) => {
      const soTags = so.tags || [];
      hasTag = soTags.some((tag) => filterTag.includes(tag)) || hasTag;
    });
  }

  const isOpen =
    (salesOrderSetup[item.id] && salesOrderSetup[item.id].SOs) ||
    salesOrders.length <= 1 ||
    query;

  const showIconDisclouser = () => {
    if (salesOrders.length > 0) {
      return (
        <div
          className="iconAnimated"
          style={{
            opacity: !isOpen ? 1 : 0,
            zIndex: !isOpen ? 1 : 0,
            ...iconStyle,
          }}
        >
          {HiddenDataIconAnimated({
            styles: {
              cursor: "pointer",
              display: "flex",
            },
            color: hasTag ? colors.diamondRed : "",
            onClick: () => {
              onChangeSalesOrderSetup({
                ...salesOrderSetup,
                [item.id]: {
                  ...salesOrderSetup[item.id],
                  POs: !salesOrderSetup[item.id]
                    ? true
                    : !salesOrderSetup[item.id].POs,
                },
              });
            },
          })}

          <span style={{ marginLeft: 6, fontWeight: 500 }}>
            {salesOrders.length}
          </span>
        </div>
      );
    }
  };

  const showItems = () => {
    return salesOrders.map((salesOrder, index) => {
      const purchaseOrderIds = item.poIds || salesOrder.purchaseOrderIds || [];
      const purchaseOrders = item.purchaseOrders || [];
      const firstPOId = purchaseOrderIds.find((id) =>
        purchaseOrders.find((po) => po.id === id)
      );
      const showIconVendor = verifyVendorIcon(filters, salesOrder);
      const salesOrderTag = salesOrder.tags || [];
      const shipmentId =
        item.poShipmentId !== undefined ? item.poShipmentId : item.id;
      return (
        <div
          key={salesOrder.id + index}
          style={{
            display: "flex",
          }}
          className="orderList"
        >
          <div
            className="po-progress-diamonds-container"
            style={!poProgress && !poDiamonds ? { width: "0%" } : {}}
          >
            {poProgress && salesOrder.status !== SALES_ORDER_STATUS.VOIDED && (
              <div className="progress-container">
                <CircularStaticProgress
                  label={`${salesOrder.completedTasks}/${salesOrder.totalTasks} Tasks`}
                  value={+getProgressValue(salesOrder)}
                  thickness={16}
                  style={{
                    transform: "rotate(270deg)",
                    color: "#39F",
                  }}
                  size={15}
                />
              </div>
            )}
          </div>
          <div
            style={{
              width: !poProgress && !poDiamonds ? "100%" : "75%",
              display: "flex",
              justifyContent: " space-between",
            }}
          >
            <div className={"number-table-container"}>
              {isAllowed("see_tags") && (
                <TagsModal
                  key={salesOrder.id}
                  label={getTagsLabel(tags, salesOrderTag).tagsString}
                  tags={getTagsLabel(tags, salesOrderTag).tagsArray}
                  allTags={tags}
                  item={salesOrder}
                  currentUser={user}
                  tagReference={dbTables.SALES_ORDERS}
                  onUpdateItem={onUpdateItem}
                  isThereItemRef={false}
                  isReadOnly={isTradeDashEmployee(user)}
                  status={getTagStatus({
                    tags: salesOrderTag,
                    currentTags: filterTag,
                  })}
                />
              )}

              {showIconVendor && (
                <TooltipTD label={salesOrder.factoryName}>
                  <img
                    src={FactoryIcon}
                    alt={"FactoryIcon"}
                    style={{
                      width: 11,
                      marginRight: 5,
                      marginBottom: 5,
                    }}
                  />
                </TooltipTD>
              )}

              <NavLink
                to={getDashboardSearchPath({
                  salesOrderId: salesOrder.id,
                  purchaseOrderId: firstPOId,
                  shipmentId: shipmentId,
                  section: ORDER_DASHBOARD_ACTUAL_VIEWS.SALES_ORDER,
                })}
                id="list-view-content-salesorder"
                className={"navLink"}
                style={{
                  textDecoration: "none",
                }}
              >
                <SalesOrderBadge number={salesOrder.number} />
              </NavLink>
            </div>
            <ThreePanelButton
              soId={salesOrder.id}
              poId={firstPOId}
              shipmentId={shipmentId}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <AnimatedListStyled
      className="listContainer"
      style={{
        height: isOpen
          ? salesOrders.length * 22.1 + (salesOrders.length - 1) * 5
          : 24,
      }}
    >
      <div
        className="displayItems"
        style={{
          opacity: isOpen ? 1 : 0,
          zIndex: isOpen ? 1 : 0,
        }}
      >
        {showItems()}
      </div>
      {showIconDisclouser()}
    </AnimatedListStyled>
  );
}

export default SalesOrdersItem;
