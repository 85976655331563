import React, { useState } from "react";
import { uniq } from "lodash";
import { dbTables } from "../../api/types/dbTables";
import CustomModal from "./Modal";
import LoadingBackdrop from "../WholeScreenFocusBackdrop/LoadingBackdrop";
import { firestore } from "../../firebase";
import Shipment from "../../api/model/Shipment";
import { useEffect } from "react";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import NewShipmentInfo from "../PurchaseOrderDashboard/NewShipmentInfo";
import { CLIENT_IS_OFFLINE } from "../../actions/types";
import { useDispatch } from "react-redux";

function NewShipmentModal({
  openModal = false,
  companyId = [],
  currentShipment,
  currentPurchaseOrder,
  currentSalesOrder,
  itemRefToUpdate,
  setItemRefToUpdate = () => {},
  onChangeShipment = () => {},
  handleClose = () => {},
  onChangeData = () => {},
  customers,
  updateCurrentShimpent,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [newShipmentNumber, setNewShipmentNumber] = useState(1000);
  const dispatch = useDispatch();
  const shipmentsRef = query(
    collection(
      firestore,
      `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}`
    ),
    orderBy("number", "desc"),
    limit(1)
  );

  useEffect(() => {
    const getLastNumber = async () => {
      try {
        const shipments = await getDocs(shipmentsRef);
        const [lastShipment] = shipments.docs.map((shipment) => ({
          ...shipment.data(),
        }));
        if (lastShipment) {
          const number = parseInt(lastShipment.number) + 1;
          setNewShipmentNumber(number);
        }
      } catch (error) {
        console.log("ERROR RETRIVING SHIPMENTS:NewShihpmentModal ", error);
        dispatch({
          type: CLIENT_IS_OFFLINE,
          payload: {
            value: true,
            text: "Please wait a moment and try again",
          },
        });
      }
      setIsLoading(false);
    };
    if (openModal) {
      getLastNumber();
    } else {
      setIsLoading(false);
    }
  }, [openModal]);
  return (
    <CustomModal
      isOpen={openModal}
      hiddenHeader
      onClose={() => {
        if (isLoading) {
          return;
        }
        setItemRefToUpdate({
          shipment: null,
          item: null,
          salesOrderIndex: null,
        });
        handleClose();
      }}
      className="new-shipment-modal"
    >
      {isLoading && <LoadingBackdrop withLogo={true} />}
      <NewShipmentInfo
        companyId={companyId}
        currentShipment={currentShipment}
        currentNumber={newShipmentNumber}
        isReadOnly={false}
        isLoading={isLoading}
        onSave={async () => {
          let currentNumber = newShipmentNumber;
          setIsLoading(true);
          const shipments = await getDocs(shipmentsRef);
          const [lastShipment] = shipments.docs.map((shipment) => ({
            ...shipment.data(),
          }));
          if (lastShipment) {
            const number = parseInt(lastShipment.number) + 1;
            setNewShipmentNumber(number);
            currentNumber = number;
          }
          const { itemRef, rowRef, parentRowRef, referenceRef } =
            itemRefToUpdate;
          const salesOrderIds = itemRef.salesOrderIds || [];
          const newShipmentData = {
            ...new Shipment({
              ...currentShipment,
              number: currentNumber,
              purchaseOrderIds: [currentPurchaseOrder.id],
              mainSalesOrderId: currentSalesOrder.id,
              mainPurchaseOrderId: parentRowRef.id,
              salesOrderIds: uniq([parentRowRef.id, ...salesOrderIds]),
            }),
          };
          const [rowImage = ""] = itemRef.image;
          const shipmentItem = {
            id: itemRef.id,
            itemNumber: itemRef.itemNumber,
            description: itemRef.description,
            cbmMaster: itemRef.cbmMaster,
            piecesPerMaster: itemRef.piecesPerMaster,
            unitCost: itemRef.unitCost,
            shipmentQuantity: { [currentShipment.id]: rowRef.allocation },
            currentPurchaseOrderId: currentPurchaseOrder.id,
            image: [rowImage] || [],
            weight: parseFloat(itemRef.weight) || 0,
          };
          const ref = doc(
            firestore,
            `${dbTables.COMPANIES}/${companyId}/${dbTables.SHIPMENTS}/${currentShipment.id}`
          );
          try {
            await setDoc(ref, {
              ...newShipmentData,
              items: [shipmentItem],
            });
          } catch (error) {
            setIsLoading(false);
            return console.log(error);
          }

          const POShipments = [...currentPurchaseOrder.shipmentIds];
          POShipments.push(currentShipment.id);
          updateDoc(currentPurchaseOrder.ref, {
            shipmentIds: POShipments,
          });

          onChangeShipment({ ...newShipmentData });
          if (!itemRef || !parentRowRef || referenceRef === null) {
            setIsLoading(false);
            handleClose();
            return console.log("NO REFERENCE.");
          }

          const rowToUpdate = {
            ...rowRef,
            id: currentShipment.id,
            number: newShipmentData.number,
            customerId: newShipmentData.customerId,
            prevId: rowRef.id,
          };
          onChangeData({
            item: itemRef,
            row: rowToUpdate,
            parentRow: parentRowRef,
            reference: referenceRef,
            shipmentReference: {
              ...newShipmentData,
              items: [shipmentItem],
              ref,
            },
            blockRedirect: true,
          });
          setIsLoading(false);
          handleClose();
        }}
        customers={customers}
        onClose={handleClose}
        onUpdate={updateCurrentShimpent}
      />
    </CustomModal>
  );
}

export default NewShipmentModal;
